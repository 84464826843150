import React, { useCallback, useEffect, useState } from "react";
import noimage from "../../assets/noimage.png";

export default ({
  src,
  placeholderImg,
  errorImg = noimage,
  className,
  ...props
}) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);
  const [blur, setBlur] = useState("blur");
  const onLoad = useCallback(() => {
    setSrc(src);
    setBlur("");
  }, [src]);
  const onError = useCallback(() => {
    setSrc(errorImg || placeholderImg);
    setBlur("");
  }, [errorImg, placeholderImg]);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad, onError]);
  return (
    <img
      className={`${className} ${blur}`}
      {...props}
      alt={imgSrc}
      src={imgSrc}
    />
  );
};
