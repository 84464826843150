import { Modal, Button, Menu } from "antd";
import { useState, useEffect } from "react";
import * as Components from "..";
import { owntoast } from "../../utils";
import Formatter from "../../utils/data_formatter";
import { CloseOutlined } from "@ant-design/icons";

const MASTER = [
  {
    title: "Порц",
    data: "resbranchportion",
    url: "/branch/portion",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Порцын нэр",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
  {
    title: "Материал",
    data: "resbranchmaterial",
    url: "/branch/material",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Материалын нэр",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
  {
    title: "Нэмэлт",
    data: "resbranchspice",
    url: "/branch/spice",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Нэмэлтийн нэр",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
  {
    title: "Хоолны карт",
    data: "resbranchcard",
    url: "/branch/card",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Хоолны картын нэр",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
  {
    title: "Хоол",
    data: "resbranchproduct",
    url: "/branch/product",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Хоолны нэр",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Нэгж үнэ",
        dataIndex: "sprice",
        key: "sprice",
        width: 100,
        align: "right",
        render: (value) => <Formatter value={value} format="price" />,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
  {
    title: "Сэт хоол",
    data: "resbranchsetfood",
    url: "/branch/set",
    column: [
      {
        title: "№",
        dataIndex: "no",
        key: "no",
        width: 60,
        align: "center",
        render: (value, row, index) => index + 1,
      },
      {
        title: "Сэтын нэр",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Бүртгэсэн огноо",
        dataIndex: "insymd",
        key: "insymd",
        width: 100,
        align: "center",
        render: (value) => <Formatter value={value} format="date" />,
      },
    ],
  },
];

const MasterRegister = (props) => {
  const [name, setName] = useState(null);
  const [columns, setColumns] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [curUrl, setcurUrl] = useState("");
  const [msg, setMsg] = useState("");
  const { SubMenu } = Menu;
  const { isOpen, data, setIsOpen } = props;
  useEffect(() => {
    if (data && props.isOpen) {
      setName(data.name);
      props.request({ url: "/branch/unit/" + data.branchid }).then((res) => {
        if (res.success) {
          let unitList = [];
          res.data.mainFood.map((item) => {
            unitList.push(item.id);
          });
          props
            .request({
              url: "/branch/unit/" + data.branchid,
              method: "POST",
              body: unitList,
            })
            .then((res) => {
              if (res.success) {
                owntoast(res.message, "success");
              } else {
                owntoast(res.message, "error");
              }
            });
        } else {
          // owntoast(res.message, "error");
        }
      });
    }
  }, [props.isOpen]);

  const handleSubmit = () => {
    props
      .request({
        url: curUrl + "/" + data.branchid,
        method: "POST",
        body: selectedRows.keys,
      })
      .then((res) => {
        if (res.success) {
          owntoast(res.message, "success");
          if (res.data && res.data.length > 0) {
            setMsg(res.data);
            setIsOpenWarning(true);
          }
          handleGetData(curUrl);
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const handleGetData = (url) => {
    setcurUrl(url);
    // setSelectedRows([]);
    props.request({ url: url + "/" + data.branchid }).then((res) => {
      if (res.success) {
        setMainData(res.data.mainFood);
        setBranchData(res.data.branchFood);
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  return (
    <Components.CustomModal
      width="90%"
      isOpen={isOpen}
      title="Мастер бүртгэл татах"
      onClose={() => setIsOpen(false)}
      footer={null}
      bodyStyle={{
        height: window.innerHeight * 0.7,
      }}
    >
      <div className="grid grid-cols-5 gap-5 px-4 h-full w-full overflow-auto py-2 rounded-b-lg">
        <div className="col-span-1 master-step">
          <Menu
            mode="inline"
            defaultSelectedKeys={["0"]}
            defaultOpenKeys={["0"]}
            className="rounded-xl"
          >
            <SubMenu
              key={0}
              title={"Лавлах бүртгэлүүд"}
              className="shadow drop-shadow-sm "
            >
              {MASTER &&
                MASTER.map((item, i) => (
                  <Menu.Item
                    key={i}
                    title={item.title}
                    onClick={() => {
                      handleGetData(item.url);
                      setColumns(item.column);
                    }}
                  >
                    {item.title}
                  </Menu.Item>
                ))}
            </SubMenu>
          </Menu>
        </div>
        <div className="text-center col-span-2">
          <p className="font-bold text-lg text-black">Үндсэн салбар</p>
          <Components.AntdTable
            columns={columns}
            dataSource={mainData}
            isPagination={false}
            pagination={true}
            isModal={true}
            isSelectRow={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isBranch={true}
          />
          <div className="justify-end flex w-full mt-4">
            <Button
              type="primary"
              className="bg-primary"
              onClick={handleSubmit}
              autoFocus
              htmlType="submit"
            >
              Татаж хадгалах
            </Button>
          </div>
        </div>
        <div className="text-center col-span-2">
          <p className="font-bold text-lg text-black">{name} салбар</p>
          <Components.AntdTable
            columns={columns}
            dataSource={branchData}
            isModal={true}
            isPagination={false}
            isBranch={true}
            pagination={true}
          />
        </div>
      </div>
      <Components.CustomModal
        footer={[<Button onClick={() => setIsOpenWarning(false)}>Хаах</Button>]}
        isOpen={isOpenWarning}
        title="Анхааруулга"
        onClose={() => setIsOpenWarning(false)}
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          {msg &&
            msg.map((item, i) => {
              return (
                <span>
                  {i + 1}. {item}
                </span>
              );
            })}
        </div>
      </Components.CustomModal>
    </Components.CustomModal>
  );
};

export default MasterRegister;
