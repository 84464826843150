import * as Components from "../";
import { Button, Popconfirm } from "antd";

const Index = (props) => {
  return (
    <Components.CustomModal
      footer={null}
      isOpen={props?.isOpen}
      onClose={() => props?.setIsOpen?.(false)}
      title={"Хүргэлтийн хаяг"}
    >
      <div className="p-3 flex flex-col">
        <span>{props?.data?.location}</span>
        <span>{props?.data?.address}</span>
        <div className="flex items-center justify-center mt-2">
          <Button
            className="bg-grey text-white"
            onClick={() => props?.setIsOpen?.(false)}
          >
            Хаах
          </Button>
        </div>
      </div>
    </Components.CustomModal>
  );
};

export default Index;
