import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import zaal4 from "../../assets/zaal4.png";
import gal_togoo4 from "../../assets/gal_togoo4.png";
import menu4 from "../../assets/menu4.png";
import report4 from "../../assets/report4.png";
import settings5 from "../../assets/settings5.png";
import doc from "../../assets/icons/doc.svg";
import time from "../../assets/icons/time.svg";
import snowCap from "../../assets/snow-cap.png";
import { MAIN_MENU } from "../../data";
import { Layout } from "antd";
import dayjs from "dayjs";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { isNewYear } from "../../utils";

function Home(props) {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Нүүр хуудас";
  }, []);

  const navigateWindow = ({ route, permission, type, license }) => {
    if (permission) {
      let result = UsefulFunctions.checkPermission({
        pagePermission: permission,
        license: license,
        reslicense: props.reslicense,
        permission: props._auth.permission,
        isMsg: true,
        setError: props.setError,
      });
      if (result) {
        props.request({ url: `/user/touchaccess/${type}`, method: "PUT" });
        navigate(route);
      }
    } else {
      if (!license) {
        props.request({ url: `/user/touchaccess/${type}`, method: "PUT" });
        navigate(route);
      } else {
        let result = UsefulFunctions.checkPermission({
          pagePermission: permission,
          license: license,
          reslicense: props.reslicense,
          permission: props._auth.permission,
          isMsg: true,
          setError: props.setError,
        });
        if (result) {
          props.request({ url: `/user/touchaccess/${type}`, method: "PUT" });
          navigate(route);
        }
      }
    }
  };

  const checkTouchAccess = (item) => {
    let values = {};
    if (props.restouchaccess) {
      values = props.restouchaccess.find((x) => x.window === item.type);
    }
    return (
      <div className="w-60 px-5 pb-2">
        <div className="text-primary font-semibold py-2">{item.title}</div>
        <div className="flex justify-between flex-col">
          <div className="flex items-center">
            <img src={doc} alt="" className="w-4 h-4 mr-2" />
            {props.istouchaccess ? (
              <div className="h-2.5 bg-gray-200 rounded-full animate-pulse w-full" />
            ) : (
              <span className="text-xs text-grey">
                {values
                  ? `${values.username} - ${
                      values.rolename ? values.rolename.replace("_", " ") : ""
                    }`
                  : "-"}
              </span>
            )}
          </div>
          <div className="flex items-center mt-2">
            <img src={time} alt="" className="w-4 h-4 mr-2" />
            {props.istouchaccess ? (
              <div className="h-2.5 bg-gray-200 rounded-full animate-pulse w-full" />
            ) : (
              <span className="text-xs text-grey">
                {values && values.logymd
                  ? `${calculateTime(values.logymd)} / сүүлд нэвтэрсэн`
                  : "-"}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  const DATE_FORMAT = "YYYY-MM-DD HH-mm-ss";
  const calculateTime = (logymd) => {
    if (logymd) {
      let now = dayjs().format(DATE_FORMAT);
      let duration = dayjs.duration(
        dayjs(now, DATE_FORMAT).diff(dayjs(logymd, DATE_FORMAT))
      );

      if (duration.asMinutes() < 1) {
        return `${0} мин`;
      } else if (duration.asMinutes() < 60) {
        return `${Math.round(duration.asMinutes())} мин`;
      } else if (duration.asHours() < 60) {
        return `${Math.round(duration.asHours())} цаг`;
      } else if (duration.asDays() <= dayjs(now, "YYYY-MM").daysInMonth()) {
        return `${Math.round(duration.asDays())} өдөр`;
      } else if (duration.asMonths() < 12) {
        return `${Math.round(duration.asMonths())} сар`;
      }

      return `${Math.round(duration.asYears())} жил`;
    }
    return null;
  };

  return (
    <Layout className="items-center justify-center h-full">
      <div className="w-full p-5 flex items-center justify-center overflow-auto flex-wrap  gap-6">
        {MAIN_MENU({ ...props }).map((item, i) => (
          <div
            className="rounded-t-2xl relative"
            key={i}
            onClick={() => {
              navigateWindow({
                route: item.route,
                permission: item.permission,
                license: item.license,
                type: item.type,
              });
            }}
          >
            <div
              className="relative cursor-pointer drop-shadow-xl p-5 rounded-lg flex justify-center"
              /*  style={{ backgroundColor: "rgba(198, 32, 49, 0.4)" }} */
            >
              <img
                src={
                  item.img === "zaal"
                    ? zaal4
                    : item.img === "menu"
                    ? menu4
                    : item.img === "report"
                    ? report4
                    : item.img === "kitchen"
                    ? gal_togoo4
                    : settings5
                }
                alt=""
                className="rounded-t-2xl w-48"
              />
              <div className="absolute bottom-0 w-full px-2 flex justify-center">
                {isNewYear() ? (
                  <img
                    src={snowCap}
                    alt="snowCap"
                    className="w-full absolute h-fit z-20 -top-4"
                  />
                ) : null}

                <div className="w-full p-2 text-xl font-bold text-black uppercase bg-white rounded-t-2xl text-center opacity-70">
                  {item.name}
                </div>
              </div>
            </div>
            {checkTouchAccess(item)}
          </div>
        ))}
        {/* <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 h-full">
          
        </div> */}
      </div>
    </Layout>
  );
}
export default Home;
