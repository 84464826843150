import React, { useRef, useState } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Group,
  Text,
} from "react-konva";
import { TableFloors, TABLES } from "../../data";
import ResizeObserver from "resize-observer-polyfill";
import Konva from "konva";

const Anchor = ({ x, y, onDragMove }) => {
  const handleDragMove = (e) => {
    onDragMove(e);
  };

  return (
    <Rect
      x={x}
      y={y}
      width={10}
      height={10}
      draggable
      fill="red"
      onDragMove={handleDragMove}
      dragOnTop={false}
      onMouseOver={(e) => {
        document.body.style.cursor = "nwse-resize";
      }}
      onMouseOut={(e) => {
        document.body.style.cursor = "default";
      }}
    />
  );
};

const URLImage = ({
  table,
  setTables,
  tables,
  isEdit,
  onClickTable,
  onClickTableX,
  isLicence,
}) => {
  const imgRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [position, setPosition] = useState({
    x: table.x ? Number(table.x) : 10,
    y: table.y ? Number(table.y) : 10,
  });
  const [borderColor, setBorderColor] = useState("black");

  React.useEffect(() => {
    const img = new window.Image();
    img.src = TABLES[table?.tableimage]?.img;
    img.addEventListener("load", () => {
      setImage(img);
      const imgNode = imgRef.current;
      setImageSize({
        width: getStringToNumber(table?.width, 100),
        height: getStringToNumber(table?.height, 100),
      });
      if (table?.orderid > 0 && table?.isorder && !isEdit) {
        imgNode.filters([Konva.Filters.RGB]);
        imgNode.red(255);
        imgNode.green(255);
        imgNode.blue(255);
        imgNode.alpha(1);
        imgNode.cache();
      }
    });
  }, [table?.tableimage]);

  React.useEffect(() => {
    let found = tables.findIndex((x) => x.id === table?.id);
    if (found > -1) {
      const updatedImg = {
        ...table,
        width: imageSize.width,
        height: imageSize.height,
        x: position.x,
        y: position.y,
      };
      const newImages = [
        ...tables.slice(0, found),
        updatedImg,
        ...tables.slice(found + 1),
      ];
      setTables(newImages);
    }
  }, [imageSize, position]);

  const getStringToNumber = (string, defaultValue) => {
    if (string) return Number(string);
    return defaultValue;
  };

  const handleAnchorDragMove = (e) => {
    const anchor = e.target;

    const newX = anchor.getAbsolutePosition().x;
    const newY = anchor.getAbsolutePosition().y;

    const aspectRatio = imageSize.width / imageSize.height;

    let newWidth = newX - position.x;
    let newHeight = newY - position.y;

    if (newWidth / newHeight > aspectRatio) {
      newWidth = newHeight * aspectRatio;
    } else {
      newHeight = newWidth / aspectRatio;
    }

    if (newWidth > 100 && newHeight > 100) {
      setImageSize({ width: newWidth, height: newHeight });
    }
  };

  const handleImageDragMove = (e) => {
    if (isEdit) {
      setPosition(e.target.position());
    }
  };

  const getText = () => {
    let text = table?.name;
    if (table?.orderid > 0 && table?.isorder) {
      text = `${text} \n №:${table?.salenum}`;
    } else if (!table.isenable) {
      text = `${text} \n Идэвхигүй`;
    }
    return `${text} ${
      table?.price && isLicence
        ? `\n${new Intl.NumberFormat().format(table.price)}₮`
        : ""
    }`;
  };

  return (
    <React.Fragment>
      <Group
        draggable={isEdit}
        onClick={() => {
          if (onClickTable) onClickTable({ ...table });
        }}
        onTap={() => {
          if (onClickTable) onClickTable({ ...table });
        }}
        onDragMove={handleImageDragMove}
        onMouseEnter={(e) => {
          document.body.style.cursor = "grab";
          setBorderColor("blue");
        }}
        onMouseLeave={(e) => {
          document.body.style.cursor = "default";
          setBorderColor("black");
        }}
        onDragStart={(e) => {
          if (isEdit) document.body.style.cursor = "grabbing";
        }}
        onDragEnd={(e) => {
          document.body.style.cursor = "default";
        }}
        x={position.x}
        y={position.y}
      >
        <KonvaImage
          image={image}
          width={imageSize.width}
          height={imageSize.height}
          ref={imgRef}
        />
        {isEdit && (
          <Rect
            width={imageSize.width}
            height={imageSize.height}
            stroke={borderColor}
            strokeWidth={2}
          />
        )}

        <Text
          text={getText()}
          x={0}
          fill="white"
          y={0} //{imageSize.height / 2}
          verticalAlign="middle"
          width={imageSize.width}
          height={imageSize.height}
          fontSize={14}
          align="center"
        />
        {/*  {table?.orderid > 0 && table?.isorder && (
          <Text
            text={`№: ${table?.orderid}`}
            x={0}
            fill="white"
            y={imageSize.height / 2.8}
            width={imageSize.width}
            fontSize={14}
            align="center"
          />
        )} */}
      </Group>
      {isEdit && (
        <Anchor
          x={imageSize.width + position.x - 5}
          y={imageSize.height + position.y - 5}
          onDragMove={handleAnchorDragMove}
        />
      )}

      {isEdit && (
        <Rect
          x={position.x + imageSize.width - 7}
          y={position.y - 7}
          width={15}
          onClick={() => onClickTableX && onClickTableX({ ...table })}
          height={15}
          fill="black"
        />
      )}
    </React.Fragment>
  );
};

const CanvasWithImage = ({
  data,
  isEdit,
  width,
  height,
  containerRef,
  floorImage,
  setData,
  onClickTable,
  onClickTableX,
  IsDraggable,
  isLicence,
}) => {
  const [stageSize, setStageSize] = useState({
    width: width ? width : containerRef?.current?.offsetWidth,
    height: height ? height : containerRef?.current?.offsetHeight,
  });

  const [scale, setScale] = useState({ x: 1, y: 1 });

  React.useEffect(() => {
    handleResize();
  }, [data]);

  const handleResize = () => {
    const container = containerRef.current;
    var containerWidth = container.offsetWidth;
    var scale = containerWidth / stageSize.width;
    setStageSize({
      width: stageSize.width * scale,
      height: stageSize.height * scale,
    });
    setScale({
      x: scale,
      y: scale,
    });
  };

  React.useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        handleResize();
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateSize();

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full h-full relative">
      <Stage
        width={stageSize.width}
        height={stageSize.height - 50}
        scaleX={scale.x}
        scaleY={scale.y}
        style={{
          backgroundImage: `url(${TableFloors[floorImage]?.img})`,
          height: "100%",
          width: "100%",
        }}
      >
        <Layer>
          {data.map((img, i) => (
            <URLImage
              key={i}
              table={img}
              onClickTable={onClickTable}
              onClickTableX={onClickTableX}
              isLicence={isLicence}
              tables={data}
              draggable
              setTables={setData}
              isEdit={isEdit}
              // isEdit={IsDraggable === undefined ? true : false}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default CanvasWithImage;
