import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReceiptType, IsEnable } from "../../utils/enums";
import { SaleBill } from "../../components/SaleBill";
import { Form, Input, Button, Alert, Spin, Tooltip } from "antd";
import * as Components from "../../components";
import BraftEditor from "braft-editor";
import { useReactToPrint } from "react-to-print";
import loader from "../../assets/logos/loader.gif";
import AddModal from "./Modal";
import dayjs from "dayjs";
import { API_URL, owntoast } from "../../utils";
import UsefulFunctions from "../../utils/UsefulFunctions";

/* Шинэ эсвэл засах цонх бүгд ашиглах */
function AddOrEdit(props) {
  const { page } = props;
  const printRef = useRef();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [files, setFiles] = useState({});
  const [price, setPrice] = useState({});
  const [changed, setChanged] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [printData, setPrintData] = useState({});
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isPerson, setIsPerson] = useState(true);
  const [warningSms, setwarningSms] = useState("");
  const [isunitproduct, setisunitproduct] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgKiosk, setSelectedImgKiosk] = useState(null);
  const [disabled, setDisabled] = useState({});
  const { pathname } = window.location;
  /* const editData = useEditDataHook(props, page.model); */
  useEffect(() => {
    let permResult = true; //check();

    if (
      params["*"].includes("edit") &&
      searchParams.get("index") &&
      permResult
    ) {
      let edit = JSON.parse(localStorage.getItem("editRow"));
      if (edit) {
        let files = {};
        let spices = [];
        setPrintData({
          ...edit,
          type: ReceiptType.Карт,
          vatname: props._auth.vatname,
        });
        Object.keys(edit).map((keyname) => {
          if (keyname.includes("img") || keyname.includes("icon")) {
            files[`prev${keyname}`] = edit[keyname];
            // delete edit[keyname];
          }
          if (keyname.includes("ymd")) edit[keyname] = dayjs(edit[keyname]);
          if (keyname === "manual")
            edit[keyname] = BraftEditor.createEditorState(edit.manual);
          if (keyname === "spices") {
            edit[keyname].map((item) => {
              spices.push(item.spicesid);
            });
          }
          if (keyname === "hastax" || keyname === "isunit")
            edit[keyname] = edit[keyname]
              ? IsEnable.Идэвхтэй
              : IsEnable.Идэвхгүй;
          if (keyname === "isunit") {
            if (edit?.isunit) {
              setisunitproduct(true);
            }
          }
        });
        edit.spices = spices;
        edit.files = files;

        form.setFieldsValue({ ...edit });
        if (edit.files) setFiles(edit.files);
        setEditData(edit);
      }
    } else if (permResult && !page.islist) {
      /*  if (!page.initialValues || !page.initialValues.length) {
        page.initialValues = JSON.parse(localStorage.getItem("editRow"));
      } */
      setInitialValues(page.initialValues);
    }
    if (page && page.forms && page.forms.initRequests) {
      page.forms.initRequests.map((req) => {
        if (req.url.includes("$foodid")) {
          req.url = req.url.replace("$foodid", props._auth.foodid);
        }
        props.request({
          url: req.url,
          method: req.method ? req.method : "GET",
          model: req.model,
        });
      });
    }
  }, [form]);

  const setInitialValues = (initialValues) => {
    if (initialValues) {
      let files = {};
      Object.keys(initialValues).map((keyname) => {
        if (
          keyname.includes("banner") ||
          keyname.includes("logo") ||
          keyname.includes("img")
        ) {
          if (Array.isArray(initialValues[keyname])) {
            let tmp = [];
            initialValues[keyname]?.map((item, i) => {
              tmp.push({
                uid: i,
                name: item,
                status: "done",
                url: `${API_URL}${item}`,
              });
            });
            files[`${keyname}`] = tmp;
          } else {
            files[`prev${keyname}`] = initialValues[keyname];
          }

          // delete initialValues[keyname];
        }
        if (keyname.includes("artdaytime")) {
          initialValues[keyname] = dayjs(initialValues[keyname], "HH:mm");
        }
      });
      setSelectedImgKiosk(initialValues.takeout);
      setSelectedImg(initialValues.eat);
      initialValues.files = files;
      if (
        initialValues.iscompany === true ||
        initialValues.iscompany === false
      ) {
        setIsPerson(initialValues.iscompany);
      }

      form.setFieldsValue({ ...initialValues });
      if (initialValues.files) setFiles(initialValues.files);

      let disable = {};
      page?.forms?.leftForms?.form?.map((item) => {
        if (item?.setDisabled) {
          if (initialValues[item?.setDisabled] === null) {
            disable = { ...disable, [item.setDisabled]: true };
          } else {
            disable = { ...disable, [item.setDisabled]: false };
          }
        }
      });
      setDisabled(disable);
      setEditData(initialValues);
    }
  };
  const check = () => {
    return UsefulFunctions.checkPermission({
      license: page.license,
      reslicense: props.reslicense,
      pagePermission: page.permission,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  /*  useLayoutEffect(() => {
    return () => {
      
    };
  }, []); */

  /* Баруун эсвэл Зүүн талд хамаарах Формуудын зурах хэсэг */

  const getFormItem = (item) => {
    let disable = item.disabled;
    if (disabled[item.name] === true || disabled[item.name] === false) {
      return { ...item, disabled: disabled[item.name] };
    }

    if (page.initialValues && page.checkDisable) {
      if (page.initialValues[page.checkDisable]) {
        disable = true;
      }
    }

    return { ...item, disabled: disable };
  };

  const checkButtonDisabled = () => {
    if (page.initialValues && page.checkDisable) {
      if (page.initialValues[page.checkDisable]) {
        return true;
      }
    }
    return false;
  };

  const renderForms = (type) => {
    const { forms } = page;
    if (forms && forms[type]) {
      if (forms[type].Component) {
        var SubComponent = forms[type].Component;
        return (
          <SubComponent
            form={form}
            editData={editData}
            {...props}
            files={files}
            columns={forms[type].columns}
            name={forms[type].name}
            method={forms[type].method}
            url={forms[type].url}
            buttonText={forms[type].buttonText}
            isModal={forms[type].isModal}
            filterOption={forms[type].filterOption}
            price={price}
            setPrice={setPrice}
          />
        );
      }
      return (
        forms[type].form &&
        forms[type].form.map((item, i) => {
          if (item.name) {
            return (
              <Components.FormItems
                props={props}
                item={getFormItem(item)}
                form={form}
                key={i}
                initialValues
                files={files}
                setFiles={setFiles}
                setChanged={setChanged}
                setDisabled={setDisabled}
                disabled={disabled}
                changed={changed}
                editData={editData}
                setEditData={setEditData}
                sendDataRefresh={sendDataRefresh}
                setPrice={setPrice}
                isPerson={isPerson}
                setIsPerson={setIsPerson}
                setisunitproduct={setisunitproduct}
                isunitproduct={isunitproduct}
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
                selectedImgKiosk={selectedImgKiosk}
                setSelectedImgKiosk={setSelectedImgKiosk}
              />
            );
          }
          return null;
        })
      );
    }
  };
  const handleModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const handleSendArtlab = () => {
    props.request({ url: `/artlab/send`, method: "POST" }).then((res) => {
      if (res.success) {
        owntoast(res.message, "success");
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  const renderSubmitBtn = () => {
    let btnDisabled = checkButtonDisabled();
    return (
      <div
        className={forms.ishorizontal && "col-span-4"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title={btnDisabled ? "Холболт хийгдсэн байна." : ""}>
          <Button
            type="primary"
            loading={loading}
            className="w-fit bg-primary"
            disabled={btnDisabled}
            htmlType="submit"
            style={{ float: "right" }}
          >
            Хадгалах
          </Button>
        </Tooltip>

        {forms.hideBackBtn ? null : (
          <Button
            className="w-fit mr-2 ml-2"
            style={{ float: "right" }}
            onClick={() => navigate(-1)}
          >
            Буцах
          </Button>
        )}

        {forms.isPrint && editData && Object.keys(editData).length > 0 ? (
          <Button
            className="w-fit mr-2"
            style={{ float: "right" }}
            onClick={print}
          >
            Хэвлэх
          </Button>
        ) : null}
        {page?.changePassBtn && (
          <Button
            className="w-fit mr-2"
            onClick={handleModal}
            style={{ float: "right" }}
          >
            Нууц үг сэргээх
          </Button>
        )}
        {page?.editBtn && (
          <Button
            className="w-fit mr-2"
            onClick={handleModal}
            style={{ float: "right" }}
          >
            Засах
          </Button>
        )}
        {forms?.artlabBtn && (
          <Button
            className="w-fit mr-2"
            onClick={handleSendArtlab}
            style={{ float: "right" }}
          >
            Санхүү-д гүйлгээ илгээх
          </Button>
        )}
      </div>
    );
  };

  /* Хадгалаад амжилттай бол лист ийг дахин дуудах */
  const sendDataRefresh = () => {
    let searchValues = JSON.parse(localStorage.getItem(`search${page.url}`));
    if (!searchValues) {
      searchValues = { foodid: props._auth.foodid };
    }
    if (page.model === "productlist") {
      props.request({
        url: `/product/unitlist`,
        method: "POST",
        model: "unitproductlist",
        body: { foodid: props._auth.foodid },
      });
    }
    props.request({
      url: page.url,
      body: searchValues,
      method: page.method,
      model: page.model,
    });
  };

  const settlement = () => {
    props?.request({ url: "/system/golomtset" });
    if (props.resdealerinfoippos) {
      if (forms && forms.settlmentBtn && forms.bankid) {
        if (forms.bankid === 1) {
          setLoading(true);
          props
            .request({
              url: `/golomt/settlement`,
              body: {
                terminalid: props.resdealerinfoippos.terminalid,
                entryMode: 0,
              },
              method: "POST",
              isservice: true,
            })
            .then((res) => {
              setLoading(false);
              if (res.success) {
                owntoast(res.message, "success");
              } else {
                owntoast(res.message, "error");
              }
            });
        }
      }
    } else {
      owntoast("Ippos тохиргоо хийгдээгүй байна.", "error");
    }
  };
  /* Форм хадгалах */
  const handleSave = (values) => {
    try {
      values = { ...values, ...files };
      if (Array.isArray(values.img)) {
        let newImgList = [];
        values?.img?.map((item) => {
          if (item.originFileObj) {
            newImgList?.push(item.originFileObj);
          }
        });
        values.img = newImgList;
      }
      if (forms.url && forms.method && check()) {
        if (forms.checkArr) {
          if (!values[forms.checkArr] || !values[forms.checkArr].length) {
            setwarningSms(forms.error ? forms.error : "Алдаа");
            setIsOpenWarning(true);
            return;
          }
        }
        setLoading(true);
        values.foodid = props._auth.foodid;
        values.insby = props._auth.userid;
        if (values.foodsphone) {
          values.foodsphone = Number(values.foodsphone);
        }
        Object.keys(values).map((keyname) => {
          if (keyname.includes("dfield")) delete values[keyname];
          if (keyname.includes("ymd"))
            values[keyname] = dayjs(values[keyname]).format("YYYY-MM-DD");
          if (values[keyname] === null) values[keyname] = "";
          if (keyname === "manual")
            values[keyname] = values[keyname] ? values[keyname].toHTML() : "";
          if (keyname === "artdaytime")
            values[keyname] = values[keyname] = dayjs(values[keyname]).format(
              "HH:mm"
            );

          if (keyname === "eat") values[keyname] = selectedImg;
          if (keyname === "takeout") values[keyname] = selectedImgKiosk;
          if (keyname === "fee")
            values[keyname] = values[keyname] ? Number(values[keyname]) : 0;
          if (
            keyname === "hastax" ||
            keyname === "iskiosk" ||
            keyname === "isprodfooter" ||
            keyname === "isonly" ||
            keyname === "isunit"
          )
            values[keyname] = values[keyname]
              ? IsEnable.Идэвхтэй
              : IsEnable.Идэвхгүй;
        });
        props
          .request({
            url: forms.url,
            body: values,
            method: forms.method,
            isfile: forms.isFile,
          })
          .then((res) => {
            if (!forms?.nextUrl || !Object.keys(values.products).length > 0) {
              setLoading(false);
            }

            if (res.success) {
              if (forms.nextUrl) {
                if (Object.keys(values.products).length > 0) {
                  props
                    .request({
                      url: `${forms.nextUrl}/${res.data.id}`,
                      body: values.products,
                      method: forms.method,
                      isfile: forms.nextIsFile,
                    })
                    .then((res) => {
                      setLoading(false);
                      if (forms?.nextUrlMessage) {
                        if (res?.success) owntoast(res.message, "success");
                        else owntoast(res.message, "error");
                      }
                      if (forms.isPopup && !res.success) {
                        setwarningSms(res.message);
                        setIsOpenWarning(true);
                      } else {
                        if (forms.isRefresh && page.isList) {
                          sendDataRefresh();
                        }
                        //navigate(-1);
                      }
                    });
                }
              }
              if (forms.isClear) form.resetFields();
              if (forms.isMessage) {
                if (res.message === "Амжилттай")
                  owntoast(res.message, "success");
                else if (forms.isPopup) {
                  setwarningSms(res.message);
                  setIsOpenWarning(true);
                } else if (res.success) {
                  owntoast(res.message, "success");
                }
              }
              if (res.message === "Амжилттай" && !forms.nextUrl) {
                if (forms.isRefresh && page.isList) {
                  sendDataRefresh();
                  navigate(-1);
                } else {
                  if (page.url && page.model) {
                    props.request({ url: page.url, model: page.model });
                  }
                }
              } else {
                if (forms.isRefresh && page.isList) {
                  sendDataRefresh();
                  navigate(-1);
                }
              }
            } else {
              if (forms.isMessage) {
                if (res.message !== "Амжилтгүй" && forms.isPopup) {
                  setwarningSms(res.message);
                  setIsOpenWarning(true);
                } else {
                  owntoast(res.message, "error");
                }
              }
            }
          });
      } else {
        Object.keys(values).map((keyname) => {
          if (keyname.includes("dfield")) delete values[keyname];
          if (keyname.includes("ymd"))
            values[keyname] = dayjs(values[keyname]).format("YYYY-MM-DD");
          if (
            keyname === "hastax" ||
            keyname === "iskiosk" ||
            keyname === "isunit"
          )
            values[keyname] = values[keyname]
              ? IsEnable.Идэвхтэй
              : IsEnable.Идэвхгүй;
        });
      }
    } catch (message) {
      setLoading(false);
      if (forms.isMessage) owntoast(message, "error");
    }
  };

  const { forms } = page;
  return (
    <Spin
      spinning={loading}
      indicator={<img src={loader} alt="" className="w-16 h-10" />}
    >
      {forms &&
        forms.helps &&
        forms.helps.map((item, i) => (
          <Alert key={i} {...item} showIcon className="mb-5" />
        ))}
      <Form
        initialValues={{
          isenable: 1,
        }}
        form={form}
        name="baseForm"
        labelCol={{ flex: "10rem" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        layout="horizontal"
        onFinish={handleSave}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <div className="grid grid-cols-12 gap-x-0 md:gap-x-10 gap-y-5">
          {/* Баруун талд харагдах форм - ууд зурах */}
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-5 2xl:col-span-5">
            {renderForms("leftForms")}
            {forms && forms.noButton
              ? null
              : forms && !forms["rightForms"]
              ? renderSubmitBtn()
              : null}
            {form && forms.settlmentBtn && (
              <Button
                type="primary"
                loading={loading}
                className="w-fit bg-primary"
                onClick={settlement}
                style={{ float: "left" }}
              >
                Хаалт хийх
              </Button>
            )}
          </div>
          {/* Зүүн талд харагдах форм - ууд зурах */}
          <div
            className={`col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7${
              forms.ishorizontal && "flex grid grid-cols-4"
            }`}
          >
            {renderForms("rightForms")}
            {forms && forms["rightForms"] ? renderSubmitBtn() : null}
          </div>
          <div className="col-span-12">
            {forms && forms.BottomComponent ? (
              <forms.BottomComponent
                form={form}
                changed={changed}
                request={props.request}
                editData={editData}
              />
            ) : null}
          </div>
        </div>
      </Form>
      <Components.CustomModal
        footer={[
          <Button
            onClick={() => {
              setIsOpenWarning(false);
              if (forms.isBack) navigate(-1);
            }}
          >
            Хаах
          </Button>,
        ]}
        width={400}
        isOpen={isOpenWarning}
        onClose={() => {
          setIsOpenWarning(false);
          if (forms.isBack) navigate(-1);
        }}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          {warningSms}
        </div>
      </Components.CustomModal>
      <AddModal
        isOpen={isOpen}
        openModal={() => setIsOpen(true)}
        closeModal={closeModal}
        {...props}
      />
      <div className="hidden">
        <SaleBill ref={printRef} data={printData} />
      </div>
    </Spin>
  );
}

export default AddOrEdit;
