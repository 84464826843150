import React from "react";
import { createRoot } from "react-dom/client";
import locale from "antd/lib/locale/mn_MN";
import { ConfigProvider } from "antd";
import "./utils/momentLocale";
import "./styles/index.css";
import "braft-editor/dist/index.css";
import "./styles/antd.less";
import "react-image-crop/dist/ReactCrop.css";
import { App } from "./containers";
import { GlobalProvider } from "./context";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import dayjs from "dayjs";

const { search } = window.location;
if (new URLSearchParams(search).has("deviceType")) {
  localStorage.setItem(
    "deviceType",
    new URLSearchParams(search).get("deviceType")
  );
} else {
  localStorage.setItem("deviceType", null);
}

var duration = require("dayjs/plugin/duration");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
dayjs.extend(duration);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <GlobalProvider>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#C62031",
            buttonColor: "#C62031",
          },
        }}
      >
        {/* <OnlineStatusProvider> */}
        <App />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
            style: {
              fontFamily:
                "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
              fontSize: "14px",
            },
          }}
        />
        {/* </OnlineStatusProvider> */}
      </ConfigProvider>
    </GlobalProvider>
  </BrowserRouter>
);
serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
