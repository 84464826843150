import { useState, useEffect, useRef } from "react";
import { Select, Button, Form, Tooltip } from "antd";
import * as Components from "../../components";
import UsefulFunctions from "../../utils/UsefulFunctions";
import billIcon from "../../assets/icons/bill.png";
import {
  groupBy,
  owntoast,
  postMessageMobile,
  saveTime,
  useMobileEvent,
} from "../../utils";
import { useReactToPrint } from "react-to-print";
import {
  SaleType,
  ReceiptType,
  PaperSize,
  IsEnable,
  IsOwnerPerm,
  NLicenceType,
} from "../../utils/enums";
import { SaleBill } from "../SaleBill";
const { Option } = Select;
/* Захиалгийн нийт дүн болон төрлүүд */
function OrderPayment(props) {
  // const orderRef = useRef();
  const loaderRef = useRef(false);
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const [isOpenDivide, setIsOpenDivide] = useState(false);
  const { saleList, resordertypelist, order } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [activePaymentType, setPaymentType] = useState(1);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const sum = UsefulFunctions.getSum({
    saleList,
    fee: props?.resprinterconfig?.fee,
  });
  const calculatedFee = UsefulFunctions.getSum({
    saleList,
    onlyFee: true,
    fee: props?.resprinterconfig?.fee,
  });
  const orderTypeData = resordertypelist
    ? groupBy(resordertypelist, "enumid")
    : {};

  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  };
  const print = ({ data, type, edited }) => {
    const { printqty, footer, logo } = props?.resprinterconfig;
    const { prevDetail } = props;
    if (data && data?.products?.length > 0) {
      const deviceType = localStorage.getItem("deviceType");
      const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));
      let body = {
        ...data,
        type: type,
        vatname: props._auth.vatname,
        foodsname: props._auth.foodsname,
        regno: props?._auth?.posapinew ? props._auth.tin : props._auth.regno,
        username: props._auth.name,
        printqty: printqty ? printqty : 0,
        printersize: getPrinterSize(),
        footer: footer ? footer : "",
        logo: logo,
      };
      if (data?.printtype?.toLowerCase?.() === "захиалгын өөрчлөлт") {
        body.printqty = 1;
      }
      if (props.rescheckService === true) {
        if (type === ReceiptType.Захиалга) {
          if (printqty >= 0) {
            if (props?.reskitchenprintlist?.length) {
              let isPrint = false;
              if (prevDetail && prevDetail.length) {
                props?.saleList?.map((item, i) => {
                  let found = prevDetail.find((x) => x.id === item.id);
                  if (found) {
                    if (found.qty !== item.qty)
                      if (!isPrint) isPrint = found.printkitchen === 1;
                  } else {
                    if (!isPrint) isPrint = item.printkitchen === 1;
                  }
                });

                const removed = getDifference(
                  props?.prevDetail,
                  props?.saleList
                );
                if (removed.length) {
                  let removedRes = removed.filter((x) => x.printkitchen === 1);
                  isPrint = removedRes.length > 0;
                }
              } else isPrint = true;
              if (data?.printtype?.toLowerCase?.() === "захиалгын өөрчлөлт") {
                body.printqty = 1;
              } else body.printqty = printqty > 1 ? printqty - 1 : printqty;

              if (isPrint) {
                body.kitchenlist = props?.reskitchenprintlist;
              }
            }
            sendPrintRequest(body);
          }
        } else {
          sendPrintRequest(body);
        }
      } else if (deviceType === "ios" || deviceType === "android") {
        if (bluetooth && bluetooth.address) {
          postMessageMobile({
            type: "PRINT_BILL",
            data: JSON.stringify({ btaddress: bluetooth.address, ...body }),
          });
        } else {
          owntoast("Bluetooth принтер сануулаагүй байна.", "error");
        }
      } else {
        setReceiptData(body);
      }
      // props.request({ url: "/sale/orderlist/ongoing", model: "ongoingorders" });
    }
  };

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "RESULT_PRINT_BILL") {
          if (data.success) {
            owntoast(data.message, "success");
          } else {
            owntoast(data.message, "error");
          }
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const sendPrintRequest = (body) => {
    props
      .request({
        url: `/print/${getPrinterSize()}`,
        body: { ...body, posapinew: props?._auth?.posapinew },
        method: "POST",
        isservice: true,
      })
      .then((res) => {
        if (!res.success) {
          owntoast(res.message, "error");
        }
      });
  };

  const getPrinterSize = () => {
    if (props.resprinterconfig) {
      if (props.resprinterconfig.printertype) {
        return props.resprinterconfig.printertype;
      }
    }
    return PaperSize.m58;
  };

  const printOrder = useReactToPrint({
    content: () => props?.orderRef?.current,
  });

  useEffect(() => {
    if (
      props.resprinterconfig &&
      props.resprinterconfig.printqty &&
      props.resprinterconfig.printqty > 0
    ) {
      if (receiptData && Object.keys(receiptData).length > 0) printOrder();
    }
  }, [receiptData]);

  const handleOrder = (values) => {
    // setIsOpenTime(true);
    if (saleList && saleList.length > 0) {
      sendOrder({
        values: { ...values, details: props?.saleList },
        isOrder: true,
        order: props?.order,
      });
    } else {
      owntoast("Хоол байхгүй байна.", "error");
    }
  };

  const getSaleBody = ({ values, orderid }) => {
    return {
      orderid: orderid,
      discount: values?.details?.reduce((a, v) => (a = a + v.discount), 0),
      saletype: SaleType.Борлуулалт,
      amount: UsefulFunctions.getSum({
        saleList: values?.details,
        fee: props?.resprinterconfig?.fee,
      }),
      fee: UsefulFunctions.getSum({
        saleList: values.details,
        onlyFee: true,
        fee: props?.resprinterconfig?.fee,
      }),
      changeprice: values?.changeprice,
      totalamount: values?.details?.reduce(
        (a, v) => (a = a + v.totalamount),
        0
      ),
      userAmount: values?.userAmount,
      payments: values?.payments,
      iscompany: values.billType === 3,
      taxpayername: values.taxpayername,
      taxpayertin: values.taxpayertin,
      taxpayerregno: values.taxpayerregno,
      cards: [],
      isOwner: values?.isOwner,
      ongoingtime: Number(values?.ongoingtime?.toFixed(1)) || 0,
      timelogid: values?.timelogid || 0,
      timeedate: values?.timeedate || null,
      timesdate: values?.timesdate || null,
      hour: values?.hour || null,
    };
  };

  const sendOrder = ({
    values,
    isSale,
    isOrder,
    isDividePrint = false,
    order,
    newOrder,
    isNewOrder = false,
    reload,
    isFinish = false,
  }) => {
    values = {
      ...values,
      activeTable: props?.activeTable,
    };
    if (loaderRef.current === false) {
      loaderRef.current = true;
      if (isSale && values?.isOwner) {
        if (!check()) {
          return;
        }
      }
      setLoading(true);
      if (isSale && isOrder) {
        let body = UsefulFunctions.getOrderBody({
          values,
          order: order,
        });
        if (isNewOrder && props?.order) {
          body.mainorderid = props?.order?.id;
          delete body?.tableid;
        }
        props
          .request({ url: `/sale/order`, method: "POST", body: body })
          .then((res) => {
            if (res.success) {
              if (!isNewOrder) {
                localStorage.removeItem("deliveryLocation");
                localStorage.removeItem("waiter");
                if (order && props?.prevDetail && props?.prevDetail.length) {
                  if (
                    JSON.stringify(values?.details) !==
                    JSON.stringify(props?.prevDetail)
                  ) {
                    if (!isDividePrint) {
                      print({
                        data: { ...res.data },
                        type: ReceiptType.Захиалга,
                        edited: order?.id ? true : false,
                      });
                    }
                  }
                } else {
                  if (!isDividePrint) {
                    print({
                      data: { ...res.data },
                      type: ReceiptType.Захиалга,
                      edited: order?.id ? true : false,
                    });
                  }
                }
              }

              let body = getSaleBody({ values, orderid: res.data.id });
              props
                .request({ url: "/sale/pay", body, method: "POST" })
                .then((payres) => {
                  setTimeout(() => {
                    setLoading(false);
                    loaderRef.current = false;
                  }, 1000);
                  if (payres.success) {
                    setTimeout(
                      function () {
                        finishSale({
                          type: ReceiptType.Борлуулалт,
                          res: { ...payres },
                          isDividePrint: isDividePrint,
                          reload: reload,
                          isFinish: isFinish,
                        });
                      }.bind(this),
                      1000
                    );
                  } else {
                    props.setError({
                      isOpen: true,
                      message: `Борлуулалт хийхэд алдаа гарлаа. ${payres.message}`,
                    });
                  }
                });
            } else {
              setLoading(false);
              loaderRef.current = false;
              props.setError({
                isOpen: true,
                message: `Захиалга үүсгэхэд алдаа гарлаа. ${res.message}`,
              });
            }
          });
      } else if (isOrder) {
        let body = UsefulFunctions.getOrderBody({
          values,
          order: order,
        });
        props
          .request({ url: `/sale/order`, method: "POST", body: body })
          .then((res) => {
            setLoading(false);
            loaderRef.current = false;
            if (res.success) {
              if (newOrder) {
                sendOrder({
                  values: { ...newOrder },
                  isSale: true,
                  isOrder: true,
                  isNewOrder: true,
                  reload: reload,
                });
              } else {
                localStorage.removeItem("deliveryLocation");
                localStorage.removeItem("waiter");
                finishSale({
                  type: ReceiptType.Захиалга,
                  res: { ...res },
                  edited: order?.id ? true : false,
                  isDividePrint: isDividePrint,
                });
              }
            } else
              props.setError({
                isOpen: true,
                message: `Захиалга үүсгэхэд алдаа гарлаа. ${res.message}`,
              });
          });
      } else if (isSale) {
        if (props.order && props.order.id) {
          let body = getSaleBody({ values, orderid: order?.id });
          props
            .request({ url: "/sale/pay", body, method: "POST" })
            .then((res) => {
              setLoading(false);
              loaderRef.current = false;
              if (res.success)
                finishSale({
                  type: ReceiptType.Борлуулалт,
                  res: { ...res },
                  isDividePrint: isDividePrint,
                });
              else
                props.setError({
                  isOpen: true,
                  message: `Борлуулалт хийхэд алдаа гарлаа. ${res.message}`,
                });
            });
        } else
          props.setError({
            isOpen: true,
            message: `Уучлаарай захиалгын мэдээлэл олдсонгүй.`,
          });
      } else {
        owntoast("Буруу үйлдэл", "error");
        loaderRef.current = false;
        setLoading(false);
      }
      localStorage.setItem("deviceType", null);
      localStorage.setItem("comment", "");
      localStorage.setItem("waiter", "");
    }
  };

  const finishSale = ({
    type,
    res,
    isDividePrint = false,
    reload,
    isFinish,
    edited,
  }) => {
    props.request({
      url: `/category/dintable/list`,
      method: "POST",
      model: "dintablelist",
      body: { foodid: props._auth.foodid },
    });
    if (type === ReceiptType.Борлуулалт) {
      setIsOpen(false);
    }
    if (type === ReceiptType.Захиалга) {
      if (props?.order && props?.prevDetail && props?.prevDetail.length) {
        if (
          JSON.stringify(props?.saleList) !== JSON.stringify(props?.prevDetail)
        ) {
          if (props?.resprinterconfig?.isprint && !isDividePrint)
            print({ data: { ...res.data }, type: type, edited });
        }
      } else {
        if (!isDividePrint)
          print({ data: { ...res.data }, type: type, edited });
      }
    } else {
      if (!isDividePrint) print({ data: { ...res.data }, type: type, edited });
    }

    clearData(reload, isFinish);
    owntoast(res.message, "success");
  };

  const sendOrderDivide = ({ order, oldOrder, newOrder, reload, isSale }) => {
    if (oldOrder) {
      if (isSale) {
        sendOrder({
          values: { ...oldOrder },
          order: order,
          isOrder: true,
          isSale: true,
          reload: reload,
          isFinish: true,
        });
      } else {
        sendOrder({
          values: { ...oldOrder },
          order: order,
          isDividePrint: true,
          isOrder: true,
          newOrder: newOrder,
          reload: reload,
        });
      }
    }
  };

  const clearData = (reload, isFinish) => {
    setDefaultValues();
    modalForm.setFieldsValue({
      billType: 1,
      taxpayerregno: "",
      taxpayername: "",
      taxpayertin: "",
      ongoingtime: 0,
      timelogid: 0,
    });
    props.setActiveTable(null);
    setPaymentType(1);

    if (reload) {
      if (props?.getOrderDetail) {
        if (isFinish) {
          props.removeSaleList("clear");
          props.setOrderDetail({
            order: {},
            detail: [],
          });
        } else {
          props?.getOrderDetail(props?.order?.id, false);
        }

        reload(isFinish);
      }
    } else {
      props.removeSaleList("clear");
      props.setOrderDetail({
        order: {},
        detail: [],
      });
    }
  };

  const renderOptions = (data) => {
    return (
      data &&
      data.map((item, i) => (
        <Option value={item.typeid} key={i}>
          {item.typenm.replace("_", " ")}
        </Option>
      ))
    );
  };

  useEffect(() => {
    if (order) {
      form.setFieldsValue({
        orderType: order.orderenum,
        deliveryType: order.deliveryenum,
      });
      onChangeDelivery(order.deliveryenum, null, true);
    }
  }, [order]);

  useEffect(() => {
    setDefaultValues();
  }, [orderTypeData]);

  const setDefaultValues = () => {
    if (orderTypeData && Object.keys(orderTypeData).length > 0) {
      if (orderTypeData[1] && !form.getFieldValue("orderType")) {
        const normalOrder = orderTypeData[1].find((x) => x.typeid === 1);
        if (normalOrder) form.setFieldsValue({ orderType: normalOrder.typeid });
        else form.setFieldsValue({ orderType: orderTypeData[1][0].typeid });
      }
      if (orderTypeData[2] && !form.getFieldValue("deliveryType")) {
        const normalDelivery = orderTypeData[2].find((x) => x.typeid === 3);
        if (normalDelivery)
          form.setFieldsValue({ deliveryType: normalDelivery.typeid });
        else form.setFieldsValue({ deliveryType: orderTypeData[2][0].typeid });
      }
    }
  };

  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: IsOwnerPerm,
      permission: props._auth.permission,
      reslicense: props.reslicense,
      isMsg: true,
      setError: props.setError,
    });
  };

  const closeTimeModal = () => {
    setIsOpenTime(false);
  };

  const handleSaveTime = (
    duration,
    amount,
    ongoingduration,
    event,
    sdate,
    edate
  ) => {
    let result = saveTime(
      duration,
      amount,
      ongoingduration,
      event,
      sdate,
      edate,
      props?.order?.tableprice
    );
    if (result) {
      props.addSaleList({ ...result?.itemObj });
      modalForm?.setFieldsValue({ ...result?.modalObj });
      closeTimeModal();
      setIsOpen(true);
    }
  };

  const calculateDurartionQty = (duration) => {
    const summinutes = (duration?.hour || 0) * 60 + (duration?.minute || 0);
    const procent = summinutes / 60;
    return procent;
  };

  const handleDivide = () => {
    if (props?.order && props?.order?.id) {
      setIsOpenDivide(true);
    } else {
      owntoast("Захиалга сонгоно уу.", "error");
    }
  };

  const onChangeDelivery = (e, row, initial) => {
    if (e === 5 && !initial) {
      props?.setIsOpenLocation(true);
    }
    props?.setActiveDelivery(e);
  };

  return (
    <div className="flex-none bg-white p-3 rounded-md">
      <span className="text-grey">Тооцоо</span>
      <Form
        form={form}
        className="grid grid-cols-6 gap-2 mt-2"
        onFinish={handleOrder}
      >
        {orderTypeData[1] || orderTypeData[2] ? (
          <div className="sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-3 2xl:cols-span-3">
            {orderTypeData[1] ? (
              <div className="flex items-center justify-center">
                <span className="text-black font-semibold w-20">
                  Захиалга :
                </span>
                <Form.Item noStyle name="orderType">
                  <Select className="grow">
                    {renderOptions(orderTypeData[1])}
                  </Select>
                </Form.Item>
              </div>
            ) : null}
            {orderTypeData[2] ? (
              <div className="flex items-center justify-center mt-2">
                <span className="text-black font-semibold w-20">Хүргэлт :</span>
                <Form.Item noStyle name="deliveryType">
                  <Select className="grow" onChange={onChangeDelivery}>
                    {renderOptions(orderTypeData[2])}
                  </Select>
                </Form.Item>
              </div>
            ) : null}
          </div>
        ) : null}
        <Components.OrderAmounts
          orderTypeData={orderTypeData}
          count={UsefulFunctions.getFoodCount({ saleList })}
          sum={UsefulFunctions.getSum({
            saleList,
            onlyFee: false,
            onlySum: true,
          })}
          sumfee={calculatedFee}
          basefee={props?.resprinterconfig?.fee}
        />

        <div className="col-span-6 flex gap-2">
          <Button
            className="col-span-3 flex justify-center text-primary border-primary h-12 whitespace-normal w-full"
            htmlType="submit"
            loading={loading}
          >
            Захиалга хадгалах
          </Button>
          <Button
            className="col-span-3 flex justify-center font-semibold whitespace-normal h-12 bg-primary w-full"
            type="primary"
            loading={loading}
            onClick={() => {
              if (saleList && saleList.length > 0) {
                if (
                  order?.tableprice > 0 &&
                  !saleList?.find((x) => x.istime) &&
                  props?.reslicense?.detail?.find(
                    (x) => Number(x) === NLicenceType.time
                  )
                ) {
                  setIsOpenTime(true);
                } else {
                  if (sum > 0) {
                    setIsOpen(true);
                  } else {
                    owntoast("Үнийн дүн 0 байна.", "error");
                  }
                }
              } else {
                owntoast("Хоол байхгүй байна.", "error");
              }
            }}
          >
            Тооцоо хийх ({new Intl.NumberFormat().format(sum)})
          </Button>
          <div>
            <Tooltip title="Тооцоо хуваах">
              <Button className="h-12 w-12 p-2" onClick={handleDivide}>
                <img src={billIcon} className="object-contain" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </Form>
      <Components.OrderPaymentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={props?.saleList}
        sendOrder={sendOrder}
        form={form}
        modalForm={modalForm}
        {...props}
      />
      {isOpenTime && (
        <Components.RoomTimeModal
          isOpen={isOpenTime}
          closeModal={closeTimeModal}
          order={order}
          handleSave={handleSaveTime}
          timelog={props?.restimelog || []}
          {...props}
        />
      )}
      {isOpenDivide && (
        <Components.DivideOrderModal
          isOpen={isOpenDivide}
          loading={loading}
          setIsOpen={setIsOpenDivide}
          sendOrderDivide={sendOrderDivide}
          form={form}
          modalForm={modalForm}
          {...props}
        />
      )}

      <div className="hidden">
        <SaleBill
          ref={props?.orderRef}
          data={receiptData}
          printqty={props.resprinterconfig && props.resprinterconfig.printqty}
        />
      </div>
    </div>
  );
}

export default OrderPayment;
