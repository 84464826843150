import { useEffect, useState } from "react";
import { Marker, useMapEvents } from "react-leaflet";

export default function AddMarker(props) {
  const [coord, setPosition] = useState(null);

  useMapEvents({
    click: (e) => {
      setPosition({ lat: e.latlng.lat, lng: e.latlng.lng });
      if (props.setLatLng) {
        props.setLatLng({ lat: e.latlng.lat, lng: e.latlng.lng });
      } else {
        props.changePosition({ lat: e.latlng.lat, lng: e.latlng.lng });
      }
    },
  });

  useEffect(() => {
    if (props.defaultValue) {
      setPosition({ ...props.defaultValue });
    }
  }, []);
  return coord && <Marker position={[coord.lat, coord.lng]} draggable={true} />;
}
