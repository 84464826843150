import { useEffect } from "react";
import useCheckTimer from "../../../hooks/useCheckTimer";
import { useNavigate } from "react-router-dom";
import { owntoast } from "../../../utils";

const Index = (props) => {
  const navigate = useNavigate();

  const checkPayment = () => {
    props
      .request({
        url: `/license/checkpay/${props?.invoice?.bank?.description}`,
        model: "licenseinfo",
      })
      .then((res) => {
        if (res.success) {
          owntoast(res.message, "success");
          props
            .request({ url: `/license/checklicense`, model: "license" })
            .then((res) => {
              if (res.success) {
                localStorage.removeItem("currentlicense");
                localStorage.removeItem("license");
                navigate("/");
              }
            });
        }
      });
  };
  const minute = useCheckTimer({ callback: checkPayment, initial: 300 });

  useEffect(() => {
    if (minute?.seconds === 0 && props?.handleBack) {
      props?.handleBack();
    }
  }, [minute]);

  return (
    <div>
      <p className="text-left text-center">Шилжүүлгийн мэдээлэл</p>
      <div className="px-10 flex flex-col">
        <div className="mb-3 items-center justify-between text-md border-b grid grid-cols-2 ">
          <div className="flex justify-start">Хүлээн авагч</div>
          <div className="flex justify-end">
            {props?.invoice?.bank?.accountname}
          </div>
        </div>
        <div className="mb-3 items-center justify-between text-md border-b grid grid-cols-2 ">
          <div className="flex justify-start">Дансны дугаар</div>
          <div className="flex justify-end">
            {props?.invoice?.bank?.accountno}
          </div>
        </div>
        <div className="mb-3 items-center justify-between text-md border-b grid grid-cols-2 ">
          <div className="flex justify-start">Банк</div>
          <div className="flex justify-end">
            {props?.invoice?.bank?.bankname}
          </div>
        </div>
        <div className="mb-3 items-center justify-between text-md border-b grid grid-cols-2 ">
          <div className="flex justify-start">Гүйлгээний утга</div>
          <div className="flex justify-end">
            {props?.invoice?.bank?.description}
          </div>
        </div>
        <div className="mb-3 items-center justify-between text-md border-b grid grid-cols-2 ">
          <div className="flex justify-start">Төлөх дүн</div>
          <div className="flex justify-end">
            {props?.invoice?.bank?.amount
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            ₮
          </div>
        </div>
        <p className="text-center mt-10" style={{ marginLeft: -50 }}>
          QPAY төлөлт
        </p>
        <p className="text-center text-md">
          Тус QR кодыг уншуулж төлбөрөө хялбар төлөөрэй
        </p>
        <span className="text-xs text-grey text-center">{minute?.time}</span>
        <div className="w-full flex justify-center">
          <img
            className="3xl:w-48 3xl:h-48 w-36 h-36"
            src={`data:image/jpeg;base64,${props?.invoice?.qpay?.qpayQrImage}`}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
