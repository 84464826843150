import { useState, useRef, useEffect } from "react";
import { Button, Drawer, Space, Spin } from "antd";
import { getEnabledOptions } from "../../utils";
import { TableCustomizer } from "..";
import { NLicenceType } from "../../utils/enums";

/* Ширээ сонгох /борлуулалтын цонх/  */

function OrderTable(props) {
  const { visible, onClose, setIsOpen } = props;
  const containerRef = useRef(null);
  const [floorImage, setFloorImage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.resdintablelist && props.resdintablelist.length) {
      setData([]);
      setTimeout(() => {
        const newData = getEnabledOptions(props, "dintablelist");
        setData(newData);
        if (newData[0]) {
          setFloorImage(newData[0]?.floorid);
        }
      }, 400);
    }
  }, [props.resdintablelist]);
  /*  const data = getEnabledOptions(props, "dintablelist"); */

  const isLicence = props?.reslicense?.detail?.find(
    (x) => Number(x) === NLicenceType.time
  );

  const onClickTable = (item) => {
    if (item) {
      if (props.saleList && props.saleList.length) {
        if (props.order && props.order.id) {
          let found = data.find((x) => x.id === props.activeTable);
          if (found) {
            if (
              JSON.stringify(found.details) !==
              JSON.stringify(
                props.saleList || found.tableid !== props.activeTable
              )
            ) {
              props.setClickedTable(item);
              setIsOpen(true);
            } else {
              props?.getOrderDetail(item.orderid);
              props.setActiveTable(item.id);
            }
          } else {
            props.setActiveTable(item.id);
          }
        } else {
          props.setActiveTable(item.id);
        }
      } else {
        if (item.isorder && item.orderid) {
          props?.getOrderDetail(item.orderid);
        }
        props.setActiveTable(item.id);
      }
      onClose();
    }
  };

  if (data && data.length) {
    return (
      <Drawer
        title="Ширээ сонгох"
        className="order-table-container"
        placement="bottom"
        onClose={onClose}
        closable={false}
        open={visible}
        getContainer={false}
        height={"80%"}
        /*  style={{ position: "absolute" }} */
        extra={
          <Space>
            <Button onClick={onClose}>Хаах</Button>
          </Space>
        }
      >
        <Spin spinning={props?.loading}>
          <div className="h-full w-full" id="testContainer" ref={containerRef}>
            <TableCustomizer
              data={data}
              setData={setData}
              width={1920}
              isLicence={isLicence}
              height={700}
              isEdit={false}
              containerRef={containerRef}
              floorImage={floorImage}
              onClickTable={onClickTable}
            />
          </div>
          {/* <div className="grid grid-cols-5 sm:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8 2xl:grid-cols-11 gap-5">
            {data.map((item, i) => (
              <Popover
                key={i}
                content={
                  item.isorder ? (
                    <div>
                      <span>Хоолны тоо: </span>
                      <span>{item.detailqty}</span>
                    </div>
                  ) : null
                }
                title={
                  item.isorder
                    ? `Нийт дүн: ${new Intl.NumberFormat().format(
                        item.orderamount
                      )} ₮`
                    : "Хоосон"
                }
              >
                <div
                  className={`hover:bg-primaryOpa col-span-1 relative flex flex-col justify-center items-center  py-2 px-4 rounded-xl border ${
                    props.activeTable === item.id
                      ? "border-primary bg-primaryOpa"
                      : ""
                  }  cursor-pointer`}
                  onClick={() => onClickTable(item)}
                >
                  <img
                    src={table}
                    alt=""
                    className={`${!item.isorder ? "" : "grayscale"}`}
                  />
                  <div className="absolute text-white font-bold flex flex-col text-center">
                    <span>
                      <span className="text-xs">Ширээ:</span>
                      {`  ${item.name}`}
                    </span>
                    {item.salenum ? (
                      <span>
                        <span className="text-xs">Захиалга:</span>
                        {`  ${item.salenum}`}
                      </span>
                    ) : null}
                  </div>
                </div>
              </Popover>
            ))}
          </div> */}
        </Spin>
      </Drawer>
    );
  }
  return null;
}

{
  /* <div className="flex-none bg-white pt-2 px-3 rounded-md mb-3 ">
        <span className="text-grey">Ширээ - {data && data.length}</span>
        <div className="flex gap-2 gap-2 mt-1 overflow-x-auto pb-2">
          {data.map((item, i) => (
            <Button
              key={i}
              className={`flex items-center justify-center ${
                props.activeTable === item.id ? "active-btn" : ""
              } `}
              onClick={() => props.setActiveTable(item.id)}
            >
              {item.name}
            </Button>
          ))}
        </div>
      </div> */
}

export default OrderTable;
