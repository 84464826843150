import { useState, useEffect } from "react";
import logo from "../../assets/logos/logo.png";
import logow from "../../assets/logos/logo_white.svg";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { useNavigate, Link } from "react-router-dom";
import { Dropdown } from "antd";
import * as Components from "../";
import notification from "../../assets/notification.png";
import help from "../../assets/icons/help.png";
import order from "../../assets/order-now.png";
import Home from "../../assets/icons/Home.svg";
import Discount from "../../assets/icons/Discount.svg";
import Branch from "../../assets/icons/branch.png";
import Logout from "../../assets/icons/Logout.svg";
import QuestionCircle from "../../assets/icons/QuestionCircle.svg";
import Calling from "../../assets/icons/Calling.svg";

/* Header */
function OwnHeader(props) {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isOpen, setIsOpenModal] = useState(false);
  const [data, setData] = useState(JSON.parse(localStorage.getItem("auth")));
  const installApp = (e) => {
    e.preventDefault();
    if (promptInstall) {
      promptInstall.prompt();
    } else {
      return;
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler, false);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const logout = () => {
    navigate("/login");
    localStorage.removeItem("auth");
    localStorage.removeItem("access_token");
    props.setlogin(false);
  };

  const getMenu = (item) => {
    if (item.isHead && !props?._auth?.ishead) return null;
    return {
      key: item.key,
      label: (
        <div className="flex items-center">
          <img
            src={item.icon}
            onClick={() => navigate(item.route)}
            alt=""
            className="w-4 mr-2"
          />
          {item.logout ? (
            <span
              onClick={() => setIsOpenModal(true)}
              className="text-black w-full"
            >
              Гарах
            </span>
          ) : !item.isFile ? (
            <Link to={item.route} className="text-black w-full">
              {item.name}
            </Link>
          ) : (
            <a className="text-black" href={item.file}>
              {item.name}
            </a>
          )}
        </div>
      ),
    };
  };

  const DROPDOWN_MENU = [
    getMenu({
      key: 1,
      name: "Байгууллагын мэдээлэл",
      route: "/management/settings/1/1",
      icon: Home,
    }),
    getMenu({
      key: 2,
      name: "Төлбөр",
      route: "/license",
      icon: Discount,
    }),
    getMenu({
      key: 3,
      name: "Салбар нэмэх",
      route: "/addBranch/maininfo",
      icon: Branch,
      isHead: true,
    }),
    getMenu({
      key: 4,
      name: "Холбоо барих",
      route: "/contact",
      icon: Calling,
    }),
    getMenu({
      key: 5,
      name: "Тусламж",
      route: "/help",
      icon: QuestionCircle,
    }),
    getMenu({
      key: 6,
      name: "Нэмэлт сан татах",
      icon: QuestionCircle,
      file: "https://food.onispos.mn/files/OnisTpApi.exe",
      isFile: true,
    }),
    getMenu({
      key: 7,
      name: "Гарах",
      logout: true,
      icon: Logout,
    }),
  ];

  return (
    <header className="bg-headerBack bg-cover w-full px-2 lg:px-6 py-1 flex justify-between items-center">
      <img
        src={logo}
        alt="logo"
        className="w-28 h-6 cursor-pointer hidden lg:block"
        onClick={() => navigate("/")}
      />
      <img
        src={logow}
        alt="logo"
        className="w-10 cursor-pointer lg:hidden"
        onClick={() => navigate("/")}
      />
      <div className="flex items-center">
        <Tooltip title="Заалруу орох">
          <img
            src={order}
            alt="logo"
            className="h-5 w-5 mr-4 cursor-pointer hidden lg:block"
            onClick={() => navigate("/sale")}
          />
        </Tooltip>
        {supportsPWA ? (
          <Components.Ripple
            className="glass-btn rounded-2xl"
            onClick={installApp}
          >
            <span>Татах</span>
          </Components.Ripple>
        ) : null}
        <Tooltip
          title={
            props.rescheckService === true
              ? "Service амжилттай холбогдсон байна."
              : "Сервис холбогдоогүй байна."
          }
        >
          <SettingOutlined
            size={50}
            /* spin={props.rescheckService === true} */
            style={{ fontSize: "1rem", color: "#FFF" }}
            className="mr-5 cursor-pointer"
          />
        </Tooltip>

        {/*   <img
          src={help}
          alt=""
          className="h-4 w-4 cursor-pointer"
          onClick={() => setIsOpen(true)}
        /> */}
        <Link
          data-tour="notification-button"
          to="/notification"
          className="p-2 rounded-lg cursor-pointer mr-1 border-white ml-3"
        >
          {props.resnotifications &&
          props.resnotifications.filter((item) => item.isview === 0).length >
            0 ? (
            <span
              className="absolute text-black ml-3 mb-3 bg-white rounded-full h-3 w-3 flex items-center justify-center z-10"
              style={{ fontSize: "10px" }}
            >
              {
                props.resnotifications.filter((item) => item.isview === 0)
                  .length
              }
            </span>
          ) : null}

          <img src={notification} alt="" className="h-4 w-4" />
        </Link>

        <div className="bg-[#E9AC22] text-white rounded-lg text-sm absolute px-1.5 top-0 right-0"></div>
        <div className="flex items-center justify-center ml-3">
          {/* <img src={smile} alt="smile" className="w-5 h-5 mr-2" /> */}
          <Dropdown
            menu={{ items: DROPDOWN_MENU }}
            className="flex items-center"
            trigger={["click"]}
          >
            <div
              className="ant-dropdown-link text-white font-semibold cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              {/* <span className="text-xs"> {data && data.username}</span> */}
              <span className="flex items-center">
                {`${data && data.foodsname} / ${data && data.name} /`}
                <DownOutlined className="pl-2" />
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
      <Components.CustomModal
        footer={[
          <Button onClick={() => setIsOpenModal(false)}>Үгүй</Button>,
          <Button
            type="primary"
            className="bg-primary"
            onClick={logout}
            autoFocus
          >
            Тийм
          </Button>,
        ]}
        width={400}
        isOpen={isOpen}
        onClose={() => setIsOpenModal(false)}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          <span className="text-center">Та гарахдаа итгэлтэй байна уу?</span>
        </div>
      </Components.CustomModal>
    </header>
  );
}

export default OwnHeader;
