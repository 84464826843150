import React, { useState, useRef, useEffect } from "react";
import Swiper from "react-id-swiper";
import { Modal, Statistic } from "antd";
import { API_URL } from "../../../src/utils";
const { Countdown } = Statistic;

function Index(props) {
  const swiperRef = useRef(null);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    let timeOut = null;
    if (seconds) {
      timeOut = setTimeout(
        () =>
          setSeconds((prev) => {
            if (prev - 1 === 0) {
              if (
                swiperRef.current?.swiper?.activeIndex !==
                props?.resbanners?.length - 1
              ) {
                swiperRef.current?.swiper?.slideNext();
                return 10;
              } else {
                setSeconds(0);
                props.setNotification(false);
                swiperRef.current?.swiper?.slideTo(0, 0);
                if (props._auth.licensetext) {
                  props.setError({
                    isOpen: true,
                    message: props._auth.licensetext,
                    isLicenseModal: props._auth.licenseday <= 3,
                  });
                }
              }
            }
            return prev - 1;
          }),
        1000
      );
    }
    if (timeOut) return () => clearTimeout(timeOut);
  }, [seconds]);

  useEffect(() => {
    if (props.notification) {
      setSeconds(10);
    }
  }, [props.notification, props?.resbanners]);

  /*  useEffect(() => {
    const goNext = () => {
      if (swiperRef.current && swiperRef.current.swiper && props.notification) {
        if (
          swiperRef.current.swiper.activeIndex !==
          props.resbanners.length - 1
        ) {
          swiperRef.current.swiper.slideNext();
          setSeconds(notifSecond);
        } else {
          setSeconds(null);
          props.setNotification(false);
          swiperRef.current.swiper.slideTo(0, 0);
          if (props._auth.licensetext) {
            props.setError({
              isOpen: true,
              message: props._auth.licensetext,
              isLicenseModal: props._auth.licenseday <= 3,
            });
          }
        }
      }
    };
    if (props.notification && seconds !== null) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        goNext();
      }
    }
  }, [props.notification, props.setNotification, seconds]); */

  const params = {
    effect: "coverflow",
    noSwiping: true,
    pagination: {
      el: ".swiper-custom-pagination",
      renderBullet: (index, className) => {
        return `<span className='${className}'></span>`;
      },
    },
  };

  return (
    <Modal
      open={props.notification}
      footer={null}
      width={700}
      centered
      className="modal-rounded"
      onCancel={() => {
        setSeconds(0);
        props.setNotification(false);
        swiperRef.current.swiper.slideTo(0, 0);
      }}
    >
      <div className="p-4 text-center ">
        <div className="flex-col lg:flex items-center justify-center">
          <span className="text-xs text-grey lg:absolute right-10">
            {seconds} секунд хүлээнэ үү...
          </span>
        </div>
        <div>
          <Swiper {...params} ref={swiperRef}>
            {props.resbanners &&
              props.resbanners.map((item, i) => (
                <div className="flex flex-col items-center" key={i}>
                  <span className="text-sm text-black font-semibold mb-2">
                    {item.name}
                  </span>
                  <img
                    src={`${API_URL}${item.image}`}
                    alt="img"
                    className="rounded-lg w-full h-full"
                  />
                </div>
              ))}
          </Swiper>
        </div>
      </div>
    </Modal>
  );
}

export default Index;
