import { useState, useEffect } from "react";
import { Select, Table, Button, Form, Input, Modal } from "antd";
import GlobalModal from "../Modal";
import CustomModal from "../CustomModal";
import { CloseOutlined } from "@ant-design/icons";
function FoodCardTable(props) {
  const Option = Select;
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFood, setselectedFood] = useState("");
  const [filteredList, setfilteredList] = useState([]);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [proname, setproname] = useState("");

  const catlist = props.rescategorylist ? props.rescategorylist : [];

  const filteredfoodList = props.resfoodlist
    ? props.resfoodlist.filter((x) => x.isset === 0)
    : [];

  useEffect(() => {
    if (props.editData && props.editData.id) {
      if (props.method && props.url) {
        props
          .request({
            url: `${props.url}/${props.editData.id}`,
            method: props.method,
          })
          .then((res) => {
            if (res.success) {
              setData(res.data);
            }
          });
      } else {
        setData(props.editData.products);
      }
    }
  }, [props.editData]);

  useEffect(() => {
    props.form.setFieldsValue({
      [props.name]: data,
      qty: data.length,
      detailqty: data.length,
    });
    props.form.setFieldsValue({
      amount: data.reduce(
        (totalHolder, item) => item && totalHolder + item.amount,
        0
      ),
      // price: data.reduce((totalHolder, item) => totalHolder + item.amount, 0),
    });
    const cols = props.columns();
    data.map((prod, index) => {
      if (prod) {
        Object.keys(prod).map((key) => {
          let col = cols.find((x) => x.dataIndex === key);
          if (col) {
            props.form.setFieldsValue({
              [`dfield${key}${index}`]: prod[key] ? prod[key] : 0,
            });
          }
        });
      } else {
        cols.map((col) => {
          props.form.setFieldsValue({
            [`dfield${col.dataIndex}${index}`]: 0,
          });
        });
      }
    });

    calculateDiscounts();
  }, [data]);

  useEffect(() => {
    calculateDiscounts();
  }, [props.price]);

  useEffect(() => {
    setfilteredList(
      filteredfoodList
        ? filteredfoodList.filter((x) => x.id !== selectedFood)
        : []
    );
  }, [selectedFood]);

  const getMax = (arr) => {
    let max = arr[0];
    let index = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i]?.price > max?.price) {
        max = arr[i];
        index = i;
      }
    }

    return { max: { ...max }, index };
  };
  const calculateDiscounts = () => {
    let value = props.form.getFieldsValue();
    /*  */
    let diffAmt = value.amount - value.price;
    data.map((item, i) => {
      if (item) {
        let procent = (100 / value.amount) * item.amount;
        //item.procent = procent;
        let disco = Math.round((diffAmt / 100) * procent);
        let discoAmt = Math.round(item.amount - disco);

        if (isNaN(discoAmt)) discoAmt = 0;
        if (isNaN(disco)) disco = 0;
        item.discount = disco;
        item.discoAmt = discoAmt;
        props.form.setFieldsValue({
          [`dfielddiscount${i}`]: disco,
          [`dfielddiscoAmt${i}`]: discoAmt,
        });
      }
    });

    let sumDiscoAmt =
      data &&
      data?.reduce((totalHolder, item) => totalHolder + item?.discoAmt || 0, 0);

    if (data?.length) {
      let fix = value?.price - sumDiscoAmt;
      let res = getMax(data);
      if (fix > 0) {
        if (data[res.index]) {
          data[res.index].discount = res?.max?.discount - fix;
          data[res.index].discoAmt = res?.max?.discoAmt + fix;
          props.form.setFieldsValue({
            [`dfielddiscount${res.index}`]: data[res.index]?.discount,
            [`dfielddiscoAmt${res.index}`]: data[res.index]?.discoAmt,
          });
        }
      } else {
        let fixed = fix * -1;
        if (data[res.index]) {
          data[res.index].discount = res?.max?.discount || 0 + fixed;
          data[res.index].discoAmt = res?.max?.discoAmt || 0 - fixed;
          props.form.setFieldsValue({
            [`dfielddiscount${res.index}`]: data[res.index]?.discount,
            [`dfielddiscoAmt${res.index}`]: data[res.index]?.discoAmt,
          });
        }
      }
    }

    setData(data);
    // adjustCalculation(data, diffAmt);
  };

  const adjustCalculation = (rounded, diffAmt) => {
    const scale = diffAmt / 100;
    let roundedData = rounded?.map((item) => Math.round(item?.procent * scale));
    let roundedSum = roundedData.reduce((acc, curr) => acc + curr, 0);
    let error = diffAmt - roundedSum;

    for (let i = 0; i < Math.abs(error); i++) {
      if (error < 0) {
        let maxIndex = roundedData.indexOf(Math.max(...roundedData));
        roundedData[maxIndex] -= 1;
      } else if (error > 0) {
        let minIndex = roundedData.findIndex(
          (num, idx) => num > 0 && data[idx] > 0
        );
        roundedData[minIndex] += 1;
      }
    }
    let newData = [...data];
    roundedData?.map((item, i) => {
      if (newData?.[i]) {
        let discoAmt = Math.round(newData?.[i].amount - item);
        newData[i].discount = item;
        newData[i].discoAmt = discoAmt;
        props.form.setFieldsValue({
          [`dfielddiscount${i}`]: item,
          [`dfielddiscoAmt${i}`]: discoAmt,
        });
      }
    });

    // setData(newData);
    // setData(data);
  };

  const handleDelete = (row, pos) => {
    var array = [...data];
    array.splice(pos, 1);
    let removeData = {};
    if (row) {
      Object.keys(row).map((key) => {
        removeData[`dfield${key}${pos}`] = null;
        removeData[`dfieldunitid${pos}`] = null;
      });
    }

    props.form.setFieldsValue({ ...removeData });
    array.map((item, i) => {
      props.form.setFieldsValue({
        [`dfielddiscount${i}`]: 0,
        [`dfielddiscoAmt${i}`]: 0,
      });
    });
    setData(array);
  };
  const addNew = () => {
    if (props.isModal) {
      setIsOpen(true);
    } else {
      var newlist = [...data, null];
      setData(newlist);
    }
  };
  const checkProduct = (e) => {
    const find = data && data.find((x) => x.productid === e.productid);
    if (find) return true;
    else return false;
  };
  const handleSave = (e) => {
    if (e) {
      const food =
        props.resfoodlist &&
        props.resfoodlist.find((x) => x.id === e.productid);
      if (!checkProduct(e)) {
        let tmp = {
          productid: e.productid,
          unitname: food.unitname,
          qty: e.qty,
          price: food.sprice,
          imgpos: food.imgpos,
          amount: food.sprice * e.qty,
        };
        if (e.subs && e.isconnect) {
          let subs = [];
          e.subs.map((item) => {
            subs.push({ productid: item });
          });
          tmp.subs = subs;
        }

        setData(() => {
          const newData = [...data, tmp];
          props.form.setFieldsValue({
            price: newData.reduce(
              (totalHolder, item) => totalHolder + item.amount,
              0
            ),
          });
          return newData;
        });
        setIsOpen(false);
      } else {
        setproname(food.name);
        setIsOpenWarning(true);
      }
    }
  };
  const onChange = ({ value, i, name }) => {
    let newData = [...data];
    if (newData[i] === null) {
      newData[i] = {};
    }
    newData[i][name] = value;
    if (name === "materialid") {
      const material =
        props.resmateriallist &&
        props.resmateriallist.find((x) => x.id === value);
      if (material) {
        newData[i]["unitid"] = material.unitid;
        props.form.setFieldsValue({ [`dfieldunitid${i}`]: material.unitid });
      }
    }
    if (newData[i].price) {
      let amount = newData[i].price * newData[i]?.qty || 0;
      newData[i].amount = amount;
      if (newData[i].discount && newData[i].discoAmt) {
        newData[i].discount = 0;
        newData[i].discoAmt = 0;
      }
      let sum = newData.reduce((totalHolder, item) => {
        if (item.amount && item.amount > 0) return totalHolder + item.amount;
        return totalHolder;
      }, 0);
      props.form.setFieldsValue({
        [`dfieldamount${i}`]: amount,
        [`dfielddiscount${i}`]: 0,
        [`dfielddiscoAmt${i}`]: 0,
        amount: sum,
        price: sum,
      });
    }
    setData(newData);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const SetFoodAddForms = {
    isFile: true,
    url: "/set",
    method: "POST",
    isClear: true,
    isMessage: true,
    isRefresh: true,
    leftForms: {
      form: [
        {
          name: "catid",
          label: "Ангилал",
          type: "select",
          options: [{ name: "Бүгд", value: 0 }, ...catlist],
          isModal: props.isModal,
          isChange: true,
          fieldNames: { label: "name", value: "id" },
        },
        {
          name: "productid",
          label: "Бүтээгдэхүүн",
          type: "select",
          options: filteredfoodList,
          isModal: props.isModal,
          isChange: true,
          fieldNames: { label: "name", value: "id" },
        },
        {
          name: "sprice",
          label: "Үнэ",
          type: "number",
          disabled: true,
          rules: ({ setFieldsValue }) => ({
            validator(_, value) {
              let regex = new RegExp("^[0-9]{1,10}$");
              if (!regex.test(value))
                return Promise.reject(
                  new Error("Дээд тал нь 10 оронтой тоо оруулах боломжтой.")
                );
              else {
                /* setFieldsValue({ sprice: new Intl.NumberFormat().format(value) }); */
                return Promise.resolve();
              }
            },
          }),
          required: true,
        },
        {
          name: "qty",
          label: "Тоо хэмжээ",
          type: "number",
          rules: ({ setFieldsValue }) => ({
            validator(_, value) {
              let regex = new RegExp("^[0-9]{1,10}$");
              if (!regex.test(value))
                return Promise.reject(
                  new Error("Дээд тал нь 10 оронтой тоо оруулах боломжтой.")
                );
              else {
                return Promise.resolve();
              }
            },
          }),
          required: true,
        },
        {
          name: "imgpos",
          label: "Зураг /ПОС/",
          type: "upload",
          desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
        },
        {
          name: "imgmobile",
          label: "Зураг /Утас/",
          type: "upload",
          desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
        },
        {
          name: "imgtv",
          label: "Зураг /Заал/",
          type: "upload",
          desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
        },
      ],
    },
    rightForms: {
      form: [
        {
          name: "isconnect",
          label: "Орлох бүтээгдэхүүн сонгох эсэх",
          type: "checkbox",
          valuePropName: "checked",
        },
        {
          name: "subs",
          label: "Орлох бүтээгдэхүүн сонгох",
          type: "select",
          options: filteredList,
          filterOption: true,
          fieldNames: { label: "name", value: "id" },
          mode: "multiple",
          allowClear: true,
          showImage: true,
          // defaultValue: true,
          // disabled:true,
        },
      ],
    },
  };

  return (
    <div>
      <Table
        columns={[
          ...props.columns(props, onChange),
          {
            title: "Үйлдэл",
            dataIndex: "edit",
            align: "center",
            width: 120,
            render: (_, record, index) => (
              <a onClick={() => handleDelete(record, index)}>Устгах</a>
            ),
          },
        ]}
        dataSource={data}
        size="small"
        pagination={false}
      />
      <Form.Item hidden initialValue={data} name={props.name}>
        <Input />
      </Form.Item>
      <Button
        className="w-full flex justify-center my-2"
        type="dashed"
        danger
        onClick={addNew}
      >
        {props.buttonText ? props.buttonText : "Шинэ орц"}
      </Button>
      <GlobalModal
        isOpen={isOpen}
        onClose={handleClose}
        closeBtnTxt="Буцах"
        saveBtnTxt="Сонгох"
        handleSave={(e) => handleSave(e)}
        width={800}
        headerTitle="Бүтээгдэхүүн сонгох"
        content={SetFoodAddForms}
        props={props}
        setselectedFood={setselectedFood}
      />
      <CustomModal
        footer={[<Button onClick={() => setIsOpenWarning(false)}>Хаах</Button>]}
        isOpen={isOpenWarning}
        title="Анхааруулга"
        onClose={() => setIsOpenWarning(false)}
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          {proname}-бүтээгдэхүүн сонгогдсон байна.
        </div>
      </CustomModal>
    </div>
  );
}

export default FoodCardTable;
