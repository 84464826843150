import React from "react";
import dayjs from "dayjs";
import packageJson from "../../../package.json";
function Index() {
  return (
    <div className="bg-headerBack w-full hidden md:block h-10">
      <div className="container mx-auto py-3 px-5 flex items-center justify-center">
        <span className="text-white text-sm text-center">
          &copy; {`Datacare LLC ${dayjs().format("YYYY")}`}
        </span>
        <span className="inline-flex ml-10">
          <a
            href="https://www.facebook.com/OnisSystem"
            rel="noreferrer"
            target="_blank"
            className="text-gray-200"
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/onissystems"
            rel="noreferrer"
            target="_blank"
            className="ml-3 text-gray-200"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
        </span>
        <span className="text-white ml-2">{process.env.NODE_ENV}</span>
        <span className="text-white ml-2">version: {packageJson.version}</span>
      </div>
    </div>
  );
}

export default Index;
