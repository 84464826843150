import React, { useState } from "react";
import { Form, Button } from "antd";
import { StopOutlined, SaveOutlined } from "@ant-design/icons";
import { owntoast } from "../../../utils";
import { AntdTable, CustomModal } from "../../";
import { LicenseUserListColumns } from "../../../data/Columns";
import { useEffect } from "react";

function Modals(props) {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {}, [props?.userList]);

  const formSubmit = () => {
    if (selectedRows.keys && selectedRows.keys.length > 0) {
      props?.setUserList(selectedRows);
      setOpen(false);
    } else {
      owntoast("Хэрэглэгч сонгоно уу", "error");
    }
  };

  useEffect(() => {
    if (open) {
      props?.request({
        url: `/user/userlist`,
        model: "userlist",
        method: "POST",
        body: {
          name: "",
        },
      });
      setSelectedRows(props?.userList);
    }
  }, [open]);
  return (
    <div className="mt-2">
      <Button
        disabled={props?.disabled}
        onClick={() => setOpen(true)}
        type="dashed text-kitchenYellow border-kitchenYellow"
        className="w-fit"
      >
        Хэрэглэгч нэмэх
      </Button>
      <CustomModal
        title="Хэрэглэгч сонгох"
        isOpen={open}
        onClose={() => setOpen(false)}
        footer={null}
        width={750}
      >
        <Form onFinish={formSubmit} form={form} className="p-4">
          <AntdTable
            columns={LicenseUserListColumns}
            dataSource={
              props?.resuserlist
                ?.filter?.((x) => x.roletype !== 1)
                ?.filter?.((x) => x.isfree !== 1) || []
            }
            loading={props?.isuserlist === true}
            isModal
            isSelectRow={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              width: "100%",
              marginRight: 15,
            }}
          >
            <Button
              icon={<StopOutlined />}
              onClick={() => setOpen(false)}
              style={{ backgroundColor: "#595959", color: "white" }}
            >
              Болих
            </Button>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5 }}
            >
              Сонгох
            </Button>
          </div>
        </Form>
      </CustomModal>
    </div>
  );
}
export default Modals;
