import { useState, useEffect, useRef } from "react";
import { Button, Input, Alert, Form } from "antd";
import { License, ChooseBilltype } from "../../components";
import { useNavigate } from "react-router-dom";
import back from "../../assets/back.png";
import dayjs from "dayjs";
import trash from "../../assets/trash.png";
import { owntoast } from "../../utils";

const Index = (props) => {
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const resDiscount = useRef(0);
  const promoDiscount = useRef(0);
  const [promoData, setPromoData] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [kioskList, setKioskList] = useState({
    rows: [],
    keys: [],
  });
  const [userList, setUserList] = useState({
    rows: [],
    keys: [],
  });
  const [invoice, setInvoice] = useState(null);
  const [billType, setBillType] = useState(1);
  const [license, setLicense] = useState(
    JSON.parse(localStorage.getItem("license"))
  );
  const currentlicense = JSON.parse(localStorage.getItem("currentlicense"));
  const kioskLicense = license?.subLicense?.find?.((x) => x.type === 4);
  const userLicense = license?.subLicense?.find?.((x) => x.type === 3);

  const disabled = invoice ? true : false;
  const getTotAmt = () => {
    let amount =
      license?.subLicense?.reduce(
        (holder, item) =>
          item.type !== 4 && item.type !== 3
            ? holder + (item?.price || 0) * (item.qty || 1)
            : holder + 0,
        0
      ) +
      kioskList?.rows?.length * (kioskLicense?.price || 0) +
      userList?.rows?.length * (userLicense?.price || 0);
    if (currentlicense) {
      let smonth = dayjs(props?.reslicense?.sdate)?.month();
      let usedmonth = dayjs()?.month() - smonth;
      let packdiscount = (currentlicense?.price / 12) * usedmonth;

      let newdiscount = (license?.license?.price / 12) * (12 - usedmonth);
      let resDes = Math.round(
        newdiscount + packdiscount - currentlicense?.price
      );
      resDiscount.current = license?.license?.price - resDes;
      amount += resDes;
    } else {
      amount += license?.license?.price || 0;
    }

    if (promoData) {
      if (promoData.type === 1) {
        amount -= promoData.amount;
        promoDiscount.current = promoData?.amount;
      } else if (promoData.type === 2) {
        let discount = Math.round(amount * (promoData.procent / 100));
        promoDiscount.current = discount;
        amount -= discount;
      }
    }

    return amount < 0 ? 0 : amount;
  };

  const handleBack = () => {
    if (invoice) setInvoice(null);
    else {
      localStorage.removeItem("currentlicense");
      localStorage.removeItem("license");
      navigate(-1);
    }
  };

  const handleRemove = ({ id, isUser, isKiosk }) => {
    let tmp;
    if (isKiosk) tmp = { ...kioskList };
    else if (isUser) tmp = { ...userList };
    tmp.rows = tmp?.rows?.filter((x) => x.id !== id);
    tmp.keys = tmp?.keys?.filter((x) => x !== id);

    if (isKiosk) setKioskList(tmp);
    else if (isUser) setUserList(tmp);
  };

  const checkPromoCode = () => {
    if (promoData) {
      setPromoCode("");
      setPromoData(null);
    } else {
      if (promoCode) {
        setLoading(true);
        props.request({ url: `/license/getpromo/${promoCode}` }).then((res) => {
          setLoading(false);
          if (res.success) {
            owntoast(res.message, "success");
            setPromoData(res.data);
          } else {
            owntoast(res.message, "error");
          }
        });
      } else owntoast("Купон оруулна уу", "error");
    }
  };

  const getPromoNames = () => {
    if (promoData) {
      let sum = "";
      if (promoData.amount) {
        sum = `${new Intl.NumberFormat().format(promoData.amount)}₮`;
      } else if (promoData.procent) {
        sum = `${promoData.procent}%`;
      } else if (promoData.day) {
        sum = `${promoData.day}хоног`;
      }
      return sum;
    }
    return 0;
  };

  const getQty = (item) => {
    if (item?.type === 4) return kioskList?.rows?.length;
    else if (item?.type === 3) return userList?.rows?.length;
    else return item?.qty;
  };

  const handlePay = async () => {
    const result = await form.validateFields();
    if (kioskLicense) {
      if (!kioskList?.rows || kioskList?.rows?.length === 0) {
        owntoast("Kiosk /өөртөө үйлчлэх ПОС/ сонгоно уу.", "error");
        return;
      }
    }

    if (userLicense) {
      if (!userList?.rows || userList?.rows?.length === 0) {
        owntoast("Хэрэглэгч сонгоно уу.", "error");
        return;
      }
    }

    let tmp = {
      promocode: promoData ? promoCode : null,
      paytype: 1,
      amount: getTotAmt(),
      resamount: resDiscount.current || 0,
      discount: promoDiscount.current || 0,
      details: [],
      kiosks: kioskList?.keys,
      users: userList?.keys,
      iscompany: result.billType === 3 ? 1 : 0,
      vatregno: result.billType === 3 ? result.taxpayerregno : "",
      vatname: result.billType === 3 ? result.taxpayername : "",
    };
    if (license?.license)
      tmp.details.push({ productid: license?.license?.id, qty: 1 });
    if (license?.subLicense?.length) {
      license?.subLicense?.map((item) => {
        tmp.details.push({
          productid: item?.id,
          qty: getQty(item),
        });
      });
    }
    setLoading(true);
    props
      .request({ url: `/license/sale`, method: "POST", body: tmp })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          owntoast(res.message, "success");
          setInvoice(res?.data);
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  return (
    <div className="flex items-center h-full justify-center">
      <div className="bg-white px-10 py-5 rounded-lg border border-gray-200 overflow-x-auto drop-shadow-xl h-5/6">
        <p className="text-license text-center text-2xl">Төлбөрийн мэдээлэл</p>
        <div
          className={`grid gap-5 ${invoice ? "grid-cols-2" : "grid-cols-1"}`}
        >
          <div className="gap-3 flex flex-col">
            <span>Сонгосон багц</span>
            <div className="flex gap-2  px-10">
              <License.LicenseSmallCard
                isPayment={true}
                data={license?.license}
              />
              {license?.subLicense?.map?.((item, i) => (
                <License.LicenseSmallCard
                  key={i}
                  isPayment={true}
                  data={item}
                />
              ))}
            </div>
            <span>Задаргаа</span>
            <div className="flex flex-col px-10 gap-2">
              <License.DetailRow data={license?.license} />
              {license?.subLicense?.map?.((item, i) =>
                item?.type !== 4 && item?.type !== 3 ? (
                  <License.DetailRow
                    key={i}
                    data={item}
                    setLicense={setLicense}
                    license={license}
                  />
                ) : null
              )}
            </div>
            {userLicense ? (
              <div className="flex flex-col gap-3">
                <span>Хэрэглэгч</span>
                <div className="flex flex-col px-10">
                  {userList?.rows?.map?.((item, i) => (
                    <License.DetailRow
                      key={i}
                      data={item}
                      isUser={true}
                      userLicense={userLicense}
                      disabled={disabled}
                      handleRemove={handleRemove}
                    />
                  ))}
                  <License.ChooseUser
                    {...props}
                    setUserList={setUserList}
                    userList={userList}
                    disabled={disabled}
                  />
                </div>
              </div>
            ) : null}
            {kioskLicense ? (
              <div className="flex flex-col gap-3">
                <span>Kiosk/өөртөө үйлчлэх ПОС/</span>
                <div className="flex flex-col px-10">
                  {kioskList?.rows?.map?.((item, i) => (
                    <License.DetailRow
                      key={i}
                      data={item}
                      isKiosk={true}
                      kioskLicense={kioskLicense}
                      disabled={disabled}
                      handleRemove={handleRemove}
                    />
                  ))}
                  <License.ChooseKiosk
                    {...props}
                    setKioskList={setKioskList}
                    kioskList={kioskList}
                    disabled={disabled}
                  />
                </div>
              </div>
            ) : null}
            {currentlicense ? (
              <div className="flex flex-col gap-3">
                <span>Хуучин багц</span>
                <div className="px-10">
                  <License.DetailRow
                    data={currentlicense}
                    kioskLicense={kioskLicense}
                    disabled={disabled}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            ) : null}
            {promoData ? (
              <div className="flex flex-col gap-3">
                <span>Купоны задаргаа</span>
                <div className="px-10">
                  <div className="grid grid-cols-3 border-b pb-0.5">
                    <div className="flex justify-start">{promoData.name}</div>
                    <div className="flex justify-center">Хямдрал</div>
                    <div className="font-bold flex justify-end">
                      {getPromoNames()}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="px-10 flex font-semibold justify-end text-lg text-primary uppercase">
              <span className="mr-2">төлөх дүн:</span>
              <span className="">
                {new Intl.NumberFormat().format(getTotAmt() || 0)} ₮
              </span>
            </div>
            <span>Купон</span>
            <div className="px-10 flex gap-2">
              <Input
                placeholder="Купон оруулах"
                disabled={disabled || promoData}
                value={promoCode}
                onChange={(e) => setPromoCode(e?.target?.value)}
              />
              <Button
                disabled={disabled}
                loading={loading}
                onClick={checkPromoCode}
              >
                {promoData ? "Засах" : "Шалгах"}
              </Button>
            </div>
            <span>Баримтын төрөл</span>
            <div className="px-10 flex flex-col">
              <Alert
                type="warning"
                showIcon
                message="Таны баримт бүртгэлтэй утасны дугаарлуу очно."
              />
              <Form name="form" form={form}>
                <ChooseBilltype
                  form={form}
                  disabled={disabled}
                  {...props}
                  billType={billType}
                  setBillType={setBillType}
                />
              </Form>
            </div>
            <div className="mt-10 2xl:mt-6 flex justify-between px-10">
              <Button
                icon={<img className="w-4 h-4 mr-2" src={back} alt="logo" />}
                onClick={handleBack}
                loading={loading}
              >
                Буцах
              </Button>
              <Button
                type="primary"
                disabled={disabled}
                loading={loading}
                onClick={handlePay}
              >
                Худалдаж авах
              </Button>
            </div>
          </div>
          {invoice ? (
            <License.Invoice
              invoice={invoice}
              handleBack={handleBack}
              request={props?.request}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Index;
