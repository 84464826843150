import React, { useEffect, useState, useRef, useCallback } from "react";
import * as Components from "../../components";
import { useNavigate, Link } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { owntoast } from "../../utils";
function Verify(props) {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("register"));
  const Ref = useRef(null);
  const [isBranch] = useState(props.isBranch ? true : false)
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Баталгаажуулах";
  }, []);

  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback((e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  }, []);

  const clearTimer = useCallback(
    (e) => {
      setTimer("00:00");

      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1);
      Ref.current = id;
    },
    [startTimer]
  );

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [clearTimer]);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const handleNext = (e) => {
    if (!data.type) {
      props
        .request({
          url: `/user/verification/${data.foodid}/${e.smscode}`,
          method: "PUT",
        })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            if (isBranch) {
              props.setStep(3);
              props.setProcent(100);
              navigate("/addBranch/success");
            } else {
              navigate("/register/success");
            }
          } else {
            owntoast(res.message, "error");
          }
        });
    } else {
      props
        .request({
          url: `/user/checksmscode/${data.foodid}/${data.phone}/${e.smscode}`,
        })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            let tmp = {
              type: data.type,
              foodid: data.foodid,
              phone: data.phone,
              code: e.smscode,
            };
            localStorage.setItem("register", JSON.stringify(tmp));
            navigate("/register/subinfo");
          } else {
            owntoast(res.message, "error");
          }
        });
    }
  };
  const handleSmsAgain = () => {
    if (!data.type) {
      props
        .request({ url: `/user/trysms/${data.foodid}`, method: "PUT" })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            onClickReset();
          } else {
            owntoast(res.message, "error");
          }
        });
    } else {
      props
        .request({
          url: `/user/resetsms/${data.foodid}/${data.phone}`,
          method: "PUT",
        })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            onClickReset();
          } else {
            owntoast(res.message, "error");
          }
        });
    }
  };
  const changeInput = (e, name) => {
    let check = form.getFieldValue(name);
    if (check && name === "smscode") {
      props.setProcent(100);
    }
  }
  const handleBack = () => {
    if (isBranch) {
      props.setStep(1);
      props.setProcent(0)
    }
  }
  return (
    <div className="items-center flex flex-col text-center">
      <p className="font-bold text-lg">БАТАЛГААЖУУЛАЛТ</p>
      <Form
        className="w-full mt-4 items-center flex flex-col"
        onFinish={handleNext}
        initialValues={{
          phone: data ? data.phone : "",
        }}
        form={form}
      >
        <div className="lg:w-72 w-full text-center">
          <span className="text-xs text-grey">{timer}</span>
          <div>
            <Form.Item className="mt-4" name="phone">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="smscode"
              rules={[{ required: true, message: "Код оруулна уу" }]}
              onBlur={isBranch ? (e) => changeInput(e, "smscode") : null}
            >
              <Input placeholder="Баталгаажуулах код" maxLength={5} />
            </Form.Item>
            <Components.Ripple
              color="red"
              className="mt-2"
              onClick={handleSmsAgain}
            >
              Мессеж дахин илгээх
            </Components.Ripple>
          </div>
          <Button
            className="bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm lg:mt-2"
            type="primary"
            htmlType="submit"
          >
            БАТАЛГААЖУУЛАХ
          </Button>
        </div>{" "}
        <Link
          className="mt-2 3xl:mt-8"
          to={isBranch ? "/addBranch/subinfo" : !data.type ? "/register/subinfo" : "/register/forgetpass"}
          onClick={handleBack}
        >
          Буцах
        </Link>
      </Form>
    </div>
  );
}

export default Verify;
