import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import ringing from "../../assets/ringing.png";
import * as Components from "../../components";

function Index(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    document.title = "Мэдэгдэл";
  }, []);

  const handleOpenModal = (selectedRow) => {
    setSelected(selectedRow);
    setIsOpen(true);
    if (selectedRow.isview === 0) {
      props
        .request({
          method: "PUT",
          url: `/system/viewnotf/${selectedRow.id}`,
        })
        .then((res) => {
          if (res.success) selectedRow.isview = 1;
        });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div className="px-20 mt-8 w-full">
      <p className="font-bold text-center text-2xl">Мэдэгдлүүд</p>
      {props.resnotifications &&
        props.resnotifications.map((item, i) => (
          <div
            key={i}
            onClick={() => handleOpenModal(item)}
            className={`flex p-3 ${
              item.isview === 0 ? "bg-white font-bold" : "bg-gray-100"
            } shadow-customShadow rounded-md justify-between mb-2 transform transition duration-500 hover:scale-105 cursor-pointer`}
          >
            <span className="text-sm text-customGrey">{item.name}</span>
            <span className="text-sm text-primary">
              {dayjs(item.insymd).format("YYYY-MM-DD")}
            </span>
          </div>
        ))}
      <Components.CustomModal
        isOpen={isOpen}
        onClose={handleCancel}
        footer={null}
        width={500}
      >
        <div className="text-center flex flex-col items-center justify-center">
          <img src={ringing} alt="ring" className="w-8 my-2" />
          <h3>{selected.name}</h3>
          <p>{selected.body}</p>
        </div>
      </Components.CustomModal>
    </div>
  );
}
export default Index;
