import React from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import * as Components from "../../components";
import logo from "../../assets/logos/logo_word.png";
import { motion, AnimatePresence } from "framer-motion";
import { pageMotion } from "../../utils/enums";
import { LoginContainer } from "../../components";

function Register(props) {
  const location = useLocation();
  return (
    <LoginContainer>
    <div className="flex items-center h-full justify-center bg-white">
      {/* <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-4/6 sm:p-8 lg:p-24 rounded-md border border-gray-200 drop-shadow-xl md:px-64"> */}
        {/* <div className="lg:grid grid-cols-2 sm:grid grid-cols-0 w-full items-center flex flex-col text-center md:mt-32 lg:mt-12 xl:mt-24 2xl:mt-0"> */}
          {/* <div className="col-span-1 w-full h-full sm:hidden lg:block">
            <img className="w-36 h-36 mx-auto" src={logo} alt="logo" />
            <p className="font-bold text-lg mt-20">ТУРШААД ҮЗ</p>
            <p className="text-gray-500 text-md">
              24 цаг тасралтгүй Онлайн горимоор ажиллан захиалга, борлуулалтаа
              хялбараар удирдах хоол захиалгын “Оньс Фүүд” системийг танд санал
              болгож байна.
            </p>
          </div> */}
          {/* Route ийн дагуу бүртгүүлэх эсвэл нууц үг сэргээх component-үүд үүсгэж зурах хэсэг */}
          <div className="w-full h-full sm:col-span-2 lg:col-span-1 lg:mt-0">
            <img
              className="w-36 h-36 mx-auto lg:hidden mb-8"
              src={logo}
              alt="logo"
            />
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route
                  path="maininfo"
                  element={
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageMotion}
                    >
                      <Components.Register {...props} />
                    </motion.div>
                  }
                />
                <Route
                  path="subInfo"
                  element={
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageMotion}
                    >
                      <Components.SubInfo {...props} />
                    </motion.div>
                  }
                />
                <Route
                  path="verify"
                  element={
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageMotion}
                    >
                      <Components.Verify {...props} />
                    </motion.div>
                  }
                />
                <Route
                  path="success"
                  element={
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageMotion}
                    >
                      <Components.Success />
                    </motion.div>
                  }
                />
                <Route
                  path="forgetpass"
                  element={
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageMotion}
                    >
                      <Components.ForgetPass {...props} />
                    </motion.div>
                  }
                />
              </Routes>
            </AnimatePresence>
          </div>
        {/* </div> */}
      {/* </div> */}
    </div>
    </LoginContainer>
  );
}

export default Register;
