import { InputNumber, Form, Input } from "antd";

/* Захиалгийн нийт дүн болон төрлүүд */
function InputNumberForm({
  name,
  length,
  placeholder,
  errMsg,
  required,
  autoFocus,
  className,
  max,
  noStyle,
  onChange,
  disabled,
}) {
  const handleChangeInput = (e) => {
    if (onChange) {
      const prev = e?.target?.value?.replace(/\$\s?|(,*)/g, "");
      onChange(prev ? prev : 0);
    }
  };

  return (
    <>
      <Form.Item
        name={`w${name}`}
        className="mb-0"
        noStyle={noStyle}
        rules={[
          {
            required: required,
            message: errMsg,
          },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              const prev = value?.replace(/\$\s?|(,*)/g, "");
              if (prev <= max) {
                if (!isNaN(+prev)) {
                  setFieldsValue({
                    [`w${name}`]: new Intl.NumberFormat().format(prev),
                    [name]: Number(prev),
                  });
                } else {
                  setFieldsValue({
                    [`w${name}`]: new Intl.NumberFormat().format(
                      Number(prev?.slice(0, -1))
                    ),
                    [name]: Number(prev?.slice(0, 1)),
                  });
                }
              } else {
                setFieldsValue({
                  [`w${name}`]: new Intl.NumberFormat().format(
                    Number(prev?.slice(0, -1))
                  ),
                  [name]: Number(prev?.slice(0, 1)),
                });
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          className="w-36 font-bold text-right pay-input"
          onChange={handleChangeInput}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item name={name} hidden>
        <InputNumber placeholder={placeholder} disabled className="" />
      </Form.Item>
    </>
  );
}

export default InputNumberForm;
