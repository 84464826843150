import { useState, useEffect } from "react";
import { Button } from "antd";
import { HeartTwoTone } from "@ant-design/icons";
import { CATEGORYS } from "../../data";
import { getEnabledOptions } from "../../utils";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

/* Ангилалууд */
const btnStyle = "mb-2 w-full flex justify-center";
function OrderCategory(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [active, setActive] = useState();

  useEffect(() => {
    let type = active;
    if (active === undefined) type = 0;
    props.request({
      url: `/product/getProducts/${props._auth.foodid}/${type}`,
      model: "filteredproducts",
    });
  }, [active]);

  const filter = (type) => {
    if (type !== active) {
      setActive(type);
    }
  };

  const getActive = (type) => (type === active ? "active-btn" : "");
  const data = getEnabledOptions(props, "categorylist");
  //resize-x col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2
  return (
    <div
      className={`${
        isOpen ? "w-60" : "w-10"
      } bg-white  rounded-md flex flex-col h-full relative tansition transition-all`}
      style={{ marginRight: "0.75rem" }}
    >
      {isOpen ? (
        <div className="px-2 text-grey mt-2 font-bold">
          Ангилал - {data.length}
        </div>
      ) : (
        <div
          className="px-2 text-grey mt-2 font-bold"
          style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
        >
          Ангилал - {data.length}
        </div>
      )}

      {isOpen && (
        <div className="mt-3 h-full relative mb-10">
          <div className="px-2 absolute w-full top-0 bottom-0 overflow-y-auto">
            <Button
              className={`bg-primaryOpa ${btnStyle} ${getActive(-1)}`}
              onClick={() => filter(-1)}
            >
              <HeartTwoTone twoToneColor="#FF4D4F" />
              Best
            </Button>
            <Button
              className={`bg-buttonGrey ${btnStyle} ${getActive(0)}`}
              onClick={() => filter(0)}
            >
              Бүгд
            </Button>
            {data.map((item, i) => (
              <Button
                className={`${btnStyle} whitespace-normal h-12 py-2 ${getActive(
                  item.id
                )}`}
                key={i}
                onClick={() => filter(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </div>
        </div>
      )}
      <div
        className="flex items-center justify-center w-full py-2 bg-primary cursor-pointer bottom-0 absolute rounded-b-md"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <LeftOutlined className="text-white" />
        ) : (
          <RightOutlined className="text-white" />
        )}
      </div>
    </div>
  );
}

export default OrderCategory;
