import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import * as Components from "../";

const COLUMNS = [
  {
    title: "Орц",
    dataIndex: "materialname",
    key: "materialname",
    width: 300,
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitname",
    key: "unitname",
    width: 100,
  },
  {
    title: "Бохир жин",
    dataIndex: "gross",
    key: "gross",
    width: 150,
  },
  {
    title: "Цэвэр жин",
    dataIndex: "net",
    key: "net",
    width: 150,
  },
];

function FoodCardDescription({ form, changed, request, editData }) {
  const [data, setData] = useState({});
  useEffect(() => {
    if (changed.cardid || editData.cardid) {
      request({
        url: `/product/card/${
          changed.cardid ? changed.cardid : editData.cardid
        }`,
      }).then((res) => {
        if (res.success) setData(res.data);
      });
    } else {
      setData({});
      form.setFieldsValue({ cardid: null });
    }
  }, [changed.cardid, editData]);

  const removeCard = () => {
    form.setFieldsValue({ cardid: null });
    setData({});
  };

  return (
    <div className="grid grid-cols-12 gap-x-5">
      <div className="bg-backGrey col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-4 p-5">
        <Button type="primary" className="bg-primary" onClick={removeCard}>
          Устгах
        </Button>
        <div className="mt-2 flex">
          <div style={{ width: "7rem" }}>Картын дугаар: </div>
          <div>{data.card && data.card.id}</div>
        </div>
        <div className="mt-2 flex">
          <div style={{ width: "7rem" }}>Картын нэр:</div>
          <div className="break-words">{data.card && data.card.name}</div>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6">
        <Components.AntdTable
          columns={COLUMNS}
          dataSource={data.card ? data.detail : []}
          pagination={false}
          isModal={true}
        />
      </div>
    </div>
  );
}

export default FoodCardDescription;
