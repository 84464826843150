import { useState } from "react";
import { Form, Radio, Input } from "antd";
import { BillTypeData } from "../../data";
import { owntoast } from "../../utils";
function ChooseBillType(props) {
  const [loading, setLoading] = useState(false);

  const onChangeRegno = (e) => {
    props.setBillType((prev) => {
      if (prev !== e.target.value) props.form.setFieldsValue({ regno: "" });
      return e.target.value;
    });
  };
  const getTaxPayerName = (e) => {
    if ((e && e.length === 10) || e.length === 7) {
      if (props?._auth?.posapinew) {
        setLoading(true);
        props.request({ url: `/system/companytin/${e}` }).then((res) => {
          setLoading(false);
          if (res.success) {
            props.form.setFieldsValue({
              taxpayername: res.data.name,
              taxpayertin: res.data.tin,
            });
            owntoast(res.message, "success");
          } else owntoast(res.message, "error");
        });
      } else {
        setLoading(true);
        props.request({ url: `/system/company/${e}` }).then((res) => {
          setLoading(false);
          if (res.success) {
            props.form.setFieldsValue({ taxpayername: res.data.name });
            owntoast(res.message, "success");
          } else owntoast(res.message, "error");
        });
      }
    } else {
      owntoast("РД зөв оруулна уу.", "error");
    }
  };
  let isCompany = 1;
  return (
    <div>
      <div className="mt-3 mb-2">
        <Form.Item name="billType" initialValue={1} noStyle>
          <Radio.Group
            options={BillTypeData}
            disabled={props.isOwner}
            onChange={onChangeRegno}
          />
        </Form.Item>
      </div>
      {props.billType === 3 ? (
        <div>
          <div className="bg-labelGrey flex justify-between rounded">
            <span className="p-2">РД:</span>
            <Form.Item
              disabled={props.disabled}
              name="taxpayerregno"
              className="mb-0"
              rules={[
                {
                  required: true,
                  message: "РД оруулна уу.",
                },
                ({ setFieldsValue }) => ({
                  validator(_, value) {
                    if (value.length === 1) {
                      if (new RegExp(/\d/).test(value)) {
                        isCompany = 3;
                      } else if (new RegExp(/[А-Яа-яӨҮөү]/).test(value)) {
                        isCompany = 1;
                      }
                    }

                    if (
                      (value.length <= 7 && isCompany === 3) ||
                      (value.length <= 10 && isCompany === 1)
                    ) {
                      let regArray =
                        isCompany === 1
                          ? [
                              /[А-Яа-яӨҮөү]/,
                              /[А-Яа-яӨҮөү]/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]
                          : [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                      value.split("").map((char, charIndex) => {
                        if (new RegExp(regArray[charIndex]).test(char)) {
                          setFieldsValue({
                            taxpayerregno: value.toUpperCase(),
                          });
                        } else {
                          setFieldsValue({
                            taxpayerregno: value.slice(0, -1).toUpperCase(),
                          });
                        }
                      });
                    } else {
                      setFieldsValue({
                        taxpayerregno: value.slice(0, -1).toUpperCase(),
                      });
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Search
                placeholder="РД"
                enterButton="Шалгах"
                loading={loading}
                disabled={loading || props.disabled}
                className={
                  props.disabled
                    ? "w-52 font-bold text-right pay-input rounded-lg"
                    : "w-52 font-bold text-right pay-input bg-primary rounded-lg"
                }
                onSearch={getTaxPayerName}
              />
            </Form.Item>
          </div>
          <div className="bg-labelGrey flex justify-between rounded">
            <span className="p-2">ТТ нэр:</span>
            <Form.Item
              name="taxpayername"
              className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Татвар төлөгчийн нэр оруулна уу.",
                },
              ]}
            >
              <Input
                loading={loading}
                disabled={loading || props.disabled}
                placeholder="Татвар төлөгчийн нэр"
                className="w-52 font-bold text-right pay-input"
              />
            </Form.Item>
          </div>
          {props?._auth?.posapinew ? (
            <div className="bg-labelGrey flex justify-between rounded">
              <span className="p-2">Тин дугаар:</span>
              <Form.Item
                name="taxpayertin"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: "Тин дугаар оруулна уу.",
                  },
                ]}
              >
                <Input
                  loading={loading}
                  disabled={true}
                  placeholder="Тин дугаар"
                  className="w-52 font-bold text-right pay-input"
                />
              </Form.Item>
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <div className="bg-labelGrey flex justify-between rounded mt-2">
        <span className="p-2">Нэр:</span>
        <Form.Item name="name" className="mb-0" noStyle>
          <Input placeholder="Нэр" className="w-36 font-bold text-right pay-input" disabled />
        </Form.Item>
      </div> */}
    </div>
  );
}

export default ChooseBillType;
