import { useState, useEffect } from "react";
import { Button, Spin } from "antd";

import { OrderListColumns } from "../../data/Columns";
import * as Components from "../";
import { KitchenCard } from "../../components";
import Card from "./card";

/* Хүлээгдэж буй захиалгийн жагсаалт */
function OrderCard(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOrder, setIsopenOrder] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);

  useEffect(() => {
    if (isOpen && !isOpenOrder) {
      props.request({ url: "/sale/orderlist", model: "orderlistall" });
    }
  }, [isOpen, isOpenOrder]);

  const getOrderDetail = (id) => {
    if (props.saleList && props.saleList.length) {
      if (props.prevDetail && props.prevDetail.length && props.prevOrder) {
        if (
          JSON.stringify(props.prevDetail) !== JSON.stringify(props.saleList) ||
          props.activeTable !== props.prevOrder?.tableid
        ) {
          props.setIsOpen(true);
        } else {
          getDetail(id);
        }
      } else {
        getDetail(id);
      }
    } else {
      getDetail(id);
    }
  };

  const getDetail = (id) => {
    setActiveOrder(id);
    props?.getOrderDetail(id);
  };

  const hanldeNew = () => {
    if (props.saleList && props.saleList.length) {
      if (props.prevDetail && props.prevDetail.length && props.prevOrder) {
        if (
          JSON.stringify(props.prevDetail) !== JSON.stringify(props.saleList) ||
          props.activeTable !== props.prevOrder?.tableid
        ) {
          props.setIsOpen(true);
        } else {
          handleReset();
        }
      } else {
        handleReset();
      }
    } else {
      handleReset();
    }
  };

  const handleReset = () => {
    props.setActiveTable(null);
    props.setOrderDetail({
      order: {},
      detail: [],
    });
    localStorage.removeItem("deliveryLocation");
    localStorage.removeItem("waiter");
  };

  const orderClick = (row) => {
    props.request({
      url: `/sale/kitchen/kass/${row?.id}`,
      model: "kitchenOrder",
    });
    setIsopenOrder(true);
  };

  return (
    <Spin spinning={props.isongoingorders || props?.loading}>
      <div className="py-2 px-1 flex-none bg-white rounded-md mb-3">
        <span className="text-grey px-2">
          Төлбөр хүлээгдэж буй захиалгууд -{" "}
          {props.resongoingorders && props.resongoingorders.length}
        </span>

        {/* grid grid-rows-2 2xl:grid-cols-3 xl:grid-cols-7 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 */}
        {/* grid-scroll-x-rows-2 gap-2*/}
        <div className="max-h-36 overflow-y-auto px-2 mt-1 grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2">
          <Button
            type="primary"
            className="flex items-center justify-center h-16 bg-primary"
            onClick={hanldeNew}
          >
            Шинэ
          </Button>
          <Button
            className="flex items-center justify-center h-16"
            onClick={() => setIsOpen(true)}
          >
            Бүгд
          </Button>
          {props?.resongoingorders?.map((item, i) => (
            <Card item={item} key={i} getOrderDetail={getOrderDetail} />
          ))}
        </div>
        <Components.CustomModal
          isOpen={isOpen}
          width={960}
          title="Захиалгын жагсаалт"
          onClose={() => setIsOpen(false)}
          footer={<Button onClick={() => setIsOpen(false)}>Хаах</Button>}
        >
          <div className="px-3 max-h-96 h-96">
            <Components.AntdTable
              scroll={{ y: "20rem" }}
              dataSource={props.resorderlistall && props.resorderlistall}
              loading={props.isorderlistall}
              columns={OrderListColumns(orderClick, props)}
              isModal={true}
              pagination={false}
            />
          </div>
        </Components.CustomModal>
        <Components.CustomModal
          isOpen={isOpenOrder}
          footer={null}
          width={500}
          onClose={() => setIsopenOrder(false)}
          title="Бэлтгэгдсэн захиалга өөрчлөх"
          bodyStyle={{ padding: 0, borderRadius: "2rem" }}
        >
          <div className="h-full">
            <div className=" flex flex-nowrap h-full gap-3">
              {props?.reskitchenOrder?.map((item, i) => (
                <div key={i} className="h-full w-full ">
                  <KitchenCard
                    {...item}
                    request={props.request}
                    showFooter={true}
                    close={() => setIsopenOrder(false)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Components.CustomModal>
      </div>
    </Spin>
  );
}

export default OrderCard;
