import { Button } from "antd";
import start from "../../assets/icons/star.svg";
import icClock from "../../assets/icons/icClock.svg";
import { CommentOutlined } from "@ant-design/icons";
import iLocation from "../../assets/icons/location.png";
const Index = (props) => {
  return (
    <Button
      className={`flex items-center justify-center h-16 z-50 ${
        props?.item?.isedited ? "pulse" : ""
      } `}
      onClick={() => {
        props?.getOrderDetail && props?.getOrderDetail(props?.item?.id);
      }}
    >
      {props?.item?.orderenum === 2 ? (
        <img
          src={start}
          alt="star"
          className="absolute top-0.5 h-fit left-0.5 w-4"
        />
      ) : null}
      {props?.item?.deliveryenum === 5 ? (
        <img
          src={iLocation}
          alt="star"
          className="absolute bottom-1 h-fit left-0.5 w-4"
        />
      ) : null}

      <div className="flex flex-col">
        {props?.item?.tablenm ? (
          <span className="text-[#d4b27f] font-bold">{`Ш: ${props?.item?.tablenm}`}</span>
        ) : null}
        <span>{`№ ${props?.item?.salenum}`}</span>
        {props?.item?.kioskname ? (
          <span className="text-[#d4b27f] font-bold">{`${props?.item?.kioskname}`}</span>
        ) : (
          <span className="text-[#d4b27f] font-bold">
            {props?.item?.ismobile === 1 ? "QRmenu" : "касс"}
          </span>
        )}
      </div>
      {props?.item?.tableprice ? (
        <img
          src={icClock}
          alt="clock"
          className="absolute w-4 h-4 top-0.5 right-0.5"
        />
      ) : null}

      {props?.item?.comments ? (
        <CommentOutlined className="text-primary absolute w-4 h-4 bottom-0.5 right-0.5" />
      ) : null}
    </Button>
  );
};

export default Index;
