import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { owntoast } from "../../utils";
const { Option } = Select;
const { TextArea } = Input;
function FoodInfo(props) {
  const { resuserconfig, resprovinces, resclasscodes } = props;

  const [district, setDistrict] = useState([]);
  const [commitee, setCommitee] = useState([]);
  const [proId, setProId] = useState(0);
  const [provinceId, setProvinceId] = useState(resuserconfig?.province || 0);
  const [districtid, setDistrictId] = useState(resuserconfig?.district || 0);

  useEffect(() => {
    if (props?.isLocation && props?.order?.locid) {
      setProvinceId(props?.order?.province);
      setDistrictId(props?.order?.district);
    }
  }, [props?.order]);

  /* Аймаг хот сонгосон үед түүнд харъялагдах сум дүүрэг дуудаж байгаа хүсэлт*/
  useEffect(() => {
    if (resprovinces && provinceId)
      props
        .request({ url: `/system/location/district/${provinceId}` })
        .then((res) => {
          if (res.success) {
            setDistrict(res.data);
          } else {
            owntoast(res.message, "error");
          }
        });
  }, [provinceId, resprovinces]);

  const changeProvince = (e) => {
    setProId(e);
    props.request({ url: `/system/location/district/${e}` }).then((res) => {
      if (res.success) {
        setDistrict(res.data);
      } else {
        owntoast(res.message, "error");
      }
    });
  };
  /* Сум дүүрэг сонгосон үед түүнд харъялагдах баг хороо дуудаж байгаа хүсэлт*/
  useEffect(() => {
    if (provinceId) {
      if (district)
        props
          .request({
            url: `/system/location/commitee/${provinceId}/${districtid}`,
          })
          .then((res) => {
            if (res.success) {
              setCommitee(res.data);
            } else {
              owntoast(res.message, "error");
            }
          });
    }
  }, [district]);

  const changeDistrict = (e) => {
    props
      .request({
        url: `/system/location/commitee/${proId ? proId : provinceId}/${e}`,
      })
      .then((res) => {
        if (res.success) {
          setCommitee(res.data);
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const renderOptions = (data, label, value) =>
    data &&
    data.map((item, i) => (
      <Option key={i} value={item[value]}>
        {`${item[label]}`}
      </Option>
    ));

  return (
    <div className={`flex flex-col ${props?.isLocation ? "gap-2" : ""}`}>
      {!props?.isLocation && (
        <div className="relative">
          <span className="input-top-label">Байгууллагын дугаар</span>
          <Form.Item name="foodid">
            <Input placeholder="Байгууллагын дугаар" disabled />
          </Form.Item>
        </div>
      )}
      {!props?.isLocation && (
        <div className="relative">
          <span className="input-top-label">Байгууллагын нэр</span>
          <Form.Item name="foodsname">
            <Input placeholder="Байгууллагын нэр" />
          </Form.Item>
        </div>
      )}
      {!props?.isLocation && (
        <div className="relative">
          <span className="input-top-label">Чиглэл</span>
          <Form.Item name="classid">
            <Select placeholder="Чиглэл" showSearch optionFilterProp="children">
              {renderOptions(resclasscodes, "uname", "id")}
            </Select>
          </Form.Item>
        </div>
      )}
      {!props?.isLocation && (
        <div className="relative">
          <span className="input-top-label">Утасны дугаар</span>
          <Form.Item name="foodsphone">
            <Input placeholder="Утасны дугаар" />
          </Form.Item>
        </div>
      )}

      <div className="relative">
        <span className="input-top-label">Хот/Аймаг</span>
        <Form.Item
          name="province"
          rules={[
            {
              required: props?.isLocation,
              message: "Хот аймаг сонгоно уу.",
            },
          ]}
        >
          <Select
            placeholder="Хот/Аймаг"
            showSearch
            optionFilterProp="children"
            onChange={changeProvince}
          >
            {renderOptions(resprovinces, "provincenm", "provinceid")}
          </Select>
        </Form.Item>
      </div>
      <div className="relative">
        <span className="input-top-label">Дүүрэг/Сум</span>
        <Form.Item
          name="district"
          rules={[
            {
              required: props?.isLocation,
              message: "Дүүрэг сум сонгоно уу.",
            },
          ]}
        >
          <Select
            placeholder="Дүүрэг/Сум"
            showSearch
            optionFilterProp="children"
            onChange={changeDistrict}
          >
            {renderOptions(district, "districtnm", "districtid")}
          </Select>
        </Form.Item>
      </div>
      <div className="relative">
        <span className="input-top-label">Баг/Хороо</span>
        <Form.Item
          name="locid"
          rules={[
            {
              required: props?.isLocation,
              message: "Баг хороо сонгоно уу.",
            },
          ]}
        >
          <Select
            placeholder="Баг/Хороо"
            showSearch
            optionFilterProp="children"
          >
            {renderOptions(commitee, "commiteenm", "id")}
          </Select>
        </Form.Item>
      </div>
      <div className="relative">
        <span className="input-top-label">Хаяг</span>
        <Form.Item name="address">
          <TextArea
            placeholder="Хаяг оруулна уу..."
            rows={3}
            maxLength={300}
            showCount
          />
        </Form.Item>
      </div>
    </div>
  );
}
export default FoodInfo;
