import { IsEnable, UserPerm } from "../utils/enums";
import * as Columns from "./Columns";
import * as Components from "../components";
import Maps from "../components/Maps";
import FoodInfo from "../components/FoodInfo";
import { priceDecimal } from "../utils";

import dayjs from "dayjs";

const IsEnableOptions = [
  {
    label: "Идэвхтэй",
    value: IsEnable.Идэвхтэй,
  },
  {
    label: "Идэвхгүй",
    value: IsEnable.Идэвхгүй,
  },
];

const PrintOptions = [
  {
    label: "Хэвлэнэ",
    value: IsEnable.Идэвхтэй,
  },
  {
    label: "Хэвлэхгүй",
    value: IsEnable.Идэвхгүй,
  },
];

export const YesNoOptions = [
  {
    label: "Тийм",
    value: IsEnable.Идэвхтэй,
  },
  {
    label: "Үгүй",
    value: IsEnable.Идэвхгүй,
  },
];

/* Хоолны бүртгэлийн формууд */
export const FoodAddForms = {
  isFile: true,
  url: "/product",
  method: "POST",
  isPopup: true,
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "barcode",
        label: "Код",
        type: "input",
        maxLength: 10,
        showCount: true,
        normalize: "number",
      },
      {
        name: "name",
        label: "Нэр",
        type: "input",
        maxLength: 50,
        required: true,
        showCount: true,
      },
      {
        name: "portionid",
        label: "Порц",
        type: "radiobutton",
        optionType: "button",
        fieldNames: { label: "name", value: "id" },
        options: "portionlist",
      },
      {
        name: "catid",
        label: "Хоолны ангилал",
        type: "select",
        options: "categorylist",
        allowClear: true,
        fieldNames: { label: "name", value: "id" },
      },
      {
        name: "sprice",
        label: "Үнэ",
        type: "number",
        rules: ({ setFieldsValue }) => ({
          validator(_, value) {
            let regex = new RegExp("^[0-9]{1,10}$");
            if (!regex.test(value))
              return Promise.reject(
                new Error("Дээд тал нь 10 оронтой тоо оруулах боломжтой.")
              );
            else {
              /* setFieldsValue({ sprice: new Intl.NumberFormat().format(value) }); */
              return Promise.resolve();
            }
          },
        }),
        required: true,
      },
      {
        name: "unitname",
        label: "Хэмжих нэгж",
        type: "select",
        required: true,
        fieldNames: { label: "name", value: "name" },
        options: "unitlist",
        linkBtn: "/management/settings/4/5/new",
        linkTooltip: "Шинэ хэмжих нэгж бүртгэх",
      },
      {
        name: "hastax",
        label: "НХАТ тооцох эсэх",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "isunit",
        label: "Бараа эсэх",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "printkitchen",
        required: true,
        label: "Гал тогоонд хэвлэх эсэх",
        type: "select",
        options: IsEnableOptions,
        required: true,
      },
      {
        name: "description",
        label: "Тайлбар",
        type: "textArea",
        rows: 3,
      },
      {
        name: "spices",
        label: "Нэмэлт",
        type: "select",
        options: "spiceslist",
        // isenable: true,
        fieldNames: { label: "name", value: "id" },
        linkBtn: "/management/menu/2/5/new",
        linkTooltip: "Нэмэлт бүртгэх",
        mode: "multiple",
        allowClear: true,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
      {
        name: "cardid",
        label: "Хоолны карт",
        type: "select",
        options: "foodcardlist",
        isChange: true,
        fieldNames: { label: "name", value: "id" },
      },
    ],
  },
  rightForms: {
    form: [
      {
        name: "imgpos",
        label: "Зураг /ПОС/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgmobile",
        label: "Зураг /Утас/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgtv",
        label: "Зураг /Заал/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
  BottomComponent: Components.FoodCardDesciption,
};

/* Сэт хоолны бүртгэлийн формууд */
export const SetFoodAddForms = {
  isFile: true,
  nextIsFile: false,
  url: "/set",
  checkArr: "products",
  error: "Бүтээгдэхүүн хоосон байж болохгүй",
  nextUrl: "/set/prods",
  nextUrlMessage: true,
  isBack: true,
  method: "POST",
  isClear: true,
  isMessage: true,
  isPopup: true,
  isRefresh: true,
  helps: [
    {
      message:
        "Та бүртгэсэн сэт-ийг борлуулсан бол зарах үнэ засах боломжгүйг анхаарна уу?",
      type: "warning",
    },
  ],
  leftForms: {
    form: [
      {
        name: "name",
        label: "Сэтний нэр",
        type: "input",
        maxLength: 50,
        required: true,
        showCount: true,
      },
      {
        name: "catid",
        label: "Ангилал",
        type: "select",
        options: "categorylist",
        fieldNames: { label: "name", value: "id" },
      },
      {
        name: "detailqty",
        label: "Бүтээгдэхүүний тоо",
        type: "input",
        disabled: true,
      },
      {
        name: "amount",
        label: "Багцын үнэ",
        type: "number",
        disabled: true,
      },
      {
        name: "price",
        label: "Зарах үнэ",
        type: "number",
        rules: ({ setFieldsValue }) => ({
          validator(_, value) {
            let regex = new RegExp("^[0-9]{1,10}$");
            if (!regex.test(value))
              return Promise.reject(
                new Error("Дээд тал нь 10 оронтой тоо оруулах боломжтой.")
              );
            else {
              /* setFieldsValue({ sprice: new Intl.NumberFormat().format(value) }); */
              return Promise.resolve();
            }
          },
        }),
        required: true,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
      {
        name: "imgpos",
        label: "Зураг /ПОС/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgmobile",
        label: "Зураг /Утас/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgtv",
        label: "Зураг /Заал/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
  rightForms: {
    name: "products",
    buttonText: "Шинэ бүтээгдэхүүн нэмэх",
    isModal: true,
    Component: Components.FoodCardTable,
    columns: Columns.SetFoodDetailColumns,
  },
  initRequests: [
    {
      url: "/product/getProducts/$foodid/0",
      model: "foodlist",
    },
  ],
};

/* Нэмэлтны бүртгэлийн формууд */
export const SeosoningAddForms = {
  isFile: true,
  url: "/spices",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        label: "Нэмэлтийн нэр",
        type: "input",
        maxLength: 50,
        required: true,
        showCount: true,
      },
      /* {
        name: "isonly",
        label: "Дангаар нь зарах эсэх",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "price",
        label: "Үнэ",
        type: "number",
        rules: ({ getFieldsValue }) => ({
          validator(_, value) {
            let regex = new RegExp("^[0-9]{1,10}$");
            let test = getFieldsValue("isonly");
            if (test.isonly) {
              if (!value)
                return Promise.reject(new Error("Үнэ 0 байж болохгүй"));
              else if (!regex.test(value)) {
                return Promise.reject(
                  new Error("Дээд тал нь 10 оронтой тоо оруулах боломжтой.")
                );
              } else return Promise.resolve();
            } else return Promise.resolve();
          },
        }),
      }, */
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
  rightForms: {
    form: [
      {
        name: "imgpos",
        label: "Зураг /ПОС/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgmobile",
        label: "Зураг /Утас/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "imgtv",
        label: "Зураг /Заал/",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
};

/* Хоолны картын формууд */
export const FoodCardForms = {
  url: "/material/addFoodCard",
  method: "POST",
  isCheckDField: true,
  isClear: true,
  isMessage: true,
  isRefresh: true,
  isPrint: true,
  leftForms: {
    form: [
      {
        name: "name",
        label: "Хоолны картын нэр",
        type: "input",
        maxLength: 100,
        required: true,
        showCount: true,
      },
      {
        name: "portionid",
        label: "Порц",
        type: "radiobutton",
        optionType: "button",
        fieldNames: { label: "name", value: "id" },
        options: "portionlist",
      },
      {
        name: "manual",
        label: "Заавар",
        type: "editor",
        rows: 6,
      },
    ],
  },
  rightForms: {
    name: "materials",
    url: "/material/foodCard",
    method: "GET",
    Component: Components.FoodCardTable,
    columns: Columns.FoodCardDetailColumns,
  },
};

/* Материалын лавлахын формууд */
export const MaterialForms = {
  url: "/material/post",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  helps: [
    {
      message:
        "Материалуудаа бүртгэснээр хоолны орцоо тооцох, материалын татан авалтаа хийх боломжтой",
      type: "warning",
    },
  ],
  leftForms: {
    form: [
      {
        name: "name",
        label: "Материалын нэр",
        type: "input",
        maxLength: 30,
        showCount: true,
        required: true,
      },
      {
        name: "unitid",
        label: "Хэмжих нэгж",
        type: "select",
        required: true,
        fieldNames: { label: "name", value: "id" },
        options: "unitlist",
        linkBtn: "/management/settings/4/5/new",
        linkTooltip: "Шинэ хэмжих нэгж бүртгэх",
      },
      {
        name: "catid",
        label: "Материалын ангилал",
        type: "select",
        options: "catmateriallist",
        allowClear: true,
        fieldNames: { label: "name", value: "id" },
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

/* Барааны татан авалтын формууд */
export const ProductBuyingForms = {
  isCheckDField: true,
  url: "/product/buy",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  checkArr: "detail",
  error: "Материал хоосон байж болохгүй",
  helps: [
    {
      message: "Барааны татан авалтаа бүртгэж, тайлангаа хараарай",
      type: "warning",
    },
  ],
  leftForms: {
    form: [
      {
        name: "logymd",
        label: "Огноо",
        required: true,
        defaultValue: dayjs(),
        type: "datepicker",
      },
      {
        name: "supplier",
        label: "Нийлүүлэгч",
        required: true,
        maxLength: 50,
        showCount: true,
        type: "input",
      },
      {
        name: "qty",
        label: "Барааны тоо",
        type: "input",
        disabled: true,
      },
      {
        name: "amount",
        label: "Нийт дүн",
        type: "number",
        disabled: true,
      },
    ],
  },
  rightForms: {
    name: "detail",
    url: "/product/buydetail",
    method: "GET",
    buttonText: "Шинэ бараа",
    Component: Components.FoodCardTable,
    columns: Columns.ProductBuyingDetailColumns,
  },
};

/* Материалын татав авалтын формууд */
export const MaterialBuyingForms = {
  isCheckDField: true,
  url: "/material/buy",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  checkArr: "detail",
  error: "Материал хоосон байж болохгүй",
  helps: [
    {
      message: "Материалын татан авалтаа бүртгэж, тайлангаа хараарай",
      type: "warning",
    },
  ],
  leftForms: {
    form: [
      {
        name: "logymd",
        label: "Огноо",
        required: true,
        defaultValue: dayjs(),
        type: "datepicker",
      },
      {
        name: "supplier",
        label: "Нийлүүлэгч",
        required: true,
        maxLength: 50,
        showCount: true,
        type: "input",
      },
      {
        name: "qty",
        label: "Материалын тоо",
        type: "input",
        disabled: true,
      },
      {
        name: "amount",
        label: "Нийт дүн",
        type: "numbercustom",
        // type: "number",
        disabled: true,
      },
    ],
  },
  rightForms: {
    name: "detail",
    url: "/material/buydetail",
    method: "GET",
    buttonText: "Шинэ материал",
    Component: Components.FoodCardTable,
    columns: Columns.MaterialBuyingDetailColumns,
  },
};

export const MaterialGroupForms = {
  isCheckDField: true,
  url: "/category/post/categorymaterial",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  error: "Ангилал хоосон байж болохгүй",
  leftForms: {
    form: [
      {
        name: "name",
        label: "Ангиллын нэр",
        required: true,
        maxLength: 50,
        showCount: true,
        type: "input",
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

/* Хоолны газрын мэдээлэлийн формууд */
export const BaseSettings = {
  method: "PUT",
  url: "/user/editfood",
  isMessage: true,
  isRefresh: true,
  leftForms: {
    Component: FoodInfo,
  },
  rightForms: {
    Component: Maps,
  },
};

/* Кассын тохиргооний формууд */
export const CashierSettings = {
  url: "/system/printer/post",
  method: "POST",
  isMessage: true,
  isRefresh: true,
  isFile: true,
  leftForms: {
    form: [
      {
        name: "logo",
        label: "Баримтын лого",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "printqty",
        type: "number",
        label: "Захиалгын хуудас хэвлэх хувь",
        required: true,
        defaultValue: 1,
        maxLength: 1,
      },
      {
        name: "isprint",
        type: "checkbox",
        valuePropName: "checked",
        setDisabled: "isprintdetail",
        label: "Захиалга өөрчлөхөд захиалгын баримт хэвлэх эсэх",
      },
      {
        name: "isprintdetail",
        type: "radiobutton",
        label: "Төрөл",
        itemClass: "mt-2",
        options: [
          {
            value: 0,
            label: "Захиалгын баримтыг бүтнээр хэвлэх",
          },
          {
            value: 1,
            label: "Зөвхөн өөрчлөгдсөн хоолнуудыг хэвлэх",
          },
        ],
      },
      {
        name: "footer",
        type: "input",
        label: "Баримтын сүүл хэсэг",
        required: true,
      },
      {
        name: "printertype",
        type: "radiobutton",
        optionType: "button",
        label: "Цаасны хэмжээ",
        required: true,
        options: [
          {
            value: 1,
            label: "58mm",
          },
          {
            value: 2,
            label: "80mm",
          },
        ],
      },
      {
        name: "fee",
        type: "number",
        label: "Үйлчилгээний нэмэгдэл тооцох %",
        defaultValue: 0,
        maxLength: 2,
        addonAfter: "%",
      },
      {
        name: "warning",
        type: "warning",
        message: (
          <div>
            Борлуулалтын үнийн дүнгээс тус %-р нэмэгдэл төлбөр тооцно. <br /> Та
            Нийслэл хотын албан татвар төлөгч бол тус нэмэгдэлд НХАТ тооцно.
          </div>
        ),
      },
    ],
  },
};

/* Гал тогоо тохиргооний формууд */
export const KithchenSettings = {
  url: "/system/printkitchen/post",
  method: "POST",
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "kitchenname",
        type: "input",
        label: "Принтерийн нэр",
        required: true,
      },
      {
        name: "kitchenip",
        type: "input",
        label: "Принтерийн IP хаяг",
        required: true,
      },
      {
        name: "kitchentype",
        type: "radiobutton",
        optionType: "button",
        label: "Цаасны хэмжээ",
        required: true,
        options: [
          {
            value: 1,
            label: "58mm",
          },
          {
            value: 2,
            label: "80mm",
          },
        ],
      },
      {
        name: "catids",
        label: "Ангилал",
        type: "select",
        options: "categorylist",
        mode: "multiple",
        allowClear: true,
        fieldNames: { label: "name", value: "id" },
      },
      {
        name: "kitchenisenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: PrintOptions,
      },
    ],
  },
};

/* Татварийн тохиргооны формууд */
export const VatSettings = (props) => {
  return {
    noButton: true,
    leftForms: {
      form: [
        {
          name: "isposapi",
          type: "checkbox",
          valuePropName: "checked",
          label: `Татварын холболт /${
            props?._auth?.posapinew ? "3.0" : "2.0"
          }/`,
          disabled: true,
        },
        props?._auth?.posapinew && {
          name: "tin",
          type: "input",
          label: "Тин дугаар",
          disabled: true,
        },
        {
          name: "vatymd",
          type: "input",
          label: "Татварын холболт хийсэн огноо",
          disabled: true,
        },
        {
          name: "regno",
          type: "input",
          label: "Татвар төлөгчийн дугаар",
          disabled: true,
        },
        {
          name: "vatname",
          type: "input",
          label: "Татвар төлөгчийн нэр",
          disabled: true,
        },
        {
          name: "hasvat",
          type: "checkbox",
          valuePropName: "checked",
          label: "НӨАТ төлөгч",
          disabled: true,
        },
        {
          name: "hastax",
          type: "checkbox",
          valuePropName: "checked",
          label: "НХАТ төлөгч",
          disabled: true,
        },
      ],
    },
  };
};

/* Зардалын төрлийн формууд */
export const CostTypeSettings = {
  url: "/cost/costtype/post",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  helps: [
    {
      message: "Та зардал үүсгэвэл тухайн зардал нь хоолны өртөгт тооцогдоно.",
      type: "warning",
    },
  ],
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Зардлын нэр",
        type: "textArea",
      },
      {
        name: "amount",
        required: true,
        label: "Зардлын дүн",
        maxLength: 10,
        type: "number",
        rules: ({ setFieldsValue }) => ({
          validator(_, value) {
            let regex = new RegExp(priceDecimal);
            if (value > 0) {
              if (!regex.test(value))
                return Promise.reject(new Error("2 оронгийн нарийвчлалтай."));
            } else {
              return Promise.reject(
                new Error("Хасах утгатай дүн оруулж болохгүй")
              );
            }
            return Promise.resolve();
          },
        }),
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

/* Захилагын  төрөлийн формууд */
export const OrderTypeSettings = {
  url: "/order/ordertype/post",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  leftForms: {
    form: [
      {
        name: "enumid",
        required: true,
        label: "Нэр",
        type: "select",
        options: [
          {
            label: "Захиалга",
            value: 1,
          },
          {
            label: "Хүргэлт",
            value: 2,
          },
        ],
        isChange: true,
      },
      {
        name: "typeid",
        required: true,
        label: "Төрөл",
        type: "select",
        depends: "enumid",
        options_1: [
          {
            label: "Энгийн",
            value: 1,
          },
          {
            label: "Яаралтай",
            value: 2,
          },
        ],
        options_2: [
          {
            label: "Зааланд",
            value: 3,
          },
          {
            label: "Авч явах",
            value: 4,
          },
          {
            label: "Хүргэж өгөх",
            value: 5,
          },
        ],
      },
    ],
  },
};

/* Хэрэглэгчийн эрх бүртгэлийн формууд */
export const UserPermForms = {
  url: "/user/changeuser",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  editBtn: true,
  leftForms: {
    form: [
      {
        name: "name",
        label: "Нэр",
        type: "input",
        required: true,
      },
      {
        name: "roletype",
        label: "Түвшин",
        type: "select",
        required: true,
        isChange: true,
        options: [
          {
            label: "Супер Админ",
            value: 1,
          },
          {
            label: "Админ",
            value: 2,
          },
          {
            label: "Кассчин",
            value: 3,
          },
          {
            label: "Менежер",
            value: 4,
          },
          {
            label: "Тогооч",
            value: 5,
          },
        ],
      },
      {
        name: "uname",
        label: "Нэвтрэх нэр",
        type: "input",
        required: true,
      },
      // {
      //   name: "password",
      //   label: "Нууц үг",
      //   type: "password",
      //   ishide: true,
      // rules: ({ setFieldsValue }) => ({
      //   validateTrigger: "onSubmit",
      //   validator(_, value) {
      //     let count = 8;
      //     let validPassword = new RegExp(
      //       "^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$"
      //     );
      //     if (validPassword.test(value)) {
      //       if (value.length < count) {
      //         setFieldsValue({ password: value });
      //         return Promise.reject(
      //           new Error(
      //             "Нууц үг дор хаяж 8 буюу түүнээс дээш оронтой байна"
      //           )
      //         );
      //       }
      //     } else {
      //       setFieldsValue({ password: value });
      //       return Promise.reject(
      //         new Error("Нууц үг үсэг тоо агуулсан байх ёстой")
      //       );
      //     }
      //     return Promise.resolve();
      //   },
      // }),
      // },
    ],
  },
  rightForms: {
    form: [
      {
        name: "permission",
        label: "Хэрэглэгчийн эрх",
        type: "checkboxgroup",
        depends: "roletype",
        options: UserPerm,
      },
    ],
  },
};

export const PortionTypeSettings = {
  url: "/unitandportion/portion",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Нэр",
        type: "input",
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

export const CategorySettings = {
  url: "/category/post",
  method: "POST",
  isFile: true,
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Нэр",
        type: "input",
        maxLength: 50,
        showCount: true,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
      {
        name: "description",
        label: "Тайлбар",
        type: "textArea",
        rows: 3,
        maxLength: 200,
        showCount: true,
      },
      {
        name: "iskiosk",
        label: "Киоск дээр харуулах эсэх",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "icon",
        label: "Зураг",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
};

export const OrderWindowSettings = {
  url: "/order/orderconfig",
  method: "POST",
  isFile: true,
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "description",
        label: "Урсдаг тайлбар",
        type: "textArea",
        rows: 3,
        maxLength: 200,
        showCount: true,
      },
      {
        name: "logo",
        label: "Лого",
        type: "upload",
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
      {
        name: "img",
        label: "Зураг",
        type: "uploadlist",
        qty: 5,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
};

export const UnitTypeSettings = {
  url: "/unitandportion/unit",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "measureunit",
        required: true,
        label: "Нэр",
        type: "select",
        options: [
          {
            value: 1,
            label: "кг",
          },
          {
            value: 2,
            label: "гр",
          },
          {
            value: 3,
            label: "л",
          },
          {
            value: 4,
            label: "мл",
          },
          {
            value: 5,
            label: "ш",
          },
        ],
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

export const TimeEventSettings = {
  url: "/system/timelog",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "description",
        required: true,
        label: "Нэр",
        type: "input",
        showCount: true,
        maxLength: 50,
      },
      {
        name: "time",
        required: true,
        label: "Урамшууллын цаг",
        type: "number",
        showCount: true,
        maxLength: 2,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

export const WaiterSettings = {
  url: "/user/waiter",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Нэр",
        type: "input",
        showCount: true,
        maxLength: 50,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

export const KioskSettings = {
  isFile: true,
  url: "/kiosk/edit",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  ishorizontal: true,
  leftForms: {
    form: [
      {
        name: "bigbanner",
        label: "Үндсэн баннер /Нүүр хуудас болон үндсэн цонхнуудад харагдана/",
        type: "upload",
        noncrop: true,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋ 1078 x 1804",
      },
      {
        name: "smallbanner",
        label: "Баннер /Захиалгын хэсгийн толгой хэсэгт харагдана/",
        type: "upload",
        noncrop: true,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋ 1078 x 451",
      },
      {
        name: "logo",
        label: "Лого /Нүүр хуудсанд харагдана/",
        type: "upload",
        noncrop: true,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋ 215 x 225 ",
      },

      /* {
        name: "isprodfooter",
        label: "Карт дээр улаан харуулах эсэх",
        type: "checkbox",
        valuePropName: "checked",
      }, */
    ],
  },

  rightForms: {
    form: [
      {
        id: "https://food.onispos.mn/files/kioskicon/juice.png",
        name: "eat",
        label: "Захиалгын төрөл /Сууж идэх/",
        type: "image",
        alt: "Image",
      },
      {
        id: "https://food.onispos.mn/files/kioskicon/coffee.png",
        name: "eat",
        type: "image",
        alt: "Image",
      },
      // {
      //   id: "https://food.onispos.mn/files/kioskicon/fast-food.png",
      //   name: "eat",
      //   type: "image",
      //   alt: "Image",
      // },
      {
        id: "https://food.onispos.mn/files/kioskicon/fast-food.png",
        name: "eat",
        type: "image",
        alt: "Image",
      },
      {
        id: "https://food.onispos.mn/files/kioskicon/defaulteat.png",
        name: "eat",
        type: "image",
        alt: "Image",
      },
      {
        id: "https://food.onispos.mn/files/kioskicon/coffee-cups.png",
        name: "takeout",
        label: "Захиалгын төрөл /Авч явах/",
        type: "kioskimage",
        alt: "Image",
      },
      {
        id: "https://food.onispos.mn/files/kioskicon/take-away.png",
        name: "takeout",
        type: "kioskimage",
        alt: "Image",
      },
      // {
      //   id: "https://food.onispos.mn/files/kioskicon/take-away.png",
      //   name: "takeout",
      //   type: "kioskimage",
      //   alt: "Image",
      // },
      {
        id: "https://food.onispos.mn/files/kioskicon/takeaway.png",
        // id: "https://food.onispos.mn/files/kioskicon/coffee-cups.png",
        name: "takeout",
        type: "kioskimage",
        alt: "Image",
      },
      {
        id: "https://food.onispos.mn/files/kioskicon/defaulttakeaway.png",
        name: "takeout",
        type: "kioskimage",
        alt: "Image",
      },
    ],
  },
  /*   rightForms: {
    name: "kioskpreview",
    Component: KioskPreview,
  }, */
};

export const QRMenuSettings = {
  isFile: true,
  url: "/menu/config",
  method: "POST",
  // isClear: true,
  isMessage: true,
  isRefresh: true,
  ishorizontal: true,
  leftForms: {
    form: [
      {
        name: "note",
        label: "Тайлбар",
        type: "textArea",
        rows: 3,
      },
      {
        name: "img",
        label: "Зураг /лого/",
        type: "upload",
        noncrop: true,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
};

export const QRTemplateSettings = {
  url: "/menu/qrdesign",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "size",
        type: "radiobutton",
        optionType: "button",
        label: "Хэмжээ",
        required: true,
        options: [
          {
            value: 0,
            label: "8смх8см",
          },
          {
            value: 1,
            label: "16смх16см",
          },
        ],
      },
      {
        name: "tableid",
        label: "Ширээний дугаар харуулах",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "logo",
        label: "QR голд лого харуулах",
        type: "checkbox",
        valuePropName: "checked",
      },
      {
        name: "header",
        label: "Дээд хэсэг",
        type: "textArea",
        rows: 3,
      },
      {
        name: "footer",
        label: "Доод хэсэг",
        type: "textArea",
        rows: 3,
      },
      {
        name: "qrimg",
        label: "Зураг / Дэвсгэр / ",
        type: "upload",
        noncrop: true,
        desc: "2MB-с ихгүй багтаамжтай зураг оруулна уу᠋",
      },
    ],
  },
};

export const KioskDealerSettings = {
  url: "/kiosk/editpays",
  method: "POST",
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "pays",
        label: "Киоск дээр оруулах төлбөрийн төрөл",
        type: "checkboxgroup",
        options: "kioskdealers",
        fieldNames: { label: "name", value: "type" },
      },
      {
        name: "isqrpaper",
        required: true,
        defaultValue: 0,
        label: "Борлуулалтын баримт хэвлэх эсэх",
        type: "select",
        options: YesNoOptions,
      },
      {
        name: "isorderpaper",
        required: true,
        defaultValue: 0,
        label: "Захиалгын баримт хэвлэх эсэх",
        type: "select",
        options: YesNoOptions,
      },
      {
        name: "iskitchen",
        required: true,
        label: "Гал тогоо руу баримт хэвлэх эсэх",
        type: "select",
        defaultValue: 0,
        options: YesNoOptions,
      },
      {
        name: "iscashpay",
        required: true,
        label: "Кассанд төлөх сонголт харуулах эсэх",
        type: "select",
        defaultValue: 0,
        options: YesNoOptions,
      },
      // {
      //   name: "isunit",
      //   label: "Кассанд төлөх эсэх",
      //   type: "checkbox",
      //   valuePropName: "checked",
      // },
    ],
  },
  initRequests: [
    {
      url: "/kiosk/dealer",
      model: "kioskdealers",
    },
  ],
};

export const KioskDeviceSettings = {
  url: "/kiosk/device",
  method: "POST",
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Киоск төхөөрөмжийн нэр",
        type: "input",
        maxLength: 50,
        showCount: true,
      },
      {
        name: "terminalid",
        label: "Голомт IPPOS terminalid",
        type: "input",
        normalize: "number",
        maxLength: 10,
        showCount: true,
      },
      /* {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      }, */
    ],
  },
};

export const DinTableSettings = {
  url: "/category/dintable/post",
  method: "POST",
  helps: [
    {
      message:
        "Та ширээ үүсгэвэл захиалгаа бүртгэхдээ ширээний дугаар оруулж бүртгэх боломжтой",
      type: "warning",
    },
  ],
  isClear: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "name",
        required: true,
        label: "Ширээний дугаар",
        type: "input",
        inputType: "number",
        rules: () => ({
          validator(_, value) {
            let regex = new RegExp("^[0-9]{1,3}$");
            if (!regex.test(value))
              return Promise.reject(
                new Error("Дээд тал нь 3 оронтой тоо оруулах боломжтой.")
              );
            return Promise.resolve();
          },
        }),
      },
      {
        name: "description",
        label: "Тайлбар",
        type: "textArea",
        rows: 3,
      },
      {
        name: "price",
        label: "Ширээний цагийн үнэ",
        type: "input",
        maxLength: 10,
        showCount: true,
        normalize: "number",
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
    ],
  },
};

/* Голомт IPPOS-ийн формууд */
export const GolomtIPPOSSettings = {
  noButton: true,
  settlmentBtn: true,
  bankid: 1,
  leftForms: {
    form: [
      {
        name: "isenable",
        required: true,
        label: "Холболт",
        type: "select",
        options: IsEnableOptions,
        disabled: true,
      },
      {
        name: "terminalid",
        type: "input",
        label: "TerminalID",
        disabled: true,
      },
      {
        name: "insymd",
        type: "input",
        label: "Холболт хийсэн огноо",
        disabled: true,
      },
    ],
  },
};

export const SocialPaySettings = {
  noButton: true,
  leftForms: {
    form: [
      {
        name: "terminalid",
        type: "input",
        label: "TerminalID",
        disabled: true,
      },
      {
        name: "key",
        type: "input",
        label: "Key",
        disabled: true,
      },
      {
        name: "insymd",
        type: "input",
        label: "Холболт хийсэн огноо",
        disabled: true,
      },
    ],
  },
};

export const ArtlabSettings = {
  url: "/artlab/edit",
  method: "POST",
  artlabBtn: true,
  isMessage: true,
  isRefresh: true,
  leftForms: {
    form: [
      {
        name: "username",
        type: "input",
        label: "Username",
        disabled: true,
      },
      {
        name: "password",
        type: "input",
        label: "Password",
        disabled: true,
      },
      {
        name: "type",
        type: "select",
        label: "Төрөл",
        options: [
          {
            value: 1,
            label: "Борлуулалт",
          },
          {
            value: 2,
            label: "Бараа",
          },
        ],
        disabled: true,
      },
      {
        name: "artdaytime",
        type: "timepicker",
        label: "Автомат илгээх цаг",
      },
    ],
  },
};

export const QpaySettings = {
  url: "/dealer/qpay",
  method: "POST",
  isMessage: true,
  hideBackBtn: true,
  leftForms: {
    form: [
      {
        name: "iscompany",
        type: "radiobutton",
        optionType: "button",
        label: "Татвар төлөгчийн төрөл",
        defaultValue: true,
        required: true,
        options: [
          {
            value: true,
            label: "Хувь хүн",
          },
          {
            value: false,
            label: "Байгууллага",
          },
        ],
      },
      {
        name: "regno",
        type: "input",
        label: "Регистрийн дугаар",
        rules: ({ getFieldValue }) => ({
          validator(_, value) {
            let isperson = getFieldValue("iscompany");
            if (isperson) {
              if (value.length > 10)
                return Promise.reject(
                  new Error("10с дээш тэмдэгт байж болохгүй")
                );
              else if (value.length < 10)
                return Promise.reject(
                  new Error("10с дooш тэмдэгт байж болохгүй")
                );
            } else {
              if (value.length > 7)
                return Promise.reject(
                  new Error("7с дээш тэмдэгт байж болохгүй")
                );
              else if (value.length < 7)
                return Promise.reject(
                  new Error("7с дooш тэмдэгт байж болохгүй")
                );
            }
            return Promise.resolve();
          },
        }),
      },
      {
        name: "name",
        type: "input",
        label: "Байгууллагын нэр",
        checkRegno: true,
        isCompany: true,
      },
      {
        name: "lastname",
        type: "input",
        label: "Овог",
        checkRegno: true,
        isCompany: false,
      },
      {
        name: "firstname",
        type: "input",
        label: "Нэр",
        checkRegno: true,
        isCompany: false,
      },
      {
        name: "mail",
        type: "input",
        label: "Имэйл",
      },
      {
        name: "phoneno",
        type: "input",
        label: "Утас",
      },
      {
        name: "bankcode",
        type: "select",
        isChange: true,
        label: "Банк",
        options: [
          {
            label: "Монгол банк",
            value: "010000",
          },
          {
            label: "Капитал банк",
            value: "020000",
          },
          {
            label: "Худалдаа хөгжлийн банк",
            value: "040000",
          },
          {
            label: "Хаан банк",
            value: "050000",
          },
          {
            label: "Голомт банк",
            value: "150000",
          },
          {
            label: "Тээвэр хөгжлийн банк",
            value: "190000",
          },
          {
            label: "Ариг банк",
            value: "210000",
          },
          {
            label: "Кредит банк",
            value: "220000",
          },
          {
            label: "ҮХО банк",
            value: "290000",
          },
          {
            label: "Капитрон банк",
            value: "300000",
          },
          {
            label: "Хас банк",
            value: "320000",
          },
          {
            label: "Чингисхаан банк",
            value: "330000",
          },
          {
            label: "Төрийн банк",
            value: "340000",
          },
          {
            label: "Хөгжлийн банк",
            value: "360000",
          },
          {
            label: "Богд банк",
            value: "380000",
          },
          {
            label: "Төрийн сан",
            value: "900000",
          },
          {
            label: "Мобифинанс",
            value: "990000",
          },
          {
            label: "М банк",
            value: "390000",
          },
          {
            label: "Инвэскор ББСБ",
            value: "993000",
          },
        ],
      },
      {
        name: "bankname",
        type: "input",
        label: "bankname",
        hidden: true,
      },
      {
        name: "acccode",
        type: "input",
        label: "Дансны дугаар",
        required: true,
        showCount: true,
        maxLength: 20,
      },
      {
        name: "accname",
        type: "input",
        label: "Данс эзэмшигч",
        required: true,
        showCount: true,
        maxLength: 30,
      },
      {
        name: "terminalid",
        type: "password",
        label: "Merchant ID",
        disabled: true,
      },
      {
        name: "key",
        type: "password",
        label: "Vendor ID",
        disabled: true,
      },
      {
        name: "isenable",
        required: true,
        label: "Төлөв",
        type: "select",
        options: IsEnableOptions,
      },
      // {
      //   name: "address",
      //   type: "textArea",
      //   label: "Хаяг",
      // },
      {
        name: "insymd",
        type: "input",
        label: "Холболт хийсэн огноо",
        disabled: true,
      },
    ],
  },
};

/* Databank-ийн формууд */
export const DatabankSettings = {
  noButton: true,
  leftForms: {
    form: [
      {
        name: "isenable",
        required: true,
        label: "Холболт",
        type: "select",
        options: IsEnableOptions,
        disabled: true,
      },
      {
        name: "insymd",
        type: "input",
        label: "Холболт хийсэн огноо",
        disabled: true,
      },
    ],
  },
};

/* MinuPay-ийн формууд */
export const MidSettings = {
  noButton: true,
  leftForms: {
    form: [
      {
        name: "isenable",
        required: true,
        label: "Холболт",
        type: "select",
        options: IsEnableOptions,
        disabled: true,
      },
      {
        name: "merchantid",
        type: "input",
        label: "MerchantID",
        disabled: true,
      },
      {
        name: "terminalid",
        type: "input",
        label: "TerminalID",
        disabled: true,
      },
    ],
  },
};

/* Most Money-ийн формууд */
export const MostSettings = {
  noButton: true,
  leftForms: {
    form: [
      {
        name: "isenable",
        required: true,
        label: "Холболт",
        type: "select",
        options: IsEnableOptions,
        disabled: true,
      },
      {
        name: "payeecode",
        type: "input",
        label: "MerchantID",
        disabled: true,
      },
      {
        name: "posno",
        type: "input",
        label: "PosID",
        disabled: true,
      },
    ],
  },
};
