import React, { useState } from "react";
import { Checkbox } from "antd";

function CheckBox() {
  const [checked, setChecked] = useState(true);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const label = `${checked ? "Тйим" : "Үгүй"}`;
  return (
    <Checkbox checked={checked} onChange={onChange}>
      {label}
    </Checkbox>
  );
}

export default CheckBox;
