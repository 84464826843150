import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button } from "antd";
import * as Components from "../../components";
import { useWindowSize } from "../../hooks/useWindowSize";
import sad from "../../assets/sad.png";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { SalePerm } from "../../utils/enums";
import { owntoast, getEnabledOptions } from "../../utils";
import notfSound from "../../assets/sound/notification.mp3";
function Sale(props) {
  const orderRef = useRef();
  const data = getEnabledOptions(props, "dintablelist");
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const [activeTable, setActiveTable] = useState(null);
  const [activeDelivery, setActiveDelivery] = useState(0);
  const [activeProduct, setActiveProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [clickedTable, setClickedTable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenLocation, setIsOpenLocation] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const clearData = () => {
    const orderPaymentForm = document.querySelector('form');
    if (orderPaymentForm) {
      orderPaymentForm.requestSubmit();
    }
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    document.title = "Заал";
  }, []);

  useEffect(() => {
    if (props.order) {
      if (props.order.tableid) setActiveTable(props.order.tableid);
      else {
        if (clickedTable) {
          let found = data.find((x) => x.id === clickedTable.id);
          if (found && found.details) {
            getOrderDetail(found.orderid);
          } else {
            setActiveTable(clickedTable.id);
          }
          setClickedTable(null);
        } else {
          setActiveTable(null);
        }
      }
    }
  }, [props.order]);

  const setData = (data) => {
    let found = props?.resongoingorders?.find((x) => x.isedited === 1);

    if (found) {
      var sound;
      sound = new window.Howl({
        src: [notfSound],
      });
      sound.play();
    }
    props.setData({ data: data, model: "setdata_ongoingorders" });
  };

  useEffect(() => {
    if (check()) {
      props.request({ url: "/sale/orderlist/ongoing", model: "ongoingorders" });
    } else navigate("/");
  }, []);

  useEffect(() => {
    if (props.wbConnection) {
      props.wbConnection.on("ongoing", setData);
    }

    return function clenup() {
      if (props.wbConnection) {
        props.wbConnection.off("ongoing", setData);
      }
    };
  }, [props?.resongoingorders]);

  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: SalePerm,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  const getOrderDetail = (id, ismessage = true) => {
    if (id) {
      setLoading(true);
      props.request({ url: `/sale/orderdetail/${id}` }).then((res) => {
        setLoading(false);
        if (res.success) {
          if (ismessage) owntoast(res.message, "success");
          if (res?.data?.order?.locid) {
            let tmp = {
              province: res?.data?.order?.province,
              district: res?.data?.order?.district,
              locid: res?.data?.order?.locid,
              address: res?.data?.order?.address,
            };
            localStorage.setItem("deliveryLocation", JSON.stringify(tmp));
          } else {
            localStorage?.removeItem("deliveryLocation");
          }
          if (res?.data?.order?.waiterid) {
            localStorage.setItem("waiter", res?.data?.order?.waiterid);
          } else {
            localStorage?.removeItem("waiter");
          }
          props.setOrderDetail(res?.data);
          onClose();
        } else {
          owntoast(res.message, "error");
        }
      });
    }
  };
  console.log(props?.saleList);
  return width < 756 ? (
    <div className="flex items-center justify-center h-full flex-col">
      <img src={sad} alt="chef" className="mb-4 object-contain h-1/6" />
      <span className="text-primary font-semibold mt-5 w-52 text-center">
        Уучлаарай ажиллах боломжгүй төхөөрөмж байна.
      </span>
    </div>
  ) : (
    <Layout className="p-4 bg-fullBack h-full flex flex-row">
      <Components.OrderCategory {...props} />
      <div className="grid grid-cols-12 gap-3 h-full w-full">
        {/* Ангиллын жагсаалт */}

        {/* Ангилалд хамаарах бараануудын жагсаалт */}
        <Components.OrderProducts
          {...props}
          onOpen={onOpen}
          activeProduct={activeProduct}
          setActiveProduct={setActiveProduct}
          activeTable={activeTable}
          setActiveTable={setActiveTable}
        />
        <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-4 2xl:col-span-4 flex flex-col">
          {/* Хүлээгдэж буй захиалгуудын жагсаалт */}
          <Components.OrderCard
            {...props}
            setActiveTable={setActiveTable}
            setIsOpen={setIsOpen}
            activeTable={activeTable}
            getOrderDetail={getOrderDetail}
            loading={loading}
          />
          {/* Захиалгын хүснэгт */}
          <Components.OrderTable
            {...props}
            activeProduct={activeProduct}
            activeDelivery={activeDelivery}
            orderRef={orderRef}
            setActiveProduct={setActiveProduct}
            activeTable={activeTable}
            setOpenSave={setIsOpen}
            setActiveTable={setActiveTable}
            isOpenLocation={isOpenLocation}
            getOrderDetail={getOrderDetail}
            setIsOpenLocation={setIsOpenLocation}
          />
          {/* Ширээний жагсаалт. Тохиргоо хийгээгүй бол харагдахгүй. */}
          {/*  <Components.OrderChooseTable {...props} activeTable={activeTable} setActiveTable={setActiveTable} /> */}
          {/* Нийт дүн болон төрлүүд харуулах хэсэг */}
          <Components.OrderPayment
            {...props}
            orderRef={orderRef}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            getOrderDetail={getOrderDetail}
            setActiveDelivery={setActiveDelivery}
            setIsOpenLocation={setIsOpenLocation}
          />
        </div>
      </div>
      <Components.OrderChooseTable
        {...props}
        visible={visible}
        onClose={onClose}
        onOpen={onOpen}
        setIsOpen={setIsOpen}
        activeTable={activeTable}
        setActiveTable={setActiveTable}
        setClickedTable={setClickedTable}
        getOrderDetail={getOrderDetail}
        loading={loading}
      />
      <Components.CustomModal
        footer={[
          <Button
            onClick={() => {
              setIsOpen(false);
              props.setOrderDetail({
                order: {},
                detail: [],
              });
            }}
          >
            Үгүй
          </Button>,
          <Button type="primary" autoFocus onClick={clearData}>
            Тийм
          </Button>,
        ]}
        width={400}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          <span className="text-center">Та захиалгаа хадгална уу ?</span>
        </div>
      </Components.CustomModal>
    </Layout>
  );
}
export default Sale;
