import { Modal } from "antd";

const Index = ({
  children,
  footer,
  title,
  isOpen,
  onClose,
  width,
  bodyStyle,
}) => {
  const close = () => {
    onClose && onClose(false);
  };

  return (
    <Modal
      centered
      destroyOnClose={true}
      footer={footer}
      title={title}
      width={width ? width : 400}
      open={isOpen}
      bodyStyle={bodyStyle}
      onCancel={close}
    >
      {children}
    </Modal>
  );
};

export default Index;
