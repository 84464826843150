/* Программ тай холбоотой тогтмол утгууд болон enum ууд */

export const ACTIONS = {
  EXCEL: "1",
  PRINT: "2",
};

export const pageMotion = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const IsEnable = {
  Идэвхтэй: 1,
  Идэвхгүй: 0,
};

export const PaymentType = {
  Бэлнээр: 1,
  Картаар: 2,
  Голомтоор: 3,
  Qpay: 4,
  SocialPay: 5,
  Хаанбанк: 6,
  MinuPay: 7,
  Most: 8,
};

export const SaleType = {
  Борлуулалт: 1,
  Буцаалт: 2,
};

export const ReceiptType = {
  Борлуулалт: "Борлуулалт",
  Захиалга: "Захиалга",
  Буцаалт: "Буцаалт",
  Хаалт: "Хаалт",
  Карт: "Карт",
};

export const PaperSize = {
  m58: 1,
  m80: 2,
};

export const MenuPerm = 1;
export const BuyingPerm = 2;
export const SalePerm = 3;
export const SettingsPerm = 4;
export const ReportPerm = 5;
export const DashPerm = 6;
export const DiscountPerm = 7;
export const KitchenPerm = 8;
export const IsOwnerPerm = 9;
export const DeleteBuyingPerm = 10;
export const ChangeTimePerm = 11;
export const DeleteOrderPerm = 12;
export const GolomtSettlementPerm = 13;
export const QpaySettingsPerm = 14;

export const UserPerm = [
  {
    label: "Меню бүртгэл",
    value: MenuPerm,
  },
  {
    label: "Материалын татан авалт",
    value: BuyingPerm,
  },
  {
    label: "Захиалга заал",
    value: SalePerm,
  },
  {
    label: "Тохиргоо",
    value: SettingsPerm,
  },
  {
    label: "Тайлан",
    value: ReportPerm,
  },
  {
    label: "Дашбоард",
    value: DashPerm,
  },
  {
    label: "Хямдрал",
    value: DiscountPerm,
  },
  {
    label: "Гал тогоо",
    value: KitchenPerm,
  },
  {
    label: "Дотоодын хэрэгцээний зарлага",
    value: IsOwnerPerm,
  },
  {
    label: "Татан авалт устгах",
    value: DeleteBuyingPerm,
  },
  {
    label: "Тооцоо хийх цаг өөрчлөх",
    value: ChangeTimePerm,
  },
  {
    label: "Захиалга устгах",
    value: DeleteOrderPerm,
  },
  {
    label: "Голомт хаалт хийх",
    value: GolomtSettlementPerm,
  },
  {
    label: "Qpay тохиргоо хийх",
    value: QpaySettingsPerm,
  },
];
export const MasterAdminRole = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export const CashierRole = [3];

export const ManagerRole = [1, 2, 3];
export const ChefRole = [8];

export const MainWindow = {
  Zaal: 1,
  Menu: 2,
  Tailan: 3,
  Tohirgoo: 4,
};

export const LicenseType = {
  Demo: 1,
  Main: 2,
  All: 99,
};

export const NLicenceType = {
  menu: 1,
  card: 2,
  order: 3,
  product: 4,
  cost: 5,
  dintable: 6,
  systemconfig: 7,
  productcost: 8,
  cashierset: 9,
  artlab: 10,
  bankpos: 11,
  merchant: 12,
  qrmenu: 13,
  time: 14,
  kitchen: 15,
  branch: 16,
  report: 17,
  dashboard: 18,
};
