import React, { useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import * as Components from "../../components";
import { motion, AnimatePresence } from "framer-motion";
import { NLicenceType, pageMotion } from "../../utils/enums";
import { Steps, Button, Checkbox } from "antd";
import warning from "../../assets/warning.png";
import { LICENSE_MSG } from "../../utils";
const { Step } = Steps;

function AddBranch(props) {
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [procent, setProcent] = useState(0);
  const [showSms, setShowSms] = useState(true);
  const [agreement, setAgreement] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleChange = (e) => {
    setDisabled(e.target.checked);
  };
  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop > 150) setDisabled(true);
    else setDisabled(false);
  };

  const handleAgreement = () => {
    if (
      props?.reslicense?.detail?.find((x) => Number(x) === NLicenceType.branch)
    ) {
      setAgreement(true);
    } else {
      props.setError({
        isOpen: true,
        message: LICENSE_MSG,
        isLicenseModal: true,
      });
    }
  };

  return (
    <div className="flex items-center h-full w-full justify-center bg-white">
      {showSms ? (
        agreement ? (
          <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-5/6 rounded-lg border border-gray-200 drop-shadow-xl">
            <header className="bg-addBranchHeader bg-cover 2xl:rounded-t-lg w-full px-2 lg:px-6 py-4 flex justify-between items-center ">
              <span className="font-bold text-lg w-full text-center text-white">
                ШИНЭ САЛБАР ҮҮСГЭХ
              </span>
            </header>
            <div className="w-full justify-center items-center flex flex-row text-center lg:pt-2 sm:p-8">
              <img className="w-12" src={warning} alt="warning" />
              <span className="font-bold text-lg ml-2">
                ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ
              </span>
            </div>
            <div className="w-full flex justify-center">
              <div
                className="text-gray-500 text-md w-4/5 md:h-60 lg:h-96 overflow-y-auto"
                onScroll={handleScroll}
              >
                <p className="font-bold">1. ОньсФүүд салбар бүртгэл</p>
                <p>
                  Салбар гэдэг нь та ОньсФүүд системд бүртгэлтэй хоолны газрын
                  салбар хоолны газраа үүсгэхийг хэлнэ.
                </p>
                <p>
                  Салбар нь үндсэн салбараас тусдаа бүртгэл үүсэх ба нэр байршил
                  үйл ажиллагааны чиглэлийн хувьд үндсэн салбараас өөр байж
                  болно. Үйл ажиллагаа нь үндсэн салбараас тусдаа бие даан
                  явагдана. Жишээ нь салбар тус бүр өөр өөр хоолны меню үүсгэж
                  болно. Салбар тус бүр нь тус тусдаа бие даан тайлан гаргана.
                </p>
                <p className="font-bold">2. Төлбөр</p>
                <p>
                  Салбарын лицензийн төлбөр үндсэн салбараас тусдаа
                  тооцоологдоно. Та өөрийн салбарын үйл ажиллагаанд тохирох багц
                  лицензээс сонгон төлбөрөө төлж системээ хэрэглэх боломжтой.
                </p>
                <p>
                  Хэрэв хэрэглэгч нэмэх, киоск төхөөрөмж ашиглах бол салбар тус
                  бүрт лиценз авах шаардлагатай.
                </p>
                <p className="font-bold">3. Мастер бүртгэл татах тухай</p>
                <p>
                  Үндсэн салбарын мастер бүртгэлийг салбар татан ашиглах
                  боломжтой. Мастер бүртгэлд дараах бүртгэл хамрана.
                </p>
                <p className="ml-8">• Хэмжих нэгж</p>
                <p className="ml-8">• Порц</p>
                <p className="ml-8">• Материал</p>
                <p className="ml-8">• Нэмэлт</p>
                <p className="ml-8">• Хоолны карт</p>
                <p className="ml-8">• Хоолны бүртгэл</p>
                <p className="ml-8">• Сэт хоол</p>
                <p>
                  Мастер бүртгэл татахад хэмжих нэгж нь автоматаар татагдах бол
                  бусад бүртгэлүүд нь хэрэглэгч хүссэн бүртгэлээ татах
                  боломжтой.
                </p>
                <p>
                  Хэрэв мастер бүртгэл нь бусад бүртгэлүүдээс өгөгдөл ашиглаж
                  байгаа бол тухайн өгөгдлийг татсан тохиолдолд татах боломжтой.
                  Үгүй бол өгөгдөл дутуу татсан учир тухайн бүртгэл татагдахгүйг
                  анхаарна уу!
                </p>
                <p className="font-bold">4. Тайлан</p>
                <p>
                  Салбар нь өөрийн үйл ажиллагааны хүрээнд хийсэн борлуулалт,
                  татан авалт, баримт гэх мэт тайлан мэдээллүүдийг харна.
                </p>
                <p>
                  Салбаруудын тайлан мэдээллийг нэгтгэсэн байдлаар авахыг хүсвэл
                  ОньсФүүдийн эзний тайлангаас харах боломжтой.
                </p>
              </div>
            </div>
            <Checkbox
              className="mt-6 justify-center flex"
              checked={disabled}
              onChange={handleChange}
            >
              Зөвшөөрч байна
            </Checkbox>
            <div className="w-full items-center justify-center flex mt-2">
              <Button
                className="h-8 sm:w-3/5 lg:w-1/5 flex rounded-sm"
                type="primary"
                onClick={() => {
                  setShowSms(false);
                }}
                disabled={!disabled}
              >
                БҮРТГҮҮЛЭХ
              </Button>
            </div>
            <p color="black" className="mt-2 justify-center flex">
              <a
                onClick={() => {
                  setAgreement(false);
                  setDisabled(false);
                }}
              >
                Буцах
              </a>
            </p>
          </div>
        ) : (
          <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-5/6 rounded-lg border border-gray-200 drop-shadow-xl">
            <header className="bg-addBranchHeader 2xl:rounded-t-lg w-full px-2 lg:px-6 py-4 flex justify-between items-center ">
              <span className="font-bold text-lg w-full text-center text-white">
                ШИНЭ САЛБАР ҮҮСГЭХ
              </span>
            </header>
            <div className="w-full justify-center items-center flex flex-row text-center lg:pt-12 sm:p-8">
              <img className="w-12" src={warning} alt="warning" />
              <span className="font-bold text-lg ml-2">АНХААРУУЛГА</span>
            </div>
            <div className="text-center justify-center flex w-full">
              <span className="flex text-gray-500 text-lg w-3/5">
                Та өөрийн салбар хоолны газараа бүртгэхээс өмнө Үйлчилгээний
                нөхцөл, Ашиглах заавар, Төлбөрийн мэдээлэл-тэй дэлгэрэнгүй
                танилцаж өөрийн үйл ажиллагаандаа тохирох, үйл ажиллагааг тань
                дэмжих эсэхийг шалгана уу! Хэрвээ тохирч байвал салбар хоолны
                газраа үүсгэнэ үү
              </span>
            </div>
            <div className="w-full items-center justify-center flex my-16">
              <Button
                className="bg-primary h-8 w-1/5 sm:w-3/5 lg:w-1/5 flex text-white rounded-sm"
                type="primary"
                onClick={handleAgreement}
              >
                НӨХЦӨЛТЭЙ ТАНИЛЦАХ
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-4/6 sm:p-8 lg:pt-12 rounded-md border border-gray-200 drop-shadow-xl license-step">
          <Steps current={step} percent={procent} className="text-sm">
            <Step title="Ерөнхий мэдээлэл" />
            <Step title="Нэвтрэх тохиргоо" description="" />
            <Step title="Баталгаажуулалт" />
            <Step title="Мэдээлэл татах" />
          </Steps>
          <div className="w-full items-center flex flex-col text-center md:mt-32 lg:mt-12 xl:mt-24 2xl:mt-8">
            {/* Route ийн дагуу бүртгүүлэх эсвэл нууц үг сэргээх component-үүд үүсгэж зурах хэсэг */}
            <div className="w-full h-full lg:mt-0">
              {/* <img className="w-36 h-36 mx-auto lg:hidden mb-8" src={logo} alt="logo" /> */}
              <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                  <Route
                    path="maininfo"
                    element={
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageMotion}
                      >
                        <Components.Register
                          {...props}
                          setStep={setStep}
                          setProcent={setProcent}
                          procent={procent}
                          isBranch={true}
                        />
                      </motion.div>
                    }
                  />
                  <Route
                    path="subInfo"
                    element={
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageMotion}
                      >
                        <Components.SubInfo
                          {...props}
                          setStep={setStep}
                          setProcent={setProcent}
                          procent={procent}
                          isBranch={true}
                        />
                      </motion.div>
                    }
                  />
                  <Route
                    path="verify"
                    element={
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageMotion}
                      >
                        <Components.Verify
                          {...props}
                          setStep={setStep}
                          setProcent={setProcent}
                          procent={procent}
                          isBranch={true}
                        />
                      </motion.div>
                    }
                  />
                  <Route
                    path="success"
                    element={
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageMotion}
                      >
                        <Components.Success
                          {...props}
                          setStep={setStep}
                          setProcent={setProcent}
                          procent={procent}
                          isBranch={true}
                        />
                      </motion.div>
                    }
                  />
                </Routes>
              </AnimatePresence>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBranch;
