import React, { useState, useEffect, useRef } from "react";
import * as Components from "../../components";
import * as Containers from "../../containers";
import Formatter from "../../utils/data_formatter";
import { SaleBill } from "../../components/SaleBill";
import { useReactToPrint } from "react-to-print";
import { ReceiptType, PaperSize } from "../../utils/enums";
import dayjs from "dayjs";
import { TablePrint } from "../../components/TablePrint";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { postMessageMobile, owntoast } from "../../utils";
/* import { dataSource, columns } from "../../data"; */
function List(props) {
  const { page } = props;
  const manuallink = props?.resmanuallist?.find(
    (x) => x.manualid === page?.manualid
  )?.link;
  const printRef = useRef();
  const printRef1 = useRef();
  const [searchText, setSearchText] = useState("");
  const [isOpenModals, setIsOpenModals] = useState({});
  const [printData, setPrintData] = useState({});
  let data = [];
  let states = {};

  page.columns &&
    page.columns.map((col) => {
      if (col.clickable) {
        col.onCell = (record, rowIndex) => ({
          onClick: () => {
            if (col.key === "delivery") {
              if (record.deliveryenum === 5 && record.location) {
                setIsOpen(true, col.dataIndex, record);
              }
            } else {
              setIsOpen(true, col.dataIndex, record);
            }
          },
        });
        if (col.Modal) {
          states[`isOpen${col.dataIndex}`] = false;
          states[`data${col.dataIndex}`] = {};
        }
      }
      if (col.dataIndex === "isenable" || col.dataIndex === "delete") {
        col.render = (value, row) => (
          <Formatter
            value={value}
            row={row}
            form={page.forms}
            request={props.request}
            page={page}
            props={props}
            format={col.customRender ? col.customRender : "status"}
          />
        );
      }
      if (col.dataIndex !== "no" && col.dataIndex !== "edit") {
        col.sorter = {
          compare: (rowA, rowB) => {
            if (
              col.dataIndex.includes("ymd") &&
              rowA[col.dataIndex] &&
              rowB[col.dataIndex]
            ) {
              return dayjs(rowA[col.dataIndex]).diff(
                dayjs(rowB[col.dataIndex])
              );
            } else {
              let a = !isNaN(+rowA[col.dataIndex])
                ? Number(rowA[col.dataIndex])
                : rowA[col.dataIndex];
              let b = !isNaN(+rowB[col.dataIndex])
                ? Number(rowB[col.dataIndex])
                : rowB[col.dataIndex];
              if (a < b) return -1;
              if (b < a) return 1;
              return 0;
            }
          },
        };
      }
    });

  useEffect(() => {
    setIsOpenModals(states);
    // check();
  }, []);

  if (page.isList) {
    if (!page.isPagination) {
      const filterTable =
        props[`res${page.model}`] &&
        props[`res${page.model}`]?.filter?.((o) =>
          Object.keys(o).some(
            (k) =>
              o[k] &&
              String(o[k]).toLowerCase().includes(searchText.toLowerCase()) &&
              page.columns.find((col) => col.dataIndex === k)
          )
        );
      data = filterTable;
    } else {
      const filterTable =
        props[`res${page.model}`] &&
        props[`res${page.model}`].reportList &&
        props[`res${page.model}`]?.reportList?.filter?.((o) =>
          Object.keys(o).some(
            (k) =>
              o[k] &&
              String(o[k]).toLowerCase().includes(searchText.toLowerCase()) &&
              page.columns.find((col) => col.dataIndex === k)
          )
        );
      data = {
        data: filterTable,
        count: props[`res${page.model}`] && props[`res${page.model}`].count,
        footer:
          props[`res${page.model}`] && props[`res${page.model}`].reportSum,
      };
    }
  }

  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: page.permission,
      license: page.license,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  const getPrinterSize = () => {
    if (props.resprinterconfig) {
      if (props.resprinterconfig.printertype) {
        return props.resprinterconfig.printertype;
      }
    }
    return PaperSize.m58;
  };

  //let newDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const printSettlement = (data) => {
    if (data) {
      const deviceType = localStorage.getItem("deviceType");
      const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));
      let body = {
        amount: data.totalamount,
        saleamount: data.saleamount,
        returnamount: data.returnamount,
        products: data.saleDetail,
        setProds: data.salePackSet,
        username: props._auth.username,
        insymd: data?.insymd,
        cash: 0,
        noncash: 0,
        type: ReceiptType.Хаалт,
        totalqty: data.totalqty,
        salePay: data.salePay,
        useramount: data.useramount,
        timeamount: data.timeamount,
        fee: data.fee,
        ischeck: data?.islast ? true : false,
        printersize: getPrinterSize(),
        printBillType: data.printBillType,
      };
      if (body.products && body.products.length) {
        const cats = body?.products?.reduce((a, item) => {
          const obj = a.get(item.catid) || { products: [] };
          obj.catid = item.catid;
          if (!obj.catid) {
            obj.catname = "Бусад";
          } else {
            obj.catname = item.catname;
          }

          obj.products.push({ ...item });
          return a.set(item.catid, obj);
        }, new Map());

        const pays = body?.salePay?.reduce((a, item) => {
          const obj = a.get(item.saledate) || { salePay: [] };
          obj.saledate = item.saledate;
          if (!obj.saledate) {
            obj.saledate = "Бусад";
          } else {
            obj.saledate = item.saledate;
          }

          obj.salePay.push({ ...item });
          return a.set(item.saledate, obj);
        }, new Map());

        body.products = [...cats.values()].sort((a, b) =>
          a?.catid > b?.catid ? -1 : 1
        );
        body.salePay = [...pays.values()].sort((a, b) =>
          a?.saledate > b?.saledate ? -1 : 1
        );
      }
      if (props.rescheckService === true) {
        props.request({
          url: `/print/settlement/${
            props.resprinterconfig && props.resprinterconfig.printertype
              ? props.resprinterconfig.printertype
              : PaperSize.m58
          }`,
          body: body,
          method: "POST",
          isservice: true,
        });
      } else if (deviceType === "ios" || deviceType === "android") {
        if (bluetooth && bluetooth.address) {
          postMessageMobile({
            type: "PRINT_BILL",
            data: JSON.stringify({ btaddress: bluetooth.address, ...body }),
          });
        } else {
          owntoast("Bluetooth принтер сануулаагүй байна.", "error");
        }
      } else {
        setPrintData(body);
      }
    }
  };

  useEffect(() => {
    if (printData && Object.keys(printData).length > 0) print();
  }, [printData]);

  /* Хайх */
  const handleSearch = (values) => {
    console.log("valuesйййыыы: ", values);
    if (check()) {
      const pagination = JSON.parse(
        localStorage.getItem(`pagination${props.page.url}`)
      );

      values.foodid = props._auth.foodid;
      values.pagenumber = pagination ? pagination.defaultCurrent - 1 : 0;
      values.count = pagination ? pagination.defaultPageSize : 10;
      values.materialid = 0;
      localStorage.setItem(`search${props.page.url}`, JSON.stringify(values));
      if (values.sdate && values.sdate.format)
        values.sdate = values.sdate.format("");
      if (values.edate && values.edate.format)
        values.edate = values.edate.format("");
      if (values.sdatehour && values.sdatehour.format)
        values.sdatehour = values.sdatehour.format("");
      if (values.edatehour && values.edatehour.format)
        values.edatehour = values.edatehour.format("");
      props.request({
        url: page.url,
        body: values,
        method: page.method,
        model: page.model,
      });
    }
  };

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const setIsOpen = (value, name, record) => {
    if (name === "print-settlement") {
      props
        ?.request({ url: `/sale/payset/reprint/${record?.id}`, method: "PUT" })
        ?.then((res) => {
          if (res.success) {
            printSettlement(res?.data);
          }
        });
    } else if (name === "print") {
      setPrintData({
        ...record,
        type: ReceiptType.Карт,
        vatname: props._auth.vatname,
      });
    } else {
      if (name === "mainorder") {
        if (record?.mainorder <= 0) return;
      }

      setIsOpenModals((prev) => {
        prev[`isOpen${name}`] = value;
        if (record) prev[`data${name}`] = record;
        else prev[`data${name}`] = {};
        return { ...prev };
      });
    }
  };

  /* isSettings === true бол тохиргооны цонхнууд гэж үзэн зөвхөн тохиргоо хийх цонх-г зурна */
  if (page.component) {
    return <page.component {...props} {...page} />;
  } else if (page.isSettings) {
    return <Containers.AddOrEdit {...props} />;
  } else {
    /* Ялгаатай үед үндсэн Жагсаалтын цонхыг зурна */
    return (
      <div className="h-full flex flex-col">
        {/* Жагсаалтын цонхны хайх болон үйлдэл хэсгүүд */}
        <Components.Search
          onSubmit={handleSearch}
          isNew={page.isNew}
          printRef={printRef1}
          name={page.name}
          onChangeSearch={(e) => setSearchText(e.target.value)}
          controls={page.controls}
          isModal={page.isModal}
          isSearch={page.isSearch}
          dataSource={
            !page.isPagination
              ? props[`res${page.model}`] && props[`res${page.model}`]
              : props[`res${page.model}`] &&
                props[`res${page.model}`].reportList
          }
          columns={page.columns}
          url={page.url}
          loading={props[`is${page.model}`]}
          props={props}
          check={check}
          manuallink={manuallink}
        />
        <div className="relative h-full">
          <div className="absolute bottom-0 top-0 overflow-y-auto h-full w-full">
            <Components.AntdTable
              dataSource={data}
              loading={props[`is${page.model}`]}
              columns={page.columns}
              form={page.forms}
              method={page.method}
              model={page.model}
              isPagination={page.isPagination}
              pagination={true}
              url={page.url}
              isDoubleClick={page.isDoubleClick}
              request={props.request}
            />
          </div>
        </div>
        {/* Жагсаалтын цонхны хүснэгт page д хамаарах багануудаар зурагдана */}

        {page.columns &&
          page.columns.map((col) => {
            if (col.Modal) {
              return (
                <col.Modal
                  isOpen={isOpenModals[`isOpen${col.dataIndex}`]}
                  data={isOpenModals[`data${col.dataIndex}`]}
                  dataIndex={col.dataIndex}
                  onSubmit={handleSearch}
                  columns={col.modalColumns}
                  isReturn={col.isReturn}
                  modalUrl={col.modalUrl}
                  modalMethod={col.modalMethod}
                  modalIdKey={col.modalIdKey}
                  modalHeader={col.modalHeader}
                  modalTitles={col.modalTitles}
                  setIsOpen={(value) => setIsOpen(value, col.dataIndex)}
                  {...props}
                />
              );
            }
          })}
        <div className="hidden">
          <SaleBill ref={printRef} data={printData} />
        </div>
        <div className="hidden">
          <TablePrint
            name={page.name}
            dataSource={data}
            columns={page.columns}
            ref={printRef1}
            isPagination={page.isPagination}
          />
        </div>
      </div>
    );
  }
}

export default List;
