import React from "react";
import QRCode from "react-qr-code";
import { ReceiptType } from "../../utils/enums";
import dayjs from "dayjs";
import { API_URL } from "../../utils";
export const SaleBill = React.forwardRef((props, ref) => {
  const { data, printqty } = props;
  let pays = [
    {
      col1name: "НИЙТ:",
      col1: data && new Intl.NumberFormat().format(data.totalamount),
      col2name: "ТӨЛСӨН:",
      col2: "-",
    },
    {
      col1name: "ХЯМДРАЛ:",
      col1: data && new Intl.NumberFormat().format(data.discount),
      col2name: "БЭЛЭН:",
      col2: "-",
    },
    {
      col1name: "ТӨЛӨХ:",
      col1: data && new Intl.NumberFormat().format(data.amount),
      col2name: "КАРТ:",
      col2: "-",
    },
  ];

  if (data && data.type === ReceiptType.Борлуулалт) {
    pays.push({
      col1name: "НӨАТ:",
      col1: data && new Intl.NumberFormat().format(data.vat),
    });
    pays.push({
      col1name: "НХАТ:",
      col1: data && new Intl.NumberFormat().format(data.tax),
    });
  }

  const calculateAmount = () => {
    if (data && data.pays != null && data.type == ReceiptType.Борлуулалт) {
      data.pays.map((item, i) => {
        if (pays[i]) {
          pays[i].col2name = `${data.pays[i].typename}:`;
          pays[i].col2 = new Intl.NumberFormat().format(data.pays[i].amount);
        } else {
          pays.push({
            col2name: `${data.pays[i].typename}:`,
            col2: new Intl.NumberFormat().format(data.pays[i].amount),
          });
        }

        if (pays.length >= data.pays.length) {
          if (pays.length > data.pays.length) {
            pays[data.pays.length].col2name = "ТӨЛСӨН:";
            pays[data.pays.length].col2 = new Intl.NumberFormat().format(
              data.useramount
            );
          } else {
            pays.push({
              col2name: `ТӨЛСӨН:`,
              col2: new Intl.NumberFormat().format(data.useramount),
            });
          }

          if (pays.length > data.pays.length + 1) {
            pays[data.pays.length + 1].col2name = "ХАРИУЛТ:";
            pays[data.pays.length + 1].col2 = new Intl.NumberFormat().format(
              data.userretamount
            );
          } else {
            pays.push({
              col2name: `ХАРИУЛТ:`,
              col2: new Intl.NumberFormat().format(data.userretamount),
            });
          }
        }
      });
    }

    return pays;
  };

  const renderSpices = (item) => {
    let tmp;
    if (item.isset === 1 && item.setdetails && item.setdetails.length > 0) {
      tmp = item.setdetails.map((setdetail, detailIndex) => (
        <tr>
          <td className="text-left">
            &nbsp;&nbsp;&nbsp;
            {`${setdetail.name} ${
              setdetail.spiceid && setdetail.spicename
                ? `/${setdetail.spicename}/`
                : ""
            }`}
          </td>
          <td>{setdetail.qty}</td>
          <td>-</td>
          <td>-</td>
        </tr>
      ));
    } else if (item.spiceid && item.spicename) {
      return (
        <tr>
          <td className="text-left">
            &nbsp;&nbsp;&nbsp;
            {item.spicename}
          </td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
    return tmp;
  };

  const renderSaleBill = (qty) => {
    return (
      <div
        className="bg-white roboto tracking-tight"
        style={{ fontSize: "9px", marginRight: "10px" }}
      >
        {data?.logo ? (
          <div className="flex items-center justify-center w-full mb-4">
            <img
              src={`${API_URL}${data?.logo}`}
              className="h-16 w-16 object-contain"
            />
          </div>
        ) : null}

        <div
          className="break-words font-bold text-center mb-1"
          style={{ fontSize: "10px" }}
        >
          {data.foodsname}
        </div>
        <div
          className="break-words font-bold text-center mb-1"
          style={{ fontSize: "10px" }}
        >
          {data.printtype} №: {data.ordernum}
        </div>
        <table className="w-full">
          <tr>
            <td colSpan={1}>Борлуулагч:</td>
            <td colSpan={3}>
              {data.regno} {data.vatname}
            </td>
          </tr>
          <tr>
            {/* <td class colSpan={1}>
              Захиалга №:
            </td>
            <td colSpan={1} style={{ fontWeight: "bold", fontSize: "larger" }}>
              {data.ordernum}
            </td> */}
            <td
            // colSpan={1}
            >
              Бар/Хувь:
            </td>
            <td
              // colSpan={1}
              className="text-left"
            >
              {qty && qty}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>Кассчин:</td>
            <td colSpan={3}>{data.username}</td>
          </tr>
          {data.tablenm ? (
            <tr>
              <td colSpan={1}>Ширээ:</td>
              <td colSpan={3}>{data.tablenm}</td>
            </tr>
          ) : null}
          <tr>
            <td colSpan={1}>Баримтын төрөл:</td>
            <td colSpan={3}>{data.printtype}</td>
          </tr>
          {data.orderenum && (
            <tr>
              <td colSpan={1}>Захиалгын төрөл:</td>
              <td colSpan={2}>{`${data.orderenum} ${
                data.deliveryenum && `, ${data.deliveryenum}`
              }`}</td>
            </tr>
          )}

          <tr>
            <td colSpan={1}>Огноо:</td>
            <td colSpan={3}>
              {data.insymd && dayjs(data.insymd).format("YYYY-MM-DD HH:mm:ss")}
            </td>
          </tr>
          {data.type === ReceiptType.Борлуулалт && !data.isReturn ? (
            <tr>
              <td colSpan={1}>ДДТД:</td>
              <td colSpan={3}>{data.ddtd}</td>
            </tr>
          ) : null}
          {data.type === ReceiptType.Борлуулалт && data.taxpayerregno ? (
            <tr>
              <td colSpan={1}>Худалдан авагч:</td>
              <td colSpan={3}>
                {data.taxpayerregno} {data.taxpayername}
              </td>
            </tr>
          ) : null}
        </table>
        <table className="w-full border-b border-t border-black text-right">
          <tr className="border-b border-black ">
            <td className="text-left">Бараа</td>
            <td>Тоо/ш</td>
            <td>Үнэ</td>
            <td>Дүн</td>
          </tr>

          {data.products &&
            data.products.map((item, i) => (
              <>
                <tr className={`${item.isset ? "font-bold" : ""}`}>
                  <td className="text-left">{item.name}</td>
                  <td>{item.qty}</td>
                  <td>{new Intl.NumberFormat().format(item.price)}</td>
                  <td>
                    {item.discount && item.discount > 0
                      ? null
                      : new Intl.NumberFormat().format(item.totalamount)}
                  </td>
                </tr>
                {item.discount && item.discount > 0 ? (
                  <tr>
                    <td colSpan={2} className="text-right">
                      хямдралтай
                    </td>
                    <td>{new Intl.NumberFormat().format(item.unitdiscount)}</td>
                    <td>{new Intl.NumberFormat().format(item.amount)}</td>
                  </tr>
                ) : null}

                {renderSpices(item)}
              </>
            ))}
          <tr className="border-t border-black">
            <td className="text-right font-bold uppercase">
              Үйлчилгээний нэмэгдэл
            </td>
            <td>{new Intl.NumberFormat().format(data.fee || 0)}</td>
            <td className="font-bold uppercase"></td>
            <td></td>
          </tr>
          {calculateAmount().map((item, i) => (
            <tr className="border-t border-black" key={i}>
              <td className="text-right font-bold uppercase">
                {item.col1name}
              </td>
              <td>{item.col1}</td>
              <td className="font-bold uppercase">{item.col2name}</td>
              <td>{item.col2}</td>
            </tr>
          ))}
          {/* <tr className="border-t border-black">
            <td className="text-right font-bold">НИЙТ:</td>
            <td>{new Intl.NumberFormat().format(data.totalamount)}</td>
            <td className="font-bold">ТӨЛСӨН:</td>
            <td>{data.type === ReceiptType.Захиалга ? "-" : new Intl.NumberFormat().format(data.useramount)}</td>
          </tr>
          <tr>
            <td className="text-right font-bold">ХЯМДРАЛ:</td>
            <td>{data.discount}</td>
            <td className="font-bold">БЭЛЭН:</td>
            <td>-</td>
          </tr>
          <tr>
            <td className="text-right font-bold">ТӨЛӨХ:</td>
            <td>{new Intl.NumberFormat().format(data.amount)}</td>
            <td className="font-bold">КАРТ:</td>
            <td>-</td>
          </tr>
          {data.type === ReceiptType.Борлуулалт ? (
            <>
              <tr>
                <td className="text-right font-bold">НӨАТ:</td>
                <td>{data.vat}</td>
                <td className="font-bold">ХАРИУЛТ:</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="text-right font-bold">НХАТ:</td>
                <td>{data.tax}</td>
                <td></td>
                <td></td>
              </tr>
            </>
          ) : null} */}
        </table>
        {data.type === ReceiptType.Борлуулалт &&
        !data.isReprint &&
        !data.isReturn ? (
          <div className="w-full flex mt-2 justify-center items-center">
            <div className="flex-col flex pr-1">
              {data.lottery ? (
                <span className="text-right">Сугалааны дугаар:</span>
              ) : null}
              {data.lottery ? (
                <span className="text-right font-bold">{data.lottery}</span>
              ) : null}

              <span className="text-right mt-2">Ибаримт-н дүн:</span>
              <span className="text-right font-bold">
                {new Intl.NumberFormat().format(data.amount)}
              </span>
            </div>
            {data.qr ? <QRCode value={data.qr} size={120} /> : null}
          </div>
        ) : null}
        {data?.location ? (
          <div className="mt-2">{`Хүргэлтийн хаяг: ${data.location}`}</div>
        ) : null}
        {data?.address ? <div className="mt-2">{data.address}</div> : null}
        <div className="text-center mt-2">{data.footer}</div>
        {data.isReprint || data.isReturn ? (
          <table className="text-center w-full">
            <tr className="text-center">
              <td colSpan={2}>
                {data.isReprint
                  ? "Дахин хэвлэсэн баримт"
                  : data.isReturn
                  ? "Буцаалтын баримт"
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Хэвлэсэн огноо:</td>
              <td>{dayjs().format("YYYY-MM-DD HH:mm:ss")}</td>
            </tr>
          </table>
        ) : null}
        <div className="text-center mt-2">Хөгжүүлсэн www.onispos.mn</div>
      </div>
    );
  };

  const getCatQty = (e) => {
    var qty = 0;
    e?.products?.map((item, i) => {
      qty += item.qty;
    });
    return qty;
  };

  const getCatAmt = (e) => {
    var amt = 0;
    e?.products?.map((item, i) => {
      amt += item.totalamount;
    });
    return amt;
  };

  const returnSettlementBill = () => {
    let proQty =
      data.products &&
      data.products.reduce(
        (totalHolder, item) =>
          totalHolder +
          item?.products?.reduce(
            (totalHolder, item1) => totalHolder + item1.qty,
            0
          ),
        0
      );
    let setQty =
      data.setProds &&
      data.setProds.reduce((totalHolder, item) => totalHolder + item.qty, 0);
    let ttlQty = Math.round(proQty + setQty);
    return (
      <div
        className="bg-white roboto tracking-tight"
        style={{ fontSize: "9px", marginRight: "10px" }}
      >
        <div className="break-words text-center" style={{ fontSize: "10px" }}>
          Кассын хаалтын баримт
        </div>
        <table className="w-full mb-2">
          <tr>
            <td colSpan={1}>Хаалт хийсэн:</td>
            <td colSpan={3}>{data.username}</td>
          </tr>
          <tr>
            <td colSpan={1}>Хаалт хийсэн огноо:</td>
            <td colSpan={3}>
              {dayjs(data.insymd).format("YYYY-MM-DD HH:mm:ss")}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>Тооцоо хаасан дүн:</td>
            <td colSpan={3}>
              {new Intl.NumberFormat().format(data.useramount)}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>Хаалтын төрөл:</td>
            <td colSpan={3}>
              {data.ischeck
                ? "Тооцоо хийгээгүй захиалгыг устгах /Бүх кассчины/"
                : "Зөвхөн өөрийн захиалгын тооцоог хаах"}
            </td>
          </tr>
        </table>
        <table className="w-full border-b border-t border-black text-right">
          <tr className="border-b border-black ">
            <td className="text-left">Хоолны нэр</td>
            <td>Тоо</td>
            <td>Үнэ</td>
            <td>Нийт</td>
          </tr>
          {data.products &&
            data.products.map((item, i) => (
              <>
                <tr key={i}>
                  <td className="text-left">{`${i + 1}. ${item.catname}`}</td>
                </tr>
                {item?.products?.map((item1, i1) => (
                  <tr key={i1}>
                    <td className="text-left">
                      &nbsp;&nbsp;&nbsp;{`${item1.productname}`}
                    </td>
                    <td>{item1.qty}</td>
                    <td>{new Intl.NumberFormat().format(item1.amount)}</td>
                    <td>{new Intl.NumberFormat().format(item1.totalamount)}</td>
                  </tr>
                ))}
                <tr className="border-t border-black">
                  <td className="font-bold">Дүн:</td>
                  <td className="font-bold">{getCatQty(item)}</td>
                  <td></td>
                  <td className="font-bold">
                    {new Intl.NumberFormat().format(getCatAmt(item))}
                  </td>
                </tr>
              </>
            ))}
          {data.setProds && data.setProds.length ? (
            <tr>
              <td className="text-left">Сет хоол</td>
            </tr>
          ) : null}
          {data?.setProds?.map((item, i) => (
            <tr key={i}>
              <td className="text-left">
                &nbsp;&nbsp;&nbsp;{item.packsetname}
              </td>
              <td>{item.qty}</td>
              <td>{new Intl.NumberFormat().format(item.price)}</td>
              <td>{new Intl.NumberFormat().format(item.totalamount)}</td>
            </tr>
          ))}
          {data?.timeamount ? (
            <tr className="border-black">
              <td className="font-bold">Цагийн төлбөр:</td>
              <td></td>
              <td></td>
              <td className="font-bold">
                {new Intl.NumberFormat().format(data.timeamount)}
              </td>
            </tr>
          ) : null}
          {data?.fee ? (
            <tr className=" border-black">
              <td className="font-bold">Үйлчилгээний нэмэгдэл:</td>
              <td></td>
              <td></td>
              <td className="font-bold">
                {new Intl.NumberFormat().format(data.fee)}
              </td>
            </tr>
          ) : null}
          <tr className="border-t border-black">
            <td className="font-bold">Нийт дүн:</td>
            <td className="font-bold">{ttlQty}</td>
            <td></td>
            <td className="font-bold">
              {new Intl.NumberFormat().format(data.amount)}
            </td>
          </tr>
        </table>
        <div class="w-full my-4 bg-black border-dashed border-t " />
        <table className="w-full border-b border-black ">
          <tr className="border-b border-black ">
            <td className="text-left">Төлбөрийн төрөл</td>
            <td className="text-right">Дүн</td>
          </tr>
          {data.salePay &&
            data.salePay.map((item, i) => (
              <>
                <tr key={i}>
                  <td className="text-left">{`${item?.saledate}`}</td>
                </tr>
                {item?.salePay?.map((item1, i1) => (
                  <tr key={i1}>
                    <td className="text-left">
                      &nbsp;&nbsp;&nbsp;{item1.typename}
                    </td>
                    <td className="text-right">
                      {new Intl.NumberFormat().format(item1.amount)}
                    </td>
                  </tr>
                ))}
              </>
            ))}

          <tr className="border-t border-black">
            <td className="text-center">Борлуулалт дүн</td>
            <td className="text-right font-bold">
              {new Intl.NumberFormat().format(data.saleamount || 0)}
            </td>
          </tr>
          <tr className="border-t border-black">
            <td className="text-center">Буцаалт дүн</td>
            <td className="text-right font-bold">
              {new Intl.NumberFormat().format(data.returnamount || 0)}
            </td>
          </tr>
        </table>
      </div>
    );
  };

  const renderCard = () => {
    return (
      <div
        className="bg-white roboto tracking-tight p-10"
        style={{ fontSize: "9px", marginRight: "10px" }}
      >
        <div className="break-words text-center" style={{ fontSize: "10px" }}>
          Хоолны технологийн карт
        </div>
        <table className="w-full">
          <tr>
            <td>Байгууллагын нэр:</td>
            <td>{data.vatname}</td>
            <td>Картын дугаар:</td>
            <td>{data.id}</td>
          </tr>
          <tr>
            <td>Бичилт хийсэн огноо:</td>
            <td>{dayjs(data.insymd).format("YYYY-MM-DD")}</td>
            <td>Картын нэр:</td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td>Баталсан:</td>
            <td>............................................</td>
            <td>Порц:</td>
            <td>{data.portionnm}</td>
          </tr>
        </table>
        <table className="w-2/4 my-4">
          <thead>
            <th className="border-y border-l border-black">#</th>
            <th className="border-y border-l border-black">Орц</th>
            <th className="border-y border-l border-black">Хэмжих нэгж</th>
            <th className="border-y border-l border-black">Бохир жин</th>
            <th className="border-y border-l border-r border-black">
              Цэвэр жин
            </th>
          </thead>
          {data.materials &&
            data.materials.map((item, i) => (
              <tr>
                <td className="text-center border-y border-l border-black">
                  {i + 1}
                </td>
                <td className="border-y border-l border-black">
                  {item.cardnm}
                </td>
                <td className="border-y border-l border-black">
                  {item.unitnm}
                </td>
                <td className="text-right border-y border-l border-black">
                  {new Intl.NumberFormat().format(item.net ? item.net : 0)}
                </td>
                <td className="text-right border-y border-l border-r border-black">
                  {new Intl.NumberFormat().format(item.gross ? item.gross : 0)}
                </td>
              </tr>
            ))}
        </table>
        <span>Заавар:</span>
        <div className="" dangerouslySetInnerHTML={{ __html: data.manual }} />
      </div>
    );
  };

  const renderReciepts = () => {
    if (data.type === ReceiptType.Борлуулалт) {
      return renderSaleBill();
    } else if (data.type === ReceiptType.Захиалга) {
      if (printqty && printqty > 0) {
        let tmp = [];
        for (let i = 1; i <= printqty; i++) {
          tmp.push(<p>{renderSaleBill(i)}</p>);
        }
        return tmp;
      }
      return renderSaleBill(1);
    } else if (data.type === ReceiptType.Хаалт) {
      return returnSettlementBill();
    } else if (data.type === ReceiptType.Карт) {
      return renderCard();
    }
  };

  return (
    <div
      ref={ref}
      style={{ all: "initial" }}
      className={data && data.type === ReceiptType.Карт ? "" : "printpage"}
    >
      {data && renderReciepts()}
    </div>
  );
});
