import { Table } from "antd";
import { LICENSE_MSG } from "../utils";
import { LicenseType } from "./enums";

class UsefulFunctions {
  static calculateFooterCells = (data, columns) => {
    let cells = [];
    let count = 0;

    data.forEach((record, i) => {
      columns.forEach((column, i1) => {
        if (column.isSum) {
          const a = cells[i1] ? parseFloat(cells[i1]["sumamt"]) || 0 : 0;
          const b = record[column.dataIndex]
            ? parseFloat(record[column.dataIndex])
            : 0;
          const sum = a + b;

          cells[i1] = {
            ...column,
            sumamt: sum.toFixed(2),
          };

          count += sum;
        } else {
          cells[i1] = { ...column, sumamt: "" };
        }
      });
    });
    return {
      cells,
      count: parseFloat(count).toFixed(2),
    };
  };

  static getPrice = (pricenon, value) => {
    if (value) {
      if (pricenon) {
        return new Intl.NumberFormat("en-NZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      }
      return new Intl.NumberFormat().format(Math.round(value * 100) / 100);
    }
    return " ";
  };

  static renderSummaryCell = (value, key, isPrint, pricenon = false) => {
    if (isPrint) {
      return (
        <td
          key={key}
          style={{
            border: "1px solid black",
            paddingRight: "3px",
            paddingLeft: "3px",
          }}
          index={key}
          className="font-bold text-right"
        >
          {this.getPrice(pricenon, value)}
        </td>
      );
    }
    return (
      <Table.Summary.Cell
        key={key}
        index={key}
        className="font-bold text-right"
      >
        {this.getPrice(pricenon, value)}
      </Table.Summary.Cell>
    );
  };

  static renderSummary = ({ data, columns, isPagination, isPrint }) => {
    if (isPagination) {
      return columns.map((col, i) => {
        if (col.children && col.children.length > 0) {
          return col.children.map((childCol, childi) => {
            if (data && data[childCol.dataIndex])
              return this.renderSummaryCell(
                data[childCol.dataIndex],
                childi,
                isPrint
              );
            return this.renderSummaryCell(null, childi, isPrint);
          });
        }
        if (data && data[col.dataIndex])
          return this.renderSummaryCell(data[col.dataIndex], i, isPrint);
        return this.renderSummaryCell(null, i, isPrint);
      });
    } else {
      let { cells, count } = this.calculateFooterCells(data, columns);
      if (count === 0) return null;
      return cells.map((item, i) =>
        this.renderSummaryCell(item?.sumamt, i, isPrint, item?.pricenon)
      );
    }
  };

  static renderSummaryRow = ({ footer, columns, isPagination, isPrint }) => {
    if (footer) {
      return footer.map((item, i) => {
        if (isPrint) {
          return (
            <tr>
              {this.renderSummary({
                data: item,
                columns,
                isPagination,
                isPrint,
              })}
            </tr>
          );
        } else {
          return (
            <Table.Summary.Row key={i}>
              {this.renderSummary({
                data: item,
                columns,
                isPagination,
                isPrint,
              })}
            </Table.Summary.Row>
          );
        }
      });
    }
    return null;
  };

  static checkPermission = ({
    pagePermission,
    permission,
    isMsg,
    setError,
    license,
    reslicense,
  }) => {
    let licenseresult = false;
    if (reslicense === null) licenseresult = false;
    else if (!license) licenseresult = true;
    else if (reslicense?.detail?.find((x) => Number(x) === license))
      licenseresult = true;
    /* if (!reslicense) {
      if (license === -1) {
        licenseresult = true;
      } else {
        setError({ isOpen: true, message: LICENSE_MSG, isLicenseModal: true });
        return false;
      }
    } else if (license === undefined) {
      licenseresult = true;
    } else if (
      (license && license === reslicense.type) ||
      license == LicenseType.All
    ) {
      licenseresult = true;
    } else {
      if (license === -1) {
        licenseresult = true;
      } else {
        setError({ isOpen: true, message: LICENSE_MSG, isLicenseModal: true });
      }
    } */
    if (licenseresult) {
      if (!pagePermission) return true;
      else if (permission) {
        let check = permission.find((x) => x === pagePermission);
        if (check) return true;
        if (isMsg && setError)
          setError({
            isOpen: true,
            message: "Уучлаарай та хэрэглэгчийн эрхгүй байна.",
          });
      }
    } else {
      setError({ isOpen: true, message: LICENSE_MSG, isLicenseModal: true });
      return false;
    }

    return false;
  };

  static checkSetProducts = (listOne, listTwo) => {
    if (listOne === undefined || listTwo === undefined) return false;
    if (listOne.length !== listTwo.length) return false;
    let isValid = true;
    listOne.map((item) => {
      let result = listTwo.find(
        (x) => x.productid === item.productid && x.spiceid === item.spiceid
      );
      if (!result) isValid = false;
    });
    return isValid;
  };

  static getSetDetailItem = (sub, spice) => {
    let tmp = {
      productid: spice ? spice.productid : sub.productid,
      productname: sub.name,
      barcode: null,
      qty: 0,
      buyqty: spice ? spice.buyqty : sub.buyqty,
      spiceid: spice ? spice.spicesid : 0,
      spicename: spice ? spice.name : "",
      price: sub.price,
      amount: sub.price,
      discount: 0,
      unitdiscount: 0,
      totalamount: sub.price,
    };

    return tmp;
  };

  static calcFee = (amount, fee) => {
    if (fee) {
      let feeamt = (amount * fee) / 100;
      return Math.round(feeamt);
    }
    return 0;
  };

  static getSum = ({
    saleList = [],
    onlyFee = false,
    onlySum = false,
    fee = 0,
  }) => {
    let sumamt = saleList?.reduce((a, v) => (a = a + v.amount), 0);
    if (onlyFee) {
      return this.calcFee(sumamt, fee);
    }
    if (onlySum) return sumamt;

    sumamt = sumamt + this.calcFee(sumamt, fee);
    return sumamt;
  };

  static getFoodCount = ({ saleList }) => {
    return saleList?.reduce((a, v) => {
      if (!v.istime) a = a + v.qty;
      return a;
    }, 0);
  };

  static getOrderBody = ({ values, order }) => {
    let location = JSON.parse(localStorage.getItem("deliveryLocation"));
    return {
      orderid: order?.id || 0,
      status: 2,
      comment: localStorage.getItem("comment"),
      waiterid: Number(localStorage.getItem("waiter")) || 0,
      // comment: JSON.parse(localStorage.getItem("comment")),
      orderenum: values.orderType,
      deliveryenum: values.deliveryType,
      amount: values?.details?.reduce?.((a, v) => (a = a + v.amount), 0),
      tableid: values?.activeTable,
      details: values?.details,
      locid: location?.locid || 0,
      address: location?.address || "",
    };
  };

  static getMax = (arr) => {
    let max = arr[0];
    let index = 0;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i]?.price > max?.price) {
        max = arr[i];
        index = i;
      }
    }

    return { max: { ...max }, index };
  };

  static calculateSaleData = (product, type) => {
    if (product.isset === 1) {
      let setAmount = 0;
      if (product.setdetails && product.setdetails.length) {
        setAmount = product.setdetails.reduce(
          (totalHolder, item) => totalHolder + item.price * item.buyqty,
          0
        );
      }
      let diffAmt = setAmount - product.price;
      product.setdetails &&
        product.setdetails.map((item) => {
          if (type === "minus") {
            item.qty = item.qty - item.buyqty;
          } else if (type === "plus") {
            item.qty = item.qty === 0 ? item.buyqty : item.qty + item.buyqty;
          } else if (type === "qty") {
            item.qty = product.qty * item.buyqty;
          }
          let procent = (100 / setAmount) * item.price;
          let discount = Math.round((diffAmt / 100) * procent);
          item.unitdiscount = discount;
          this.calcualteDiscount(item);
        });

      let sumDiscoAmt = product?.setdetails?.reduce(
        (totalHolder, item) => totalHolder + item.amount,
        0
      );
      if (product?.setdetails?.length) {
        let fix = product?.price - sumDiscoAmt;
        let res = this.getMax(product?.setdetails);
        if (fix > 0) {
          product.setdetails[res.index].unitdiscount = res?.max?.discount - fix;
        } else {
          let fixed = fix * -1;
          console.log(product.setdetails[res.index]);
          // product.setdetails[res.index].unitdiscount =
          //   res?.max?.discount + fixed;
        }
        // product?.setdetails?.map((item) => {
        //   this.calcualteDiscount(item);
        // });
      }
    }

    this.calcualteDiscount(product);
  };

  static calcualteDiscount = (product) => {
    if (!product.istime) {
      product.amount =
        product.price * product.qty - product.unitdiscount * product.qty;
      product.discount = product.unitdiscount * product.qty;
      product.totalamount = product.price * product.qty;
    } else {
      product.discount = 0;
      product.totalamount = product.amount;
    }
  };
}

export default UsefulFunctions;
