import React, { useState, useCallback } from "react";
import { HeartTwoTone, HeartFilled } from "@ant-design/icons";
import { Button, Tag } from "antd";
import * as Components from "../";
import grocery from "../../assets/icons/grocery.png";
import { useMotionHook } from "../../hooks/motionHook";
import { API_URL, owntoast } from "../../utils";
import SetFood from "./SetFood";
import UsefulFunctions from "../../utils/UsefulFunctions";
import CustomModal from "../CustomModal";
import LazyImage from "../LazyImage";

const params = {
  slidesPerView: 2,
  spaceBetween: 10,
};

/* Хоолны карт хэсэг */
const status1 = "border border-blue text-blue";
const status0 = "bg-[#4f4f73] text-white";

function Card(props) {
  let { item, i } = props;
  const animations = useMotionHook();
  const [isOpen, setIsOpen] = useState(false);
  const [foodData, setFoodData] = useState(null);
  const [isOpenSet, setIsOpenSet] = useState(false);

  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  const setFav = () => {
    let isFav = item.isfav ? 0 : 1;
    const url = `/${item.isset ? "set/fav" : "product/setfav"}/${
      item.id
    }/${isFav}`;
    props.request({ url: url, method: "PUT" }).then((res) => {
      if (res.success) {
        item.isfav = isFav;
        forceUpdate();
        owntoast(res.message, "success");
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  const setStatus = () => {
    let isEmpty = item.isempty ? 0 : 1;
    const url = `/${item.isset ? "set/empty" : "product/setempty"}/${
      item.id
    }/${isEmpty}`;
    props
      .request({
        url: url,
        method: "PUT",
      })
      .then((res) => {
        if (res.success) {
          item.isempty = isEmpty;
          forceUpdate();
          owntoast(res.message, "success");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const handleCardClick = () => {
    if (item.isempty === 1) {
      setIsOpen(true);
    } else {
      if (item.isset) {
        props
          .request({
            url: `/set/detail/${item.id}`,
            method: "POST",
          })
          .then((res) => {
            if (res.success) {
              if (res.data && res.data.products) {
                let openModal = false;
                res.data.products.map((product) => {
                  if (product.subs && product.subs.length > 1) {
                    openModal = true;
                  } else if (product.subs) {
                    product.subs.map((sub) => {
                      if (sub.spices.length > 0) {
                        openModal = true;
                      }
                    });
                  }
                });
                if (openModal) {
                  setIsOpenSet(true);
                  setFoodData(res.data);
                } else {
                  let tmp = {
                    productid: 0,
                    productname: res.data.name,
                    barcode: null,
                    qty: 1,
                    price: res.data.price,
                    amount: res.data.price,
                    discount: 0,
                    unitdiscount: 0,
                    totalamount: res.data.price,
                    type: "plus",
                    isset: 1,
                    printkitchen: res.data.printkitchen,
                    catid: res.data.catid,
                    setid: res.data.id,
                  };
                  let setDetails = [];
                  res.data.products.map((product) => {
                    if (product.subs) {
                      product.subs.map((sub) => {
                        setDetails.push(
                          UsefulFunctions.getSetDetailItem(
                            { ...sub, buyqty: product.qty },
                            null
                          )
                        );
                      });
                    }
                  });
                  tmp.setdetails = setDetails ? [...setDetails] : [];
                  props.addSaleList(tmp);
                }
              } else {
                owntoast("Hool bvrtgelgvi set baina", "error");
              }
            } else {
              owntoast(
                "set iin medeelel duudhad aldaa garlaa " + res.message,
                "error"
              );
            }
          });
      } else {
        if (item.spices && item.spices.length > 0) {
          setIsOpenSet(true);
          setFoodData(item);
        } else {
          addToProduct();
        }
      }
    }
  };

  const addToProduct = () => {
    props.addSaleList({
      productid: item.id,
      productname: item.name,
      barcode: item.barcode,
      qty: 1,
      price: item.sprice,
      amount: item.sprice,
      spiceid: 0,
      discount: 0,
      unitdiscount: 0,
      printkitchen: item.printkitchen,
      catid: item.catid,
      totalamount: item.sprice,
      type: "plus",
    });
    if (isOpen) setIsOpen(false);
  };

  const onCloseModal = () => {
    setIsOpenSet(false);
    setFoodData(null);
  };

  return (
    <div className="border border-borderBrey rounded-md h-[210px] z-10 hover:bg-primaryOpa">
      <div
        /*  {...animations} */
        className="p-2 h-full flex flex-col"
        key={`${i}${item.name}`}
      >
        <div className="flex justify-between items-center">
          <Components.Ripple className="flex " onClick={setFav}>
            {item.isfav === 1 ? (
              <HeartFilled style={{ color: "#FF4D4F" }} />
            ) : (
              <HeartTwoTone twoToneColor="#FF4D4F" />
            )}
          </Components.Ripple>
          <div className="flex items-center">
            <Tag color={item?.isunit ? "green" : "red"}>
              {item?.isunit ? "Бараа" : "Хоол"}
            </Tag>
            <Components.Ripple
              className={`${
                item.isempty === 0 ? status1 : status0
              } rounded-md px-1.5 h-5 flex items-center justify-center text-xs`}
              onClick={setStatus}
            >
              {item.isempty === 0 ? "төлөв" : "дууссан"}
            </Components.Ripple>
          </div>
        </div>
        <div
          className="flex flex-col h-full cursor-pointer justify-between"
          onClick={handleCardClick}
        >
          <div className="h-[100px] mt-2">
            <div className="h-full w-full">
              <LazyImage
                src={`${API_URL}${item.imgpos}`}
                placeholderImg={`${API_URL}${item.imgpos?.replace(
                  /\.[^\.]+$/,
                  ".thumb"
                )}`}
                className="w-full h-full object-contain rounded-t-xl"
                alt=""
              />
              {/*  <img
                src={item.imgpos ? `${API_URL}${item.imgpos}` : noimage}
                alt=""
                onError={({ currentTarget }) => (currentTarget.src = noimage)}
                className="w-full h-full object-contain rounded-t-xl"
              /> */}
            </div>
          </div>
          <div className="flex flex-col justify-between space-between ">
            {/* text-ellipsis whitespace-nowrap overflow-hidden */}
            <div
              className="py-1 text-sm text-ellipsis overflow-hidden"
              style={{ height: "45px" }}
            >
              {item.barcode && (
                <span className="text-blue mr-2">{item.barcode}</span>
              )}
              {item.name}
            </div>
            <div className="flex items-end justify-between">
              <span className="text-grey mr-2">{item.portionnm}</span>
              <span className="text-black font-bold">
                {new Intl.NumberFormat().format(item.sprice)}₮
              </span>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        onClose={setIsOpen}
        title="Анхааруулга"
        isOpen={isOpen}
        footer={[
          <Button onClick={() => setIsOpen(false)}>Үгүй</Button>,
          <Button
            type="primary"
            className="bg-primary"
            onClick={addToProduct}
            autoFocus
          >
            Тийм
          </Button>,
        ]}
      >
        <div className="flex flex-col items-center justify-center">
          <img
            src={grocery}
            alt="grocery"
            className="py-4 object-contain w-32"
          />
          <span className="text-center">
            Таны сонгосон бараа дууссан төлөвтэй байна. Нэмэхдээ итгэлтэй байна
            уу ?
          </span>
        </div>
      </CustomModal>
      <SetFood
        isOpenSet={isOpenSet}
        onCloseModal={onCloseModal}
        foodData={foodData}
        addSaleList={props.addSaleList}
      />
    </div>
  );
}

export default Card;
