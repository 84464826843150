import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";
import { MAIN_MENU } from "../../data";
import { Routes, Route } from "react-router-dom";
import * as Components from "../../components";
import * as Containers from "../../containers";
import { motion, AnimatePresence } from "framer-motion";
import { pageMotion } from "../../utils/enums";
import { useWindowSize } from "../../hooks/useWindowSize";
import UsefulFunctions from "../../utils/UsefulFunctions";
const { Sider, Content } = Layout;
const { SubMenu } = Menu;
/*  */
function Management(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [width] = useWindowSize();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // МЕНЮ/ТАЙЛАН/ТОХИРГОО аль дээр дарсныг авч тухайн хуудсанд хамаарах меню нүүдийн авах хэсэг
  const menus = MAIN_MENU({ ...props }).find(
    (a) => a.route === `/management/${params.id}`
    // (a) => a.route.includes(`/management`)
  );

  const check = (page) => {
    return UsefulFunctions.checkPermission({
      pagePermission: page.permission,
      license: page.license,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  /* Унждаг меню зурах хэсэг */
  const renderSubMenu = (menus, { name, icon, id, isHead }) => {
    return isHead && props._auth && !props?._auth?.ishead ? null : (
      <SubMenu key={id} icon={icon} title={name}>
        {menus && menus.map((item, i) => renderMenus(item, id))}
      </SubMenu>
    );
  };

  /* Унждаг меню -ны дотор меню зурах */
  const renderMenus = (item, subid) =>
    item?.name && (
      <Menu.Item
        key={subid ? `${subid}/${item?.id}` : item?.id}
        icon={item?.icon}
        /* style={{ whiteSpace: "normal", height: "auto", lineHeight: "35px" }} */
        onClick={() => {
          /*  if (isSettings && initialValues) {
            localStorage.setItem("editRow", JSON.stringify(initialValues));
          } */
          let result = check(item);
          if (result) navigate(subid ? `${subid}/${item?.id}` : `${item?.id}`);
        }}
      >
        <Tooltip title={item?.name} placement="right">
          {item?.name}
        </Tooltip>
      </Menu.Item>
    );
  /* Route ийн дагуу Шинэ/засах эсвэл жагсаалтын component үүсгэж зурах хэсэг */
  const renderAnimatedPage = (route, page) =>
    route ? (
      <Route
        path={route}
        key={route}
        element={
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            className="h-full"
            variants={pageMotion}
          >
            {route.includes("new") || route.includes("edit") ? (
              <Containers.AddOrEdit {...props} page={page} />
            ) : (
              <Containers.List {...props} page={page} />
            )}
          </motion.div>
        }
      />
    ) : null;

  /* Цонхны дагуу бүх route үүдийг үүсгэх */
  const renderAllRoutes = (page, route) => {
    let tmp = [
      /* isList === true бол жагсаалттай цонхыг зурна */
      page.isList || page.isSettings ? renderAnimatedPage(route, page) : null,
      page.isDashboard ? renderAnimatedPage(`${route}/dashboard`, page) : null,
      /* isNew === true бол Шинэ цонхыг зурна */
      page.isNew ? renderAnimatedPage(`${route}/new`, page) : null,
      page.isEdit ? renderAnimatedPage(`${route}/edit`, page) : null,
    ];
    return tmp;
  };

  /* Зурсан меню дотор routes байвал dбүгдийн зурна */
  const renderRoutes = () => {
    return (
      menus &&
      menus.menus &&
      menus.menus.map((item, i) => {
        if (item.menus) {
          return item.menus.map((item1, i1) => {
            return renderAllRoutes(item1, `${item.id}/${item1.id}`);
          });
        } else return renderAllRoutes(item, `${item.id}`);
      })
    );
  };
  return (
    <Layout className="w-full h-full">
      <Components.BreadCrumbs menus={menus} />
      <Layout>
        {/* Хажуу талын меню */}
        <Sider
          className="bg-white border-t-2 h-full"
          collapsible={!(width < 756)}
          width={240}
          collapsed={width < 756 ? true : collapsed}
          onCollapse={(collapsed) => setCollapsed(collapsed)}
        >
          <Menu
            mode="inline"
            defaultOpenKeys={[params["*"].split("/")[0]]}
            defaultSelectedKeys={params["*"]}
          >
            {menus &&
              menus.menus &&
              menus.menus.map((item, i) =>
                item.menus ? renderSubMenu(item.menus, item) : renderMenus(item)
              )}
          </Menu>
        </Sider>
        <Content className={`bg-fullBack ${params["*"] === "1" ? "" : "p-4"}`}>
          {/*  bg-white */}
          <div
            className={`${
              params["*"] === "1" ? "" : "bg-white"
            } w-full h-full rounded-lg p-5 overflow-auto`}
          >
            <AnimatePresence mode="wait" key={location.pathname}>
              {/* sub route зурах хэсэг */}
              <Routes location={location}>{renderRoutes()}</Routes>
            </AnimatePresence>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Management;
