import { useEffect, useState, useRef } from "react";
import * as Components from "../";
import { Button, Popconfirm } from "antd";
import { ReceiptType, PaperSize } from "../../utils/enums";
import { owntoast, postMessageMobile, useMobileEvent } from "../../utils";
import { SaleBill } from "../SaleBill";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";

function SaleDetailModal(props) {
  const {
    isOpen,
    setIsOpen,
    data,
    request,
    onSubmit,
    page,
    _auth,
    rescheckService,
    columns,
    isReturn,
    modalUrl,
    modalHeader,
    modalMethod,
    modalIdKey,
    dataIndex,
    modalTitles,
  } = props;
  const orderRef = useRef();
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  useEffect(() => {
    if (receiptData) {
      printOrder();
    }
  }, [receiptData]);

  useEffect(() => {
    if (isOpen && data && modalMethod && modalUrl && modalIdKey) {
      let body = {};
      if (modalMethod === "POST") {
        let tmp = JSON.parse(localStorage.getItem(`search${props.page.url}`));
        if (tmp) body = { ...tmp };
      }
      request({
        url: `${modalUrl}/${data[modalIdKey]}`,
        model: `${dataIndex}detail`,
        method: modalMethod,
        body,
      });
    }
  }, [isOpen]);

  const handleReturn = () => {
    if (data) {
      if (data.saletype !== 1) {
        owntoast(
          "Буцаалт хийсэн борлуулалтыг дахин буцаах боломжгүй.",
          "error"
        );
      } else {
        setLoading(true);
        request({
          url: "/sale/payreturn",
          method: "POST",
          body: {
            returnsaleid: data.id,
            ddtd: data.ddtd,
          },
        }).then((res) => {
          setLoading(false);
          if (res.success) {
            const data = {
              ...res.data,
              isReturn: true,
              isReprint: false,
              type: ReceiptType.Борлуулалт,
            };
            let searchValues = JSON.parse(
              localStorage.getItem(`search${page.url}`)
            );
            onSubmit(searchValues);
            setIsOpen(false);
            print({ ...data, isReturn: true, isReprint: false });
            owntoast(res.message, "success");
          } else {
            owntoast(res.message, "error");
          }
        });
      }
    } else {
      owntoast("Борлуулалтын мэдээлэл олдсонгүй.", "error");
    }
  };

  const getPrinterSize = () => {
    if (props.resprinterconfig) {
      if (props.resprinterconfig.printertype) {
        return props.resprinterconfig.printertype;
      }
    }
    return PaperSize.m58;
  };

  const print = (data) => {
    if (data) {
      const deviceType = localStorage.getItem("deviceType");
      const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));

      let body = {
        ...data,
        vatname: _auth.vatname,
        regno: _auth.regno,
        foodsname: _auth.foodsname,
        // username: _auth.username,
        printersize: getPrinterSize(),
      };

      if (rescheckService === true) {
        request({
          url: `/print/${PaperSize.m58}`,
          body: body,
          method: "POST",
          isservice: true,
        });
      } else if (deviceType === "ios" || deviceType === "android") {
        if (bluetooth && bluetooth.address) {
          postMessageMobile({
            type: "PRINT_BILL",
            data: JSON.stringify({ btaddress: bluetooth.address, ...body }),
          });
        } else {
          owntoast("Bluetooth принтер сануулаагүй байна.", "error");
        }
      } else {
        setReceiptData(body);
      }
    }
  };

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "RESULT_PRINT_BILL") {
          if (data.success) {
            owntoast(data.message, "success");
          } else {
            owntoast(data.message, "error");
          }
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const printOrder = useReactToPrint({
    content: () => orderRef.current,
  });

  const handleReprint = () => {
    if (data) {
      setLoading(true);
      request({ url: `/sale/reprint/${data.id}` }).then((res) => {
        setLoading(false);
        if (res.success) {
          const data = {
            ...res.data,
            isReturn: false,
            isReprint: true,
            type: ReceiptType.Борлуулалт,
          };
          owntoast(res.message, "success");
          print({ ...data, isReturn: false, isReprint: true });
        } else {
          owntoast(res.message, "error");
        }
      });
    }
  };
  const renderTitles = () => {
    let tmp = JSON.parse(localStorage.getItem(`search${props.page.url}`));
    if (data && modalTitles && tmp) {
      return modalTitles.map((item, i) => (
        <div className="flex mb-2">
          <span>{`${item.label} : `}</span>
          <span className="ml-1">
            {item.valueprop.includes("sdate,edate")
              ? `${dayjs(tmp.sdate).format("YYYY-MM-DD")} - ${dayjs(
                  tmp.edate
                ).format("YYYY-MM-DD")}`
              : data[item.valueprop]}
          </span>
        </div>
      ));
    }
  };

  const getTitle = () => {
    if (modalHeader === "divide") {
      return `Тооцоо хувааж төлсөн захиалгын дэлгэрэнгүй - №${
        data?.mainorder || ""
      }`;
    } else if (modalHeader) return modalHeader;
    return `Баримтын дэлгэрэнгүй - №${data?.salenum}`;
  };

  return (
    <Components.CustomModal
      footer={null}
      width={800}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={getTitle()}
    >
      <div className="p-3 ">
        {renderTitles()}
        <Components.AntdTable
          dataSource={
            props[`res${dataIndex}detail`] ? props[`res${dataIndex}detail`] : []
          }
          loading={props[`is${dataIndex}detail`] ? true : false}
          columns={columns}
          pagination={false}
          isModal={true}
        />
        <div className="flex gap-2 justify-between mt-2">
          {isReturn ? (
            <div className="flex gap-2">
              <Popconfirm
                title="Та уг борлуулалтыг буцаалт хийхдээ итгэлтэй байна уу ?"
                onConfirm={handleReturn}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button type="primary" loading={loading} className="bg-primary">
                  Буцаалт хийх
                </Button>
              </Popconfirm>

              <Button
                className="bg-buttonBlue text-white"
                onClick={handleReprint}
              >
                Дахин хэвлэх
              </Button>
            </div>
          ) : (
            <div />
          )}

          <Button
            className="bg-grey text-white"
            onClick={() => setIsOpen(false)}
          >
            Хаах
          </Button>
        </div>
      </div>
      {isReturn ? (
        <div className="hidden">
          <SaleBill ref={orderRef} data={receiptData} />
        </div>
      ) : null}
    </Components.CustomModal>
  );
}

export default SaleDetailModal;
