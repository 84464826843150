const Index = ({ orderTypeData, count, sum, sumfee, basefee }) => {
  const getClassName = () => {
    if (orderTypeData) {
      if (orderTypeData[1] || orderTypeData[2])
        return "sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-3 2xl:cols-span-3";
    }
    return "sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:cols-span-6";
  };

  return (
    <div
      className={`${getClassName()} border-borderGrey border rounded-md p-2`}
    >
      <div className="flex justify-between items-center font-semibold uppercase">
        <span className="text-grey">Хоолны тоо :</span>
        <span className="text-black text-base ">{count}</span>
      </div>
      <div className="flex justify-between items-center font-semibold uppercase">
        <span className="text-grey ">Дүн :</span>
        <span className="text-black text-base ">
          {new Intl.NumberFormat().format(sum)}
        </span>
      </div>
      {basefee ? (
        <div className="flex justify-between items-center font-semibold uppercase">
          <span className="text-grey">Үйлчилгээний нэмэгдэл :</span>
          <span className="text-black text-base ">
            {new Intl.NumberFormat().format(sumfee || 0)}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Index;
