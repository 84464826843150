import { useRef, useEffect, useState } from "react";
import { TableCustomizer, FormItems } from "..";
import { Button, Drawer, Form, Input, Spin, Alert } from "antd";
import { TABLES, TableFloors } from "../../data";
import { DinTableSettings } from "../../data/Forms";
import { owntoast } from "../../utils";
import * as Components from "../";
import { PlusCircleOutlined } from "@ant-design/icons";
import FImage from "../../assets/tables/paving.png";
import SImage from "../../assets/tables/check-mark.png";
import { NLicenceType } from "../../utils/enums";

const FloorDrawer = ({ isOpen, onChange, selected, onClose }) => {
  return (
    <Drawer
      title="Заалны шал сонгох"
      placement="bottom"
      height={350}
      open={isOpen}
      onClose={onClose}
    >
      <div className="grid grid-cols-10 gap-3">
        {TableFloors?.map((item, i) => (
          <div
            key={i}
            onClick={() => onChange && onChange(i)}
            className={`w-full h-full border  rounded-lg p-1 cursor-pointer ${
              selected === i && "border-primary"
            }`}
          >
            <img src={item?.img} className="h-20 rounded-lg object-cover" />
          </div>
        ))}
      </div>
    </Drawer>
  );
};

const TableDraw = (props) => {
  const [form] = Form.useForm();
  const [openFloor, setOpenFloor] = useState(false);
  const [tableImage, setTableImage] = useState(0);
  const [floorImage, setFloorImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const addTable = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (props.resdintablelist && props.resdintablelist.length) {
      setData([...props.resdintablelist]);
      if (props.resdintablelist[0]) {
        setFloorImage(props.resdintablelist[0]?.floorid);
      }
    }
  }, [props.resdintablelist]);

  const onFinish = (values) => {
    setLoading(true);
    values.tableImage = tableImage;
    values.foodid = props._auth.foodid;
    values.price = values.price ? Number(values.price) : 0;
    props
      .request({
        url: DinTableSettings.url,
        method: DinTableSettings.method,
        body: values,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          handleSavePosition();
          setIsOpen(false);
          owntoast(res.message, "success");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setTableImage(0);
    }
  }, [isOpen]);

  const handleSavePosition = () => {
    const newList = Array.from({ length: data.length }, (_, index) => {
      data[index].floorid = floorImage;
      data[index].width =
        Number(data[index].width) <= 0 ? "100" : data[index].width.toString();
      data[index].height =
        Number(data[index].height) <= 0 ? "100" : data[index].height.toString();
      data[index].x =
        Number(data[index].x) <= 0 ? "10" : data[index].x.toString();
      data[index].y =
        Number(data[index].y) <= 0 ? "10" : data[index].y.toString();
      return data[index];
    });
    setLoading(true);

    props
      .request({
        url: "/category/dintable/tablespace",
        method: "POST",
        body: newList,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          reload();
          owntoast(res.message, "success");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const reload = () => {
    props.request({
      url: `/category/dintable/list`,
      method: "POST",
      model: "dintablelist",
      body: { foodid: props._auth.foodid },
    });
  };

  const onClickTable = (tableItem) => {
    let found = data.find((x) => x.id === tableItem.id);
    if (found) {
      let tableFound = props.resdintablelist.find((x) => x.id === found.id);
      if (tableFound) {
        setTableImage(tableFound.tableimage);
        form.setFieldsValue({
          name: tableFound.name,
          description: tableFound.description,
          isenable: tableFound.isenable,
          price: tableFound.price,
          id: tableFound.id,
        });
        setIsOpen(true);
      }
    }
  };

  const onClickTableX = (tableItem) => {
    setLoading(true);
    tableItem.foodid = props._auth.foodid;
    tableItem.isenable = 0;
    tableItem.x = tableItem?.x?.toString();
    tableItem.y = tableItem?.y?.toString();
    tableItem.width = tableItem?.width?.toString();
    tableItem.height = tableItem?.height?.toString();
    props
      .request({
        url: DinTableSettings.url,
        method: DinTableSettings.method,
        body: tableItem,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          reload();
          owntoast(res.message, "success");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const isLicence = props?.reslicense?.detail?.find(
    (x) => Number(x) === NLicenceType.time
  );

  return (
    <div className="h-full w-full flex flex-col">
      <Alert
        type="warning"
        message="Та ширээнийхээ байрлал болон хэмжээг хүссэнээрээ тохируулж заалаа зохион байгуулаарай."
        showIcon
        className="mb-2"
      />
      <div className="flex gap-2 pb-2">
        <Button
          loading={loading}
          onClick={addTable}
          icon={<PlusCircleOutlined className="text-primary" />}
        >
          Ширээ нэмэх
        </Button>
        <Button
          loading={loading}
          onClick={() => setOpenFloor(true)}
          icon={<img src={FImage} alt="" className="object-contain mr-1 w-4" />}
        >
          Шалны өнгө сонгох
        </Button>
        <Button
          loading={loading}
          onClick={handleSavePosition}
          icon={<img src={SImage} alt="" className="object-contain mr-1" />}
        >
          Байрлал хадгалах
        </Button>
      </div>
      <Spin spinning={loading}>
        <div className="h-full w-full" id="testContainer" ref={containerRef}>
          <TableCustomizer
            data={data}
            setData={setData}
            width={1920}
            height={961}
            isEdit={true}
            isLicence={isLicence}
            containerRef={containerRef}
            floorImage={floorImage}
            onClickTable={onClickTable}
            onClickTableX={onClickTableX}
          />
        </div>
      </Spin>

      <FloorDrawer
        isOpen={openFloor}
        onClose={() => setOpenFloor(false)}
        onChange={(index) => setFloorImage(index)}
        selected={floorImage}
      />

      <Components.CustomModal
        title="Ширээ бүртгэх"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onFinish}
          defaultValue={{
            id: 0,
          }}
          className="p-4"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          {DinTableSettings?.leftForms?.form?.map((item, i) =>
            !isLicence && item?.name === "price" ? null : (
              <FormItems key={i} item={item} form={form} />
            )
          )}

          <div className="grid grid-cols-5 gap-3 mb-2">
            {TABLES?.map((item, i) => (
              <div
                key={i}
                onClick={() => setTableImage(i)}
                className={`w-full h-full border  rounded-lg p-1 cursor-pointer ${
                  tableImage === i && "border-primary"
                }`}
              >
                <img
                  src={item?.img}
                  className="w-full h-full rounded-lg object-contain"
                />
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-2 justify-end">
            <Button loading={loading} onClick={() => setIsOpen(false)}>
              Хаах
            </Button>
            <Button
              loading={loading}
              className="bg-primary"
              type="primary"
              htmlType="submit"
            >
              Хадгалах
            </Button>
          </div>
        </Form>
      </Components.CustomModal>
    </div>
  );
};

export default TableDraw;
