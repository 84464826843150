function CashierItem({ loading, data, label, suffix }) {
  return (
    <div className="p-5 bg-white rounded-lg">
      {loading ? (
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-3 py-1">
            <div className="h-4 bg-borderGrey rounded"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-4 bg-borderGrey rounded col-span-2"></div>
              <div className="h-4 bg-borderGrey rounded col-span-1"></div>
            </div>
            <div className="h-4 bg-borderGrey rounded"></div>
          </div>
        </div>
      ) : (
        <div>
          <span className="text-base">
            {data && data.name}{" "}
            {data &&
              data.price &&
              `/${new Intl.NumberFormat().format(data.price)}/`}
          </span>
          <div className="flex items-end mt-4 mb-1">
            <span className="font-bold text-2xl">
              {data &&
                new Intl.NumberFormat().format(
                  data.amount ? data.amount : 0
                )}{" "}
              {suffix ? suffix : data && data["measure"] && data["measure"]}
            </span>
            <span
              className={`${
                data && data.procent < 0 ? "text-red" : "text-green"
              } font-bold text-base  ml-4`}
            >
              {data && data.procent !== undefined ? `${data.procent} %` : ""}
            </span>
          </div>
          <span className="text-grey text-xs">{label}</span>
        </div>
      )}
    </div>
  );
}

export default CashierItem;
