import { useEffect } from "react";
import toast from "react-hot-toast";
import { Select } from "antd";
import noimage from "../assets/noimage.png";
import dayjs from "dayjs";
const LICENSE_MSG = "Та ТӨЛБӨР хэсгээс лиценз худалдан авна уу.";
const Option = Select;
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const format = /^[0-9\b]+$/;
const count = 8;
const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
const priceDecimal = /^[0-9]*(\.[0-9]{0,2})?$/;
const priceDecimalfloat = /^[0-9]*(\.[0-9]{0,4})?$/;
const priceInteger = /^[0-9]*?$/;
const PATTERN_TTD = new RegExp("^(([А-Яа-яӨҮөү]{2}[0-9]{8})|[0-9]{7})$");

const PRICE_PARSERS = {
  parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
};
const PRICE_PARSERS_CUSTOM = {
  parser: (value) => parseFloat(value.replace(/\$\s?|(,*)/g, "")),
  formatter: (value) => {
    let formattedValue = Number(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    if (formattedValue.endsWith(".00")) {
      formattedValue = formattedValue.slice(0, -3);
    }
    return formattedValue;
  },
};

const PHONENO_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Утасны дугаар оруулна уу.",
  },
  ({ setFieldsValue }) => ({
    validateTrigger: "onSubmit",
    validator(_, value) {
      if (format.test(value)) {
        if (value.length < count) {
          return Promise.reject(
            new Error("Утасны дугаар 8 оронтой байх ёстой.")
          );
        }
      } else {
        setFieldsValue({ phoneno: value });
        return Promise.reject(new Error("Утасны дугаар дан тоо байх ёстой."));
      }
      return Promise.resolve();
    },
  }),
];
const PASSWORD_VALIDATOR = [{ required: true, message: "Нууц үг оруулна уу." }];

const CREATEPASSWORD_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Нууц үг оруулна уу.",
  },
  ({ setFieldsValue }) => ({
    // validateTrigger: "onSubmit",
    validator(_, value) {
      if (!validPassword.test(value) || value.length < count) {
        setFieldsValue({ password: value });
        return Promise.reject(
          new Error("Yсэг, тоо агуулсан 8 буюу түүнээс дээш оронтой байx ёстой")
        );
      }
      return Promise.resolve();
    },
  }),
];

const CONFIRMPASSWORD_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    message: "Нууц үг оруулна уу.",
  },
  ({ setFieldsValue, getFieldsValue }) => ({
    // validateTrigger: "onSubmit",
    validator(_, value) {
      if (value !== getFieldsValue().password) {
        setFieldsValue({ confirmPassword: value });
        return Promise.reject(new Error("Нууц үг таарахгүй байна"));
      }
      return Promise.resolve();
    },
  }),
];
const EMAIL_VALIDATOR = [
  {
    validateTrigger: "onSubmit",
    required: true,
    type: "email",
    message: "Зөв И-мэйл оруулна уу.",
  },
];

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng)
    owntoast("Зөвхөн .PNG эсвэл .JPG зураг оруулах боломжтой.", "error");

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) owntoast("Зурагны хэмжээ 2mb -с ихгүй байх ёстой.", "error");

  return isJpgOrPng && isLt2M;
};

const getEnabledOptions = (props, data, isenable) => {
  let tmp = [];
  if (Array.isArray(data)) {
    data &&
      data.map((item) => {
        if (isenable) tmp.push(item);
        else if (item.isenable !== 0) tmp.push(item);
        return true;
      });
  } else {
    props[`res${data}`] &&
      props[`res${data}`].map((item) => {
        if (isenable) tmp.push(item);
        else if (item.isenable !== 0) tmp.push(item);
        return true;
      });
  }
  return tmp;
};

const getAllOptions = (props, data, isenable) => {
  let tmp = [];
  if (Array.isArray(data)) {
    data &&
      data.map((item) => {
        tmp.push(item);
        return true;
      });
  } else {
    props[`res${data}`] &&
      props[`res${data}`].map((item) => {
        tmp.push(item);
        return true;
      });
  }
  return tmp;
};

const GenerateFoodCardDetailValues = (values) => {
  let tmp = [];
  for (let i = 0; i < values.datalength; i++) {
    tmp.push({
      materialid: values[`materialid${i}`],
      unitid: values[`unitid${i}`],
      gross: values[`gross${i}`],
      net: values[`net${i}`],
    });
    delete values[`materialid${i}`];
    delete values[`unitid${i}`];
    delete values[`gross${i}`];
    delete values[`net${i}`];
  }
  values.materials = tmp;
  return values;
};

const renderOptions = (data, label, value, isEnabled, isAll) =>
  data &&
  data.map((item, i) => {
    if (isEnabled) {
      if (item.isenable === 1) {
        return (
          <Option key={i} value={value ? item[value] : item.value}>
            {`${label ? item[label] : item.label}`}
          </Option>
        );
      }
    } else if (isAll) {
      return (
        <Option key={i} value={value ? item[value] : item.value}>
          <div className="flex items-center">
            <img
              src={`${API_URL}${item.imgpos}`}
              alt="zurag"
              onError={({ currentTarget }) => {
                currentTarget.src = noimage;
              }}
              className="w-6 h-6 mr-2"
            />
            {label ? item[label] : item.label}
          </div>
        </Option>
      );
    } else {
      return (
        <Option key={i} value={value ? item[value] : item.value}>
          {`${label ? item[label] : item.label}`}
        </Option>
      );
    }
  });

const groupBy = (items, field) => {
  if (items) {
    return items.reduce((acc, curr) => {
      if (curr[field]) {
        const value = curr[field];
        const currentItems = acc[value];

        return {
          ...acc,
          [value]: currentItems ? [...currentItems, curr] : [curr],
        };
      }
      return acc;
    }, {});
  }
  return [];
};

function routeToAddOrEdit({ navigate, params, index, record }) {
  navigate(`/management/${params.id}/${params["*"]}/edit?index=${index}`);
  /* let editRows = JSON.parse(localStorage.getItem("editRows"));
  editRows[params["*"]] = record; */
  localStorage.setItem("editRow", JSON.stringify(record));
}

const owntoast = (msg, type) => {
  /*  const audio = new Audio(msgSound);
  audio.play(); */
  toast[type](msg);
};

const isMobile = () => {
  const deviceType = localStorage.getItem("deviceType");
  if (deviceType === "ios" || deviceType === "android") return true;
  return false;
};

const useMobileEvent = (func) => {
  useEffect(() => {
    const device = localStorage.getItem("deviceType");
    if (device === "ios" || device === "android") {
      if (device === "ios") window.addEventListener("message", func);
      else document.addEventListener("message", func);

      return function cleanupListener() {
        if (device === "ios") window.removeEventListener("message", func);
        else document.removeEventListener("message", func);
      };
    }
  }, [func]);
};

const postMessageMobile = (data) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

const splitString = (value, char, visible) => {
  if (visible) return value.match(/.{1,4}/g).join(char);
  else return "************".match(/.{1,4}/g).join(char);
};

const loadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve(img);
    };
  });
};

const isNewYear = () => {
  const currentDate = dayjs();
  const currentYear = dayjs().year();
  const nextYear = currentYear + 1;

  const date1 = dayjs(`${currentYear}-12-01`);
  const date2 = dayjs(`${nextYear}-01-05`);

  if (date1 < currentDate && date2 > currentDate) {
    return true;
  }

  return false;
};

const calculateDurartionQty = (duration) => {
  const summinutes = (duration?.hour || 0) * 60 + (duration?.minute || 0);
  const procent = summinutes / 60;
  return procent;
};

const saveTime = (
  duration,
  amount,
  ongoingduration,
  event,
  sdate,
  edate,
  tableprice
) => {
  const procent = calculateDurartionQty(duration);
  let itemObj = {
    id: 0,
    productid: 0,
    productname: "Цаг/төлбөр",
    qty: Number(procent.toFixed(2)),
    ismake: 0,
    isset: 0,
    price: tableprice,
    amount: amount,
    spiceid: 0,
    discount: 0,
    unitdiscount: 0,
    printkitchen: 0,
    catid: 0,
    istime: true,
    totalamount: amount,
    setdetails: [],
    setid: 0,
    type: "plus",
  };
  const ongoingprocent = calculateDurartionQty(ongoingduration);
  let modalObj = {
    ongoingtime: ongoingprocent,
    timelogid: event,
    timesdate: sdate?.format(),
    timeedate: edate?.format(),
    hour: `${duration?.hour || "00"}:${duration?.minute || "00"}`,
  };
  return { itemObj, modalObj };
};

const API_URL = "https://foodapi.onispos.mn:8085/";
//const API_URL = "http://10.0.0.35:8088/";
// const API_URL = "http://10.150.10.24:8089/";
const QRMENU_URL = "https://qrmenu.onispos.mn";
//const QRMENU_URL = "http://10.160.0.35:8090";
//const QRMENU_URL = "http://10.150.10.62:3001";
const SERVICE_URL = "http://localhost:3006/";
const PAYMENT_SERVICE_URL = "https://payment.onispos.mn:6970/";
const SOCKET_URL = "https://socket.onispos.mn:3007";
//const SOCKET_URL = "http://10.150.10.34:3007";
export {
  PHONENO_VALIDATOR,
  PASSWORD_VALIDATOR,
  CREATEPASSWORD_VALIDATOR,
  CONFIRMPASSWORD_VALIDATOR,
  EMAIL_VALIDATOR,
  SOCKET_URL,
  getEnabledOptions,
  GenerateFoodCardDetailValues,
  renderOptions,
  API_URL,
  QRMENU_URL,
  groupBy,
  /* getSaleNum */
  priceDecimal,
  priceDecimalfloat,
  priceInteger,
  PRICE_PARSERS,
  SERVICE_URL,
  routeToAddOrEdit,
  PATTERN_TTD,
  owntoast,
  LICENSE_MSG,
  PAYMENT_SERVICE_URL,
  isMobile,
  useMobileEvent,
  postMessageMobile,
  loadImage,
  splitString,
  isNewYear,
  saveTime,
  PRICE_PARSERS_CUSTOM,
  getAllOptions,
};
