import React, { useRef, useEffect } from "react";
// import logoservice from "../../assets/logo/loginLogo.png";
import logoFood from "../../assets/logos/logo_word.png";
import restaurant from "../../assets/login/restaurant.png";
import pub from "../../assets/login/pub.png";
import fastfood from "../../assets/login/fastfood.png";
import coffeeshop from "../../assets/login/coffeeshop.png";
import buffet from "../../assets/login/buffet.png";
import bakery from "../../assets/login/bakery.png";
const SLIDER_LIST = [

  {
    img: restaurant,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: "РЕСТОРАН",
    des: "Оньс фүүд системийг төрөл бүрийн РЕСТОРАН-д ашиглах боломжтой.",
    name: "РЕСТОРАН",
    description: "Төрөл бүрийн",
  },
  {
    img: pub,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: "ПАБ, БАР, КАРАОКЕ",
    des: "Оньс фүүд системийг төрөл бүрийн ПАБ, БАР, КАРАОКЕ-д ашиглах боломжтой.",
    name: "Паб, Бар, Караоке",
    description: "Төрөл бүрийн",
  },
  {
    img: coffeeshop,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: " КОФЕ ШОП",
    des: "Оньс фүүд системийг төрөл бүрийн КОФЕ ШОП-д ашиглах боломжтой.",
    name: "Кофе шоп",
    description: "Төрөл бүрийн",
  },
  {
    img: fastfood,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: "ТҮРГЭН ХООЛНЫ ГАЗАР",
    des: "Оньс фүүд системийг төрөл бүрийн ТҮРГЭН ХООЛ ГАЗАР-д ашиглах боломжтой.",
    name: "Түргэн хоолны газар",
    description: "Төрөл бүрийн",
  },
  {
    img: buffet,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: "БУФЕТ РЕСТОРАН",
    des: "Оньс фүүд системийг төрөл бүрийн БУФЕТ ХООЛНЫ ГАЗАР-т ашиглах боломжтой.",
    name: "Буфет",
    description: "Төрөл бүрийн",
  },
  {
    img: bakery,
    author: "ONIS FOOD",
    title: "ТӨРӨЛ БҮРИЙН",
    topic: "БЕЙКЕРИ",
    des: "Оньс фүүд системийг төрөл бүрийн БЕЙКЕРИ-д ашиглах боломжтой.",
    name: "Бейкери",
    description: "Төрөл бүрийн",
  },
];
const Index = (props) => {
  const carouselRef = useRef(null);
  const sliderRef = useRef(null);
  const thumbnailRef = useRef(null);
  const nextTimeoutRef = useRef(null);
  const removeClassTimeoutRef = useRef(null);

  const timeRunning = 1000;
  const timeAutoNext = 5000;

  useEffect(() => {
    nextTimeoutRef.current = setTimeout(() => {
      handleShowSlider("next");
    }, timeAutoNext);

    return () => {
      clearTimeout(nextTimeoutRef.current);
      clearTimeout(removeClassTimeoutRef.current);
    };
  }, []);

  const handleShowSlider = (type) => {
    const sliderItems = sliderRef.current.querySelectorAll(".item");
    const thumbnailItems = thumbnailRef.current.querySelectorAll(".item");

    if (type === "next") {
      sliderRef.current.appendChild(sliderItems[0]);
      thumbnailRef.current.appendChild(thumbnailItems[0]);
      carouselRef.current.classList.add("next");
    } else {
      sliderRef.current.prepend(sliderItems[sliderItems.length - 1]);
      thumbnailRef.current.prepend(thumbnailItems[thumbnailItems.length - 1]);
      carouselRef.current.classList.add("prev");
    }

    clearTimeout(removeClassTimeoutRef.current);
    removeClassTimeoutRef.current = setTimeout(() => {
      carouselRef.current.classList.remove("next");
      carouselRef.current.classList.remove("prev");
    }, timeRunning);

    clearTimeout(nextTimeoutRef.current);
    nextTimeoutRef.current = setTimeout(() => {
      handleShowSlider("next");
    }, timeAutoNext);
  };

  return (
    <div className="bg-white h-full w-full flex justify-between">
      <div className="w-7/12 hidden lg:block">
        <div className="carousel" ref={carouselRef}>
          <div className="list" ref={sliderRef}>
            {SLIDER_LIST?.map((item, i) => (
              <div className="item" key={i}>
                <img src={item?.img} />
                <div className="content bg-white/80 p-10 rounded-lg">
                  <div className="author text-primary">{item?.author}</div>
                  <div className="title">{item?.title}</div>
                  <div className="topic">{item?.topic}</div>
                  <div className="des text-primary">{item?.des}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="thumbnail" ref={thumbnailRef}>
            {SLIDER_LIST?.map((item, i) => (
              <div className="item border-2" style={{borderRadius: "20px"}} key={i}>
                <img src={item?.img} />
                <div className="content">
                  <div className="title">{item?.name}</div>
                  <div className="description">{item?.description}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="arrows">
            <button
              id="prev"
              onClick={() => handleShowSlider("prev")}
            >{`<`}</button>
            <button
              id="next"
              onClick={() => handleShowSlider("next")}
            >{`>`}</button>
          </div>
          <div className="time"></div>
        </div>
        {/* <Carousel autoplay autoplaySpeed={5000}>
          <div>
            <img className="h-screen object-cover" src={logo} alt="Logo" />
          </div>
        </Carousel> */}
      </div>
      <div className="w-full lg:w-5/12 h-full flex justify-center items-center flex-col relative">
        <div className="flex justify-center h-fit py-5 w-5/6 lg:w-3/6 px-6 flex-col rounded-sm border-t-0 border-gray-300 lg:shadow-lg mt-10 ">
          <div className="flex flex-col items-center justify-center">
            {/* <img className="w-5/12" src={logoFood} alt="Food Logo" /> */}

            <span className="text-2xl font-medium my-0 text-primary">
              {/* {props?.title || "Нэвтрэх"} */}
            </span>
          </div>
          {props?.children}
        </div>
      </div>
    </div>
  );
};

export default Index;
