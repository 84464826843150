import React from "react";
import dayjs from "dayjs";
import UsefulFunctions from "../../utils/UsefulFunctions";

export const TablePrint = React.forwardRef((props, ref) => {
  const { dataSource, columns, name, isPagination } = props;

  const tdStyle = { paddingRight: "3px", paddingLeft: "3px" };
  const formatStyle = (item, col, i) => {
    if (col.title === "№") return i + 1;
    else if (col.exportRender) return col.exportRender(item[col.dataIndex]);
    else if (col.render)
      return col.render(
        item[col.dataIndex] ? item[col.dataIndex] : "",
        item,
        i
      );
    return item[col.dataIndex];
  };

  const renderRow = (data) => {
    if (data) {
      return data.map((item, i) => (
        <tr key={i}>
          {columns &&
            columns.map((col, i1) =>
              col.isExport === false ? null : (
                <td style={{ ...tdStyle, textAlign: col.align }} key={i1}>
                  {formatStyle(item, col, i)}
                </td>
              )
            )}
        </tr>
      ));
    }
  };

  return (
    <div ref={ref}>
      <div className="flex justify-between items-center">
        <span className="text-xl font-bold">{name}</span>
        <span>Хэвлэсэн огноо: {dayjs().format("YYYY-MM-DD")}</span>
      </div>
      <table className="my-5 w-full" id="table-to-xls">
        <thead>
          <tr>
            {columns &&
              columns.map((item, i) =>
                item.isExport === false ? null : (
                  <th key={i} style={{ ...tdStyle, textAlign: "center" }}>
                    {item.title}
                  </th>
                )
              )}
          </tr>
        </thead>
        <tbody>
          {isPagination
            ? renderRow(dataSource && dataSource.data)
            : renderRow(dataSource)}

          {isPagination ? (
            UsefulFunctions.renderSummaryRow({
              footer: dataSource && dataSource.footer,
              columns,
              isPagination,
              isPrint: true,
            })
          ) : (
            <tr>
              {UsefulFunctions.renderSummary({
                data: dataSource ? dataSource : [],
                columns,
                isPagination,
                isPrint: true,
              })}
            </tr>
          )}
        </tbody>
      </table>
      <span className="text-grey">Хэвлэсэн: Onis Food</span>
    </div>
  );
});
