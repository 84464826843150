import React, { useEffect, useState } from "react";
import { Form, Select, Button, Alert } from "antd";
import { useMobileEvent, postMessageMobile, owntoast } from "../../utils";
const Option = Select;

const BTSettings = () => {
  const [data, setData] = useState([]);
  const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));
  let isConnectPrinter = false;

  useEffect(() => {
    function getPairedDevice() {
      postMessageMobile({ type: "GET_BT_PAIRED_DEVICE", data: "" });
      postMessageMobile({ type: "IS_NATIVE_PRINTER", data: "" });
    }
    getPairedDevice();
  }, []);

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "RESULT_GET_BT_PAIRED_DEVICE") {
          if (data.success && data.data) {
            setData(data.data);
          } else {
            owntoast(data.message, "error");
          }
        } else if (data.type === "RESULT_IS_NATIVE_PRINTER") {
          if (data.success) {
            isConnectPrinter = data.data;
          }
        } else if (data.type === "RESULT_PRINT_BILL") {
          if (data.success) {
            owntoast(data.message, "success");
          } else {
            owntoast(data.message, "error");
          }
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const handleSave = (values) => {
    const res = data.find((x) => x.address === values.bluetooth);
    if (res) {
      localStorage.setItem("bluetooth", JSON.stringify(res));
      owntoast("Амжилттай", "success");
    } else {
      owntoast("Сонгосон принтер байхгүй байна.", "error");
    }
  };

  const print = () => {
    if (isConnectPrinter) {
      // print
    } else {
      if (bluetooth && bluetooth.address) {
        postMessageMobile({
          type: "PRINT_BILL",
          data: JSON.stringify({ salenum: 100, btaddress: bluetooth.address }),
        });
      } else {
        owntoast("Bluetooth принтер таниулаагүй байна.", "error");
      }
    }
  };

  return (
    <Form
      onFinish={handleSave}
      initialValues={{
        bluetooth: bluetooth && bluetooth.address,
      }}
    >
      {bluetooth && bluetooth.address ? (
        <Alert
          showIcon
          className="mb-5"
          message={`${bluetooth.name} /${bluetooth.address}/ принтер сануулсан байна.`}
          type="success"
        />
      ) : (
        <Alert
          showIcon
          className="mb-5"
          message="Bluetooth принтер сануулаагүй байна. Та принтерээ сонгож сануулна уу."
          type="warning"
        />
      )}

      <div className="grid grid-cols-12 gap-x-0 md:gap-x-10 gap-y-5">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-5 2xl:col-span-5">
          <Form.Item
            name="bluetooth"
            rules={[{ message: `Принтер сонгоно уу.`, required: true }]}
          >
            <Select>
              {data &&
                data.map((item, i) => (
                  <Option key={i} value={item.address}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хадгалах
            </Button>
            {/* <Button onClick={print}>test</Button> */}
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default BTSettings;
