import dayjs from "dayjs";
import { useState, useEffect } from "react";

const Index = () => {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <span className="text-white font-bold text-3xl">
      {time?.format("YYYY-MM-DD HH:mm:ss")}
    </span>
  );
};

export default Index;
