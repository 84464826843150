import { lazy } from "react";
import SaleDetailModal from "../components/SaleDetailModal";
import MasterRegister from "../components/MasterRegister";
import { Form, Select, InputNumber, Input } from "antd";
import Formatter from "../utils/data_formatter";
import {
  renderOptions,
  priceDecimal,
  PRICE_PARSERS,
  priceInteger,
  PRICE_PARSERS_CUSTOM,
  priceDecimalfloat,
} from "../utils";
import DeliveryAddress from "../components/DeliveryAddress";

const DeleteColumn = {
  title: "",
  dataIndex: "delete",
  key: "delete",
  width: 65,
  align: "center",
  isExport: false,
  fixed: "right",
  // render: (value, row, index) => (
  //   <Formatter value={value} format="delete" index={index} row={row} />
  // ),
  customRender: "delete",
};

const EditColumn = {
  title: "",
  dataIndex: "edit",
  key: "edit",
  width: 65,
  align: "center",
  isExport: false,
  fixed: "right",
  render: (value, row, index) => (
    <Formatter value={value} format="edit" index={index} row={row} />
  ),
};

const InsYmdColumn = {
  title: "Бүртгэсэн огноо",
  dataIndex: "insymd",
  key: "insymd",
  width: 100,
  align: "center",
  render: (value) => <Formatter value={value} format="date" />,
};

const InsNameColumn = {
  title: "Бүртгэсэн хэрэглэгч",
  dataIndex: "insnm",
  key: "insnm",
  width: 100,
};

const StatusColumn = {
  title: "Төлөв",
  dataIndex: "isenable",
  key: "isenable",
  align: "center",
  width: 100,
  exportRender: (value) => <Formatter value={value} format="statusexport" />,
  shouldCellUpdate: (record, prev) => true,
};

const NoColumn = {
  title: "№",
  dataIndex: "no",
  key: "no",
  width: 50,
  align: "center",
  render: (value, row, index) => index + 1,
};

const NoColumnhis = {
  title: "№",
  dataIndex: "no",
  key: "no",
  width: 20,
  align: "center",
  render: (value, row, index) => index + 1,
};

export const SaleDetailColumns = [
  NoColumn,
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
    width: 200,
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    key: "price",
    width: 120,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
    width: 60,
    isSum: true,
    align: "right",
  },
  {
    title: "Хямдрал",
    dataIndex: "discount",
    key: "discount",
    width: 100,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт үнэ",
    dataIndex: "totalamount",
    key: "totalamount",
    width: 100,
    align: "right",
    isSum: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

export const SaleDetailDivideColumns = [
  NoColumn,
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
    width: 200,
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    key: "price",
    width: 120,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
    width: 60,
    isSum: true,
    align: "right",
  },
  {
    title: "Захиалга №",
    dataIndex: "salenum",
    key: "salenum",
    width: 100,
    align: "right",
  },
  {
    title: "Хямдрал",
    dataIndex: "discount",
    key: "discount",
    width: 100,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт үнэ",
    dataIndex: "totalamount",
    key: "totalamount",
    width: 100,
    align: "right",
    isSum: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Хоолны бүртгэл жагсаалтын хүснэгтийн баганууд */
export const FoodListColumns = [
  NoColumn,
  {
    title: "Код",
    dataIndex: "barcode",
    width: 120,
    key: "barcode",
  },
  {
    title: "Хоолны нэр",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Нэгж үнэ",
    dataIndex: "sprice",
    key: "sprice",
    isSum: true,
    width: 100,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Хоолны ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 150,
  },
  {
    title: "Төрөл",
    dataIndex: "isunit",
    key: "isunit",
    width: 50,
    render: (value) => <Formatter value={value} format="isunit" />,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    key: "description",
    width: 150,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Сэт хоолны бүртгэл жагсаалтын хүснэгтийн баганууд */
export const SetFoodListColumns = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Бүтээгдэхүүний тоо",
    dataIndex: "detailqty",
    key: "detailqty",
    width: 150,
  },
  {
    title: "Бүтээгдэхүүн харах",
    dataIndex: "products",
    key: "products",
    align: "center",
    width: 100,
    render: (value, row, index) => (
      <Formatter value={value} format="See" row={row} index={index} />
    ),
  },
  {
    title: "Зарах үнэ",
    dataIndex: "price",
    key: "price",
    isSum: true,
    width: 150,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Нэмэлтны бүртгэл жагсаалтын хүснэгтийн баганууд */
export const SeasoningListColumns = [
  NoColumn,
  {
    title: "Нэмэлтийн нэр",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  /* {
    title: "Зарах эсэх",
    dataIndex: "isonly",
    key: "isonly",
    align: "center",
    width: 100,
    render: (value) => <Formatter value={value} format="kioskstatus" />,
  },
  {
    title: "Үнэ",
    dataIndex: "price",
    key: "price",
    isSum: true,
    width: 100,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  }, */
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Хоолны карт жагсаалтын хүснэгтийн баганууд */
export const FoodCartColumns = [
  NoColumn,
  {
    title: "Хоолны картын нэр",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Порц",
    dataIndex: "portionnm",
    key: "portionnm",
    align: "center",
    width: 100,
  },
  {
    title: "Материалын тоо",
    dataIndex: "materials",
    key: "materials",
    align: "right",
    width: 100,
    render: (value, record) =>
      record && record.materials && record.materials.length,
  },
  InsYmdColumn,
  InsNameColumn,
  {
    title: "Хэвлэх",
    dataIndex: "print",
    key: "print",
    align: "center",
    width: 100,
    clickable: true,
    render: (value) => <Formatter value={value} format="print" />,
  },
  EditColumn,
];

/* Хоолны ангилал үүсгэх жагсаалтын хүснэгтийн баганууд */
export const CategoryColumns = [
  NoColumn,
  {
    title: "Ангиллын нэр",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Тайлбар",
    className: "bg-red",
    dataIndex: "description",
    key: "description",
    width: 250,
    render: (text) => (
      <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
        {text === "null" ? "" : text}
      </div>
    ),
  },
  {
    title: "Зураг",
    className: "bg-red",
    dataIndex: "icon",
    key: "icon",
    width: 100,
    render: (value) => <Formatter value={value} format="img" />,
  },
  {
    title: "Киоск дээр харагдах эсэх",
    dataIndex: "iskiosk",
    key: "iskiosk",
    align: "center",
    width: 100,
    render: (value) => <Formatter value={value} format="kioskstatus" />,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Материалын лавлах жагсаалтын хүснэгтийн баганууд */
export const MaterialColumns = [
  NoColumn,
  {
    title: "Материалын нэр",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Материалын код",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Материалын ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 100,
  },
  {
    title: "Х/нэгж",
    dataIndex: "unitnm",
    key: "unitnm",
    align: "center",
    width: 80,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Материалын татан авалт жагсаалтын хүснэгтийн баганууд */
export const MaterialBuyingColumns = [
  NoColumn,
  {
    title: "Баримтын дугаар",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Баримтын огноо",
    dataIndex: "logymd",
    width: 100,
    key: "logymd",
    align: "center",
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Материалын тоо",
    dataIndex: "detailqty",
    key: "detailqty",
    isSum: true,
    align: "right",
    width: 100,
  },
  {
    title: "Дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    isSum: true,
    pricenon: true,
    width: 150,
    render: (value) => <Formatter value={value} format="pricenon" />,
  },
  {
    title: "Нийлүүлэгч",
    dataIndex: "supplier",
    key: "supplier",
    width: 150,
  },

  InsYmdColumn,
  InsNameColumn,
  DeleteColumn,
  EditColumn,
];

/* Материалын татан авалт жагсаалтын хүснэгтийн баганууд */
export const MaterialGroup = [
  NoColumn,
  {
    title: "Ангиллын нэр",
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: "Ангиллын код",
    dataIndex: "id",
    width: 100,
    key: "id",
    align: "center",
  },
  // {
  //   title: "Төлөв",
  //   dataIndex: "isenable",
  //   key: "isenable",
  //   align: "right",
  //   width: 100,
  // },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Барааны татан авалт хүснэгтийн баганууд */
export const ProductBuyingColumns = [
  NoColumn,
  {
    title: "Баримтын дугаар",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Баримтын огноо",
    dataIndex: "logymd",
    key: "logymd",
    align: "center",
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Барааны тоо",
    dataIndex: "detailqty",
    key: "detailqty",
    isSum: true,
    align: "right",
    width: 100,
  },
  {
    title: "Дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    isSum: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийлүүлэгч",
    dataIndex: "supplier",
    key: "supplier",
  },

  InsYmdColumn,
  InsNameColumn,
  DeleteColumn,
  EditColumn,
];

/* Борлуулалтын тайлан жагсаалтын хүснэгтийн баганууд */
export const SaleReportColumns = [
  NoColumn,
  {
    title: "Код",
    dataIndex: "barcode",
    key: "barcode",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
    width: 130,
    // ellipsis: true,
  },
  {
    title: "Хоолны ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 110,
    // ellipsis: true,
  },
  {
    title: "Зарах үнэ",
    dataIndex: "sprice",
    key: "sprice",
    align: "right",
    // ellipsis: true,
    width: 90,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Өртөг үнэ",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 90,
    // ellipsis: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Зарагдсан тоо",
    dataIndex: "qty",
    key: "qty",
    // ellipsis: true,
    align: "right",
    width: 90,
  },
  {
    title: "Нийт зарагдсан",
    dataIndex: "totalamount",
    key: "totalamount",
    // ellipsis: true,
    align: "right",
    width: 110,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт өртөг",
    dataIndex: "totalcost",
    key: "totalcost",
    // ellipsis: true,
    width: 110,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт хямдрал",
    dataIndex: "discount",
    key: "discount",
    // ellipsis: true,
    width: 110,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "НӨАТ",
    dataIndex: "vat",
    key: "vat",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "НХАТ",
    dataIndex: "tax",
    key: "tax",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "Нийт ашиг /дүнгээр/",
    dataIndex: "profitamount",
    width: 110,
    // ellipsis: true,
    key: "profitamount",
    align: "right",
    render: (value) => <Formatter value={value} format="lowPrice" />,
  },
  {
    title: "Ашиг %",
    dataIndex: "profit",
    // ellipsis: true,
    width: 60,
    key: "profit",
    render: (value) => (
      <Formatter value={value} format="lowPrice" isPrice={false} />
    ),
  },
];

/* Зарлага тайлан жагсаалтын хүснэгтийн баганууд */
export const LossReportColumns = [
  NoColumn,
  {
    title: "Код",
    dataIndex: "barcode",
    key: "barcode",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
    width: 130,
    // ellipsis: true,
  },
  {
    title: "Хоолны ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 110,
    // ellipsis: true,
  },
  {
    title: "Зарах үнэ",
    dataIndex: "sprice",
    key: "sprice",
    align: "right",
    // ellipsis: true,
    width: 90,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Өртөг үнэ",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 90,
    // ellipsis: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Зарагдсан тоо",
    dataIndex: "qty",
    key: "qty",
    // ellipsis: true,
    align: "right",
    width: 90,
  },
  {
    title: "Нийт зарагдсан",
    dataIndex: "totalamount",
    key: "totalamount",
    // ellipsis: true,
    align: "right",
    width: 110,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт өртөг",
    dataIndex: "totalcost",
    key: "totalcost",
    // ellipsis: true,
    width: 110,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт хямдрал",
    dataIndex: "discount",
    key: "discount",
    // ellipsis: true,
    width: 110,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт ашиг /дүнгээр/",
    dataIndex: "profitamount",
    width: 110,
    // ellipsis: true,
    key: "profitamount",
    align: "right",
    render: (value) => <Formatter value={value} format="lowPrice" />,
  },
  {
    title: "Ашиг %",
    dataIndex: "profit",
    // ellipsis: true,
    width: 60,
    key: "profit",
    render: (value) => (
      <Formatter value={value} format="lowPrice" isPrice={false} />
    ),
  },
];

/* Artlab тайлан жагсаалтын хүснэгтийн баганууд */
export const ArtlabReportColumns = [
  NoColumn,
  {
    title: "Нийт гүйлгээ",
    dataIndex: "qty",
    key: "qty",
    align: "right",
    width: 90,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Дамжуулсан гүйлгээ",
    dataIndex: "success",
    key: "success",
    align: "right",
    width: 90,
    // ellipsis: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Дамжуулаагүй гүйлгээ",
    dataIndex: "error",
    key: "error",
    // ellipsis: true,
    align: "right",
    width: 90,
  },
  {
    title: "Гүйлгээ эхлэх огноо",
    dataIndex: "startymd",
    key: "startymd",
    width: 180,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Гүйлгээ дуусах огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 180,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  // InsNameColumn,
];

/* Материалын тайлан жагсаалтын хүснэгтийн баганууд */
export const MaterialReportColumns = [
  NoColumn,
  {
    title: "Татан авсан огноо",
    dataIndex: "insymd",
    key: "insymd",
    align: "center",
    width: 120,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Код",
    dataIndex: "materialid",
    key: "materialid",
    width: 60,
  },
  {
    title: "Материалын нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Материалын ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 200,
  },
  {
    title: "х/нэгж",
    dataIndex: "unitname",
    key: "unitname",
    align: "center",
    width: 80,
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "qty",
    width: 100,
    key: "qty",
    align: "right",
  },
  {
    title: "Авсан үнэ",
    dataIndex: "price",
    key: "price",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт үнэ",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
];
/*  */
export const ProductReportColumns = [
  NoColumn,
  {
    title: "Татан авсан огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 100,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Код",
    dataIndex: "productid",
    key: "productid",
    width: 100,
  },
  {
    title: "Барааны нэр",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "Хоолны ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 200,
  },
  {
    title: "х/нэгж",
    dataIndex: "measureunit",
    key: "measureunit",
    align: "center",
    width: 80,
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "qty",
    key: "qty",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Авсан үнэ",
    dataIndex: "price",
    key: "price",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт үнэ",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Материалын үлдэгдлийн тайлан жагсаалтын хүснэгтийн баганууд */
export const MaterialStockReportColumns = [
  NoColumn,
  {
    title: "Материалын код",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Материалын нэр",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "Материалын ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 200,
  },
  {
    title: "х/нэгж",
    dataIndex: "measureunit",
    key: "measureunit",
    align: "center",
    width: 80,
  },
  {
    title: "Нийт татан авалт",
    dataIndex: "buyqty",
    key: "buyqty",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт зарцуулалт",
    dataIndex: "salenet",
    key: "salenet",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Үлдэгдэл",
    dataIndex: "stock",
    key: "stock",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="priceColored" />,
  },
  {
    title: "Дундаж өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт өртөг",
    dataIndex: "totalcost",
    key: "totalcost",
    align: "right",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Барааны үлдэгдлийн тайлан хүснэгтийн баганууд */
export const ProductStockReportColumns = [
  NoColumn,
  {
    title: "Барааны код",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Барааны нэр",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "Хоолны ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 200,
  },
  {
    title: "х/нэгж",
    dataIndex: "measureunit",
    key: "measureunit",
    align: "center",
    width: 80,
  },
  {
    title: "Нийт татан авалт",
    dataIndex: "buyqty",
    key: "buyqty",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт зарцуулалт",
    dataIndex: "saleqty",
    key: "saleqty",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Үлдэгдэл",
    dataIndex: "stock",
    key: "stock",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="priceColored" />,
  },
  {
    title: "Дундаж өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт өртөг",
    dataIndex: "totalcost",
    key: "totalcost",
    align: "right",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Зарах үнэ",
    dataIndex: "sprice",
    key: "sprice",
    align: "right",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт зарах үнэ",
    dataIndex: "totalsprice",
    key: "totalsprice",
    align: "right",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

export const MaterialCostDetailColumns = [
  NoColumn,
  {
    title: "Материал",
    dataIndex: "name",
    key: "name",
    width: 300,
  },
  {
    title: "Х/нэгж",
    dataIndex: "measureunit",
    key: "measureunit",
    width: 60,
  },
  {
    title: "Материалын дундаж өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    isSum: true,
    width: 240,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт орц",
    dataIndex: "net",
    key: "net",
    align: "right",
    isSum: true,
    width: 150,
  },
  {
    title: "Нийт өртөг",
    dataIndex: "total",
    key: "total",
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
    isSum: true,
    width: 240,
  },
];

export const CostReportDetailColumns = [
  NoColumn,
  {
    title: "Материал",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Зардлын дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
    isSum: true,
  },
];

/* Өртөгийн тайлан хоолоор жагсаалтын хүснэгтийн баганууд */
export const CostReportColumns = [
  NoColumn,
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
    width: 120,
  },
  {
    title: "Ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 120,
  },
  {
    title: "Гарц/порц",
    dataIndex: "portionname",
    key: "portionname",
    width: 100,
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
    width: 60,
  },
  {
    title: "Материалын зардлын дүн",
    dataIndex: "materialcost",
    key: "materialcost",
    align: "right",
    width: 100,
    render: (value, row) => (
      <Formatter value={value} format="linkPrice" row={row} />
    ),
    clickable: true,
    Modal: SaleDetailModal,
    modalColumns: MaterialCostDetailColumns,
    modalUrl: "/report/detailmaterialcost",
    modalMethod: "POST",
    modalIdKey: "productid",
    modalHeader: "Материалын зардлын задаргаа",
    modalTitles: [
      {
        label: "Хоолны нэр",
        valueprop: "productname",
      },
      {
        label: "Хоолны картын нэр",
        valueprop: "cardname",
      },
      {
        label: "Шүүсэн огноо",
        valueprop: "sdate,edate",
      },
    ],
  },
  {
    title: "Хуваарилсан зардлын дүн",
    dataIndex: "detailcost",
    key: "detailcost",
    align: "right",
    width: 100,
    render: (value, row) => (
      <Formatter value={value} format="linkPrice" row={row} />
    ),
    clickable: true,
    Modal: SaleDetailModal,
    modalColumns: CostReportDetailColumns,
    modalUrl: "/report/detailcost",
    modalMethod: "POST",
    modalIdKey: "productid",
    modalHeader: "Хуваарилсан зардлын задаргаа",
    modalTitles: [
      {
        label: "Хоолны нэр",
        valueprop: "productname",
      },
      {
        label: "Хоолны картын нэр",
        valueprop: "cardname",
      },
      {
        label: "Шүүсэн огноо",
        valueprop: "sdate,edate",
      },
    ],
  },
  {
    title: "Нийт зардал",
    dataIndex: "totalcost",
    key: "totalcost",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нэгж хоолны өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

export const CostMaterialdetail = [
  NoColumn,
  {
    title: "Хоол",
    dataIndex: "productname",
    key: "productname",
  },
  {
    title: "Х/нэгж",
    dataIndex: "measureunit",
    key: "measureunit",
  },
  {
    title: "Зарагдсан тоо",
    dataIndex: "productqty",
    key: "productqty",
  },
  {
    title: "Дундаж өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Орц",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Өртөг",
    dataIndex: "totalcost",
    key: "totalcost",
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Өртөгийн тайлан материалаар жагсаалтын хүснэгтийн баганууд */
export const CostMaterialReportColumns = [
  NoColumn,
  {
    title: "Материал",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Материалын ангилал",
    dataIndex: "catname",
    key: "catname",
    width: 200,
  },
  {
    title: "Х/нэгж",
    dataIndex: "measureunit",
    align: "center",
    key: "measureunit",
    width: 60,
  },
  {
    title: "Дундаж өртөг",
    dataIndex: "cost",
    key: "cost",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт орц",
    dataIndex: "net",
    key: "net",
    width: 130,
    align: "right",
    render: (value, row) => (
      <Formatter value={value} format="linkPrice" row={row} />
    ),
    clickable: true,
    Modal: SaleDetailModal,
    modalColumns: CostMaterialdetail,
    modalUrl: "/report/detailfood",
    modalMethod: "POST",
    modalIdKey: "id",
    modalHeader: "Орцын задаргаа",
    modalTitles: [
      {
        label: "Материалын нэр",
        valueprop: "name",
      },
      {
        label: "Шүүсэн огноо",
        valueprop: "sdate,edate",
      },
    ],
  },
  {
    title: "Нийт өртөг",
    dataIndex: "total",
    key: "total",
    align: "right",
    width: 150,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Сэт тайлан жагсаалтын хүснэгтийн баганууд */
export const SetReportColumns = [
  NoColumn,
  {
    title: "Сэт код",
    dataIndex: "setid",
    key: "setid",
    width: 100,
  },
  {
    title: "Сэт нэр",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Нэр төрөл",
    dataIndex: "detailqty",
    align: "center",
    key: "detailqty",
    width: 60,
  },
  {
    title: "Бүтээгдэхүүний тоо",
    dataIndex: "catqty",
    align: "center",
    key: "catqty",
    width: 100,
  },
  {
    title: "Зарах үнэ",
    dataIndex: "price",
    key: "price",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Борлуулсан тоо",
    dataIndex: "qty",
    key: "qty",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Нийт дүн",
    dataIndex: "totalamount",
    key: "totalamount",
    align: "right",
    width: 150,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Сэт задаргаа тайлан жагсаалтын хүснэгтийн баганууд */
export const SetDetailReportColumns = [
  NoColumn,
  {
    title: "Борлуулсан огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 180,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Сэтийн нэр",
    dataIndex: "setname",
    align: "center",
    key: "setname",
    width: 150,
  },
  {
    title: "Борлуулалтын төрөл",
    dataIndex: "saletype",
    key: "saletype",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="saletype" />,
  },
  {
    title: "Код",
    dataIndex: "productid",
    key: "productid",
    width: 60,
  },
  {
    title: "Бүтээгдэхүүний нэр",
    dataIndex: "productname",
    key: "productname",
    width: 250,
  },
  {
    title: "Зарах үнэ",
    dataIndex: "price",
    key: "price",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Борлуулсан тоо",
    dataIndex: "qty",
    key: "qty",
    align: "center",
    width: 100,
    render: (value) => <Formatter value={value} format="priceColored" />,
  },
  {
    title: "Хямдрал",
    dataIndex: "discount",
    key: "discount",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="priceColored" />,
  },
  {
    title: "Борлуулсан дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 130,
    render: (value) => <Formatter value={value} format="priceColored" />,
  },
  {
    title: "НӨАТ",
    dataIndex: "vat",
    key: "vat",
    align: "right",
    isSum: true,
    width: 100,
    render: (value, row) => (
      <Formatter value={value} format="priceColored" row={row} />
    ),
  },
  {
    title: "НХАТ",
    dataIndex: "tax",
    key: "tax",
    align: "right",
    isSum: true,
    width: 100,
    render: (value, row) => (
      <Formatter value={value} format="priceColored" row={row} />
    ),
  },
];

/* Лицензын тайлан жагсаалтын хүснэгтийн баганууд */
export const licenseReportColumns = [
  NoColumn,
  {
    title: "Зардлын нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Зардлын дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    isSum: true,
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Зардалын төрөл жагсаалтын хүснэгтийн баганууд */
export const CostTypeColumns = [
  NoColumn,
  {
    title: "Зардлын нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Зардлын дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    isSum: true,
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Захиалгийн төрөл жагсаалтын хүснэгтийн баганууд */
export const OrderTypeSettingsColumns = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "enumnm",
    key: "enumnm",
  },
  {
    title: "Захиалгын төрөл",
    dataIndex: "typenm",
    key: "typenm",
  },
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];
/* Хэрэглэгчийн эрх жагсаалтын хүснэгтийн баганууд */
export const UserPermColumns = [
  NoColumn,
  {
    title: "Хэрэглэгчийн нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Түвшин",
    dataIndex: "roletype",
    key: "roletype",
    render: (value) => <Formatter value={value} format="role" />,
  },
  {
    title: "Нэвтрэх нэр",
    dataIndex: "uname",
    key: "uname",
  },
  {
    title: "Дуусах огноо",
    dataIndex: "edate",
    key: "edate",
    align: "center",
    width: 160,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Лицензийн төрөл",
    dataIndex: "isfree",
    key: "isfree",
    align: "center",
    width: 160,
    render: (value) => <Formatter value={value} format="isfree" />,
  },
  {
    title: "Эрх",
    dataIndex: "perm",
    key: "perm",
    align: "center",
    render: (value, row, index) => (
      <Formatter value={value} format="SeePerm" row={row} index={index} />
    ),
  },
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

/* Принтер тохиргоо жагсаалтын хүснэгтийн баганууд */
export const PrinterSettingsColumn = [
  NoColumn,
  {
    title: "Принтерийн нэр",
    dataIndex: "kitchenname",
    key: "kitchenname",
  },
  {
    title: "Принтерийн IP хаяг",
    dataIndex: "kitchenip",
    key: "kitchenip",
  },
  {
    title: "Цаасны хэмжээ",
    dataIndex: "kitchentype",
    key: "kitchentype",
    width: 200,
    align: "center",
    render: (value, row, index) => (
      <Formatter value={value} format="papersize" row={row} index={index} />
    ),
  },
  {
    title: "Ангилал",
    dataIndex: "catnames",
    key: "catnames",
    render: (value, row, index) => (
      <Formatter value={value} format="catnames" row={row} index={index} />
    ),
  },
  {
    title: "Төлөв",
    dataIndex: "kitchenisenable",
    key: "kitchenisenable",
    align: "center",
    render: (value, row, index) => (
      <Formatter value={value} format="kioskstatus" row={row} index={index} />
    ),
    exportRender: (value) => <Formatter value={value} format="statusexport" />,
  },
  InsYmdColumn,
  EditColumn,
];

export const PortionList = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

export const UnitList = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

export const TimeEventList = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Цаг",
    dataIndex: "time",
    key: "time",
    width: 50,
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

export const WaiterList = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  StatusColumn,
  InsYmdColumn,
  EditColumn,
];

export const BranchList = [
  NoColumn,
  {
    title: "Код",
    dataIndex: "branchid",
    key: "branchid",
    width: 70,
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Утас",
    dataIndex: "phoneno",
    key: "phoneno",
    width: 110,
  },
  {
    title: "Салбарын хаяг",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Мастер бүртгэл",
    align: "center",
    dataIndex: "branchid",
    key: "branchid",
    width: 140,
    render: (value, row) => (
      <Formatter value={value} format="OnlySee" row={row} />
    ),
    clickable: true,
    Modal: MasterRegister,
    modalIdKey: "branchid",
    // modalColumns: MaterialCostDetailColumns,
    // modalUrl: "/report/detailmaterialcost",
    // modalMethod: "POST",
    // modalIdKey: "productid",
    // modalHeader: "Материалын зардлын задаргаа",
    // modalTitles: [
    //   {
    //     label: "Хоолны нэр",
    //     valueprop: "productname",
    //   },
    //   {
    //     label: "Хоолны картын нэр",
    //     valueprop: "cardname",
    //   },
    //   {
    //     label: "Шүүсэн огноо",
    //     valueprop: "sdate,edate",
    //   },
    // ],
  },
  InsYmdColumn,
  {
    title: "Бүтгэсэн хэрэглэгч",
    dataIndex: "insbyname",
    key: "insbyname",
    width: 110,
  },
];

export const KioskDeviceColumns = [
  NoColumn,
  {
    title: "Төхөөрөмж",
    dataIndex: "dkey",
    key: "dkey",
    width: 240,
    render: (value) => <Formatter value={value} format="split" count={4} />,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Лиценз эхлэх огноо",
    dataIndex: "sdate",
    key: "sdate",
    align: "center",
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Лиценз дуусах огноо",
    dataIndex: "edate",
    key: "edate",
    align: "center",
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Сүүлд нэвтэрсэн",
    dataIndex: "logymd",
    key: "logymd",
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Голомт IPPOS terminalid",
    dataIndex: "terminalid",
    key: "terminalid",
    align: "left",
  },
  {
    title: "Төхөөрөмжийн төлөв",
    dataIndex: "isenable",
    key: "isenable",
    align: "center",
    customRender: "statusconfirm",
  },
  EditColumn,
];

export const KioskDeviceModalColumns = [
  NoColumn,
  {
    title: "Төхөөрөмж",
    dataIndex: "dkey",
    key: "dkey",
    render: (value) => <Formatter value={value} format="split" count={4} />,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
    width: 160,
  },
  {
    title: "Дуусах огноо",
    dataIndex: "edate",
    key: "edate",
    align: "center",
    width: 110,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Сүүлд нэвтэрсэн",
    dataIndex: "logymd",
    key: "logymd",
    align: "center",
    width: 140,
    render: (value) => <Formatter value={value} format="date" />,
  },
];

export const LicenseUserListColumns = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Нэвтрэх нэр",
    dataIndex: "uname",
    key: "uname",
  },
  {
    title: "Лиценз дуусах огноо",
    dataIndex: "edate",
    key: "edate",
    render: (value) => <Formatter value={value} format="date" />,
  },
];

export const DinTableColumns = [
  NoColumn,
  {
    title: "Ширээний дугаар",
    dataIndex: "name",
    key: "name",
    align: "right",
    width: 150,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    key: "description",
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

export const KioskColumns = [
  NoColumn,
  {
    title: "Ширээний дугаар",
    dataIndex: "name",
    key: "name",
    align: "right",
    width: 150,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    key: "description",
  },
  StatusColumn,
  InsYmdColumn,
  InsNameColumn,
  EditColumn,
];

export const FoodCardDetailColumns = (props, onChange) => [
  NoColumn,
  {
    title: "Орц",
    dataIndex: "materialid",
    key: "materialid",
    width: 150,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        name={`dfieldmaterialid${i}`}
        initialValue={value}
        rules={[
          { message: `Материал сонгоно уу.`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              const material =
                props.resmateriallist &&
                props.resmateriallist.find((x) => x.id === value);
              if (material) {
                setFieldsValue({ [`dfieldunitid${i}`]: material.unitid });
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Select
          onChange={(e) => onChange({ value: e, i: i, name: "materialid" })}
          className="w-full"
          showSearch
          defaultActiveFirstOption
          align="left"
          optionFilterProp="children"
        >
          {renderOptions(props.resmateriallist, "name", "id", true)}
        </Select>
      </Form.Item>
    ),
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitid",
    key: "unitid",
    width: 150,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        name={`dfieldunitid${i}`}
        initialValue={value}
        rules={[{ message: `Хэмжих нэгж сонгоно уу.`, required: true }]}
      >
        <Select
          align="left"
          defaultActiveFirstOption
          onChange={(e) => onChange({ value: e, i: i, name: "unitid" })}
          className="w-full"
        >
          {renderOptions(props.resunitlist, "name", "id")}
        </Select>
      </Form.Item>
    ),
  },
  {
    title: "Бохир жин",
    dataIndex: "gross",
    key: "gross",
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldgross${i}`}
        rules={[
          { message: `Бохир жин оруулна уу.`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              let regex = new RegExp(priceDecimalfloat);
              if (!regex.test(value))
                return Promise.reject(new Error("2 оронгийн нарийвчлалтай."));
              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber
          type="number"
          onChange={(e) => onChange({ value: e, i: i, name: "gross" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Цэвэр жин",
    dataIndex: "net",
    key: "net",
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldnet${i}`}
        rules={[
          { message: `Цэвэр жин оруулна уу.`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              let regex = new RegExp(priceDecimalfloat);
              if (!regex.test(value))
                return Promise.reject(new Error("2 оронгийн нарийвчлалтай."));
              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber
          type="number"
          onChange={(e) => onChange({ value: e, i: i, name: "net" })}
        />
      </Form.Item>
    ),
  },
];

export const SetFoodDetailColumns = (props, onChange) => [
  NoColumn,
  {
    title: "Зураг",
    dataIndex: "imgpos",
    key: "imgpos",
    align: "center",
    render: (value) => <Formatter value={value} format="img" />,
  },
  {
    title: "Бүтээгдэхүүн",
    dataIndex: "productid",
    key: "productid",
    width: 150,
    align: "center",
    shouldCellUpdate: () => true,
    render: (value, row, i) => {
      return (
        <Form.Item
          name={`dfieldproductid${i}`}
          initialValue={value}
          rules={[{ message: `Хоол сонгоно уу.`, required: true }]}
        >
          <Select
            onChange={(e) => onChange({ value: e, i: i, name: "productid" })}
            className="w-full"
            showSearch
            defaultActiveFirstOption
            align="left"
            disabled
            loading={props.isfoodlist}
            optionFilterProp="children"
          >
            {renderOptions(props.resfoodlist, "name", "id")}
          </Select>
        </Form.Item>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitname",
    key: "unitname",
    width: 60,
    align: "center",
    render: (value, row, i) => (
      <Form.Item name={`dfieldunitname${i}`} initialValue={value}>
        <Input disabled />
      </Form.Item>
    ),
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "qty",
    key: "qty",
    align: "center",
    width: 80,
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldqty${i}`}
        rules={[
          { message: `Тоо хэмжээ.`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              let regex = new RegExp(priceDecimal);
              if (!regex.test(value))
                return Promise.reject(new Error("2 оронгийн нарийвчлалтай."));
              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber
          className="sm:w-20 xl:w-full"
          min={1}
          max={99999.99}
          {...PRICE_PARSERS}
          onChange={(e) => onChange({ value: e, i: i, name: "qty" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    key: "price",
    align: "center",
    width: 120,
    render: (value, row, i) => {
      return (
        <Form.Item initialValue={value} name={`dfieldprice${i}`}>
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS}
          />
        </Form.Item>
      );
    },
  },
  {
    title: "Нийт үнэ",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: 150,
    render: (value, row, i) => {
      return (
        <Form.Item initialValue={value} name={`dfieldamount${i}`}>
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS}
          />
        </Form.Item>
      );
    },
  },
  {
    title: "Хямдрал",
    dataIndex: "discount",
    key: "discount",
    align: "center",
    width: 120,
    render: (value, row, i) => {
      return (
        <Form.Item initialValue={value} name={`dfielddiscount${i}`}>
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS}
          />
        </Form.Item>
      );
    },
  },
  {
    title: "Хямдарсан дүн",
    dataIndex: "discoAmt",
    key: "discoAmt",
    align: "center",
    width: 120,
    render: (value, row, i) => {
      return (
        <Form.Item initialValue={value} name={`dfielddiscoAmt${i}`}>
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS}
          />
        </Form.Item>
      );
    },
  },
];

export const ProductBuyingDetailColumns = (props, onChange) => [
  NoColumn,
  {
    title: "Бараа",
    dataIndex: "productid",
    key: "productid",
    width: 200,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        name={`dfieldproductid${i}`}
        initialValue={value}
        rules={[{ message: `Бараа сонгоно уу.`, required: true }]}
      >
        <Select
          className="sm:w-48 xl:w-full multiple-select-antd"
          showSearch
          defaultActiveFirstOption
          align="left"
          optionFilterProp="children"
          onChange={(e) => onChange({ value: e, i: i, name: "productid" })}
        >
          {renderOptions(props.resunitproductlist, "name", "id", true)}
        </Select>
      </Form.Item>
    ),
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "qty",
    key: "qty",
    align: "center",
    width: 130,
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldqty${i}`}
        rules={[
          { message: `Тоо хэмжээ оруулна уу`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              if (value > 0) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error("Тоо ширхэг 0 с их байх ёстой.")
                );
              }
            },
          }),
        ]}
        /* rules={[{ message: `Тоо хэмжээ.`, required: true }]} */
      >
        <InputNumber
          className="sm:w-20 xl:w-full"
          min={0}
          type="number"
          max={99999}
          onChange={(e) => onChange({ value: e, i: i, name: "qty" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Авах үнэ",
    dataIndex: "price",
    key: "price",
    align: "center",
    width: 180,
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldprice${i}`}
        rules={[{ message: `Авах үнэ оруулна уу.`, required: true }]}
      >
        <InputNumber
          className="sm:w-24 xl:w-full"
          min={0}
          max={9999999999.99}
          {...PRICE_PARSERS}
          onChange={(e) => onChange({ value: e, i: i, name: "price" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Нийт үнэ",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: 200,
    render: (value, row, i) => {
      return (
        <Form.Item
          initialValue={
            value !== null && value !== undefined ? value.toFixed(2) : "0.00"
          }
          name={`dfieldamount${i}`}
        >
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS_CUSTOM}
          />
        </Form.Item>
      );
    },
  },
];

export const MaterialBuyingDetailColumns = (props, onChange) => [
  NoColumn,
  {
    title: "Материал",
    dataIndex: "materialid",
    key: "materialid",
    width: 200,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        name={`dfieldmaterialid${i}`}
        initialValue={value}
        rules={[
          { message: `Материал сонгоно уу.`, required: true },
          /*  ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              const material = props.resmateriallist && props.resmateriallist.find((x) => x.id === value);
              if (material) {
                setFieldsValue({ [`dfieldunitid${i}`]: material.unitid });
              }
              return Promise.resolve();
            },
          }), */
        ]}
      >
        <Select
          className="sm:w-48 xl:w-full multiple-select-antd"
          showSearch
          defaultActiveFirstOption
          align="left"
          optionFilterProp="children"
          onChange={(e) => onChange({ value: e, i: i, name: "materialid" })}
        >
          {renderOptions(props.resmateriallist, "name", "id", true)}
        </Select>
      </Form.Item>
    ),
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitid",
    key: "unitid",
    width: 80,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        name={`dfieldunitid${i}`}
        align="left"
        initialValue={value}
        rules={[{ message: `Хэмжих нэгж сонгоно уу.`, required: true }]}
      >
        <Select
          disabled={true}
          onChange={(e) => onChange({ value: e, i: i, name: "unitid" })}
          className="sm:w-14 xl:w-full"
        >
          {renderOptions(props.resunitlist, "name", "id")}
        </Select>
      </Form.Item>
    ),
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "qty",
    key: "qty",
    align: "center",
    width: 130,
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        name={`dfieldqty${i}`}
        rules={[
          { message: `Тоо хэмжээ.`, required: true },
          ({ setFieldsValue, getFieldsValue }) => ({
            validator(_, value) {
              if (value > 0) {
                let regex = new RegExp(priceDecimalfloat);
                if (!regex.test(value))
                  return Promise.reject(new Error("4 оронгийн нарийвчлалтай."));
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error("Тоо ширхэг 0 с их байх ёстой")
                );
              }
            },
          }),
        ]}
      >
        <InputNumber
          className="sm:w-20 xl:w-full"
          min={0}
          max={99999.99}
          {...PRICE_PARSERS}
          onChange={(e) => onChange({ value: e, i: i, name: "qty" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Авах үнэ",
    dataIndex: "price",
    key: "price",
    align: "center",
    width: 180,
    render: (value, row, i) => (
      <Form.Item
        initialValue={value}
        // initialValue={value}
        name={`dfieldprice${i}`}
        rules={[{ message: `Авах үнэ оруулна уу.`, required: true }]}
      >
        <InputNumber
          className="sm:w-24 xl:w-full"
          min={0}
          max={9999999999.99}
          {...PRICE_PARSERS}
          onChange={(e) => onChange({ value: e, i: i, name: "price" })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Нийт үнэ",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: 200,
    render: (value, row, i) => {
      return (
        <Form.Item
          initialValue={
            value !== null && value !== undefined ? value.toFixed(2) : "0.00"
          }
          // initialValue={value}
          name={`dfieldamount${i}`}
        >
          <InputNumber
            className="sm:w-28 xl:w-full"
            disabled
            {...PRICE_PARSERS_CUSTOM}
          />
        </Form.Item>
      );
    },
  },
];

export const OrderListColumns = (orderClick, props) => [
  NoColumn,
  {
    title: "Захиалга",
    dataIndex: "salenum",
    key: "salenum",
    width: 80,
    align: "center",
  },
  {
    title: "Ширээ",
    dataIndex: "tablenm",
    key: "tablenm",
    width: 70,
    align: "center",
  },
  {
    title: "Хоолны тоо",
    dataIndex: "detailqty",
    key: "detailqty",
    width: 75,
    align: "center",
  },
  {
    title: "Төлбөр",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 100,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Захиалсан огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 100,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Захиалгын төрөл",
    dataIndex: "deliveryenumtext",
    key: "deliveryenumtext",
    align: "center",
    width: 90,
  },
  {
    title: "Захиалга хийсэн",
    dataIndex: "insbyname",
    key: "insbyname",
    align: "center",
    width: 90,
  },
  {
    title: "Төлбөр",
    dataIndex: "status",
    key: "status",
    width: 110,
    align: "center",
    render: (value) => <Formatter value={value} format="salestatus" />,
  },
  {
    title: "Төлөв",
    dataIndex: "ismake",
    key: "ismake",
    width: 115,
    align: "center",
    render: (value, row) => (
      <Formatter
        value={value}
        format="ismakestatus"
        onClick={orderClick}
        row={row}
      />
    ),
  },
  {
    title: "Хайх",
    dataIndex: "issearch",
    key: "issearch",
    width: 80,
    align: "center",
    render: (value, row) => (
      <Formatter
        value={value}
        format="isHistory"
        onClick={orderClick}
        row={row}
        props={props}
      />
    ),
  },

  /*   {
    title: "Төрөл",
    dataIndex: "orderenum",
    key: "orderenum",
    render: (value) => <Formatter value={value} format="salestatus" />,
  }, */
  /* EditColumn, */
];

export const OrderHisColumns = () => [
  NoColumnhis,
  {
    title: "Хоол",
    dataIndex: "productname",
    key: "productname",
    width: 80,
    align: "left",
  },
  {
    title: "Цаг",
    dataIndex: "insymd",
    key: "insymd",
    width: 20,
    align: "center",
    render: (value) => <Formatter value={value} format="datehour" />,
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
    width: 20,
    align: "center",
    render: (value) => <Formatter value={value} format="hisqty" />,
  },
  {
    title: "Зассан",
    dataIndex: "insbyname",
    key: "insbyname",
    align: "center",
    width: 60,
  },
];

export const DiscountTableColumn = [
  {
    title: "Код",
    dataIndex: "barcode",
    key: "barcode",
    width: 80,
    align: "center",
  },
  {
    title: "Хоолны нэр",
    dataIndex: "productname",
    key: "productname",
  },
  {
    title: "Үнэ",
    dataIndex: "price",
    key: "price",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Хямдрал",
    dataIndex: "unitdiscount",
    key: "unitdiscount",
    render: (value, row, i) => (
      <Form.Item noStyle initialValue={value} name={`unitdiscount`}>
        <InputNumber disabled {...PRICE_PARSERS} />
      </Form.Item>
    ),
  },
  {
    title: "Хямдарсан үнэ",
    dataIndex: "amount",
    key: "amount",
    render: (value, row, i) => (
      <Form.Item noStyle initialValue={value} name={`amount`}>
        <InputNumber disabled {...PRICE_PARSERS} />
      </Form.Item>
    ),
  },
];

export const PaymentCloseColumn = (onChange) => [
  {
    title: "Дэвсгэрт",
    dataIndex: "amount",
    key: "amount",
    width: 120,
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
    isSum: true,
    width: 120,
    align: "center",
    render: (value, row, i) => (
      <Form.Item
        noStyle
        initialValue={0}
        name={`qty${i}`}
        normalize={(value, prevValue) => {
          if (value.includes(" ")) {
            if (prevValue) return prevValue;
            return 0;
          }
          if (!isNaN(+value)) {
            if (value === "0") return value;
            if (value.substring(0, 1) === "0") {
              return value.substring(1, value.length);
            }
            return value;
          }
          if (prevValue) return prevValue;
          return 0;
        }}
      >
        <Input
          min={0}
          maxLength={3}
          className="w-full"
          /*  {...PRICE_PARSERS} */
          onChange={(e) => onChange({ value: e, i: i })}
        />
      </Form.Item>
    ),
  },
  {
    title: "Нийт",
    dataIndex: "totalamt",
    key: "totalamt",
    isSum: true,
    width: 200,
    align: "center",
    render: (value, row, i) => (
      <Form.Item noStyle initialValue={0} name={`totalamt${i}`}>
        <InputNumber className="w-full" disabled {...PRICE_PARSERS} />
      </Form.Item>
    ),
  },
];

export const SaleListColumns = [
  NoColumn,
  {
    title: "Захиалгын огноо",
    dataIndex: "orderymd",
    key: "orderymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Борлуулагдсан огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Кассчин",
    dataIndex: "uname",
    key: "uname",
    align: "center",
    width: 80,
  },
  {
    title: "Төхөөрөмж",
    dataIndex: "device",
    key: "device",
    align: "center",
    isSum: true,
    width: 130,
    // render: (value, row) => (
    //   <Formatter value={value} format="price" row={row} />
    // ),
  },
  {
    title: "Захиалга №",
    dataIndex: "salenum",
    key: "salenum",
    width: 80,
  },
  {
    title: "Тооцоо хуваасан захиалга №",
    dataIndex: "mainorder",
    key: "mainorder",
    width: 120,
    clickable: true,
    align: "right",
    render: (value, row) => (
      <Formatter
        value={value}
        format="priceSaleType"
        clearZero={true}
        row={row}
      />
    ),
    Modal: SaleDetailModal,
    modalColumns: SaleDetailDivideColumns,
    modalUrl: "/sale/mainorderdetail",
    modalMethod: "GET",
    modalIdKey: "id",
    modalHeader: "divide",
  },
  {
    title: "Хоолны тоо",
    dataIndex: "detailqty",
    key: "detailqty",
    width: 80,
    isSum: true,
    align: "right",
  },
  {
    title: "Зөөгч",
    dataIndex: "waitername",
    key: "waitername",
    width: 150,
    align: "left",
  },
  {
    title: "Ширээ",
    dataIndex: "tablename",
    key: "tablename",
    width: 150,
    align: "center",
  },
  {
    title: "Хаяг",
    dataIndex: "delivery",
    key: "delivery",
    width: 60,
    render: (value, row) => (
      <Formatter value={value} format="deliveryaddress" row={row} />
    ),
    clickable: true,
    Modal: DeliveryAddress,
  },
  {
    title: "Төлбөрийн төрөл",
    dataIndex: "paynames",
    key: "paynames",
    align: "center",
    width: 100,
  },
  {
    title: "Баримтын төрөл",
    dataIndex: "peporcom",
    key: "peporcom",
    align: "center",
    width: 100,
  },
  {
    title: "Борлуулалтын төрөл",
    dataIndex: "saletype",
    key: "saletype",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="saletype" />,
  },
  {
    title: "Хямдрал",
    dataIndex: "discount",
    key: "discount",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },

  {
    title: "НӨАТ",
    dataIndex: "vat",
    key: "vat",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "НХАТ",
    dataIndex: "tax",
    key: "tax",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "Үйлчилгээний нэмэгдэл",
    dataIndex: "fee",
    key: "fee",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "Нийт дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 130,
    clickable: true,
    isSum: true,
    fixed: "right",
    render: (value, row) => (
      <Formatter value={value} format="priceSaleType" row={row} />
    ),
    Modal: SaleDetailModal,
    isReturn: true,
    modalColumns: SaleDetailColumns,
    modalUrl: "/sale/saledetail",
    modalMethod: "GET",
    modalIdKey: "id",
  },
];

export const TimelogListColumns = [
  NoColumn,
  {
    title: "Захиалгын огноо",
    dataIndex: "orderymd",
    key: "orderymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetimemin" />,
  },
  {
    title: "Кассчин",
    dataIndex: "uname",
    key: "uname",
    align: "center",
    width: 80,
  },
  {
    title: "Захиалга №",
    dataIndex: "salenum",
    key: "salenum",
    width: 80,
    align: "center",
  },
  {
    title: "Ширээ №",
    dataIndex: "tablenm",
    key: "tablenm",
    width: 80,
    isSum: true,
    align: "center",
  },
  {
    title: "Эхэлсэн",
    dataIndex: "timesdate",
    key: "saleymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetimemin" />,
  },
  {
    title: "Дууссан",
    dataIndex: "timeedate",
    key: "saleymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetimemin" />,
  },
  {
    title: "Үргэлжилсэн цаг",
    dataIndex: "ongoingtime",
    key: "ongoingtime",
    align: "center",
    width: 100,
    render: (value, row) => (
      <Formatter value={value} row={row} format="diffdate" />
    ),
  },
  {
    title: "Урамшуулал",
    dataIndex: "giftname",
    key: "giftname",
    width: 150,
    align: "center",
  },
  {
    title: "Урамшууллын цаг",
    dataIndex: "gifttime",
    key: "gifttime",
    align: "right",
    width: 130,
  },
  {
    title: "Тооцоо хийсэн цаг",
    dataIndex: "hour",
    key: "hour",
    align: "center",
    width: 130,
    render: (value) => <Formatter value={value} format="hourtime" />,
  },
  {
    title: "Цагийн төлбөр",
    dataIndex: "price",
    key: "price",
    align: "right",
    isSum: true,
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
  {
    title: "Төлсөн дүн",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 130,
    render: (value, row) => (
      <Formatter value={value} format="price" row={row} />
    ),
  },
];

/* Кассчны тооцоо жагсаалтын хүснэгтийн баганууд */
export const CashierReportColumns = [
  NoColumn,
  {
    title: "Огноо",
    dataIndex: "insymd",
    key: "insymd",
    width: 150,
    align: "center",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Касс",
    dataIndex: "uname",
    key: "uname",
  },
  {
    title: "Сүүлийн хаалт",
    dataIndex: "islast",
    key: "islast",
    width: 80,
    align: "center",
    render: (value) => <Formatter value={value} format="settlementtype" />,
  },
  {
    title: "Бэлэн",
    dataIndex: "cash",
    key: "cash",
    isSum: true,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Бэлэн бус",
    dataIndex: "noncash",
    key: "noncash",
    isSum: true,
    align: "right",
    render: (value, row) => <Formatter value={value} format="link" row={row} />,
  },
  {
    title: "Нийт",
    dataIndex: "totalamount",
    key: "totalamount",
    isSum: true,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Кассын хаалт",
    dataIndex: "useramount",
    key: "useramount",
    isSum: true,
    align: "right",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Кассын зөрүү",
    dataIndex: "cashdiff",
    key: "cashdiff",
    width: 150,
    isSum: true,
    align: "right",
    render: (value) => <Formatter value={value} format="lowPrice" />,
  },
  {
    title: "Хэвлэх",
    dataIndex: "print-settlement",
    key: "print-settlement",
    clickable: true,
    align: "center",
    width: 100,
    render: (value) => <Formatter value={value} format="print" />,
  },
];

export const setFoodModalCoumns = [
  NoColumn,
  {
    title: "Бүтээгдэхүүн",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitname",
    key: "unitname",
  },
  {
    title: "Тоо ширхэг",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Үнэ",
    dataIndex: "price",
    key: "price",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Үндсэн үнэ",
    dataIndex: "amount",
    key: "amount",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Орлох бүтээгдэхүүнүүд",
    dataIndex: "subs",
    key: "subs",
    width: 250,
    render: (value) => <Formatter value={value} format="onlyName" />,
  },
  {
    title: "Нэмэлт",
    dataIndex: "spices",
    key: "spices",
    render: (value) => <Formatter value={value} format="onlyName" />,
  },
];

/* Бэлэн бусын төлбөрийн задаргаа баганууд */
export const NonCashColumns = [
  NoColumn,
  {
    title: "Төрөл",
    dataIndex: "type",
    key: "type",
    width: 100,
    render: (value) => <Formatter value={value} format="paymentType" />,
  },
  {
    title: "Төлбөр",
    dataIndex: "amount",
    key: "amount",
    width: 100,
    isSum: true,
    render: (value) => <Formatter value={value} format="price" />,
  },
];

/* Касс хаалтын дата */
export const ClosePaymentData = [
  {
    amount: 20000,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 10000,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 5000,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 1000,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 500,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 100,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 50,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 20,
    qty: 0,
    totalamt: 0,
  },
  {
    amount: 10,
    qty: 0,
    totalamt: 0,
  },
];
