import { useEffect } from "react";
import Snowfall from "react-snowfall";
const Canvas = (props) => {
  return (
    <div className="flex items-center justify-center h-full">
      <Snowfall />
    </div>
  );
};

export default Canvas;
