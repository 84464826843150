import React, { useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Maps from "../../components/Maps";
import * as Components from "../../components";
import { useNavigate } from "react-router-dom";
import { owntoast } from "../../utils";

function RegisterMap(props) {
  const [latLng, setLatLng] = useState(null);
  L.Marker.prototype.options.icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
  });
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("register"));

  const handleDone = () => {
    if (latLng) {
      let tmp = {
        id: data.foodid,
        longitude: latLng.lng.toString(),
        latitude: latLng.lat.toString(),
      };
      props
        .request({ url: `/user/addlocation`, method: "PUT", body: tmp })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            localStorage.removeItem("register");
            navigate("/login");
          } else {
            owntoast(res.message, "error");
          }
        });
    } else {
      owntoast("Байршил сонгоно уу", "error");
    }
  };
  return (
    <div className="flex items-center h-full justify-center bg-white">
      <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-4/6 sm:p-8 rounded-md border border-gray-200 drop-shadow-xl md:px-64">
        <div className="grid grid-cols-0 w-full items-center flex-col text-center md:mt-32 lg:mt-12 xl:mt-24 2xl:mt-0">
          <p className="font-bold text-lg">БИЗНЕСИЙН БАЙРШИЛ</p>
          <p className="text-gray-500 text-md px-24">
            Та үйл ажиллагаа явуулж байгаа байршлаа оруулсанаар илүү олон
            боломжийг систем таньд санал болгоно
          </p>
          {<Maps setLatLng={setLatLng} type="register" />}
          <div className="w-full flex justify-end">
            <Components.Ripple
              className="bg-primary h-8 w-24 flex items-center justify-center text-white rounded-sm lg:mt-2"
              onClick={handleDone}
            >
              Болсон
            </Components.Ripple>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RegisterMap;
