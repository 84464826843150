import { useEffect, useState } from "react";
import {
  CustomModal,
  OrderAmounts,
  OrderPaymentModal,
  RoomTimeModal,
} from "..";
import ListItem from "../OrderTable/ListItem";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { Button, Alert } from "antd";
import { owntoast, saveTime } from "../../utils";
import { NLicenceType } from "../../utils/enums";
const Index = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const calculatedFee = UsefulFunctions.getSum({
    saleList: props?.divideList,
    onlyFee: true,
    fee: props?.resprinterconfig?.fee,
  });

  const onChange = (value, item) => {};

  useEffect(() => {
    if (props?.isOpen) {
      props.removeDivideList("clear");
    }
  }, [props?.isOpen]);

  const handleSale = () => {
    if (props?.divideList?.length) {
      const editOrder = checkEditOrder();
      if (editOrder?.length <= 0) {
        if (
          props?.order?.tableprice > 0 &&
          !props?.divideList?.find((x) => x.istime) &&
          props?.reslicense?.detail?.find(
            (x) => Number(x) === NLicenceType.time
          )
        ) {
          setIsOpenTime(true);
        } else {
          setIsOpen(true);
        }
      } else {
        setIsOpen(true);
      }
    } else {
      owntoast("Хуваах бараануудаа сонгоно уу", "error");
    }
  };

  const checkEditOrder = () => {
    let newSaleList = JSON.parse(JSON.stringify(props?.saleList));
    props?.divideList?.map((item) => {
      let found = newSaleList?.find((x) => x?.id === item?.id);
      if (found) {
        found.qty = found?.qty - item?.qty;
      }
    });
    let editOrderDetail = newSaleList?.filter?.((x) => x.qty > 0);
    return editOrderDetail;
  };

  const sendOrder = ({ values }) => {
    let editOrderDetail = checkEditOrder();

    if (editOrderDetail && editOrderDetail?.length > 0) {
      editOrderDetail?.map((item) => {
        UsefulFunctions.calculateSaleData(item);
      });
      props?.sendOrderDivide({
        order: { ...props?.order },
        oldOrder: {
          details: editOrderDetail,
          ...props?.form?.getFieldsValue?.(),
        },
        newOrder: {
          ...values,
          details: props?.divideList,
          ...props?.form?.getFieldsValue?.(),
        },
        reload: reloadDivideOrder,
      });
    } else {
      props?.sendOrderDivide({
        order: { ...props?.order },
        oldOrder: {
          details: props?.saleList,
          ...props?.form?.getFieldsValue?.(),
          ...values,
        },
        isOrder: true,
        isSale: true,
        reload: reloadDivideOrder,
      });
    }
  };

  const reloadDivideOrder = (ishide) => {
    setIsOpen(false);
    props.removeDivideList("clear");
    if (ishide) {
      props?.setIsOpen?.(false);
    }
  };

  const closeTimeModal = () => {
    setIsOpenTime(false);
  };

  const handleSaveTime = (
    duration,
    amount,
    ongoingduration,
    event,
    sdate,
    edate
  ) => {
    let result = saveTime(
      duration,
      amount,
      ongoingduration,
      event,
      sdate,
      edate,
      props?.order?.tableprice
    );
    if (result) {
      props.addDivideList({ ...result?.itemObj });
      props.addSaleList({ ...result?.itemObj });
      props?.modalForm?.setFieldsValue({ ...result?.modalObj });
      closeTimeModal();
      setIsOpen(true);
    }
  };

  return (
    <CustomModal
      footer={null}
      width={480}
      isOpen={props?.isOpen}
      onClose={() => props?.setIsOpen?.(false)}
      title={`Захиалгын тооцоо хуваах - ${props?.order?.salenum}`}
    >
      <div className="flex flex-col px-5 pb-3 items-end">
        <div className="w-full">
          <Alert
            type="warning"
            showIcon
            message="Тооцоо хийх бүтээгдэхүүнээ сонгож тоо ширхэгээ оруулаад төлбөрөө төлнө үү !"
            className="mb-3"
          />
          <div className="flex gap-5">
            {props?.order?.tablenm ? (
              <span className="text-xs">
                Ширээ -{" "}
                <span className="text-sm font-bold">
                  {props?.order?.tablenm}
                </span>
              </span>
            ) : null}
            {props?.order?.salenum ? (
              <span className="text-xs">
                Захиалга -{" "}
                <span className="text-sm font-bold">
                  {`№ ${props?.order?.salenum}`}
                </span>
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-2 w-full max-h-96 overflow-y-auto">
          {props?.saleList?.map((item, i) => (
            <ListItem
              {...props}
              item={item}
              key={i}
              i={i}
              isDivide={true}
              onChange={onChange}
            />
          ))}
        </div>
        <div className="mt-5 w-4/6 ">
          <OrderAmounts
            count={UsefulFunctions.getFoodCount({
              saleList: props?.divideList,
            })}
            sum={UsefulFunctions.getSum({
              saleList: props?.divideList,
              onlyFee: false,
              onlySum: true,
            })}
            sumfee={calculatedFee}
            basefee={props?.resprinterconfig?.fee}
          />
          <div className="mt-2 flex w-full grid grid-cols-2 gap-2">
            <Button onClick={() => props?.setIsOpen?.(false)}>Буцах</Button>
            <Button
              type="primary"
              onClick={handleSale}
              loading={props?.loading}
            >
              Төлбөр төлөх{" "}
              {`(${new Intl.NumberFormat().format(
                UsefulFunctions.getSum({
                  saleList: props?.divideList,
                  fee: props?.resprinterconfig?.fee,
                })
              )})`}
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <OrderPaymentModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={props?.divideList}
          loading={props?.loading}
          sendOrder={sendOrder}
          {...props}
        />
      )}

      {isOpenTime && (
        <RoomTimeModal
          isOpen={isOpenTime}
          closeModal={closeTimeModal}
          order={props?.order}
          handleSave={handleSaveTime}
          timelog={props?.restimelog || []}
          {...props}
        />
      )}
    </CustomModal>
  );
};

export default Index;
