import { useEffect } from "react";
import popoyes from "../../assets/popoyes.svg";
import orderbanner from "../../assets/orderbanner.svg";
import Marquee from "react-fast-marquee";
import { OrderWindow } from "../../components";
import { API_URL } from "../../utils";
import { Carousel } from "antd";
import logo from "../../assets/logos/logo.png";

const Index = (props) => {
  const setData = (data) => {
    props.setData({ data: data, model: "setdata_orderdisplay" });
  };

  useEffect(() => {
    props?.request({ url: "/sale/displayorder", model: "orderdisplay" });
    if (props.wbConnection) {
      props.wbConnection.on("displayorder", setData);
    }

    return function clenup() {
      if (props.wbConnection) {
        props.wbConnection.off("displayorder", setData);
      }
    };
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className=" h-full flex flex-col">
        <div className="bg-primary flex items-center justify-between px-4 py-2">
          <img src={logo} alt="popoyes" className="h-6 w-fit" />
          <OrderWindow.Time />
        </div>
        <div className="grid grid-cols-12 gap-5 p-4 h-full">
          <div className="col-span-6">
            <div className="grid grid-cols-12 gap-6 h-full">
              <OrderWindow.Card
                data={
                  props?.resorderdisplay?.filter((x) => x.ismake !== 1) || []
                }
                type={1}
              />
              <OrderWindow.Card
                data={
                  props?.resorderdisplay?.filter((x) => x.ismake === 1) || []
                }
                type={2}
              />
            </div>
          </div>
          <div className="col-span-6 flex flex-col justify-between items-center gap-4 bg-labelGrey rounded-lg">
            {props?.resorderwindow?.logo ? (
              <img
                src={`${API_URL}${props?.resorderwindow?.logo}`}
                alt="popoyes"
                className="w-1/12 h-fit mt-5"
              />
            ) : null}
            <div className="orderwindow w-full h-full">
              <Carousel className="w-full h-full" autoplay autoplaySpeed={5000}>
                {props?.resorderwindow?.img?.map((item, i) => (
                  <div
                    className="w-full h-full flex items-center justify-center relative"
                    key={i}
                  >
                    <img
                      src={`${API_URL}${item}`}
                      alt="popoyes"
                      className="w-full object-contain absolute"
                    />
                  </div>
                ))}
              </Carousel>
            </div>

            <div className="py-4 bg-white w-full">
              <Marquee className="overflow-hidden w-full">
                <span className="text-3xl font-bold">
                  {props?.resorderwindow?.description}
                </span>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
