import { useState } from "react";
import {
  beforeUpload,
  getBase64,
  getEnabledOptions,
  API_URL,
  PRICE_PARSERS,
  renderOptions,
  PRICE_PARSERS_CUSTOM,
} from "../../utils";
import { ImgCropModal } from "../";
import {
  PlusOutlined,
  PlusSquareFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Radio,
  Upload,
  DatePicker,
  Checkbox,
  Row,
  InputNumber,
  Tooltip,
  Col,
  TimePicker,
  Button,
  Alert,
  Space,
} from "antd";
import BraftEditor from "braft-editor";
import {
  MasterAdminRole,
  CashierRole,
  ManagerRole,
  ChefRole,
} from "../../utils/enums";
import noimage from "../../assets/noimage.png";
import { owntoast } from "../../utils";

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Зураг оруулах</div>
  </div>
);

const loadingButton = (
  <div>
    <LoadingOutlined />
    <div style={{ marginTop: 8 }}>Түр хүлээнэ үү.</div>
  </div>
);

function FormItems({
  props,
  item,
  files,
  setFiles,
  changed,
  setChanged,
  setDisabled,
  form,
  setPrice,
  setisconnect,
  isconnect,
  disabled,
  isunitproduct,
  setisunitproduct,
  setselectedFood,
  isPerson,
  setIsPerson,
  editData,
  setEditData,
  selectedImg,
  setSelectedImg,
  selectedImgKiosk,
  setSelectedImgKiosk,
  sendDataRefresh,
  request,
}) {
  const { pathname } = window.location;
  const [cropImg, setCropImg] = useState(null);
  const [loading, setLoading] = useState(false);

  const renderRadioGroup = (field) => {
    let options = getEnabledOptions(props, field.options);
    return (
      options &&
      options.map((item, i) => {
        if (field.optionType === "button") {
          return (
            <Radio.Button
              key={i}
              value={item[field.fieldNames ? field.fieldNames.value : "value"]}
            >
              {item[field.fieldNames ? field.fieldNames.label : "label"]}
            </Radio.Button>
          );
        } else {
          return (
            <Radio
              key={i}
              value={item[field.fieldNames ? field.fieldNames.value : "value"]}
            >
              {item[field.fieldNames ? field.fieldNames.label : "label"]}
            </Radio>
          );
        }
      })
    );
  };

  const renderCheckBox = (e, label, value) => {
    let tmp = e.map((item, i) => {
      return (
        <Row className="mt-2">
          <Checkbox key={i} value={value ? item[value] : item.value}>
            {label ? item[label] : item.label}
          </Checkbox>
        </Row>
      );
    });
    return tmp;
  };

  const getRules = (item) => {
    if (item.rules) {
      return [
        { message: `${item.label} оруулна уу.`, required: item.required },
        item.rules,
      ];
    }
    let required = item.required;
    if (item.name === "isprintdetail") {
      if (disabled?.isprintdetail) {
        required = false;
      } else {
        required = true;
      }
    }
    return [{ message: `${item.label} оруулна уу.`, required: required }];
  };
  const onChangeFile = (e, name, qty) => {
    if (e && e.file && e.file.status) {
      if (e.file.status === "uploading" && !loading) setLoading(true);
      if (qty > 0) {
        let removed = files?.removed ? files?.removed : [];
        if (e.file.status === "removed") {
          removed.push(e.file.name);
        }

        setFiles({
          ...files,
          removed: removed,
          [name]: e.fileList,
        });
        if (loading) setLoading(false);
        return;
      }

      if (e.file.status === "done") {
        if (loading) setLoading(false);
        getBase64(e.file.originFileObj, (imageUrl) => {
          setFiles({
            ...files,
            [`prev${name}`]: imageUrl,
            [name]: e.file.originFileObj,
          });
        });
      }
    } else {
      getBase64(e, (imageUrl) => {
        setFiles({
          ...files,
          [`prev${name}`]: imageUrl,
          [name]: e,
        });
      });
    }
  };

  const changeCropImg = (e) => {
    if (e.file.status === "uploading" && !loading) {
      setLoading(true);
    } else if (e.file.status === "done") {
      if (loading) setLoading(false);
      setCropImg(e.file.originFileObj);
    }
  };

  const selectChanged = (e, { name, isChange, options }, isModal) => {
    if (isChange) {
      if (name === "bankcode") {
        let res = options.find((x) => x.value === e);
        if (res) {
          form.setFieldsValue({
            bankname: res.label,
          });
        }
      } else if (name === "roletype") {
        form.setFieldsValue({
          permission: setDefaultRole(e),
        });
      } else if (isModal && name === "catid") {
        props.request({
          url: `/product/getProducts/${props._auth.foodid}/${e}`,
          model: "foodlist",
        });
      } else if (isModal && name === "productid") {
        const food =
          props.resfoodlist && props.resfoodlist.find((x) => x.id === e);
        if (food) {
          let newfiles = {
            previmgpos: food.imgpos ? food.imgpos : null,
            previmgtv: food.imgtv ? food.imgtv : null,
            previmgmobile: food.imgmobile ? food.imgmobile : null,
          };
          setFiles(newfiles);
          form.setFieldsValue({
            sprice: food.sprice,
            subs: [],
          });
        }
        setselectedFood(e);
      } else {
        let prevChanged = { ...changed };
        prevChanged[name] = e;
        setChanged(prevChanged);
      }
    }
  };

  const setDefaultRole = (e) => {
    switch (e) {
      case 1:
        return MasterAdminRole;
      case 2:
        return MasterAdminRole;
      case 3:
        return CashierRole;
      case 4:
        return ManagerRole;
      case 5:
        return ChefRole;
      default:
        return [];
    }
  };

  const getImage = (item) => {
    let url = "";
    if (loading) return loadingButton;
    if (files && files[`prev${item.name}`]) {
      if (files[`prev${item.name}`]?.includes("Resource"))
        url = `${API_URL}${files[`prev${item.name}`]}`;
      else url = `${files[`prev${item.name}`]}`;

      return (
        <div className="h-[100px]">
          <img
            src={url ? url : noimage}
            alt="zurag"
            className="w-full h-full object-contain"
          />
        </div>
      );
    }
    return uploadButton;
  };

  const getOptionsArray = (item) => {
    if (item.depends) {
      if (changed[item.depends]) {
        return getEnabledOptions(
          props,
          item[`options_${changed[item.depends]}`],
          item.isenable
        );
      }
    }
    return getEnabledOptions(props, item.options, item.isenable);
  };

  const onChangeNumber = (e) => {
    setPrice && setPrice(e);
  };

  const normalizeType = (value, prevValue, currItem) => {
    if (currItem.normalize === "number") {
      if (value.includes(" ")) {
        if (prevValue) return prevValue;
        return "";
      }
      if (!isNaN(+value)) {
        return value;
      }
      if (prevValue) return prevValue;
      return "";
    }
    return value;
  };

  const onChangeCheckbox = (e) => {
    if (e.target.id === "isconnect") setisconnect(e.target.checked);
    else if (e.target.id === "baseForm_isunit") {
      if (e.target.checked) {
        let prevEditData = { ...editData };
        delete prevEditData.cardid;
        setEditData(prevEditData);
      }
      setisunitproduct(e.target.checked);
    } else if (e.target.id === "baseForm_isprint") {
      if (item.setDisabled) {
        setDisabled((prev) => {
          return { ...prev, [item.setDisabled]: !e.target.checked };
        });
      }
      if (!e.target.checked) {
        form?.setFieldsValue({ isprintdetail: null });
      }
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeRadio = (e, item) => {
    if (item.name === "iscompany") setIsPerson(e.target.value);
  };

  const getDisabled = (item) => {
    if (item.name === "subs") return isconnect;
    if (item.name === "cardid") {
      return isunitproduct;
    }

    return item.disabled;
  };

  const deleteImage = (imgitem) => {
    const url = files[`prev${imgitem?.name}`];
    if (url?.includes("data:image")) {
      setFiles({
        ...files,
        [`prev${imgitem?.name}`]: null,
      });
    } else {
      setLoading(true);
      props
        .request({
          url: `/product/deleteimage`,
          method: "PUT",
          body: {
            img: url,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res?.success) {
            setFiles({
              ...files,
              [`prev${imgitem?.name}`]: null,
            });
            /* let prevEditData = { ...editData };
            delete prevEditData[imgitem?.name]; */
            // setEditData(prevEditData);
            // localStorage.setItem("editRow", JSON.stringify(prevEditData));
            sendDataRefresh();
            owntoast(res.message, "success");
          } else {
            owntoast(res.message, "error");
          }
        });
    }
  };
  /* type ийн дагуу ямар контрол зурхыг сонгох хэсэг */
  const getFormItems = (item) => {
    switch (item.type) {
      case "input":
        return (
          <Input
            placeholder={item.label}
            name={item.name}
            type={item.inputType}
            disabled={item.disabled}
            maxLength={item.maxLength}
            showCount={item.showCount}
          />
        );
      case "radiobutton":
        return (
          <Radio.Group
            optionType={item.optionType && item.optionType}
            placeholder={item.label}
            disabled={item.disabled}
            defaultValue={item.defaultValue}
            onChange={(e) => onChangeRadio(e, item)}
          >
            {renderRadioGroup(item)}
          </Radio.Group>
        );
      case "select":
        return (
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (item.filterOption)
                return (
                  (option?.children?.props?.children[1] ?? "")
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              else
                return (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
            }}
            placeholder={item.label}
            className="w-full"
            disabled={getDisabled(item)}
            mode={item.mode}
            allowClear={item.allowClear}
            onChange={(e) => selectChanged(e, { ...item }, item.isModal)}
          >
            {renderOptions(
              getOptionsArray(item),
              item.fieldNames && item.fieldNames.label,
              item.fieldNames && item.fieldNames.value,
              false,
              item.showImage
            )}
          </Select>
        );
      case "textArea":
        return (
          <Input.TextArea
            placeholder={item.label}
            disabled={item.disabled}
            defaultValue=""
            className={`${item.showCount ? "mb-2" : ""}`}
            showCount={item.showCount}
            maxLength={item.maxLength}
          />
        );
      case "upload":
        return !item.noncrop ? (
          <ImgCropModal
            disabled={item.noncrop}
            file={cropImg}
            onChange={onChangeFile}
            name={item.name}
            modalTitle="Зураг оруулах"
            rotate
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              accept="image/png, image/jpeg"
              showUploadList={false}
              onChange={(e) => changeCropImg(e)}
              beforeUpload={beforeUpload}
            >
              {getImage(item)}
              {/* {files[`prev${item.name}`] ? <img src={files[`prev${item.name}`]} alt="zurag" /> : uploadButton} */}
            </Upload>
          </ImgCropModal>
        ) : (
          <Upload
            customRequest={dummyRequest}
            listType="picture-card"
            accept="image/png, image/jpeg"
            showUploadList={false}
            onChange={(e) => onChangeFile(e, item.name)}
            beforeUpload={beforeUpload}
          >
            {getImage(item)}
          </Upload>
        );
      case "uploadlist": {
        return (
          <Upload
            listType="picture-card"
            customRequest={dummyRequest}
            accept="image/png, image/jpeg"
            onChange={(e) => onChangeFile(e, item.name, item.qty)}
            maxCount={item.qty}
            maxLength={item.qty}
            fileList={files[`${item?.name}`] || []}
            beforeUpload={beforeUpload}
          >
            {files[`${item.name}`]?.length >= item.qty ? null : uploadButton}
          </Upload>
        );
      }
      case "datepicker":
        return <DatePicker placeholder={item.label} className="w-full" />;
      case "timepicker":
        return <TimePicker format={"HH:mm"} />;
      case "radio":
        return <Radio.Group placeholder={item.label} />;
      case "checkbox":
        return (
          <Checkbox
            disabled={item.disabled}
            onChange={(e) => onChangeCheckbox(e)}
          />
        );
      case "password":
        if (!pathname.includes("edit"))
          return (
            <Input.Password
              disabled={
                item.disabled === undefined
                  ? pathname.includes("edit")
                    ? true
                    : false
                  : item.disabled
              }
              placeholder={item.label}
              name={item.name}
            />
          );
      case "checkboxgroup":
        return (
          <Checkbox.Group>
            <Col>
              {renderCheckBox(
                getOptionsArray(item),
                item.fieldNames && item.fieldNames.label,
                item.fieldNames && item.fieldNames.value
              )}
            </Col>
          </Checkbox.Group>
        );
      case "number":
        return (
          <InputNumber
            placeholder={item.label}
            name={item.name}
            className="w-full"
            maxLength={item.maxLength}
            min={0}
            addonAfter={item?.addonAfter}
            {...PRICE_PARSERS}
            disabled={item.disabled}
            onChange={onChangeNumber}
          />
        );
      case "numbercustom":
        return (
          <InputNumber
            placeholder={item.label}
            name={item.name}
            className="w-full"
            maxLength={item.maxLength}
            min={0}
            addonAfter={item?.addonAfter}
            {...PRICE_PARSERS_CUSTOM}
            disabled={item.disabled}
            onChange={onChangeNumber}
          />
        );
      case "image":
        return (
          <div
            className={`w-20 h-20 cursor-pointer rounded-xl ${
              selectedImg === item.id &&
              "ease-in duration-100 border-2 p-2 border-red-600"
            }`}
            onClick={() => {
              setSelectedImg(item.id);
            }}
          >
            <img key={item.id} src={item?.id} alt="Image" />
          </div>
        );
      case "kioskimage":
        return (
          <div
            className={`w-20 h-20 cursor-pointer rounded-xl ${
              selectedImgKiosk === item.id &&
              "ease-in duration-100 border-2 p-2 border-red-600"
            }`}
            onClick={() => {
              setSelectedImgKiosk(item.id);
            }}
          >
            <img key={item.id} src={item?.id} alt="Image" />
          </div>
        );
      case "onlynumber":
        return (
          <Input
            placeholder={item.label}
            name={item.name}
            className="w-full"
            maxLength={item.maxLength}
            showCount={item.showCount}
            min={0}
            disabled={item.disabled}
          />
        );
      case "editor":
        return <BraftEditor language="en" className="border" />;
      default:
        return <Input placeholder={item.label} />;
    }
  };
  const getItem = () => {
    return (
      <div className="relative">
        {!item.hidden && (
          <span
            className={`input-top-label ${
              item.type === "checkbox" ? "left-0 p-0" : ""
            }`}
          >
            {item.ishide && pathname.includes("edit") ? null : item.label}
          </span>
        )}
        <Form.Item
          className={`antd-custom-row-tooltip mt-3 ${
            item.type === "checkboxgroup" ? "border p-2 rounded-sm" : ""
          }`}
          hidden={item.hidden}
        >
          <Form.Item
            name={item.name}
            initialValue={item.defaultValue}
            valuePropName={item.valuePropName ? item.valuePropName : "value"}
            className={`${item.type === "upload" ? "upload-fit" : ""} ${
              item?.itemClass
            }`}
            normalize={(value, prevValue) => {
              return normalizeType(value, prevValue, item);
            }}
            rules={getRules(item)}
          >
            {getFormItems(item)}
          </Form.Item>
          {item.linkBtn && (
            <Tooltip title={item.linkTooltip}>
              <PlusSquareFilled
                className="text-primary text-xl px-2"
                onClick={() => {
                  if (item.linkBtn && window.location) {
                    window.location.replace(item.linkBtn);
                  }
                }}
              />
            </Tooltip>
          )}
        </Form.Item>
      </div>
    );
  };
  if (item.desc) {
    return (
      <div className="relative">
        {!item.hidden && (
          <span
            className={`input-top-label ${
              item.type === "checkbox" ? "left-0 p-0" : ""
            }`}
          >
            {item.ishide && pathname.includes("edit") ? null : item.label}
          </span>
        )}

        <Form.Item className="antd-form-row-custom " hidden={item.hidden}>
          <Form.Item
            valuePropName={item.valuePropName ? item.valuePropName : "value"}
            className={`${item.type === "upload" ? "upload-fit" : ""}`}
            normalize={(value, prevValue) => {
              return normalizeType(value, prevValue, item);
            }}
            rules={[
              { message: `${item.label} оруулна уу.`, required: item.required },
            ]}
          >
            {getFormItems(item)}
          </Form.Item>
          {item.desc && item.type === "upload" ? (
            <div className="mt-3">
              <span className="text-grey">{item.desc}</span>
              {files && files[`prev${item.name}`] ? (
                <Button
                  loading={loading}
                  size="small"
                  danger
                  onClick={() => deleteImage(item)}
                >
                  Устгах
                </Button>
              ) : null}
            </div>
          ) : null}
        </Form.Item>
      </div>
    );
  } else {
    if (item.name === "warning") {
      return <Alert {...item} showIcon className="mt-[-10px] mb-5" />;
    }
    if (item.checkRegno) {
      if (isPerson) {
        if (!item.isCompany) {
          return getItem();
        }
        return null;
      } else {
        if (item.isCompany) {
          return getItem();
        }
        return null;
      }
    }
    return getItem();
  }
}

export default FormItems;
