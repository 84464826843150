import { Bar } from "@ant-design/plots";

function BarChart({ color, data, x, y }) {
  const config = {
    data,
    xField: y,
    yField: x,
    autoFit: true,
    xAxis: {
      label: {
        /* autoHide: true,
        autoEllipsis: true,
        autoRotate: true, */
        formatter: (e) => {
          return e ? new Intl.NumberFormat().format(e) : e;
        },
      },
    },
    yAxis: {
      /* label: {
        formatter: (e) => {
          return e ? new Intl.NumberFormat().format(e) : e;
        },
      }, */
    },
    tooltip: {
      formatter: (e) => {
        return { name: "Дүн", value: new Intl.NumberFormat().format(e.amount) };
      },
    },
    minColumnWidth: 10,
    maxColumnWidth: 10,
    barStyle: {
      fill: color,
      radius: [10, 10, 0, 0],
    },
    meta: {
      type: {
        alias: "Дүн",
      },
    },
  };
  return <Bar {...config} />;
}

export default BarChart;
