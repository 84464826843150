import { useState, useEffect } from "react";
import { Switch, Button, Modal, Tag } from "antd";
import dayjs from "dayjs";
import { KitchenRing, CustomModal } from "../";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Clock from "../../assets/icons/clock.png";
import { owntoast } from "../../utils";
import comment from "../../assets/comment.png";

const CardItem = ({
  item,
  i,
  calculatePercentage,
  request,
  loading,
  ended,
}) => {
  const [checked, setChecked] = useState();
  useEffect(() => {
    if (item) {
      setChecked(item.ismake === 1);
    }
  }, [item]);

  useEffect(() => {
    calculatePercentage();
  }, [checked]);

  const onChangeSwitch = (e) => {
    request({
      url: `/sale/kitchen/detailmake/${item.id}/${e ? 1 : 0}/0`,
      method: "POST",
    }).then((res) => {
      if (!res.success) {
        owntoast(res.message, "error");
      }
    });
    item.ismake = e ? 1 : 0;
    setChecked(e);
  };

  const renderSetDetails = () => {
    let tmp = [];
    if (item.setdetails && item.setdetails.length > 0) {
      tmp = item.setdetails.map((det, deti) => (
        <tr key={deti}>
          <td className="pr-2"></td>
          <td className="text-mainOrange">{`${det.productname} ${
            det.spicename ? `/${det.spicename}/` : ""
          }`}</td>
          <td className="text-center text-mainOrange">{det.qty}</td>
          <td></td>
        </tr>
      ));
    } else if (item.spiceid && item.spicename) {
      return (
        <tr>
          <td className="pr-2"></td>
          <td className="text-mainOrange">{`${item.spicename}`}</td>
          <td className="text-center">-</td>
          <td></td>
        </tr>
      );
    }
    return tmp;
  };

  return (
    <>
      <tr>
        <td className="pr-2">{i + 1}</td>
        <td>{item.productname}</td>
        <td className="text-center">{item.qty}</td>
        <td className="text-right kitchen-toggle">
          <Switch
            loading={loading}
            disabled={ended}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            className={checked ? "bg-primary" : "bg-fullBack"}
            onChange={onChangeSwitch}
          />
        </td>
      </tr>
      {renderSetDetails()}
      <tr className="food-tr" />
    </>
  );
};

const KitchentCard = (props) => {
  const [percentage, setPercentage] = useState(null);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  const calculatePercentage = () => {
    const checked = props.details.reduce((a, v) => {
      if (v.ismake === 1) return (a = a + 1);
      return (a = a + 0);
    }, 0);
    setPercentage({ checked: checked, sum: props.details.length });
  };

  const endOrder = () => {
    setIsOpenWarning(true);
  };

  const handlePaymentClose = () => {
    setLoading(true);
    if (props.showFooter) {
      props
        .request({
          url: `/sale/kitchen/ordermake/${props.id}/2`,
          method: "POST",
        })
        .then((res) => {
          if (res.success) {
            setIsOpenWarning(false);
            props.close();
          }
        });
    } else {
      props
        .request({
          url: `/sale/kitchen/ordermake/${props.id}/1`,
          method: "POST",
        })
        .then((res) => {
          setIsOpenWarning(false);
        });
    }
    setLoading(false);
  };
  return (
    <div
      className={
        props.showFooter
          ? "h-full border shadow-lg flex flex-col bg-white"
          : "h-full border shadow-lg flex flex-col w-64 bg-white"
      }
    >
      <div className="flex flex-col bg-primaryOpa px-4 py-1 border-b-2 border-b-primaryOpa">
        <div className="flex w-full items-center justify-between">
          <span className="text-grey">
            Захиалга №
            <span className="text-black font-semibold"> - {props.salenum}</span>
          </span>
          <span className="flex items-center">
            <img src={Clock} alt="clock" className="w-4" />
            <span className="ml-1">{dayjs(props.insymd).format("HH:mm")}</span>
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-grey">
            Төхөөрөмж:
            <span className="text-black font-semibold ml-2">
              {props?.insbyname}
            </span>
          </span>
          <span className="-mr-4">
            {props.ismake === 2 && <Tag color="yellow">Дутуу гарсан</Tag>}
          </span>
        </div>
      </div>
      <div
        className={`pt-2 flex flex-col justify-between h-full ${
          props.ismake === 2 && "bg-yellow-100"
        }`}
      >
        <div className="relative h-full overflow-auto">
          <div className="px-4 w-full">
            <table className="bill-table w-full">
              {props?.catId
                ? props?.details?.map((item, i) => {
                    if (
                      props?.catId?.find((x) => x === item.catid) ||
                      item.catid === 0
                    )
                      return (
                        <CardItem
                          key={i}
                          i={i}
                          ended={props.ended}
                          loading={loading}
                          request={props.request}
                          item={item}
                          calculatePercentage={calculatePercentage}
                        />
                      );
                  })
                : props?.details?.map((item, i) => {
                    return (
                      <CardItem
                        key={i}
                        i={i}
                        ended={props.ended}
                        loading={loading}
                        request={props.request}
                        item={item}
                        calculatePercentage={calculatePercentage}
                      />
                    );
                  })}
            </table>
          </div>
        </div>
        <div className="flex items-center mb-2 p-2">
          {/* <div className="">Хэрэглэгчийн хүсэлт:</div> */}
          {props?.comment ? (
            <img src={comment} className="object-contain w-4 mr-2 ml-1" />
          ) : null}
          <div className="">{props?.comment}</div>
        </div>

        {!props.showFooter ? (
          <div className="py-1 px-4 flex items-center justify-between border-t-2 border-t-primaryOpa">
            <KitchenRing percentage={percentage} />
            {!props.ended ? (
              <Button
                type="primary"
                className="bg-primary"
                disabled={!(percentage?.checked === percentage?.sum)}
                onClick={endOrder}
                loading={loading}
              >
                Захиалга хаах
              </Button>
            ) : null}
          </div>
        ) : (
          <div className="w-full justify-center flex mt-3 mb-3">
            <Button
              type="primary"
              className="bg-primary"
              onClick={endOrder}
              loading={loading}
            >
              Батлах
            </Button>
          </div>
        )}
      </div>
      <CustomModal
        footer={[
          <Button onClick={() => setIsOpenWarning(false)}>Үгүй</Button>,
          <Button
            type="primary"
            className="bg-primary"
            onClick={handlePaymentClose}
            autoFocus
          >
            Тийм
          </Button>,
        ]}
        width={400}
        isOpen={isOpenWarning}
        onClose={() => setIsOpenWarning(false)}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          <span className="text-center">
            {props.showFooter
              ? "Захиалгыг өөрчилж гал тогооруу буцаахуу ? "
              : "Та захиалга хаахдаа итгэлтэй байна уу?"}
          </span>
          {/* <span>Хаалт хийхдээ итгэлтэй байна уу?</span> */}
        </div>
      </CustomModal>
    </div>
  );
};

export default KitchentCard;
