import { Image } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";
import manualhelp from "../../assets/icons/manualhelp.png";
import book from "../../assets/icons/book.png";
import logo from "../../assets/icons/youtube.png";
import { useState, useEffect } from "react";
import axios from "axios";

function Help(props) {
  const [open, setIsOpen] = useState(false);
  const [imgId, setImg] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    document.title = "Тусламж";
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://food.onispos.mn/files/manual.json"
        );
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const handleOpenLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="flex items-center justify-center h-full w-full ">
      <div className="bg-white p-10 rounded-lg border border-gray-200 overflow-auto overflow-x-auto drop-shadow-xl 2xl:h-4/6 2xl:w-3/5">
        <div className="mb-5 text-xl flex w-full justify-between items-center ">
          <div className=" flex ">
            <img src={book} alt="" className="w-8 mr-2" />
            <span>Тусламж</span>
          </div>
          <div className="  flex ">
            <img src={logo} alt="" className="w-8 mr-2" />
            <span>Энэ товч дээр дарж видео заавар үзнэ үү</span>
          </div>
        </div>

        <div className="grid grid-cols-3 xl:grid-cols-6 2xl:grid-cols-6 gap-5">
          {data?.map((item, i) => (
            <button
              key={i}
              className="h-24 w-40 newbtn rounded-lg border border-primary uppercase p-3 relative"
              type="button"
              onClick={() => {
                setImg(item.manuals);
                setIsOpen(true);
              }}
            >
              {item.title}
              <YoutubeOutlined
                className="flex cursor-pointer absolute top-1.5 left-1.5 z-50"
                onClick={() => {
                  handleOpenLink(item.link);
                }}
              />
              {/* <img
                src={manualhelp}
                alt=""
                className="w-4 h-4 absolute top-1.5 "
              /> */}
            </button>
          ))}
        </div>
      </div>
      <div className="hidden">
        <Image.PreviewGroup
          preview={{
            visible: open,

            onVisibleChange: (value) => {
              setIsOpen(value);
            },
          }}
        >
          {imgId?.map((item) => (
            <Image
              width={200}
              preview={{
                src: item ? require(`../../assets/manual/${item}.jpg`) : "",
              }}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
}

export default Help;
