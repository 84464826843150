import React, { useState } from "react";
import { Button, Steps } from "antd";
import { useNavigate } from "react-router-dom";
import checked from "../../assets/checked.png";
import unchecked from "../../assets/closed.svg";
import back from "../../assets/back.png";
import { owntoast } from "../../utils";
import { DEMO_VERSION_DISABLED, DEMO_VERSION_ENABLED } from "../../data";
import * as Components from "../../components";

const { Step } = Steps;
function LicenseDemo(props) {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleNext = () => {
    setIsloading(true);
    props.request({ url: `/license/demosale` }).then((res) => {
      setIsloading(false);
      if (res.success) {
        setIsOpen(true);
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  const handleBack = () => {
    navigate("/license");
  };

  const handleOk = () => {
    props
      .request({ url: `/license/checklicense`, model: "license" })
      .then((res) => {
        if (res.success) {
          navigate("/");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="bg-white p-10 rounded-lg border border-gray-200 drop-shadow-xl w-screen h-full md:w-max md:h-max xl:w-max xl:h-max overflow-auto">
        <p className="flex justify-center ">
          <div className=" text-lg text-black text-center w-96">
            Та <span className="text-license">ТУРШИЛТ</span>-ын хувилбар
            ашигласнаар доорх боломжуудын зөвхөн идэвхтэй төрлүүдийг{" "}
            <span className="text-license">ҮНЭГҮЙ</span> ашиглах боломжтой юм.
          </div>
        </p>
        <div className="justify-center  flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 justify-center">
            <div className="flex-col items-center justify-center mr-6 bg-gray-100 p-4 rounded-md border border-gray-300 w-full">
              <p className=" text-center text-lg">Идэвхтэй</p>
              <Steps
                direction="vertical"
                size="small"
                current={DEMO_VERSION_ENABLED.length}
                className="demo-enabled"
              >
                {DEMO_VERSION_ENABLED.map((item, i) => (
                  <Step
                    key={i}
                    title={item.title}
                    icon={<img className="w-8 h-8" src={checked} alt="logo" />}
                  />
                ))}
              </Steps>
            </div>
            <div className="flex-col items-center justify-center bg-gray-100 p-4 rounded-md border border-gray-300 w-full">
              <p className="text-center text-lg">Идэвхгүй</p>
              <Steps
                direction="vertical"
                size="small"
                current={DEMO_VERSION_DISABLED.length}
                className="demo-disabled"
              >
                {DEMO_VERSION_DISABLED.map((item, i) => (
                  <Step
                    key={i}
                    title={item.title}
                    icon={
                      <img className="w-8 h-8" src={unchecked} alt="logo" />
                    }
                  />
                ))}
              </Steps>
            </div>
          </div>
          <div className="flex justify-between mt-3">
            <Button
              icon={<img className="w-4 h-4 mr-2" src={back} alt="logo" />}
              onClick={handleBack}
            >
              Буцах
            </Button>
            <Button
              type="primary"
              className="bg-primary"
              loading={isloading}
              onClick={handleNext}
            >
              Идэвхжүүлэх
            </Button>
          </div>
        </div>
      </div>
      <Components.CustomModal
        footer={[
          <Button
            type="primary"
            className="bg-primary"
            onClick={handleOk}
            autoFocus
          >
            Хаах
          </Button>,
        ]}
        width={400}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          <span className="text-center">Танд 14хоног ашиглах эрх нээгдлээ</span>
        </div>
      </Components.CustomModal>
    </div>
  );
}

export default LicenseDemo;
