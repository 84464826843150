import { Checkbox } from "antd";

const Index = (props) => {
  const handleClick = () => {
    if (props?.onChange) {
      props?.onChange(!props?.checked, props?.data);
    }
  };
  if (!props?.data) return null;
  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer hover:scale-105 transition-all bg-white drop-shadow-xl rounded-lg p-3 w-52 flex flex-col items-end border ${
        props?.isPayment && "border-primary"
      }`}
    >
      <div className="flex items-center w-full">
        {!props?.isPayment ? (
          <Checkbox checked={props?.checked} className="mr-3" />
        ) : null}
        <span className="text-lg font-semibold">{props?.data?.name}</span>
      </div>
      <span className="text-primary text-xl mt-2 font-semibold">{`${new Intl.NumberFormat().format(
        props?.data?.price || 0
      )} ₮`}</span>
    </div>
  );
};

export default Index;
