import { useState, useEffect } from "react";
import moment from "moment";
import logo from "../../assets/icons/youtube.png";
import {
  Button,
  Input,
  Form,
  DatePicker,
  Dropdown,
  Menu,
  Select,
  InputNumber,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  SearchOutlined,
  PlusCircleOutlined,
  DownOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { TableExcel } from "../";
import { ACTIONS } from "../../utils/enums";
import dayjs from "dayjs";
import {
  getEnabledOptions,
  renderOptions,
  isNewYear,
  getAllOptions,
} from "../../utils";
import DragDrop from "../DragDrop";
import hat from "../../assets/hat.png";
import axios from "axios";

const { Option } = Select;

/* Жагсаалтын цонхны хайх хэсэг */
const Search = ({
  onSubmit,
  onClickNew,
  isNew,
  controls,
  loading,
  onChangeSearch,
  name,
  props,
  url,
  printRef,
  check,
  isModal,
  isSearch = true,
  manuallink,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [datalink, setdatalink] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const defaultSearch = JSON.parse(localStorage.getItem(`search${url}`));
  if (defaultSearch) {
    Object.keys(defaultSearch).map((key) => {
      if (key.includes("date") || key.includes("ymd")) {
        defaultSearch[key] = dayjs(defaultSearch[key]);
      }
    });
  }

  const params = useParams();
  const handleNew = () => {
    if (check()) {
      if (onClickNew) onClickNew();
      navigate(`/management/${params.id}/${params["*"]}/new`);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    form.submit();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleAction = async (e) => {
    switch (e.key) {
      case ACTIONS.EXCEL:
        return;
      case ACTIONS.PRINT:
        return handlePrint();
      default:
        return;
    }
  };

  const onFinish = (values) => {
    onSubmit && onSubmit(values);
  };

  const handleOpenLink = () => {
    if (manuallink) window.open(manuallink, "_blank");
  };

  const getSearchCols = (name, i) => {
    switch (name) {
      case "date":
        return (
          <Form.Item name="date" className="w-40" key={i}>
            <DatePicker className="w-full" allowClear={false} />
          </Form.Item>
        );
      case "sdate":
        return (
          <Form.Item name="sdate" className="w-40" key={i}>
            <DatePicker className="w-full" allowClear={false} />
          </Form.Item>
        );
      case "edate":
        return (
          <Form.Item name="edate" className="w-40" key={i}>
            <DatePicker className="w-full" allowClear={false} />
          </Form.Item>
        );
      case "sdatehour":
        return (
          <Form.Item name="sdatehour" className="w-40" key={i}>
            <DatePicker
              className="w-full"
              allowClear={false}
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        );
      case "edatehour":
        return (
          <Form.Item name="edatehour" className="w-40" key={i}>
            <DatePicker
              className="w-full"
              allowClear={false}
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        );
      case "barcode":
        return (
          <Form.Item name="barcode" className="w-40" key={i}>
            <Input
              type="number"
              className="w-full"
              placeholder="Кодоор хайх"
              allowClear
            />
          </Form.Item>
        );
      case "catid":
        return (
          <Form.Item name="catid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Хоолны ангилал"
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              {renderOptions(
                getEnabledOptions(props, "categorylist"),
                "name",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "name":
        return (
          <Form.Item name="name" className="w-40" key={i}>
            <Input placeholder="Хайх" allowClear onChange={onChangeSearch} />
          </Form.Item>
        );
      case "buyid":
        return (
          <Form.Item name="buyid" className="w-40" key={i}>
            <InputNumber
              placeholder="Баримтын дугаар"
              allowClear
              className="w-full"
            />
          </Form.Item>
        );
      case "saletype":
        return (
          <Form.Item name="type" className="w-40" key={i}>
            <Select
              allowClear
              defaultValue={0}
              placeholder="Борлуулалтын төрөл"
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              <Option value={1}>Борлуулалт</Option>
              <Option value={2}>Буцаалт</Option>
            </Select>
          </Form.Item>
        );
      case "paymenttype":
        return (
          <Form.Item name="paymenttype" className="w-40" key={i}>
            <Select
              allowClear
              defaultValue={null}
              placeholder="Төлбөрийн төрөл"
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={null}>Бүгд</Option>
              <Option value={1}>Бэлнээр</Option>
              <Option value={2}>Картаар</Option>
              <Option value={3}>Голомтоор</Option>
              <Option value={4}>Qpay</Option>
              <Option value={5}>SocialPay</Option>
              <Option value={6}>Хаанбанк</Option>
              <Option value={7}>MinuPay</Option>
              <Option value={8}>MostMoney</Option>
            </Select>
          </Form.Item>
        );
      case "saletypeSearch":
        return (
          <Form.Item name="type" className="w-40" key={i}>
            <Select
              allowClear
              defaultValue={null}
              placeholder="Борлуулалтын төрөл"
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={null}>Бүгд</Option>
              <Option value={1}>Борлуулалт</Option>
              <Option value={2}>Буцаалт</Option>
            </Select>
          </Form.Item>
        );
      case "catidSearch":
        return (
          <Form.Item name="catid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Хоолны ангилал"
              optionFilterProp="children"
              defaultValue={null}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={null}>Бүгд</Option>
              {renderOptions(
                getAllOptions(props, "categorylist"),
                "name",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "waiter":
        return (
          <Form.Item name="waiterid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Зөөгч"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              {renderOptions(getEnabledOptions(props, "waiters"), "name", "id")}
            </Select>
          </Form.Item>
        );
      case "table":
        return (
          <Form.Item name="tableid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Ширээ"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              {renderOptions(
                getEnabledOptions(props, "searchstables"),
                "name",
                "id"
              )}
              <Option value={0}>Бүгд</Option>
              <Option value={1}>Ширээгүй</Option>
            </Select>
          </Form.Item>
        );
      case "setSearch":
        return (
          <Form.Item name="setid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Сэт сонгох"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              {renderOptions(
                getEnabledOptions(props, "setsearch"),
                "name",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "matcatid":
        return (
          <Form.Item name="catid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Материалын ангилал"
              optionFilterProp="children"
              defaultValue={null}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={null}>Бүгд</Option>
              {/* <Option>Бүгд</Option> */}
              {renderOptions(
                getAllOptions(props, "catmateriallist"),
                "name",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "cashier":
        return (
          <Form.Item name="cashier" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Кассчин"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              {renderOptions(
                getEnabledOptions(props, "cashierinfo"),
                "uname",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "dintable":
        return (
          <Form.Item name="tableid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Ширээ"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              {renderOptions(
                getEnabledOptions(props, "dintablelist"),
                "name",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "timelog":
        return (
          <Form.Item name="giftid" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Ширээ"
              optionFilterProp="children"
              defaultValue={0}
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option value={0}>Бүгд</Option>
              {renderOptions(
                getEnabledOptions(props, "timelog"),
                "description",
                "id"
              )}
            </Select>
          </Form.Item>
        );
      case "isunit":
        return (
          <Form.Item name="isunit" className="w-40" key={i}>
            <Select
              allowClear
              placeholder="Төрөл"
              optionFilterProp="children"
              showSearch
              onInputKeyDown={(e) => {
                if (e.keyCode === 13) {
                  form.submit();
                }
              }}
            >
              <Option>Бүгд</Option>
              <Option value={0}>Хоол</Option>
              <Option value={1}>Бараа</Option>
            </Select>
          </Form.Item>
        );
    }
  };

  const menu = (
    <Menu onClick={handleAction}>
      <Menu.Item key={ACTIONS.EXCEL} icon={<FileExcelOutlined />}>
        <TableExcel name={name} />
      </Menu.Item>
      <Menu.Item key={ACTIONS.PRINT} icon={<PrinterOutlined />}>
        Хэвлэх
      </Menu.Item>
    </Menu>
  );

  console.log(dayjs()?.hour(0)?.minute(0)?.format("YYYY-MM-DD HH:mm:ss"));

  return (
    <Form
      onFinish={onFinish}
      form={form}
      initialValues={
        defaultSearch
          ? {
              actions: 0,
              edate: dayjs(),
              sdate: dayjs(),
              sdatehour: dayjs()?.hour(0)?.minute(0),
              edatehour: dayjs()?.hour(23)?.minute(59),
              date: dayjs(),
              ...defaultSearch,
            }
          : {
              actions: 0,
              date: dayjs(),
              edate: dayjs(),
              sdatehour: dayjs()?.hour(0)?.minute(0),
              edatehour: dayjs()?.hour(23)?.minute(59),
              sdate: dayjs(),
            }
      }
    >
      <div className="flex justify-between gap-2 flex-wrap">
        <div className="flex flex-wrap gap-2">
          {controls &&
            controls.split(",").map((item, i) => getSearchCols(item, i))}
          {isSearch && (
            <Form.Item name="name" className="w-40">
              <Input
                placeholder="Хайх"
                allowClear
                // onChange={onChangeSearch}
              />
            </Form.Item>
          )}
          <div className="relative">
            {isNewYear() ? (
              <img
                src={hat}
                alt="hat"
                className="absolute z-20 w-10 h-12 -top-5 -right-6 rotate-45"
              />
            ) : null}

            <Button
              htmlType="submit"
              type="primary"
              className="bg-primary"
              icon={<SearchOutlined />}
              loading={loading}
            >
              Хайх
            </Button>
          </div>

          {isNew ? (
            <Button
              className="sm:flex xl:hidden bg-primary"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleNew}
            >
              Шинэ
            </Button>
          ) : null}

          {isModal ? (
            <Button
              className="sm:flex xl:hidden"
              icon={<PlusCircleOutlined />}
              onClick={handleOpen}
            >
              Эрэмблэх
            </Button>
          ) : null}
          <Dropdown
            className="sm:flex xl:hidden"
            overlay={menu}
            trigger={["click"]}
          >
            <Button icon={<DownOutlined />}>Үйлдлүүд</Button>
          </Dropdown>
        </div>

        <div className="xl:gap-2 sm:hidden xl:flex">
          {manuallink ? (
            <Button
              className="bg-white"
              icon={<YoutubeOutlined />}
              onClick={handleOpenLink}
            ></Button>
          ) : null}
          {isNew ? (
            <Button
              type="primary"
              className="bg-primary"
              icon={<PlusCircleOutlined />}
              onClick={handleNew}
            >
              Шинэ
            </Button>
          ) : null}
          {isModal ? (
            <Button icon={<PlusCircleOutlined />} onClick={handleOpen}>
              Эрэмблэх
            </Button>
          ) : null}
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button icon={<DownOutlined />}>Үйлдлүүд</Button>
          </Dropdown>
        </div>
      </div>
      <DragDrop
        isOpen={isOpen}
        onClose={handleClose}
        setIsOpen={() => setIsOpen(false)}
        props={props}
      />
    </Form>
  );
};

export default Search;
