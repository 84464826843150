import { useParams, useNavigate } from "react-router-dom";
import { Switch, Tag, Checkbox, Button, Popconfirm } from "antd";
import dayjs from "dayjs";
import { routeToAddOrEdit, API_URL } from "../utils";
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { IsEnable, PaymentType, DeleteBuyingPerm } from "../utils/enums";
import Modals from "../data/Modals";
import React, { useState, useEffect } from "react";
import { GlobalModal, HistoryModal } from "../components";
import noimage from "../assets/noimage.png";
import { owntoast } from "../utils";
import * as Components from "../components";
import UsefulFunctions from "../utils/UsefulFunctions";
import iLocation from "../assets/icons/location.png";
import { OrderHisColumns } from "../data/Columns";

const Formatter = ({
  value,
  format,
  index,
  row,
  form,
  request,
  isPrice,
  onClick,
  page,
  props,
  clearZero,
}) => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [warningSms, setwarningSms] = useState("");
  const [visible, setVisible] = useState(false);
  const [history, setHistory] = useState(false);
  const [hisdata, setHisData] = useState(null);

  useEffect(() => {
    if (history) {
      props
        ?.request({
          url: `/sale/orderdetail/log/${row?.id}`,
          method: "GET",
        })
        .then((res) => {
          if (res.success) {
            setHisData(res?.data);
          } else {
            owntoast(res.message, "error");
          }
        });
    }
  }, [history]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onChangeStatus = (e, value, row, form, request) => {
    if (form && form.url && form.method) {
      request({
        url: form.url,
        method: form.method,
        body: { ...row, isenable: row.isenable === 1 ? 0 : 1 },
        isfile: form.isFile,
      }).then((res) => {
        if (res.success) {
          if (row.isenable === 1) row.isenable = 0;
          else row.isenable = 1;
          owntoast(res.message, "success");
        } else {
          if (res.message === "Амжилтгүй") owntoast(res.message, "error");
          else {
            setwarningSms(res.message);
            setIsOpenWarning(true);
          }
          // row.isenable = !row.isenable;
        }
      });
    }
  };
  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: DeleteBuyingPerm,
      permission: props._auth.permission,
      isMsg: true,
      reslicense: props.reslicense,
      setError: props.setError,
    });
  };

  const handleDelete = () => {
    if (!check()) {
      return;
    }
    let id = row?.id;
    let url = "/material/buy";
    if (page.deleteurl) url = page.deleteurl;
    if (page.deleteparam) id = row[page.deleteparam];
    request({
      url: `${url}/${id}`,
      method: "DELETE",
    }).then((res) => {
      if (res.success) {
        owntoast(res.message, "success");

        if (page.url && page.model) {
          let searchValues = JSON.parse(
            localStorage.getItem(`search${page.url}`)
          );
          request({
            url: page.url,
            body: searchValues,
            method: "POST",
            model: page.model,
          });
        }
      }
    });
  };

  const splitString = (value, char) => {
    if (visible) return value.match(/.{1,4}/g).join(char);
    else return "************".match(/.{1,4}/g).join(char);
  };

  if (format) {
    switch (format) {
      case "date":
        return value ? dayjs(value).format("YYYY-MM-DD") : "-";
      case "datetime":
        return value && dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      case "datetimemin":
        return value && dayjs(value).format("YYYY-MM-DD HH:mm");
      case "datehour":
        return value ? dayjs(value).format("HH:mm") : "-";
      case "diffdate": {
        if (row?.timesdate && row?.timeedate) {
          const diffInMilliseconds = dayjs(row?.timeedate)?.diff(
            dayjs(row?.timesdate)
          );
          const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
          const totalMinutes = Math.floor(
            (diffInMilliseconds / (1000 * 60)) % 60
          );
          return `${totalHours?.toString()?.padStart(2, "0")}:${totalMinutes
            ?.toString()
            ?.padStart(2, "0")}`;
        }
      }
      case "hourtime": {
        if (!value || value <= 0) return "";
        let tmp = value.split(":");
        return `${tmp[0]?.toString()?.padStart(2, "0")}:${tmp[1]
          ?.toString()
          ?.padStart(2, "0")}`;
      }
      case "numbertotime": {
        let summin = (value || 0) * 60;
        let hour = Math.floor(summin / 60);
        let min = Math.floor(summin % 60);
        return <span>{`${hour} цаг ${min} минут`}</span>;
      }
      case "catnames": {
        return value?.join?.(", ");
      }

      case "edit":
        return (
          <span
            className="text-blue cursor-pointer"
            onClick={() => {
              routeToAddOrEdit({ navigate, params, index, record: row });
              /*  localStorage.setItem("editRow", JSON.stringify(row)); */
            }}
          >
            Засах
          </span>
        );
      case "isfree":
        return (
          <span>{value === 1 ? "Багцад дагалдах" : "Нэмэлт хэрэглэгч"}</span>
        );
      case "hisqty":
        return (
          <span style={{ color: value > 0 ? "green" : "red" }}>
            {value > 0 ? `+${value}` : value}
          </span>
        );
      case "delete":
        return (
          <Popconfirm
            title={() => {
              return (
                <div className="flex flex-col">
                  <span>Устахдаа итгэлтэй байна уу?</span>
                </div>
              );
            }}
            placement="topLeft"
            onConfirm={handleDelete}
            okText="Тийм"
            loading={row.loading}
            cancelText="Үгүй"
          >
            <span className="text-red-600 cursor-pointer">Устгах</span>
          </Popconfirm>
        );
      case "statusconfirm":
        return (
          <Popconfirm
            title={() => {
              return (
                <div className="flex flex-col">
                  <span>
                    Тохиргоог идэвхгүй болгосноор тус төхөөрөмж дээр систем
                    ажиллах боломжгүй болохыг анхаарна уу.
                  </span>
                  <span>Идэвхгүй болгох уу?</span>
                </div>
              );
            }}
            onConfirm={(e) => onChangeStatus(e, value, row, form, request)}
            okText="Тийм"
            disabled={value === 0}
            loading={row.loading}
            cancelText="Үгүй"
          >
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              disabled={value === 0}
              checked={value === 1}
              loading={row.loading}
              unCheckedChildren={<CloseOutlined />}
              className={
                value === 1
                  ? "bg-primary"
                  : "bg-fullBack rounded-xl h-4 switch-disable"
              }
              /*  onChange={(e) => onChangeStatus(e, value, row, form, request)} */
            />
          </Popconfirm>
        );
      case "status":
        return (
          <div>
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              checked={value === 1}
              loading={row.loading}
              unCheckedChildren={<CloseOutlined />}
              className={value === 1 ? "bg-primary" : "bg-fullBack"}
              onChange={(e) => onChangeStatus(e, value, row, form, request)}
            />
            <Components.CustomModal
              footer={[
                <Button
                  onClick={() => {
                    setIsOpenWarning(false);
                  }}
                >
                  Хаах
                </Button>,
              ]}
              width={400}
              isOpen={isOpenWarning}
              onClose={() => {
                setIsOpenWarning(false);
              }}
              title="Анхааруулга"
            >
              <div className="py-3 px-10 flex flex-col items-center justify-center">
                {warningSms}
              </div>
            </Components.CustomModal>
          </div>
        );
      case "kioskstatus":
        if (value === 1) {
          return <Checkbox checked />;
        } else {
          return <Checkbox disabled />;
        }
      case "settlementtype":
        if (value === 1) {
          return <Checkbox checked />;
        } else if (value === 1) {
          return <Checkbox disabled />;
        }
        return null;
      case "img":
        if (value) {
          return (
            <div className="flex items-center justify-center">
              <img
                className="table-img h-10 w-10 rounded-md"
                src={`${API_URL}${value}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = noimage;
                }}
              />
            </div>
          );
        } else {
          return <span>Зураггүй</span>;
        }
      case "statusexport":
        return value === IsEnable.Идэвхтэй ? "Идэвхтэй" : "Идэвхгүй";
      case "price":
        if (value)
          return new Intl.NumberFormat().format(Math.round(value * 100) / 100);
        return 0;
      case "pricenon":
        if (value)
          return new Intl.NumberFormat("en-NZ", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
        return 0;
      case "priceColored":
        if (value) {
          if (value >= 0)
            return (
              <span>
                {new Intl.NumberFormat().format(Math.round(value * 100) / 100)}
              </span>
            );
          else
            return (
              <span className="text-red-600">
                {new Intl.NumberFormat().format(Math.round(value * 100) / 100)}
              </span>
            );
        }

        return 0;
      case "linkPrice":
        return (
          <div className="underline cursor-pointer text-blue">
            {value
              ? new Intl.NumberFormat().format(Math.round(value * 100) / 100)
              : value}
          </div>
        );
      case "papersize":
        return value === 1 ? <Tag>58мм</Tag> : <Tag>80мм</Tag>;
      case "lowPrice":
        let tmpValue = value;
        if (isNaN(+value)) {
          tmpValue = value.replace("%", "");
        }
        if (tmpValue < 0) {
          return (
            <span className="text-red-500">
              {isPrice === false
                ? value
                : new Intl.NumberFormat().format(Math.round(value * 100) / 100)}
            </span>
          );
        } else {
          return isPrice === false
            ? value
            : Intl.NumberFormat().format(Math.round(value * 100) / 100);
        }
      case "See":
        if (value.length > 0) {
          return (
            <div>
              <span
                onClick={() => setIsOpen(true)}
                className="text-blue cursor-pointer"
              >
                Харах
              </span>
              <GlobalModal
                isOpen={isOpen}
                onClose={closeModal}
                closeBtnTxt="Хаах"
                onlySee={true}
                width={850}
                headerTitle="Бүтээгдэхүүнүүд"
                props={row.products}
                isFull={true}
              />
            </div>
          );
        }
        return null;
      case "SeePerm":
        return (
          <div>
            <span
              onClick={() => setIsOpen(true)}
              className="text-blue cursor-pointer"
            >
              Харах
            </span>
            <Modals
              type={"userPerm"}
              isOpen={isOpen}
              selectedRow={row}
              closeModal={closeModal}
            />
          </div>
        );
      case "OnlySee":
        return (
          <div>
            <span className="text-blue cursor-pointer">Мастер бүртгэх</span>
          </div>
        );

      case "link":
        return (
          <div>
            <span
              onClick={() => setIsOpen(true)}
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              {new Intl.NumberFormat().format(value)}
            </span>
            <Modals
              type={"nonCash"}
              isOpen={isOpen}
              selectedRow={row}
              closeModal={closeModal}
            />
          </div>
        );
      case "role":
        if (value === 1) {
          return <span>Супер админ</span>;
        } else if (value === 2) {
          return <span>Админ</span>;
        } else if (value === 3) {
          return <span>Касс</span>;
        } else if (value === 4) {
          return <span>Менежер</span>;
        } else if (value === 5) {
          return <span>Тогооч</span>;
        }
        return null;
      case "paymentType":
        if (value === null) {
          return null;
        } else {
          let found = null;
          Object.keys(PaymentType).map((key) => {
            if (value === PaymentType[key]) {
              found = key;
            }
          });
          if (found) {
            return <span>{found}</span>;
          }
          return null;
        }
      case "licenseType":
        if (value === null) {
          return null;
        } else if (value === 1) {
          return <span>Үнэгүй хувилбар</span>;
        } else if (value === 2) {
          return <span>Үндсэн хувилбар</span>;
        } else if (value === 3) {
          return <span>Нэмэлт хэрэглэгч</span>;
        }
        break;
      case "salestatus":
        if (value === 1) {
          return <Tag color="green">Төлөгдсөн</Tag>;
        } else {
          return <Tag color="red">Төлөгдөөгүй</Tag>;
        }
      case "isunit":
        if (value === 1) {
          return <Tag color="green">Бараа</Tag>;
        } else {
          return <Tag color="red">Хоол</Tag>;
        }
      case "ismakestatus":
        if (value === 1) {
          return (
            <div>
              <Tag color="green">Бэлтгэгдсэн</Tag>
              <EditOutlined
                className="cursor-pointer text-red-700"
                onClick={() => onClick(row && row)}
              />
            </div>
          );
        } else if (value === 0) {
          return <Tag color="red">Бэлтгэгдээгүй</Tag>;
        } else {
          return <Tag color="yellow">Дутуу гарсан</Tag>;
        }
      case "isHistory":
        return (
          <div>
            <span
              onClick={() => setHistory(true)}
              className="text-blue cursor-pointer font-bold"
            >
              ...
            </span>
            <HistoryModal
              isOpen={history}
              width={960}
              title="Түүхийн жагсаалт"
              onClose={() => setHistory(false)}
              footer={<Button onClick={() => setHistory(false)}>Хаах</Button>}
            >
              <div className="px-3 max-h-96 h-96">
                <Components.AntdTable
                  scroll={{ y: "20rem" }}
                  dataSource={hisdata}
                  // loading={props.isorderlistall}
                  columns={OrderHisColumns()}
                  isModal={true}
                  pagination={false}
                />
              </div>
            </HistoryModal>
          </div>
        );

      case "onlyName":
        if (value) {
          return (
            <div>
              {value.map((item, i) => {
                return `${item.name}${value.length - 1 > i ? ", " : ""} `;
              })}
            </div>
          );
        }
        return null;
      case "saletype":
        if (value === 1) {
          return <Tag color="green">Борлуулалт</Tag>;
        } else {
          return <Tag color="red">Буцаалт</Tag>;
        }
      case "priceSaleType":
        if (clearZero && value === 0) {
          return null;
        }
        return (
          <div
            className={`cursor-pointer ${
              row && row.saletype !== 1
                ? "text-red-600 underline"
                : "underline text-blue"
            }`}
          >
            {new Intl.NumberFormat().format(value ? value : 0)}
          </div>
        );
      case "deliveryaddress": {
        if (row?.deliveryenum === 5 && row.location) {
          return (
            <div className="flex items-center justify-center">
              <Button
                size="small"
                type="dashed"
                className="p-0.5"
                icon={<img src={iLocation} alt="iLocation" />}
              />
            </div>
          );
        }
        return null;
      }
      case "print":
        return <span className="text-blue cursor-pointer">Хэвлэх</span>;
      case "split":
        if (value) {
          return (
            <div className="flex items-center ">
              <span className="text-lg font-semibold w-80">
                {splitString(value, "-")}
              </span>
              <Button type="text" onClick={() => setVisible(!visible)}>
                {!visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </Button>
            </div>
          );
        }
        return value;
      default:
        return value;
    }
  }
  return value;
};

export default Formatter;
