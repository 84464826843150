import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Checkbox } from "antd";
import loader from "../../assets/logos/loader.gif";
import { routeToAddOrEdit } from "../../utils";
import UsefulFunctions from "../../utils/UsefulFunctions";
/* Хүснэгт зурах */
function AntdTable({
  dataSource,
  columns,
  loading,
  url,
  pagination,
  isDoubleClick,
  model,
  method,
  isPagination,
  request,
  isModal,
  isSelectRow,
  selectedRows,
  isBranch,
  setSelectedRows,
  rowSelectionType,
  scroll,
}) {
  const defaultPagination = JSON.parse(
    localStorage.getItem(`pagination${url}`)
  );
  const navigate = useNavigate();
  const params = useParams();

  const sendRequest = (a, b) => {
    if (isPagination) {
      let values = JSON.parse(localStorage.getItem(`search${url}`));
      request({
        url,
        method,
        model,
        body: {
          ...values,
          pagenumber: a,
          count: b,
        },
      });
    }
  };

  const onSelectChange = (keys, rows) => {
    if (setSelectedRows)
      setSelectedRows({
        keys: keys,
        rows: rows,
      });
  };

  const rowSelection = {
    selectedRowKeys: selectedRows && selectedRows.keys,
    onChange: onSelectChange,
  };

  const rowSelectionRadio = {
    selectedRowKeys: selectedRows && selectedRows.keys,
    onChange: onSelectChange,
    type: "radio",
    renderCell: (checked, record) => {
      return (
        <Checkbox
          checked={checked}
          onChange={() => onSelectChange([record?.id], [record])}
        />
      );
    },
  };

  return (
    <Table
      dataSource={isPagination ? dataSource.data : dataSource}
      rowSelection={
        isSelectRow
          ? rowSelectionType === "radio"
            ? rowSelectionRadio
            : rowSelection
          : null
      }
      columns={columns}
      size="small"
      rowKey={(row) => row.id}
      loading={{
        spinning: loading ? true : false,
        indicator: <img src={loader} alt="" className="w-16 h-10" />,
      }}
      scroll={scroll ? scroll : { y: "100%" }}
      /*  scroll={{
        y: 300,
        x: "100vw",
      }} */
      /* scroll={
        !isModal ? { x: "80vw", y: "100%" } : isBranch ? { y: 350 } : null
      } */
      /*    scroll={{ x: "auto", y: "25rem" }} */
      /* scroll={{ y: "25rem", x: "max-content" }} */
      // rowClassName={(rowIndex) => {
      //   return rowIndex === selectedRow.rowIndex ? "selected-row" : "";
      // }}
      pagination={
        pagination
          ? {
              defaultPageSize: defaultPagination
                ? defaultPagination.defaultPageSize
                : 10,
              defaultCurrent: defaultPagination
                ? defaultPagination.defaultCurrent
                : 1,
              total: isPagination
                ? dataSource.count
                : dataSource && dataSource.length,
              showSizeChanger: true,
              onChange: (a, b, c) => {
                sendRequest(a - 1, b);
                localStorage.setItem(
                  `pagination${url}`,
                  JSON.stringify({ defaultCurrent: a, defaultPageSize: b })
                );
              },
              pageSizeOptions: [
                "10",
                "20",
                "30",
                "40",
                `${
                  isPagination
                    ? dataSource.count
                    : dataSource && dataSource.length
                }`,
              ],
            }
          : pagination
      }
      summary={(pageData) => (
        <Table.Summary fixed className="sticky top-0">
          {isPagination ? (
            UsefulFunctions.renderSummaryRow({
              footer: dataSource && dataSource.footer,
              columns,
              isPagination,
            })
          ) : (
            <Table.Summary.Row>
              {UsefulFunctions.renderSummary({
                data: pageData,
                columns,
                isPagination,
              })}
            </Table.Summary.Row>
          )}
        </Table.Summary>
      )}
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: () => {
            if (isDoubleClick) {
              routeToAddOrEdit({ navigate, params, index: rowIndex, record });
            }
          },
        };
      }}
      bordered
      className={isBranch ? null : "h-full sm:mt-4 xl:mt-0 mainTable"}
    />
  );
}

export default AntdTable;
