import { useState } from "react";
import { CustomModal, AntdTable } from "..";
import { Button, Modal } from "antd";
import { OrderListColumns } from "../../data/Columns";
import { owntoast } from "../../utils";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm, success } = Modal;

const Index = (props) => {
  const [selectedRows, setSelectedRows] = useState({});
  const [loading, setLoading] = useState(false);
  const columns = OrderListColumns(null)?.filter(
    (x) =>
      x.key !== "status" &&
      x.key !== "no" &&
      x.key !== "deliveryenumtext" &&
      x.key !== "issearch"
  );
  const combine = () => {
    if (props?.order?.id) {
      if (selectedRows?.keys && selectedRows?.keys?.length > 0) {
        confirm({
          title: "Анхааруулга",
          icon: <ExclamationCircleFilled />,
          content: `Та ${props?.order?.salenum} -р захиалга дээр ${selectedRows?.rows[0]?.salenum} -р захиалгыг нэгтгэх гэж байна. Нэгтгэх үү ?`,
          maskClosable: true,
          centered: true,
          className: "p-10",
          cancelText: "Хаах",
          okText: "Тийм",
          onOk() {
            send();
          },
        });
      } else {
        owntoast("Нэгтгэх захиалга сонгогдоогүй байна.", "error");
      }
    } else {
      owntoast("Үндсэн захиалга сонгогдоогүй байна.", "error");
    }
  };

  const send = () => {
    setLoading(true);
    props
      ?.request({
        url: "/order/orderunion",
        method: "POST",
        body: {
          mainorderid: props?.order?.id,
          orderid: selectedRows?.keys?.[0],
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props.request({
            url: "/sale/orderlist/ongoing",
            model: "ongoingorders",
          });
          setSelectedRows({});
          props?.setOrderDetail({
            order: {},
            detail: [],
          });
          if (res.message !== "Aмжилттай" && res.message) {
            success({
              content: res.message,
              title: "Амжилттай",
              onOk: () => props?.setIsOpen?.(false),
              onCancel: () => props?.setIsOpen?.(false),
              maskClosable: true,
              centered: true,
              className: "p-10",
              cancelText: "Хаах",
              okText: "Тийм",
            });
          } else {
            owntoast(res?.message, "success");
            props?.setIsOpen?.(false);
          }
        } else {
          owntoast(res?.message, "error");
        }
      });
  };

  return (
    <CustomModal
      footer={[
        <Button
          loading={loading}
          onClick={() => {
            setSelectedRows({});
            props?.setIsOpen?.(false);
          }}
        >
          Буцах
        </Button>,
        <Button loading={loading} type="primary" autoFocus onClick={combine}>
          Хадгалах
        </Button>,
      ]}
      width={960}
      isOpen={props?.isOpen}
      onClose={() => props?.setIsOpen?.(false)}
      title={`Захиалга нэгтгэх - ${props?.order?.salenum}`}
    >
      <div className="px-3 ">
        <span className="mb-2 block">Үндсэн захиалга</span>
        <AntdTable
          columns={columns}
          loading={loading}
          dataSource={[props?.order]}
          isSelectRow={true}
          rowSelectionType="radio"
          selectedRows={{
            keys: [props?.order?.id],
            rows: [props?.order],
          }}
        />
        <span className="mb-2 block">Нэгтгэх захиалга</span>
        <AntdTable
          loading={loading}
          columns={columns}
          dataSource={props?.ongoingorders?.filter(
            (x) => x.id !== props?.order?.id
          )}
          isSelectRow={true}
          rowSelectionType="radio"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </div>
    </CustomModal>
  );
};

export default Index;
