import { usePresence } from "framer-motion";
const transition = {
  type: "spring",
  stiffness: 500,
  damping: 50,
  mass: 1,
  duration: 100,
};

export function useMotionHook() {
  const [isPresent, safeToRemove] = usePresence();
  return {
    layout: true,
    initial: "out",
    style: {
      position: "static",
    },
    animate: isPresent ? "in" : "out",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };
}
