import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { PHONENO_VALIDATOR, owntoast } from "../../utils/index";
import { LoginContainer } from "..";
function ForgetPass(props) {
  useEffect(() => {
    document.title = "Нууц үг сэргээх";
  }, []);

  const navigate = useNavigate();
  const foodid = JSON.parse(localStorage.getItem("foodid"));
  const handleNext = (e) => {
    props
      .request({ url: `/user/resetsms/${foodid}/${e.phone}`, method: "PUT" })
      .then((res) => {
        if (res.success) {
          owntoast(res.message, "success");
          let tmp = {
            type: "forgetpass",
            foodid: foodid,
            phone: e.phone,
          };
          localStorage.setItem("register", JSON.stringify(tmp));
          navigate("/register/verify");
        } else {
          owntoast(res.message, "error");
        }
      });
  };
  return (
    <div className="w-full max-w-sm mx-auto 3xl: items-center flex flex-col text-center">
      {/* className="w-full max-w-sm mx-auto mt-4 3xl:mt-12 items-center flex flex-col" */}
      <p className="font-bold text-lg">НУУЦ ҮГ СЭРГЭЭХ</p>
      <Form
        className="w-full mt-4 items-center flex flex-col"
        onFinish={handleNext}
      >
        <div className="w-full ">
          <Form.Item name="phone" rules={PHONENO_VALIDATOR}>
            <Input placeholder="Утасны дугаар" maxLength={8} type="number" />
          </Form.Item>
          <Button
            className="bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm lg:mt-2"
            type="primary"
            htmlType="submit"
          >
            БАТАЛГААЖУУЛАХ
          </Button>
        </div>
        <p color="black" className="mt-2 3xl:mt-8">
          Нууц үгээ санаж байна! <Link to="/login">Нэвтрэх</Link>
        </p>
      </Form>
    </div>
  );
}

export default ForgetPass;
