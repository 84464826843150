import { useEffect, useState } from "react";

const useCheckTimer = ({ delay = 5, callback, initial = 10 }) => {
  const [count, setCount] = useState(initial);
  let interval = null;
  const formatTime = (totalSeconds) => {
    const minute = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minute.toString()?.padStart(2, "0")}:${seconds
      .toString()
      ?.padStart(2, "0")}`;
  };

  useEffect(() => {
    interval = setInterval(() => {
      setCount((prev) => {
        if (prev > 0 && prev % delay === 0) {
          if (callback) callback();
        }
        if (prev - 1 === 0) {
          clearInterval(interval);
          if (callback) callback();
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [delay]);

  return {
    seconds: count,
    time: formatTime(count),
  };
};

export default useCheckTimer;
