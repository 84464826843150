import { Pie } from "@ant-design/plots";

function PieChart({ data }) {
  const calculateSum = () => {
    return data.reduce((totalHolder, item) => totalHolder + item.qty, 0);
  };

  const config = {
    data,
    angleField: "qty",
    colorField: "deliveryname",
    radius: 1,
    innerRadius: 0.85,
    legend: {
      layout: "horizontal",
      position: "bottom",
      flipPage: false,
      slidable: false,
    },
    label: false,
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        customHtml: `<div><div>${calculateSum()}</div><div style="color:#696974;font-size:1rem">Нийт захиалга</div></div>`,
      },
    },
  };

  return <Pie {...config} />;
}

export default PieChart;
