import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { LockOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  CONFIRMPASSWORD_VALIDATOR,
  CREATEPASSWORD_VALIDATOR,
  PHONENO_VALIDATOR,
  owntoast,
} from "../../utils/index";
function SubInfo(props) {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("register"));
  const [isBranch] = useState(props.isBranch ? true : false)
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Бүртгүүлэх /Нэвтрэх мэдээлэл/";
    if (isBranch && data.phone) {
      form.setFieldsValue({
        phone: Number(data.phone),
      })
    }
  }, []);

  const handleNext = (e) => {
    if (isBranch) {
      let tmp = {
        name: data.name,
        classid: data.classid,
        phone: Number(e.phone),
        locid: data.locid,
        address: data.address,
        password: e.password,
      };
      props
        .request({ url: `/branch/signup`, method: "POST", body: tmp })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            let tmp = {
              name: data.name,
              classid: data.classid,
              locid: data.locid,
              address: data.address,
              foodid: res.data.foodid ? res.data.foodid : res.data,
              phone: Number(e.phone),
            };
            localStorage.setItem("register", JSON.stringify(tmp));
            props.setStep(2);
            props.setProcent(0);
            navigate("/addBranch/verify");
          } else {
            owntoast(res.message, "error");
          }
        });
    }
    else if (!data.type) {
      let tmp = {
        name: data.name,
        classid: data.classid,
        phone: Number(e.phone),
        locid: data.locid,
        address: data.address,
        password: e.password,
      };
      props
        .request({ url: `/user/signup`, method: "POST", body: tmp })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            let tmp = {
              name: data.name,
              classid: data.classid,
              locid: data.locid,
              address: data.address,
              foodid: res.data.foodid,
              phone: Number(e.phone),
            };
            localStorage.setItem("register", JSON.stringify(tmp));
            navigate("/register/verify");
          } else {
            owntoast(res.message, "error");
          }
        });
    } else {
      props
        .request({
          url: `/user/resetpass/${data.foodid}/${data.phone}/${data.code}/${e.password}`,
          method: "PUT",
        })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
            navigate("/register/success");
          } else {
            owntoast(res.message, "error");
          }
        });
    }
  };
  const changeInput = (e, name) => {
    let check = form.getFieldValue(name);
    if (check && name === "phone") {
      props.setProcent(33);
    } else if (check && name === "password") {
      props.setProcent(66);
    } else if (check && name === "confirmpassword") {
      props.setProcent(100);
    }
  }
  const handleBack = () => {
    if (isBranch) {
      props.setStep(0);
      props.setProcent(0)
    }
  }
  return (
    <div className="items-center flex flex-col text-center">
      <p className="font-bold text-lg">
        {data.type ? "ШИНЭ НУУЦ ҮГ ҮҮСГЭХ" : "НЭВТРЭХ БҮРТГЭЛ"}
      </p>
      <Form
        className="w-full mt-4 items-center flex flex-col"
        onFinish={handleNext}
        form={form}
      >
        <div className="lg:w-72 w-full text-left">
          {!data.type ? (
            <Form.Item name="phone" rules={PHONENO_VALIDATOR}>
              <Input
                placeholder="Утасны дугаар"
                maxLength={8}
                prefix={<PhoneOutlined />}
                keyboardType={"numeric"}
              />
            </Form.Item>
          ) : null}
          <Form.Item name="password" rules={CREATEPASSWORD_VALIDATOR}>
            <Input.Password placeholder="Нууц үг" prefix={<LockOutlined />} onFocus={isBranch ? (e) => changeInput(e, "phone") : null} />
          </Form.Item>
          <Form.Item name="confirmpassword" rules={CONFIRMPASSWORD_VALIDATOR}>
            <Input.Password
              placeholder="Нууц үг давтах"
              prefix={<LockOutlined />}
              onFocus={isBranch ? (e) => changeInput(e, "password") : null}
              onBlur={isBranch ? (e) => changeInput(e, "confirmpassword") : null}
            />
          </Form.Item>
          <Button
            className={`${isBranch ? "lg:mt-28" : ""}bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm`}
            type="primary"
            htmlType="submit"
          >
            ҮРГЭЛЖЛҮҮЛЭХ
          </Button>
        </div>
        {!isBranch ? <p color="black" className="mt-2 3xl:mt-8">
          Бүртгэлээрээ <Link to="/login">Нэвтрэх</Link>
        </p> : null}
        <Link to={isBranch ? "/addBranch/maininfo" : "/register/maininfo"} className="mt-4" onClick={handleBack}>Буцах</Link>
      </Form>
    </div>
  );
}

export default SubInfo;
