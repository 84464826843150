import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { owntoast } from "../../utils";
import { useNavigate } from "react-router-dom";
import home from "../../assets/icons/home.png";
import { License } from "../../components";
import { ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
const { confirm } = Modal;
/*function License(props) {
  const [checked, setChecked] = useState({});
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const { reslicenseinfo, reslicense } = props;
  const lenght = reslicenseinfo && reslicenseinfo.length;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Лиценз";
    props
      .request({ url: `/license/info`, model: "licenseinfo" })
      .then((res) => {
        if (!res.success) owntoast(res.message, "error");
      });
  }, []);

  const handleChangeCheck = (e, item) => {
    setChecked((prev) => {
      let tmp = { ...prev };
      if (e) {
        if (item.type === 4) {
          props
            .request({ url: `/kiosk/device`, model: "kioskdevices" })
            .then((res) => {
              if (res.success) {
                if (res.data.length > 0) tmp[item.id] = item;
                else setIsOpenWarning(true);
              } else {
                owntoast(res.message, "error");
              }
            });
        } else tmp[item.id] = item;
      } else {
        delete tmp[item.id];
      }
      return tmp;
    });
  };

  const handleNext = () => {
    if (checked && Object.keys(checked).length > 0) {
      localStorage.setItem("license", JSON.stringify(checked));
      navigate("/licensepayment");
    } else owntoast("Багц сонгоно уу.", "error");
  };

  const handleFree = () => {
    navigate("/licensedemo");
  };

  const getLicenseList = () => {
    navigate("/licenselist");
  };

  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="bg-white p-10 rounded-lg border border-gray-200 overflow-auto overflow-x-auto drop-shadow-xl 2xl:h-4/6 2xl:w-3/5 sm:h-5/6">
        <div
          className="p-2 bg-borderGrey absolute rounded rounded-10 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={home} alt="" className="w-7" />
        </div>
        <p className="text-center text-license text-3xl">Үнийн санал</p>
        <div
          className={`grid grid-cols-1 md:grid-cols-${lenght} lg:grid-cols-${lenght} xl:grid-cols-${lenght} gap-8 flex justify-center`}
        >
          {reslicenseinfo &&
            reslicenseinfo.map((item) => (
              <div
                className={`bg-white rounded-lg drop-shadow-xl xl:w-60 ${
                  item.isChecked ? "border-2 border-primary" : ""
                }`}
              >
                <div className="p-5 flex flex-col justify-between h-full w-full">
                  <div>
                    <div className="text-center text-lg font-semibold text-black">
                      {item.name}
                    </div>
                    <div className="text-center my-2">{item.about}</div>
                  </div>
                  <div className="text-center flex flex-col items-center mt-5">
                    <div className="flex items-center mb-2">
                      <div className="text-xl font-bold text-primary uppercase">
                        {item.price
                          ? `${new Intl.NumberFormat().format(item.price)} ₮`
                          : "үнэгүй"}
                      </div>
                      {item.type !== 4 && (
                        <div className="ml-2 font-semibold">{`/${item.text}`}</div>
                      )}
                    </div>
                    {item.type === 4 && (
                      <div className="-mt-2 mb-1 font-semibold">{`/${item.text}`}</div>
                    )}
                    {item.price ? (
                      <div className="flex justify-center">
                        <div className="font-semibold">Сонгох:</div>
                        <Switch
                          className="ml-4"
                          onChange={(e) => handleChangeCheck(e, item)}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <Button
                          type="primary"
                          className="bg-primary"
                          disabled={
                            reslicense && reslicense.eatbonus === 1
                              ? true
                              : false
                          }
                          onClick={handleFree}
                        >
                          Ашиглах
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex justify-between mt-5">
          <Button
            type="primary"
            style={{ backgroundColor: "#F78C62", borderColor: "#F78C62" }}
            onClick={getLicenseList}
          >
            Миний авсан лиценз
          </Button>
          <Button type="primary" className="bg-primary" onClick={handleNext}>
            Үргэлжлүүлэх
          </Button>
        </div>
      </div>
      <Components.CustomModal
        footer={[
          <Button onClick={() => setIsOpenWarning(false)}>OK</Button>,
          <Button
            onClick={() => {
              setIsOpenWarning(false);
              navigate("/management/settings/6/3/new");
            }}
            type="primary"
            className="bg-primary"
          >
            Киоск төхөөрөмж бүртгэх
          </Button>,
        ]}
        width={400}
        isOpen={isOpenWarning}
        onClose={() => setIsOpenWarning(false)}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          Танд бүртгэлтэй Киоск төхөөрөмж олдсонгүй. Та Үндсэн цэс {"->"}{" "}
          Тохиргоо {"->"} Төхөөрөмж цонхноос Киоск төхөөрөмжөө бүртгэнэ үү.
        </div>
      </Components.CustomModal>
    </div>
  );
}

export default License;
 */

const Index = (props) => {
  const [license, setLicense] = useState(null);
  const [subLicense, setSubLicense] = useState([]);
  const mainLicenses =
    props?.reslicenseinfo?.filter?.((x) => x.type === 2) || [];
  const subLicenses =
    props?.reslicenseinfo?.filter((x) => x.type === 3 || x.type === 4) || [];
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Лиценз";
    props
      .request({ url: `/license/info`, model: "licenseinfo" })
      .then((res) => {
        if (!res.success) owntoast(res.message, "error");
      });
  }, []);

  const licenseChange = (e, data) => setLicense(e ? data : null);

  const subLicenseChange = (e, data) => {
    if (e) setSubLicense([...subLicense, data]);
    else setSubLicense(subLicense?.filter((x) => x.id !== data?.id));
  };

  const handleNext = () => {
    if (license || (subLicense && subLicense?.length)) {
      const isSameMonth = dayjs().isSame(
        dayjs(props?.reslicense?.edate),
        "month"
      );
      const isSameYear = dayjs().isSame(
        dayjs(props?.reslicense?.edate),
        "year"
      );

      if (isSameYear && isSameMonth) {
        handleChange(null);
      } else {
        const currentlicense = props?.reslicenseinfo?.find(
          (x) => x.id === props?.reslicense?.productid
        );

        if (currentlicense?.price > license?.price) {
          confirm({
            title: "Анхааруулга",
            icon: <ExclamationCircleFilled />,
            content: `Та ${dayjs(props?.reslicense?.edate)?.format(
              "YYYY-MM-DD"
            )} өдрөөс хойш ${license?.name} багцруу шилжих боломжтой`,
            maskClosable: true,
            centered: true,
            className: "p-10",
            cancelText: "Хаах",
            okText: "Ойлголоо",
          });
        } else if (currentlicense?.price < license?.price) {
          confirm({
            title: "Анхааруулга",
            icon: <ExclamationCircleFilled />,
            content: `Та програмын хуучин багцаа ${license?.name} багцруу ахиулах гэж байна. Ахиулах уу ?`,
            maskClosable: true,
            centered: true,
            className: "p-10",
            cancelText: "Үгүй",
            okText: "Тийм",
            onOk() {
              handleChange(currentlicense);
            },
          });
        } else {
          handleChange(null);
        }
      }
    } else owntoast("Багц сонгоно уу.", "error");
  };

  const handleChange = (currentlicense) => {
    if (currentlicense)
      localStorage.setItem("currentlicense", JSON.stringify(currentlicense));
    else localStorage.removeItem("currentlicense");
    localStorage.setItem("license", JSON.stringify({ license, subLicense }));
    navigate("/licensepayment");
  };

  const getLicenseList = () => {
    navigate("/licenselist");
  };
  return (
    <div className="flex items-center justify-center h-full w-full sm:py-0 md:py-10">
      <div className="bg-white p-5 rounded-lg border border-gray-200 overflow-auto overflow-x-auto drop-shadow-xl h-full 2xl:w-4/6">
        <div
          className="p-2 bg-borderGrey absolute rounded rounded-10 cursor-pointer z-30"
          onClick={() => navigate("/")}
        >
          <img src={home} alt="" className="w-7" />
        </div>
        <div className="flex relative justify-center w-full">
          <p className="text-center text-license  text-2xl items-center">
            Үнийн санал
          </p>
          {props?.reslicense?.edate ? (
            <span className="absolute right-0">
              <span className="mr-2">Дуусах хугацаа:</span>
              {dayjs(props?.reslicense?.edate)?.format("YYYY-MM-DD")}
            </span>
          ) : null}
        </div>
        <div className="flex gap-4 justify-center flex-wrap">
          {mainLicenses?.map((item, i) =>
            item?.type === 1 && props?.reslicense ? null : (
              <License.LicenseCard
                key={i}
                reslicense={props?.reslicense}
                data={item}
                licenseday={props._auth.licenseday}
                checked={license?.id === item?.id}
                licenseChange={licenseChange}
              />
            )
          )}
        </div>
        <div className="flex items-center justify-center mt-10 flex-col">
          <div>
            <span className="text-2xl text-kitchenYellow">Нэмэлт эрх</span>
          </div>
          <div className="flex gap-4 mt-2">
            {subLicenses?.map((item, i) => (
              <License.LicenseSmallCard
                key={i}
                data={item}
                onChange={subLicenseChange}
                checked={
                  subLicense?.find((x) => x.id === item?.id) ? true : false
                }
              />
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            style={{ backgroundColor: "#F78C62", borderColor: "#F78C62" }}
            onClick={getLicenseList}
          >
            Миний авсан лиценз
          </Button>
          <Button onClick={handleNext} type="primary">
            Үргэлжлүүлэх
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
