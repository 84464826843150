import { Switch, Button } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const CheckOrBtn = ({
  condition,
  isDemo,
  checked,
  disabled = false,
  onChange,
  onClick,
}) => {
  const onChangeCheck = (e) => onChange?.(e);

  if (condition)
    return (
      <div className="flex justify-center">
        <div className="font-semibold">Сонгох:</div>
        <Switch
          checked={checked}
          onChange={onChangeCheck}
          className={`ml-4 ${checked ? "bg-primary" : "bg-fullBack"}`}
        />
      </div>
    );

  return (
    <div className="flex justify-center">
      <Button
        type="primary"
        className="bg-primary"
        onClick={onClick ? onClick : undefined}
        disabled={isDemo ? disabled : false}
      >
        {isDemo ? "Ашиглах" : "Идэвхтэй"}
      </Button>
    </div>
  );
};

const Index = (props) => {
  const { data, reslicense, checked, licenseChange, licenseday } = props;
  const navigate = useNavigate();
  const handleFree = () => {
    if (data?.type === 1) {
      navigate("/licensedemo");
    }
  };

  const isEnableCheck = () => {
    if (data?.id === reslicense?.productid) {
      if (licenseday <= 14) return true;
      return false;
    }
    return data?.price ? true : false;
  };
  return (
    <div
      className={`bg-white rounded-lg drop-shadow-xl w-72 ${
        data?.id === reslicense?.productid ? "border-2 border-primary" : ""
      }`}
    >
      <div className="p-5 flex flex-col justify-between h-full w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="text-lg font-medium text-black">{data?.name}</div>
          <div className="text-xl font-semibold text-primary uppercase">
            {data?.price
              ? `${new Intl.NumberFormat().format(data?.price)} ₮`
              : "үнэгүй"}
          </div>
          {data?.type !== 4 && (
            <div className="font-medium text-sm">{`${data?.text}`}</div>
          )}
          <div className="px-3 mt-2">
            <ul className="list-disc text-licenseText">
              {data?.about?.split("@")?.map((item, i) => (
                <li className="mt-0.5" style={{ fontSize: "0.7rem" }}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <CheckOrBtn
          checked={checked}
          onChange={(e) => licenseChange(e, data)}
          condition={isEnableCheck()}
          isDemo={!data?.price}
          disabled={reslicense?.eatbonus === 1}
          onClick={handleFree}
        />
      </div>
    </div>
  );
};

export default Index;
