import React from "react";
/* Дархад touchableopacity харуулдаг component */

class Ripple extends React.Component {
  initializeState = () => {
    return {
      spanStyles: {},
      count: 0,
    };
  };
  state = this.initializeState();
  bounce = null;

  showRipple = (e) => {
    const rippleContainer = e.currentTarget;
    const size = rippleContainer.offsetWidth;
    const pos = rippleContainer.getBoundingClientRect();
    const x = e.pageX - pos.x - size / 2;
    const y = e.pageY - pos.y - size / 2;

    const spanStyles = {
      top: y + "px",
      left: x + "px",
      height: size + "px",
      width: size + "px",
    };
    const count = this.state.count + 1;
    this.setState({
      spanStyles: { ...this.state.spanStyles, [count]: spanStyles },
      count: count,
    });
  };

  cleanUp = () => {
    const initialState = this.initializeState();
    this.setState({ ...initialState });
  };

  renderRippleSpan = () => {
    const { spanStyles = {} } = this.state;
    const spanArray = Object.keys(spanStyles);
    if (spanArray && spanArray.length > 0) {
      return spanArray.map((key, index) => {
        return (
          <span
            key={"spanCount_" + index}
            className=""
            style={{ ...spanStyles[key] }}
          ></span>
        );
      });
    } else {
      return null;
    }
  };

  callCleanUp = (cleanup, delay) => {
    clearTimeout(this.bounce);
    this.bounce = setTimeout(() => {
      cleanup();
    }, delay);
  };

  render() {
    const {
      children = null,
      className = "",
      onClick = null,
      style,
      disabled = false,
    } = this.props;
    return (
      <div
        className={"cursor-pointer ripple " + className}
        onClick={disabled ? null : onClick}
        style={style}
      >
        {children}
        <div
          className="rippleContainer"
          onMouseDown={this.showRipple}
          onMouseUp={() => this.callCleanUp(this.cleanUp, 3000)}
        >
          {this.renderRippleSpan()}
        </div>
      </div>
    );
  }
}

export default Ripple;
