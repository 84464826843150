import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import * as Components from "../../components";
import { setFoodModalCoumns } from "../../data/Columns";
function GlobalModal(props) {
  const [isconnect, setisconnect] = useState(false);

  const { content } = props;
  const [form] = Form.useForm();
  const [files, setFiles] = useState({});
  const [changed, setChanged] = useState({});

  useEffect(() => {
    if (!props.isOpen) {
      setFiles({});
    }
  }, [props.isOpen]);

  const onFinish = (e) => {
    form.resetFields();
    setFiles({});
    setisconnect(false);
    props.handleSave(e);
  };
  const onClose = () => {
    form.resetFields();
    props.onClose();
  };

  /* Баруун эсвэл Зүүн талд хамаарах Формуудын зурах хэсэг */
  const renderForms = (type) => {
    if (content && content[type]) {
      if (content[type].Component) {
        var SubComponent = content[type].Component;
        return (
          <SubComponent
            form={form}
            {...props.props}
            columns={content[type].columns}
            name={content[type].name}
            method={content[type].method}
            url={content[type].url}
            buttonText={content[type].buttonText}
            isModal={content[type].isModal}
          />
        );
      }
      return (
        content[type].form &&
        content[type].form.map((item, i) => (
          <Components.FormItems
            props={props.props}
            item={item}
            form={form}
            key={i}
            isconnect={!isconnect}
            setisconnect={setisconnect}
            files={files}
            setFiles={setFiles}
            setChanged={setChanged}
            changed={changed}
            setselectedFood={props.setselectedFood}
          />
        ))
      );
    }
  };
  return (
    <Components.CustomModal
      width={props.width}
      isOpen={props.isOpen}
      onCancel={() => onClose()}
      title={props.headerTitle}
      footer={!props.onlySee && null}
    >
      <Form onFinish={onFinish} form={form}>
        <div className="py-5 px-5 flex items-center justify-center">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          {/* Баруун талд харагдах форм - ууд зурах */}
          {props.isFull ? (
            <div>
              <Components.AntdTable
                columns={setFoodModalCoumns}
                dataSource={props ? props.props : []}
                pagination={false}
                isModal={true}
              />
            </div>
          ) : (
            <div className="grid grid-cols-12 gap-x-0 md:gap-x-10 gap-y-5">
              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-5 2xl:col-span-5">
                {renderForms("leftForms")}
              </div>
              {/* Зүүн талд харагдах форм - ууд зурах */}
              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7">
                {renderForms("rightForms")}
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full justify-center pb-8">
          <Button onClick={() => onClose()}>{props.closeBtnTxt}</Button>
          {!props.onlySee && (
            <Button
              type="primary"
              htmlType="submit"
              className="ml-4 bg-primary"
            >
              {props.saveBtnTxt}
            </Button>
          )}
        </div>
      </Form>
    </Components.CustomModal>
  );
}

export default GlobalModal;
