import React, { useEffect, useContext, useState } from "react";
import { Modal, Form, Checkbox, Button, Row, Col } from "antd";
import { AntdTable } from "../components";
import { NonCashColumns } from "../data/Columns";
import { GlobalContext } from "../context";
import { UserPerm } from "../utils/enums";
import { owntoast } from "../utils";
import * as Components from "../components";
import dayjs from "dayjs";
function Modals(props) {
  const context = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const formSubmit = (e) => {};
  useEffect(() => {
    if (props.selectedRow && props.type === "nonCash") {
      if (props.isOpen) {
        context
          .request({ url: `/sale/setlist/noncash/${props.selectedRow.id}` })
          .then((res) => {
            if (res.success) {
              setData(res.data);
              setName(res.data && res.data[0].name);
              setDate(res.data && res.data[0].saledate);
            } else {
              owntoast(res.message, "error");
            }
          });
      }
    } else if (props.selectedRow && props.type === "userPerm") {
      form.setFieldsValue({ ...props.selectedRow });
    }
  }, [props.isOpen]);

  const renderCheckBox = (e) => {
    let tmp = e.map((item, i) => {
      return (
        <Row className="mt-2">
          <Checkbox disabled key={i} value={item.value}>
            {`${item.label}`}
          </Checkbox>
        </Row>
      );
    });
    return tmp;
  };

  return (
    <Components.CustomModal
      title={
        props.type === "nonCash"
          ? "Бэлэн бусын төлбөрийн задаргаа"
          : "Хэрэглэгчийн эрх харах"
      }
      isOpen={props.isOpen ? true : false}
      onClose={props.closeModal}
      footer={null}
      width={500}
    >
      <Form onFinish={formSubmit} form={form} className="p-4">
        {props.type === "nonCash" ? (
          <div>
            <p>{`Касс : ${name}`}</p>
            <p>{`Огноо : ${dayjs(date).format("YYYY-MM-DD HH:mm:ss")}`}</p>
            <AntdTable columns={NonCashColumns} dataSource={data} isModal />
          </div>
        ) : (
          <div>
            <p className="font-semibold">{`Хэрэглэгчийн нэр : ${
              props.selectedRow && props.selectedRow.name
            }`}</p>
            <p className="font-semibold">{`Бүртгүүлсэн огноо : ${
              props.selectedRow &&
              dayjs(props.selectedRow.insymd).format("YYYY-MM-DD HH:mm:ss")
            }`}</p>
            <div className="">
              <p className="font-semibold">Цонхны эрхүүд :</p>
              <Form.Item name="permission" className="align-center">
                <Checkbox.Group>
                  <Col>{renderCheckBox(UserPerm)} </Col>
                </Checkbox.Group>
              </Form.Item>
            </div>
          </div>
        )}

        <div className="justify-end flex w-full">
          <Button
            onClick={props.closeModal}
            style={{ backgroundColor: "#595959", color: "white" }}
          >
            Xaax
          </Button>
        </div>
      </Form>
    </Components.CustomModal>
  );
}
export default Modals;
