import { InputNumber, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { owntoast, splitString } from "../../../utils";

import trash from "../../../assets/trash.png";
const Index = (props) => {
  const [qty, setQty] = useState(1);
  const [visible, setVisible] = useState(false);

  const onChangeQty = (e) => {
    setQty(e);
  };

  const changeQty = (type) => {
    if (props?.data?.type === 3) {
      if (type)
        if (qty < 10) setQty((prev) => prev + 1);
        else owntoast("Хамгийн ихдээ 10 хэрэглэгч авна.", "error");
      else if (qty > 1) setQty((prev) => prev - 1);
      else owntoast("0 эсвэл хасах утга оруулж болохгүй", "error");
    }
  };

  useEffect(() => {
    if (props?.data?.type === 3) {
      props.data.qty = qty;
      if (props?.setLicense && props?.license) {
        props?.setLicense({ ...props?.license });
      }
    }
  }, [qty]);

  if (!props?.data) return null;

  if (props?.isUser) {
    return (
      <div className=" flex items-center justify-between text-md border-b py-1">
        <span className="w-20">{props?.data?.name}</span>
        <div className="font-bold flex items-center w-fit ">
          <span>
            {`${new Intl.NumberFormat().format(
              props?.userLicense.price || 0
            )}₮`}
          </span>
          <img
            className="w-4 h-4 ml-3 cursor-pointer"
            src={trash}
            alt="trash"
            onClick={() => {
              if (!props?.disabled) {
                props?.handleRemove({
                  id: props?.data?.id,
                  isUser: props?.isUser,
                });
                return;
              }
            }}
          />
        </div>
      </div>
    );
  }

  if (props?.isKiosk) {
    return (
      <div className=" flex items-center justify-between text-md border-b">
        <div className="flex items-center">
          <span className="w-20">{props?.data?.name}</span>
          <div className="flex items-center">
            <span className="ml-4 w-32">
              {splitString(props?.data?.dkey, "-", visible)}
            </span>
            <Button type="text" onClick={() => setVisible(!visible)}>
              {!visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </Button>
          </div>
        </div>

        <div className="font-bold flex items-center w-fit ">
          <span>
            {`${new Intl.NumberFormat().format(
              props?.kioskLicense.price || 0
            )}₮`}
          </span>
          <img
            className="w-4 h-4 ml-3 cursor-pointer"
            src={trash}
            alt="trash"
            onClick={() => {
              if (!props?.disabled) {
                props?.handleRemove({
                  id: props?.data?.id,
                  isKiosk: props?.isKiosk,
                });
                return;
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between border-b pb-0.5">
        <div className="flex items-center">
          <span>{props?.data?.name}</span>
          {props?.data?.type === 3 ? (
            <div className="flex items-center">
              <span className="ml-2 text-gray-400">x</span>
              <InputNumber
                className="text-primary w-12"
                min={1}
                value={qty}
                onChange={onChangeQty}
                max={10}
                size="small"
                defaultValue={1}
                bordered={false}
              />
            </div>
          ) : null}
        </div>
        <span className="font-semibold">
          {new Intl.NumberFormat().format(
            (props?.data?.price || 0) * (props?.data?.qty || 1)
          )}
          ₮
        </span>
      </div>
      {props?.data?.type === 3 ? (
        <div className="flex justify-between mt-2">
          <Button
            type="dashed"
            onClick={() => changeQty(0)}
            className="text-kitchenYellow border-kitchenYellow"
          >
            Хэрэглэгч хасах
          </Button>
          <Button
            className="text-kitchenYellow border-kitchenYellow"
            type="dashed"
            onClick={() => changeQty(1)}
          >
            Хэрэглэгч нэмэх
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Index;
