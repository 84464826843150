import pp from "../../assets/icons/PP.svg";

function CashierItem({ data }) {
  return (
    <div className="mt-3 flex items-center justify-between">
      <div className="flex">
        <img src={pp} alt="" className="w-8" />
        <div className="flex flex-col ml-2">
          <span className="font-bold">{data && data.name}</span>
          <span className="text-grey">{data && data.rolename && data.rolename.replace("_", " ")}</span>
        </div>
      </div>
      <span className="font-bold text-lg">{Intl.NumberFormat().format(data.amount ? data.amount : 0)}</span>
    </div>
  );
}

export default CashierItem;
