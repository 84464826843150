import { useState, useEffect } from "react";

const useMinuteListener = () => {
  const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes());

  useEffect(() => {
    const checkMinuteChange = () => {
      const newMinute = new Date().getMinutes();
      if (newMinute !== currentMinute) {
        setCurrentMinute(newMinute);
      }
    };

    checkMinuteChange();

    const intervalId = setInterval(checkMinuteChange, 1000);

    return () => clearInterval(intervalId);
  }, [currentMinute]);

  return currentMinute;
};

export default useMinuteListener;
