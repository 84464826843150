import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Input, Form, Select, Button } from "antd";
import { owntoast } from "../../utils";
function Register(props) {
  const { Option } = Select;
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [classcodes, setClassCodes] = useState([]);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [commitee, setCommitee] = useState([]);
  const [proId, setProId] = useState(0);
  const [isBranch] = useState(props.isBranch ? true : false)
  const datas = JSON.parse(localStorage.getItem("register"));
  const [form] = Form.useForm();

  /* Үйл ажиллагааны чиглэл, аймаг хот -ийн хүсэлт дуудаж байгаа хэсэг */
  useEffect(() => {
    document.title = "Бүртгүүлэх /Ерөнхий мэдээлэл/";
    props.request({ url: `/system/classcodes` }).then((res) => {
      if (res.success) {
        setClassCodes(res.data);
      } else {
        owntoast(res.message, "error");
      }
    });
    props.request({ url: `/system/location/province` }).then((res) => {
      if (res.success) {
        setProvince(res.data);
      } else {
        owntoast(res.message, "error");
      }
    });
    if (isBranch) {
      form.setFieldsValue({
        name: props._auth.foodsname
      })
    }
    if (isBranch && datas) {
      form.setFieldsValue({
        name: datas.name,
        classid: datas.classid,
        address: datas.address,
      })
    }
  }, []);

  /* Аймаг хот сонгосон үед түүнд харъялагдах сум дүүрэг дуудаж байгаа хүсэлт*/
  const changeProvince = (e) => {
    setProId(e);
    props.request({ url: `/system/location/district/${e}` }).then((res) => {
      if (res.success) {
        setDistrict(res.data);
      } else {
        owntoast(res.message, "error");
      }
    });
  };
  /* Сум дүүрэг сонгосон үед түүнд харъялагдах баг хороо дуудаж байгаа хүсэлт*/
  const changeDistrict = (e) => {
    props
      .request({ url: `/system/location/commitee/${proId}/${e}` })
      .then((res) => {
        if (res.success) {
          setCommitee(res.data);
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const renderOptions = (data, label, value) =>
    data &&
    data.map((item, i) => (
      <Option key={i} value={item[value]}>
        {`${item[label]}`}
      </Option>
    ));

  const handleNext = (e) => {
    let tmp = {
      name: e.name,
      classid: e.classid,
      locid: e.locid,
      address: e.address,
    };
    localStorage.setItem("register", JSON.stringify(tmp));
    if (isBranch) {
      props.setStep(1);
      props.setProcent(0);
      navigate("/addBranch/subinfo")
    } else {
      navigate("/register/subinfo");
    }
  };
  const changeInput = (e, name) => {
    let check = form.getFieldValue(name);
    if (check && name === "name") {
      props.setProcent(16);
    } else if (check && name === "classid") {
      props.setProcent(32);
    } else if (check && name === "province") {
      props.setProcent(48);
    } else if (check && name === "district") {
      props.setProcent(64);
    } else if (check && name === "locid") {
      props.setProcent(82);
    } else if (check && name === "address") {
      props.setProcent(100);
    }
  }
  return (
    <div className="items-center flex flex-col text-center">
      <p className="font-bold text-lg">ЕРӨНХИЙ МЭДЭЭЛЭЛ</p>
      <Form className="w-full max-w-sm mx-auto  3xl: items-center flex flex-col" onFinish={handleNext}
        form={form}>
        <div className=" w-full text-left">
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Байгууллагын нэр оруулна уу" }]}
          >
            <Input placeholder="Байгууллагын нэр" />
          </Form.Item>
          <Form.Item
            name="classid"
            rules={[{ required: true, message: "Чиглэл оруулна уу" }]}
          >
            <Select placeholder="Чиглэл" showSearch optionFilterProp="children" onFocus={isBranch ? (e) => changeInput(e, "name") : null}>
              {renderOptions(classcodes, "uname", "id")}
            </Select>
          </Form.Item>
          <Form.Item
            name="province"
            rules={[{ required: true, message: "Хот/Аймаг оруулна уу" }]}
          >
            <Select
              placeholder="Хот/Аймаг"
              showSearch
              optionFilterProp="children"
              onChange={changeProvince}
              onFocus={isBranch ? (e) => changeInput(e, "classid") : null}
            >
              {renderOptions(province, "provincenm", "provinceid")}
            </Select>
          </Form.Item>
          <div className="grid grid-cols-2">
            <Form.Item
              name="district"
              rules={[{ required: true, message: "Дүүрэг/Сум оруулна уу" }]}
            >
              <Select
                placeholder="Дүүрэг/Сум"
                showSearch
                optionFilterProp="children"
                onChange={changeDistrict}
                onFocus={isBranch ? (e) => changeInput(e, "province") : null}
              >
                {renderOptions(district, "districtnm", "districtid")}
              </Select>
            </Form.Item>
            <Form.Item
              name="locid"
              rules={[{ required: true, message: "Баг/Хороо оруулна уу" }]}
            >
              <Select
                placeholder="Баг/Хороо"
                showSearch
                optionFilterProp="children"
                onFocus={isBranch ? (e) => changeInput(e, "district") : null}
              >
                {renderOptions(commitee, "commiteenm", "id")}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="address"
            className="textarea"
            rules={[{ required: true, message: "Дэлгэрэнгүй хаяг оруулна уу" }]}
          >
            <TextArea
              className="textarea"
              placeholder="Дэлгэрэнгүй хаяг"
              onFocus={isBranch ? (e) => changeInput(e, "locid") : null}
              onBlur={isBranch ? (e) => changeInput(e, "address") : null}
              rows={2}
            ></TextArea>
          </Form.Item>
          <Button
            className="bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm"
            type="primary"
            htmlType="submit"
          >
            ҮРГЭЛЖЛҮҮЛЭХ
          </Button>
        </div>
        {!isBranch ?
          <p color="black" className="mt-2 3xl:mt-8">
            Бүртгэлээрээ <Link to="/login">Нэвтрэх</Link>
          </p> : null}
      </Form>
    </div>
  );
}

export default Register;
