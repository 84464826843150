import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { Modal, Button } from "antd";
import { API_URL } from "../../utils";
import CustomModal from "../CustomModal";
function DragDrop(props) {
  const [mainData, setData] = useState([]);

  useEffect(() => {
    if (!props.props.rescategorylist) {
      if (props.props.method && props.props.url) {
        let body = {
          foodid: props.props.resuserconfig.foodid,
          count: 1000,
          materialid: 0,
          pagenumber: 0,
        };
        props.props
          .request({ url: `/category/list`, body: body, method: "POST" })
          .then((res) => {
            if (res.success) {
              setData(res.data);
            }
          });
      }
    } else {
      setData(props.props.rescategorylist);
    }
  }, [props.isOpen]);

  const finishSort = () => {
    let tmp = [];
    mainData.map((item, i) => tmp.push({ catid: item.id, order: i }));
    props.props
      .request({ url: `/category/orderby`, body: tmp, method: "PUT" })
      .then((res) => {
        if (res.success) {
          props.onClose();
        }
      });
  };

  return (
    <CustomModal
      width={400}
      title="Ангилал эрэмблэх"
      bodyStyle={{ overflow: "auto", height: window.innerHeight * 0.7 }}
      footer={[
        <Button
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Хаах
        </Button>,
        <Button
          type="primary"
          className="bg-primary"
          autoFocus
          onClick={finishSort}
        >
          Хадгалах
        </Button>,
      ]}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <div className="items-center justify-center h-full px-3">
        <ReactSortable tag="ul" list={mainData} setList={setData}>
          {mainData.map((item) => (
            <li
              key={item.id}
              className="rounded-md border border-gray-200 drop-shadow-xl grid grid-cols-2 cursor-grab mb-2"
            >
              <img
                className="h-24 w-24 rounded-md"
                src={`${API_URL}${item.icon}`}
              />
              <div>
                <p className="font-semibold">{item.name}</p>
                <p>{item.description}</p>
              </div>
            </li>
          ))}
        </ReactSortable>
      </div>
    </CustomModal>
  );
}

export default DragDrop;
