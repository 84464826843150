import { useState, useEffect } from "react";
import { Layout, Breadcrumb } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as Components from "../";

const { Header } = Layout;
/* Цонхны нэрнүүд зурах */
function BreadCrumbs({ menus }) {
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  /* Route солигдох үе ямар меню рүү орсон бэ гэдгийг мэдэрч цонхны нэрнүүдийг өөрчилнө */
  useEffect(() => {
    if (menus) {
      let names = [{ title: menus.name }];
      const urls = params["*"].split("/");
      if (menus.menus) {
        let sub = menus.menus.find((a) => a.id === Number(urls[0]));
        if (sub) {
          if (sub.menus) {
            let child = sub.menus.find((a) => a.id === Number(urls[1]));
            if (child) names.push({ title: child.name });
          } else names.push({ title: sub.name });
        }
      }
      if (urls[2] && urls[2] === "new") names.push({ title: "Шинэ" });
      else if (urls[2] && urls[2] === "edit") names.push({ title: "Засах" });
      if (names) {
        document.title = names[names.length - 1]?.title;
      }
      setBreadCrumbs(names);
    }
  }, [location.pathname, params, menus]);

  return (
    <Header className="bg-white flex items-center px-2 lg:px-6 h-12">
      <div style={{ width: "200px" }}>
        <Components.Ripple
          className="px-2 py-2 w-fit rounded-md uppercase text-grey font-semibold text-xs flex items-center"
          onClick={() => navigate("/")}
        >
          <ArrowLeftOutlined className="mr-2" /> Үндсэн цэс
        </Components.Ripple>
      </div>
      <Breadcrumb items={breadCrumbs} />
    </Header>
  );
}

export default BreadCrumbs;
