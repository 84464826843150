import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";
import AddMarker from "./AddMarker";
import { Form, Input } from "antd";

function Maps(props, form) {
  const { resuserconfig } = props;
  const [value, setValue] = useState(null);
  const center = [47.898469, 106.912702];
  L.Marker.prototype.options.icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
  });
  useEffect(() => {
    if (!props.type && resuserconfig) {
      if (resuserconfig.longitude && resuserconfig.latitude) {
        let tmp = {
          lat: Number(resuserconfig.latitude),
          lng: Number(resuserconfig.longitude),
        };
        setValue(tmp);
      }
    }
  }, []);

  useEffect(() => {
    if (value) {
      props.form.setFieldsValue({
        latitude: value && value.lat && value.lat.toString(),
        longitude: value && value.lng && value.lng.toString(),
      });
    }
  }, [value]);

  const changePosition = (e) => {
    setValue(e);
  };
  return (
    <div className="w-full h-full">
      <MapContainer center={center} zoom={5} className="w-full h-96">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <AddMarker
          setLatLng={props.setLatLng ? props.setLatLng : changePosition}
          defaultValue={value}
        />
      </MapContainer>
      <Form.Item hidden initialValue={value && value.lat} name="latitude">
        <Input />
      </Form.Item>
      <Form.Item hidden initialValue={value && value.lng} name="longitude">
        <Input />
      </Form.Item>
    </div>
  );
}
export default Maps;
