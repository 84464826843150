import React from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined, StopOutlined, SaveOutlined } from "@ant-design/icons";
import {
  CONFIRMPASSWORD_VALIDATOR,
  CREATEPASSWORD_VALIDATOR,
  owntoast,
} from "../../utils/index";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { SettingsPerm, LicenseType, NLicenceType } from "../../utils/enums";
import * as Components from "../../components";

function Modals(props) {
  const [form] = Form.useForm();
  let data = JSON.parse(localStorage.getItem("editRow"));
  const formSubmit = (e) => {
    if (check()) {
      if (e.oldpassword !== e.confirmpassword) {
        let tmp = {
          foodid: data.foodid,
          userid: data.id,
          insby: props._auth.userid,
          newpass: e.confirmpassword,
        };
        props
          .request({
            url: `/user/changepass/${tmp.foodid}/${tmp.userid}/${tmp.newpass}/${tmp.insby}`,
            method: "PUT",
          })
          .then((res) => {
            if (res.success) {
              owntoast(res.message, "success");
              form.resetFields();
              props.closeModal(true);
            } else {
              owntoast(res.message, "error");
            }
          });
      } else {
        owntoast("Хуучин нууц үг дахин оруулах боломжгүй", "error");
      }
    }
  };

  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: SettingsPerm,
      license: NLicenceType.systemconfig,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  return (
    <Components.CustomModal
      title="Нууц үг сэргээх"
      isOpen={props.isOpen ? true : false}
      onClose={() => props.closeModal(true)}
      footer={null}
      width={600}
    >
      <Form
        onFinish={formSubmit}
        form={form}
        className="p-4"
        initialValues={{
          phone: data && data.uname,
        }}
      >
        <div>
          <Form.Item label="Нэвтрэх нэр" name="phone">
            <Input disabled style={{ width: 350, float: "right" }} />
          </Form.Item>
          <Form.Item
            label="Шинэ нууц үг"
            name="password"
            rules={CREATEPASSWORD_VALIDATOR}
          >
            <Input.Password
              style={{ width: 350, float: "right" }}
              placeholder="Шинэ нууц үг"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item
            label="Шинэ нууц үг (давтах)"
            name="confirmpassword"
            rules={CONFIRMPASSWORD_VALIDATOR}
          >
            <Input.Password
              style={{ width: 350, float: "right" }}
              placeholder="Шинэ нууц үг (давтах)"
              prefix={<LockOutlined />}
            />
          </Form.Item>
        </div>
        <div
          style={{
            justifyContent: "end",
            display: "flex",
            width: "100%",
            marginRight: 15,
          }}
        >
          <Button
            icon={<StopOutlined />}
            onClick={() => props.closeModal(true)}
            style={{ backgroundColor: "#595959", color: "white" }}
          >
            Болих
          </Button>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
            className="bg-primary"
            style={{ marginLeft: 5 }}
          >
            Хадгалах
          </Button>
        </div>
      </Form>
    </Components.CustomModal>
  );
}
export default Modals;
