import { useState, useEffect, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { getBase64 } from "../../utils";
import {
  PlusOutlined,
  MinusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { Modal, Button, Slider } from "antd";
import Ripple from "../Ripple";
import CustomModal from "../CustomModal";
const TO_RADIANS = Math.PI / 180;

const canvasPreview = (image, canvas, crop, scale = 1, rotate = 0) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const ImgCropModal = ({ children, file, name, onChange }) => {
  const scaleStep = 0.1;
  const scaleMax = 3;
  const rotateMax = 360;
  const rotateStep = 1;
  const [isOpen, setIsOpen] = useState(false);
  const [imgUri, setImg] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef();
  const previewCanvasRef = useRef();

  const [crop, setCrop] = useState(null);

  useEffect(() => {
    if (!isOpen && file) {
      setIsOpen(!isOpen);
      getBase64(file, (imageUrl) => {
        setImg(imageUrl);
      });
    }
  }, [file]);

  const closeModal = () => {
    setIsOpen(false);
  };

  useDebounceEffect(
    async () => {
      if (
        crop?.width &&
        crop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          crop,
          scale,
          rotate
        );
      }
    },
    100,
    [crop, scale, rotate]
  );

  const controlScale = (type) => {
    if (type === "minus" && scale > 0) {
      setScale((prev) => prev - scaleStep);
    } else if (type === "plus" && scale < scaleMax) {
      setScale((prev) => prev + scaleStep);
    }
  };

  const controlRotate = (type) => {
    if (type === "left" && rotate > 0) {
      setRotate((prev) => prev - rotateStep);
    } else if (type === "right" && rotate < rotateMax) {
      setRotate((prev) => prev + rotateStep);
    }
  };

  const onImageLoad = (e) => {
    /*  setCrop({
      unit: "%",
      x: 25,
      y: 25,
      width: 50,
      height: 50,
    }); */
    /* const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 4 / 4)); */
  };

  const submitImg = () => {
    if (previewCanvasRef && previewCanvasRef.current && file) {
      previewCanvasRef.current.toBlob(
        (blob) => {
          const newImage = new File([blob], file.name, {
            type: blob.type,
          });
          setCrop(null);
          onChange(newImage, name);
          closeModal();
        },
        "image/jpg",
        1
      );
    } else if (file) {
      setCrop(null);
      onChange(file, name);
      closeModal();
    }
  };

  return (
    <div>
      {children}
      <CustomModal
        title="Зураг оруулах"
        onClose={closeModal}
        isOpen={isOpen}
        footer={[
          <Button onClick={closeModal}>Буцах</Button>,
          <Button
            type="primary"
            className="bg-primary"
            autoFocus
            onClick={submitImg}
          >
            Батлах
          </Button>,
        ]}
      >
        {file && (
          <div className="flex flex-col items-center justify-center">
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
              <img
                src={imgUri}
                ref={imgRef}
                onLoad={onImageLoad}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              />
            </ReactCrop>
            {crop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: crop.width,
                  height: crop.height,
                  display: "none",
                }}
              />
            )}
            <div className="w-1/2">
              <div className="flex items-center justify-center w-full gap-2 pt-5">
                <Ripple
                  className="p-1 rounded-md flex items-center justify-center"
                  onClick={() => controlScale("minus")}
                >
                  <MinusOutlined className="text-primary" />
                </Ripple>
                <Slider
                  min={0}
                  className="w-full"
                  max={scaleMax}
                  value={scale}
                  step={scaleStep}
                  onChange={(value) => setScale(value)}
                />
                <Ripple
                  className="p-1 rounded-md flex items-center justify-center"
                  onClick={() => controlScale("plus")}
                >
                  <PlusOutlined className="text-primary" />
                </Ripple>
              </div>
              <div className="flex items-center justify-center w-full gap-2 pt-3 pb-5">
                <Ripple
                  className="p-1 rounded-md flex items-center justify-center"
                  onClick={() => controlRotate("left")}
                >
                  <RotateLeftOutlined className="text-primary" />
                </Ripple>
                <Slider
                  step={rotateStep}
                  className="w-full"
                  value={rotate}
                  min={0}
                  onChange={(value) => setRotate(value)}
                  max={rotateMax}
                />
                <Ripple
                  className="p-1 rounded-md flex items-center justify-center"
                  onClick={() => controlRotate("right")}
                >
                  <RotateRightOutlined className="text-primary" />
                </Ripple>
              </div>
            </div>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default ImgCropModal;
