import UsefulFunctions from "../../utils/UsefulFunctions";

const calculateSaleData = (product, type) => {
  if (product.isset === 1) {
    let setAmount = 0;
    if (product.setdetails && product.setdetails.length) {
      setAmount = product.setdetails.reduce(
        (totalHolder, item) => totalHolder + item.price * item.buyqty,
        0
      );
    }
    let diffAmt = setAmount - product.price;

    product.setdetails &&
      product.setdetails.map((item) => {
        if (type === "minus") {
          item.qty = item.qty - item.buyqty;
        } else if (type === "plus") {
          item.qty = item.qty === 0 ? item.buyqty : item.qty + item.buyqty;
        } else if (type === "qty") {
          item.qty = product.qty * item.buyqty;
        }
        let procent = (100 / setAmount) * item.price;
        let discount = Math.round((diffAmt / 100) * procent); //23.63
        item.unitdiscount = discount;
        calcualteDiscount(item);
      });
  }
  calcualteDiscount(product);
};

const calcualteDiscount = (product) => {
  if (!product.istime) {
    product.amount =
      product.price * product.qty - product.unitdiscount * product.qty;
    product.discount = product.unitdiscount * product.qty;
    product.totalamount = product.price * product.qty;
  } else {
    product.discount = 0;
    product.totalamount = product.amount;
  }
};

const removeProductToList = (action, saleList) => {
  let newSaleList = [];
  if (action.response !== "clear") {
    if (action.response.isset === 1) {
      newSaleList = saleList.filter((a) => {
        let isValid = UsefulFunctions.checkSetProducts(
          a.setdetails,
          action.response.setdetails
        );
        if (a.setid !== action.response.setid || !isValid) {
          return a;
        }
      });
    } else {
      newSaleList = saleList.filter(
        (a) =>
          a.productid !== action.response.productid ||
          a.spiceid !== action.response.spiceid ||
          a.istime !== action.response.istime
      );
    }
  }

  return newSaleList;
};

const addProductToList = (action, saleList) => {
  let product = null;
  if (action.response.isset) {
    let setProduct = saleList.filter((a) => a.setid === action.response.setid);
    let isValid = false;
    if (setProduct) {
      setProduct.map((set) => {
        isValid = UsefulFunctions.checkSetProducts(
          set.setdetails,
          action.response.setdetails
        );
        if (isValid) {
          product = set;
          return;
        }
      });
    }
  } else {
    if (action.response?.istime) {
      product = saleList.find(
        (a) =>
          a.productid === action.response.productid &&
          a.istime === action.response.istime
      );
    } else {
      product = saleList.find(
        (a) =>
          a.productid === action.response.productid &&
          a.spiceid === action.response.spiceid
      );
    }
  }

  if (product) {
    if (action.response.type === "plus") {
      if (!action.response?.istime) product.qty++;
      else {
        product.qty = action?.response?.qty;
        product.amount = action?.response?.amount;
      }
      UsefulFunctions.calculateSaleData(product, action.response.type);
    } else if (action.response.type === "minus") {
      product.qty--;
      UsefulFunctions.calculateSaleData(product, action.response.type);
    } else if (action.response.type === "discount") {
      product.unitdiscount = action.response.unitdiscount;
      UsefulFunctions.calculateSaleData(product, action.response.type);
    } else if (action.response.type === "qty") {
      product.qty = action.response.qty;
      UsefulFunctions.calculateSaleData(product, action.response.type);
    }
    delete product.type;
  } else {
    if (action.response?.isset && action.response?.setdetails) {
      action.response?.setdetails?.map((item) => (item.qty = 0));
    }
    UsefulFunctions.calculateSaleData(action.response, action.response.type);
  }

  if (action.response) delete action.response.type;
  return product;
};

export default function appReducer(state, action) {
  const type = action.type.split("_")[0];
  const model = action.type.split("_")[1];
  switch (type) {
    case "request": {
      if (model !== "kitchenList" && model !== "kitchenfoods") {
        state[`res${model}`] = [];
      }

      state[`is${model}`] = true;
      return { ...state };
    }
    case "error": {
      state[action.type] = action.error;
      state[`err_${model}`] = false;
      return { ...state };
    }
    case "response": {
      state[`res${model}`] = action.response.data;
      state[`is${model}`] = false;
      return { ...state };
    }
    case "setdata": {
      state[`res${model}`] = action.response;
      return { ...state };
    }
    case "login": {
      return {
        // ...state,
        /* islogin: !!JSON.parse(localStorage.getItem("auth")),
        _auth: JSON.parse(localStorage.getItem("auth")), */
        products: [],
        notification: false,
        lang: "mn",
        filter: undefined,
        saleList: [],
        order: {},
        errorMsg: "",
        isOpenError: false,
        isOpenArtlab: false,
        islogin: action.response,
        _auth: action.response
          ? JSON.parse(localStorage.getItem("auth"))
          : undefined,
      };
    }
    case "products": {
      return { ...state, products: action.response };
    }
    case "filter": {
      return { ...state, filter: action.response };
    }
    case "addSaleList": {
      const product = addProductToList(action, state?.saleList);
      return {
        ...state,
        saleList: product
          ? [...state.saleList]
          : [{ ...action.response }, ...state.saleList],
      };
    }
    case "divideList": {
      const product = addProductToList(action, state?.divideList);
      return {
        ...state,
        divideList: product
          ? [...state.divideList]
          : [{ ...action.response }, ...state.divideList],
      };
    }
    case "setOrderDetail": {
      return {
        ...state,
        prevDetail: action.response.detail.map((step) => ({ ...step })),
        prevOrder: { ...action.response.order },
        saleList: action.response.detail,
        order: action.response.order,
      };
    }
    case "removeSaleList": {
      const newSaleList = removeProductToList(action, state.saleList);
      return { ...state, saleList: [...newSaleList] };
    }
    case "removeDivideList": {
      const newSaleList = removeProductToList(action, state.divideList);
      return { ...state, divideList: [...newSaleList] };
    }
    case "auth": {
      return {
        ...state,
        _auth: action.response,
      };
    }
    case "notification": {
      return {
        ...state,
        notification: action.response,
      };
    }
    case "setError": {
      return {
        ...state,
        errorMsg: action.response.message,
        isOpenError: action.response.isOpen,
        isLicenseModal:
          action.response.isLicenseModal === undefined
            ? false
            : action.response.isLicenseModal,
        isArtlabModal:
          action.response.isArtlabModal === undefined
            ? false
            : action.response.isArtlabModal,
      };
    }
    case "setGuide": {
      return {
        ...state,
        guideContent: action.response,
      };
    }
    case "banners": {
      return {
        ...state,
        resbanners: action.response,
      };
    }
    default:
      return state;
  }
}
