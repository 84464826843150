import { RingProgress } from "@ant-design/plots";
import Correct from "../../assets/icons/correct.png";

const KitchenRing = ({ percentage }) => {
  const getText = () => {
    if (percentage) {
      if (percentage.checked === percentage.sum)
        return `<img src=${Correct} class="ring-correct"/>`;
      else return `${percentage.checked} / ${percentage.sum}`;
    }
    return null;
  };

  const getPercentage = () => {
    if (percentage) {
      return (percentage.checked * 100) / percentage.sum / 100;
    }
    return 0;
  };

  const config = {
    height: 30,
    width: 30,
    autoFit: false,
    percent: getPercentage(),
    color: ["#51B06D", "#E8EDF3"],
    innerRadius: 0.9,
    radius: 0.98,
    statistic: {
      content: null,
      title: {
        style: {
          color: "#363636",
          fontSize: "12px",
        },
        formatter: () => getText(),
      },
    },
  };
  return <RingProgress {...config} />;
};

export default KitchenRing;
