import React, { useState, useEffect } from "react";
import * as Components from "../../components";
import { Input, Form, Checkbox, Button, message } from "antd";
import logo1 from "../../assets/logos/logo_word.png";
import logo from "../../assets/login/logoo.png";
import bolat from "../../assets/loginPic.png";
import { UserOutlined, LockOutlined, BankOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { PASSWORD_VALIDATOR, isNewYear, owntoast } from "../../utils/index";
import hatImg from "../../assets/hat.png";
function Login(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Нэвтрэх";
  }, []);

  const handleLogin = (e) => {
    let tmp = {
      foodid: Number(e.foodid),
      username: e.username.toString(),
      password: e.password,
    };
    setLoading(true);
    props.request({ url: `/auth`, method: "POST", body: tmp }).then((res) => {
      setLoading(false);
      if (res.success) {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("auth", JSON.stringify(res.data));
        props.setlogin(true);
        owntoast(res.message, "success");
        localStorage.setItem("name", JSON.stringify(res.data.foodsname));
        if (e.remember) {
          localStorage.setItem("foodid", e.foodid);
          localStorage.setItem("username", e.username);
        } else {
          localStorage.removeItem("foodid");
          localStorage.removeItem("username");
        }
        navigate("/");
        props
          .request({ url: `/system/banners/${e.foodid}` })
          .then((bannerres) => {
            if (bannerres.success) {
              if (bannerres.data && bannerres.data.length) {
                props.setData({
                  data: bannerres.data,
                  model: "setdata_banners",
                });
                props.setNotification(true);
              } else {
                if (res.data.licensetext) {
                  props.setError({
                    isOpen: true,
                    message: res.data.licensetext,
                    isLicenseModal: res.data.licenseday <= 3,
                  });
                }
              }
            }
          });
      } else {
        owntoast(res.message, "error");
      }
    });
  };
  return (
    // <div className="flex items-center h-full justify-center bg-white">
    <Components.LoginContainer>
      {/* <div className="col-span-2 w-full xl:w-4/5 2xl:w-full sm:hidden lg:block">
            <p className="font-bold text-lg">ТУРШААД ҮЗ</p>
            <p className="text-gray-500 text-md mx-auto xl:w-8/12 2xl:w-10/12">
              24 цаг тасралтгүй Онлайн горимоор ажиллан захиалга, борлуулалтаа
              хялбараар удирдах хоол захиалгын “Оньс Фүүд” системийг танд санал
              болгож байна.
            </p>
            <img className="mx-auto w-80" src={bolat} alt="logo" />
          </div> */}

      <div className="sm:col-span-3 lg:col-span-1 items-center flex flex-col w-full lg:mr-16 sm:mt-24 lg:mt-0">
        <div className="relative">
          {/* {isNewYear() ? (
            <img
              src={hatImg}
              alt="hatImg"
              className="absolute -top-5 right-3 w-14 h-14 rotate-45"
            />
          ) : null} */}

          <img className="w-36 h-30" src={logo} alt="logo" />
          {/* <img className="w-36 h-36" src={logo1} alt="logo" /> */}
        </div>
        <Form
          // className="sm:w-full md:w-80 lg:w-80 xl:w-full mt-4 3xl:mt-12 items-center flex flex-col"
          className="w-full max-w-sm mx-auto mt-4 3xl:mt-12 items-center flex flex-col"
          onFinish={handleLogin}
          initialValues={{
            foodid: localStorage.getItem("foodid")
              ? localStorage.getItem("foodid")
              : "",
            username: localStorage.getItem("username")
              ? localStorage.getItem("username")
              : "",
            remember: localStorage.getItem("username") ? true : false,
          }}
        >
          <div className="w-full px-4 sm:px-2 md:px-0">
            <Form.Item
              name="foodid"
              rules={[{ required: true, message: "Код оруулна уу" }]}
              className="w-full mb-4"
            >
              <Input
                placeholder="Код"
                prefix={<BankOutlined style={{ color: "#C62031" }} />}
              />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[{ required: true, message: "Нэвтрэх нэр оруулна уу" }]}
            >
              <Input
                placeholder="Нэвтрэх нэр"
                prefix={<UserOutlined style={{ color: "#C62031" }} />}
              />
            </Form.Item>

            <Form.Item name="password" rules={PASSWORD_VALIDATOR}>
              <Input.Password
                placeholder="Нууц үг"
                prefix={<LockOutlined style={{ color: "#C62031" }} />}
              />
            </Form.Item>

            <Form.Item
              className="text-right"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Сануулах</Checkbox>
            </Form.Item>
            <Button
              className="bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Нэвтрэх
            </Button>
          </div>
        </Form>
        <div className="w-full max-w-sm mx-auto text-right flex flex-col mt-2 3xl:mt-8">
          <Components.Ripple
            color="black"
            className="text-gray-400"
            onClick={() => navigate("/register/forgetpass")}
          >
            Нууц үгээ мартсан
          </Components.Ripple>
          <p color="black" className="mt-2 3xl:mt-8">
            Та Оньс системд бүртгэлгүй юу?{" "}
            <Link to="/register/maininfo">Бүртгүүлэх</Link>
          </p>
        </div>
      </div>
    </Components.LoginContainer>
    // </div>
  );
}

export default Login;
