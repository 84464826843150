import { useState } from "react";
import { Button, Form } from "antd";
import FoodInfo from "../FoodInfo";
import { useEffect } from "react";
import { owntoast } from "../../utils";
const Index = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const saveLocation = (values) => {
    if (props?.order?.id !== undefined) {
      const params = {
        orderid: props?.order?.id,
        locid: values?.locid,
        address: values?.address,
      };
      setLoading(true);
      props
        .request({
          url: "/sale/postlocation",
          method: "POST",
          body: params,
        })
        .then((res) => {
          setLoading(false);
          if (res.success) {
            localStorage.setItem("deliveryLocation", JSON.stringify(values));
            owntoast(res.message, "success");
            props?.setIsOpenLocation(false);
          } else {
            owntoast(res.message, "error");
          }
        });
    } else {
      localStorage.setItem("deliveryLocation", JSON.stringify(values));
      props?.setIsOpenLocation(false);
    }
  };

  useEffect(() => {
    if (props?.isOpenLocation) {
      let values = JSON.parse(localStorage.getItem("deliveryLocation"));
      if (values) {
        form?.setFieldsValue({ ...values });
      } else {
        form?.resetFields();
      }
    }
  }, [props?.isOpenLocation]);

  return (
    <div className="p-4">
      <Form onFinish={saveLocation} form={form}>
        <FoodInfo isLocation={true} {...props} />
        <div className="flex items-center justify-center gap-2">
          <Button onClick={() => props?.setIsOpenLocation(false)}>Хаах</Button>
          <Button type="primary" htmlType="submit">
            Хадгалах
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Index;
