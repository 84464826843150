import { useState } from "react";
import { CustomModal } from "..";
import { Checkbox, Select, Button, InputNumber, DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { ChangeTimePerm } from "../../utils/enums";
import { owntoast } from "../../utils";
const { Option } = Select;
const Index = (props) => {
  const [isEvent, setIsEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [duration, setDuration] = useState(null);
  const [finalDuration, setFinalDuration] = useState(null);
  const [amt, setAmt] = useState(0);
  const [sdate, setSdate] = useState(dayjs(props?.order?.insymd));
  const [edate, setEdate] = useState(dayjs());

  const handleEvent = (e) => {
    setIsEvent(e.target.checked);
    if (!e.target.checked) setSelectedEvent(null);
  };

  useEffect(() => {
    if (sdate && edate) {
      const diffInMilliseconds = edate.diff(sdate);
      const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
      const totalMinutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);
      setDuration({
        hour: totalHours > 0 ? totalHours : 0,
        minute: totalMinutes > 0 ? totalMinutes : 0,
      });
    }
  }, [sdate, edate]);

  const onChangeSelect = (e) => {
    setSelectedEvent(e);
  };

  useEffect(() => {
    setFinalDuration(duration);
  }, [duration]);

  useEffect(() => {
    const found = props?.timelog?.find((x) => x.id === selectedEvent);
    if (found) {
      let summinutes = duration?.hour * 60 + duration?.minute;
      let calcminutes = summinutes - found?.time * 60;
      if (calcminutes > 0) {
        setFinalDuration({
          hour: Math.floor(calcminutes / 60),
          minute: Math.floor(calcminutes % 60),
        });
      } else {
        setFinalDuration(null);
      }
    } else {
      setFinalDuration(duration);
    }
  }, [selectedEvent, duration]);

  useEffect(() => {
    if (finalDuration) {
      let summinutes =
        (finalDuration?.hour || 0) * 60 + (finalDuration?.minute || 0);
      let amount = (props?.order?.tableprice * summinutes) / 60;
      setAmt(Math.round(amount));
    } else {
      setAmt(0);
    }
  }, [finalDuration]);

  const checkPerm = () => {
    const tmp = props._auth.permission?.find((x) => x === ChangeTimePerm);
    return tmp ? false : true;
  };

  const disabled = checkPerm();
  return (
    <CustomModal
      isOpen={props?.isOpen}
      onClose={props?.closeModal}
      footer={null}
      width={600}
      title="Цагийн төлбөр тооцоо"
    >
      <div className="grid grid-cols-2 py-3 px-10 gap-x-10">
        <span className="font-semibold">Цагийн мэдээлэл</span>
        <span className="font-semibold">Төлбөр, тооцоо</span>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Захиалга</span>
          <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md">
            {props?.order?.salenum}
          </span>
        </div>
        <div className="mt-8">
          <Checkbox onChange={handleEvent}>Урамшуулал өгөх</Checkbox>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Ширээ</span>
          <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md">
            {props?.order?.tablenm}
          </span>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Урамшуулал сонгох</span>
          <Select
            disabled={!isEvent}
            className="time-event"
            placeholder="Сонгох"
            value={selectedEvent}
            onChange={onChangeSelect}
          >
            {props?.timelog?.map((item, i) =>
              item?.isenable === 1 ? (
                <Option key={i} value={item?.id}>
                  {`(${item?.time} цаг) ${item?.description}`}
                </Option>
              ) : null
            )}
          </Select>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Эхэлсэн</span>
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            value={sdate}
            showTime
            disabled={disabled}
            allowClear={false}
            onSelect={(e) => {
              e.$s = 0;
              setSdate(e);
            }}
            className={` px-5 py-1 ${
              disabled ? "bg-kitchenYellow bg-opacity-30" : "bg-white"
            } border border-dashed border-kitchenYellow rounded-md`}
          />
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Тооцоо бодох</span>
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center">
              <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md w-full">
                {duration?.hour || 0}
              </span>
              <span className="ml-2">цаг</span>
            </div>
            <div className="flex items-center">
              <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md w-full">
                {duration?.minute || 0}
              </span>
              <span className="ml-2">мин</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Дууссан</span>
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            value={edate}
            showTime
            allowClear={false}
            disabled={disabled}
            onSelect={(e) => {
              e.$s = 0;
              setEdate(e);
            }}
            className={`px-5 py-1 ${
              disabled ? "bg-kitchenYellow bg-opacity-30" : "bg-white"
            } border border-dashed border-kitchenYellow rounded-md`}
          />
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Төлбөр төлөх</span>
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center">
              <InputNumber
                onChange={(e) =>
                  setFinalDuration((prev) => {
                    return { ...prev, hour: e };
                  })
                }
                disabled={!disabled}
                className={`border-dashed ${
                  !disabled && "bg-opacity-30 bg-kitchenYellow"
                } border-kitchenYellow rounded-md`}
                placeholder="цаг"
                max={999}
                value={finalDuration?.hour || 0}
                min={0}
              />
              <span className="ml-2">цаг</span>
            </div>
            <div className="flex items-center">
              <InputNumber
                onChange={(e) =>
                  setFinalDuration((prev) => {
                    return { ...prev, minute: e };
                  })
                }
                disabled={!disabled}
                className={`border-dashed ${
                  !disabled && "bg-opacity-30 bg-kitchenYellow"
                } border-kitchenYellow rounded-md`}
                placeholder="минут"
                max={60}
                value={finalDuration?.minute || 0}
                min={0}
              />
              <span className="ml-2">мин</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Цагийн төлбөр</span>
          <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md">
            {new Intl.NumberFormat().format(props?.order?.tableprice)}₮
          </span>
        </div>
        <div className="flex flex-col mt-2">
          <span className="text-sm">Төлөх дүн</span>
          <span className="bg-kitchenYellow px-5 py-1 bg-opacity-30 border border-dashed border-kitchenYellow rounded-md">
            {new Intl.NumberFormat().format(amt || 0)}₮
          </span>
        </div>
      </div>
      <div className="flex gap-3 py-3 px-10 justify-end">
        <Button onClick={() => props?.closeModal()}>Болих</Button>
        <Button
          type="primary"
          onClick={() =>
            {
              const minutes = edate?.diff(sdate, "minutes");
                if(minutes >= 0) {
                  props?.handleSave(
                    finalDuration,
                    amt,
                    duration,
                    selectedEvent,
                    sdate,
                    edate
                  )
                } else owntoast("Дуусах огноо эхлэх огнооноос их байж болохгүй.", "error")
            }
          }
        >
          Хадгалах
        </Button>
      </div>
    </CustomModal>
  );
};

export default Index;
