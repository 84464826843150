import {
  EditOutlined,
  MenuOutlined,
  PieChartOutlined,
  FileMarkdownOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  PrinterOutlined,
  AppstoreAddOutlined,
  QrcodeOutlined,
  TabletOutlined,
  ShopOutlined,
  ApartmentOutlined,
  BellOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import * as Columns from "./Columns";
import * as Forms from "./Forms";
import {
  PaymentType,
  MenuPerm,
  BuyingPerm,
  SalePerm,
  SettingsPerm,
  ReportPerm,
  DashPerm,
  MainWindow,
  KitchenPerm,
  NLicenceType,
  QpaySettingsPerm,
  GolomtSettlementPerm,
} from "../utils/enums";
import { SaleDashboard, TableDraw, TableDrawForQR } from "../components";
import { BTSettings } from "../mobile";
import Visa from "../assets/icons/visa.png";
import Wallet from "../assets/icons/wallet.png";
import Golomt from "../assets/icons/golomt.png";
import Qpay from "../assets/icons/qpay.png";
import SocialPay from "../assets/icons/socialpay.png";
import Epos from "../assets/icons/epos.png";
import Mostmoney from "../assets/icons/mostmoney.png";
import LicenseList from "../containers/License/LicenseList";
import { isMobile } from "../utils";

import Parquet1 from "../assets/tables/parquet1.jpg";
import Parquet2 from "../assets/tables/parquet2.jpg";
import Parquet3 from "../assets/tables/parquet3.jfif";
import Parquet4 from "../assets/tables/parquet4.jfif";
import Parquet5 from "../assets/tables/parquet5.jfif";
import Parquet6 from "../assets/tables/parquet6.jfif";
import Parquet7 from "../assets/tables/parquet7.jfif";
import Parquet8 from "../assets/tables/parquet8.png";
import Parquet9 from "../assets/tables/parquet9.jpg";
import Parquet10 from "../assets/tables/parquet10.png";
import Parquet11 from "../assets/tables/parquet11.jpg";
import Parquet12 from "../assets/tables/parquet12.jpg";
import Parquet13 from "../assets/tables/parquet13.png";
import Parquet14 from "../assets/tables/parquet14.jpg";
import Carpet1 from "../assets/tables/carpet1.webp";

import Table1 from "../assets/tables/table1.png";
import Table2 from "../assets/tables/table2.png";
import Table5 from "../assets/tables/table5.png";
import Table6 from "../assets/tables/table6.png";
import Table8 from "../assets/tables/table8.png";
import Table9 from "../assets/tables/table9.png";
import Table10 from "../assets/tables/table10.png";
import Table11 from "../assets/tables/table11.png";
import Table12 from "../assets/tables/table12.png";

import Door1 from "../assets/tables/door1.png";
import Door2 from "../assets/tables/door2.png";
import Door3 from "../assets/tables/door3.png";
import Door4 from "../assets/tables/door4.png";

export const TableFloors = [
  {
    id: 1,
    img: Parquet1,
    name: "Шал 1",
  },
  {
    id: 2,
    img: Parquet2,
    name: "Шал 2",
  },
  {
    id: 3,
    img: Parquet3,
    name: "Шал 3",
  },
  {
    id: 4,
    img: Parquet4,
    name: "Шал 4",
  },
  {
    id: 5,
    img: Parquet5,
    name: "Шал 5",
  },
  {
    id: 6,
    img: Parquet6,
    name: "Шал 6",
  },
  {
    id: 7,
    img: Parquet7,
    name: "Шал 7",
  },
  {
    id: 8,
    img: Carpet1,
    name: "Шал 8",
  },
  {
    id: 9,
    img: Parquet8,
    name: "Шал 9",
  },
  {
    id: 10,
    img: Parquet9,
    name: "Шал 9",
  },
  {
    id: 11,
    img: Parquet10,
    name: "Шал 10",
  },
  {
    id: 12,
    img: Parquet11,
    name: "Шал 11",
  },
  {
    id: 13,
    img: Parquet12,
    name: "Шал 12",
  },
  {
    id: 14,
    img: Parquet13,
    name: "Шал 13",
  },
  {
    id: 15,
    img: Parquet14,
    name: "Шал 14",
  },
];

export const TABLES = [
  {
    id: 1,
    img: Table1,
  },
  {
    id: 2,
    img: Table2,
  },
  {
    id: 5,
    img: Table5,
  },
  {
    id: 6,
    img: Table6,
  },
  {
    id: 8,
    img: Table8,
  },
  {
    id: 9,
    img: Table9,
  },
  {
    id: 10,
    img: Table10,
  },
  {
    id: 11,
    img: Table11,
  },
  {
    id: 12,
    img: Table12,
  },
  {
    id: 13,
    img: Door1,
  },
  {
    id: 14,
    img: Door2,
  },
  {
    id: 15,
    img: Door3,
  },
  {
    id: 16,
    img: Door4,
  },
];

export const EXAMPLEDATA = {
  data: [
    {
      imageIndex: 0,
      width: 313,
      height: 313,
      x: 404,
      y: 172,
      id: 0,
    },
    {
      imageIndex: 4,
      width: 435,
      height: 435,
      x: 938,
      y: 130,
      id: 1,
    },
  ],
  stageWidth: 1920,
  stageHeight: 961,
};

/* Үндсэн мэню болон саб меню нүүд мөн түүнд хамаарах цонхны тохиргоо, хүснэгтын багандууд, формууд бүгдийн энд бүртгэнэ */
export const MAIN_MENU = (props) => [
  {
    name: "Меню",
    route: "/management/menu",
    img: "menu",
    title: "Хоол болон ангилал бүртгэх",
    type: MainWindow.Menu,
    menus: [
      {
        name: "Материал",
        id: 1,
        icon: <EditOutlined />,
        menus: [
          {
            name: "Материалын бүртгэл",
            id: 1,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            columns: Columns.MaterialColumns,
            forms: Forms.MaterialForms,
            method: "POST",
            url: "/material/list",
            model: "materiallist",
            controls: "matcatid",
            permission: BuyingPerm,
            license: NLicenceType.cost,
          },
          {
            name: "Материалын ангилал",
            id: 4,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            method: "POST",
            url: "/category/materiallist",
            model: "catmateriallist",
            columns: Columns.MaterialGroup,
            forms: Forms.MaterialGroupForms,
            permission: BuyingPerm,
            license: NLicenceType.cost,
            manualid: 1,
          },
          {
            name: "Материалын татан авалт",
            id: 2,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            method: "POST",
            url: "/material/buylist",
            model: "buylist",
            columns: Columns.MaterialBuyingColumns,
            forms: Forms.MaterialBuyingForms,
            controls: "sdate,edate,buyid,link",
            permission: BuyingPerm,
            license: NLicenceType.cost,
            manualid: 2,
          },

          {
            name: "Барааны татан авалт",
            id: 3,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            method: "POST",
            url: "/product/buylist",
            model: "productbuylist",
            columns: Columns.ProductBuyingColumns,
            forms: Forms.ProductBuyingForms,
            controls: "sdate,edate,buyid",
            permission: BuyingPerm,
            deleteurl: "/product/buy",
            license: NLicenceType.cost,
            manualid: 3,
          },
        ],
      },
      {
        name: "Меню",
        icon: <MenuOutlined />,
        id: 2,
        menus: [
          {
            name: "Хоолны ангилал үүсгэх",
            id: 1,
            isNew: true,
            isModal: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            columns: Columns.CategoryColumns,
            method: "POST",
            forms: Forms.CategorySettings,
            url: "/category/list",
            model: "categorylist",
            permission: MenuPerm,
            license: NLicenceType.menu,
            manualid: 4,
          },
          {
            name: "Хоолны карт",
            id: 2,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            columns: Columns.FoodCartColumns,
            forms: Forms.FoodCardForms,
            url: "/material/foodcardlist",
            model: "foodcardlist",
            method: "POST",
            isEdit: true,
            permission: MenuPerm,
            license: NLicenceType.card,
            manualid: 5,
          },
          {
            url: "/product/list",
            model: "productlist",
            method: "POST",
            name: "Хоолны бүртгэл",
            id: 3,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            columns: Columns.FoodListColumns,
            forms: Forms.FoodAddForms,
            initialValues: { description: "" },
            controls: "barcode,catid,isunit",
            permission: MenuPerm,
            license: NLicenceType.menu,
            manualid: 6,
          },
          {
            url: "/set/list",
            model: "setlist",
            method: "POST",
            name: "Сэт хоол бүртгэл",
            id: 4,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            columns: Columns.SetFoodListColumns,
            forms: Forms.SetFoodAddForms,
            permission: MenuPerm,
            license: NLicenceType.menu,
            manualid: 7,
          },
          {
            url: "/spices/list",
            model: "spiceslist",
            method: "POST",
            name: "Нэмэлт бүртгэл",
            id: 5,
            isNew: true,
            isList: true,
            isDoubleClick: true,
            isEdit: true,
            columns: Columns.SeasoningListColumns,
            forms: Forms.SeosoningAddForms,
            initialValues: { description: "" },
            permission: MenuPerm,
            license: NLicenceType.menu,
            manualid: 8,
          },
        ],
      },
    ],
  },
  {
    name: "Заал",
    route: "/sale",
    img: "zaal",
    title: "Захиалга үүсгэх, тооцоо хийх ",
    permission: SalePerm,
    type: MainWindow.Zaal,
    manualid: 9,
  },
  {
    name: "Тайлан",
    route: "/management/report",
    img: "report",
    title: "Тайлангууд харах",
    license: NLicenceType.dashboard,
    type: MainWindow.Tailan,
    menus: [
      {
        name: "Хяналт",
        icon: <PieChartOutlined />,
        isList: true,
        component: SaleDashboard,
        id: 1,
        permission: DashPerm,
        manualid: 10,
      },
      {
        name: "Борлуулалтын тайлан",
        icon: <PieChartOutlined />,
        isList: true,
        id: 2,
        method: "POST",
        url: "/report/sale",
        model: "salereport",
        isPagination: true,
        controls: "sdate,edate,catidSearch",
        columns: Columns.SaleReportColumns,
        permission: ReportPerm,
        manualid: 11,
      },
      {
        name: "Материалын тайлан",
        icon: <FileMarkdownOutlined />,
        method: "POST",
        url: "/report/material",
        model: "materialreport",
        isPagination: true,
        id: 3,
        isList: true,
        columns: Columns.MaterialReportColumns,
        controls: "sdate,edate,matcatid",
        permission: ReportPerm,
        manualid: 12,
      },
      {
        name: "Материалын үлдэгдлийн тайлан",
        icon: <FileMarkdownOutlined />,
        method: "POST",
        url: "/report/materialstock",
        model: "materialstockreport",
        isPagination: true,
        id: 4,
        isList: true,
        columns: Columns.MaterialStockReportColumns,
        controls: "date,matcatid",
        permission: ReportPerm,
        manualid: 13,
      },
      {
        name: "Барааны тайлан",
        icon: <ShoppingOutlined />,
        method: "POST",
        url: "/report/product",
        model: "productreport",
        isPagination: true,
        id: 16,
        isList: true,
        columns: Columns.ProductReportColumns,
        controls: "sdate,edate,catidSearch",
        permission: ReportPerm,
        manualid: 14,
      },
      {
        name: "Барааны үлдэгдлийн тайлан",
        icon: <FileMarkdownOutlined />,
        method: "POST",
        url: "/report/productstock",
        model: "productstockreport",
        isPagination: true,
        id: 5,
        isList: true,
        columns: Columns.ProductStockReportColumns,
        controls: "date,catidSearch",
        permission: ReportPerm,
        manualid: 15,
      },
      {
        name: "Баримтын жагсаалт",
        icon: <PrinterOutlined />,
        id: 6,
        isList: true,
        isPagination: true,
        columns: Columns.SaleListColumns,
        url: "/sale/salelist",
        model: "repsalelist",
        method: "POST",
        controls:
          "sdatehour,edatehour,saletype,paymenttype,cashier,waiter,table",
        permission: ReportPerm,
        isSearch: false,
        manualid: 16,
      },
      {
        name: "Кассчны тооцоо",
        icon: <FileMarkdownOutlined />,
        id: 7,
        method: "POST",
        url: "/sale/setlist",
        model: "cashierList",
        isList: true,
        permission: ReportPerm,
        columns: Columns.CashierReportColumns,
        controls: "sdate,edate",
        license: NLicenceType.cashierset,
        manualid: 17,
      },
      {
        name: "Цагийн тооцооны тайлан",
        icon: <FileMarkdownOutlined />,
        id: 15,
        isList: true,
        isPagination: true,
        columns: Columns.TimelogListColumns,
        url: "/report/timelog",
        model: "timeloglist",
        method: "POST",
        controls: "sdate,edate,cashier,dintable,timelog",
        permission: ReportPerm,
        isSearch: false,
        license: NLicenceType.time,
        manualid: 18,
      },
      {
        name: "Өртгийн тайлан /хоолоор/",
        icon: <FileMarkdownOutlined />,
        id: 8,
        method: "POST",
        url: "/report/productcalc",
        model: "prodcalclist",
        isPagination: true,
        isList: true,
        columns: Columns.CostReportColumns,
        controls: "sdate,edate",
        permission: ReportPerm,
        license: NLicenceType.productcost,
        manualid: 19,
      },
      {
        name: "Өртгийн тайлан /материал/",
        icon: <FileMarkdownOutlined />,
        id: 9,
        method: "POST",
        url: "/report/materialcalc",
        model: "matcalclist",
        isPagination: true,
        isList: true,
        columns: Columns.CostMaterialReportColumns,
        controls: "sdate,edate,matcatid",
        permission: ReportPerm,
        license: NLicenceType.productcost,
        manualid: 20,
      },
      {
        name: "Сэтийн борлуулалтын тайлан",
        icon: <FileMarkdownOutlined />,
        id: 10,
        method: "POST",
        url: "/set/report",
        model: "setreportlist",
        isPagination: true,
        isList: true,
        columns: Columns.SetReportColumns,
        controls: "sdate,edate",
        permission: ReportPerm,
        manualid: 21,
      },
      {
        name: "Сэтийн дэлгэрэнгүй тайлан /хоолоор/",
        icon: <FileMarkdownOutlined />,
        id: 11,
        method: "POST",
        url: "/set/report/detail",
        model: "setdetaillist",
        isPagination: true,
        isList: true,
        columns: Columns.SetDetailReportColumns,
        controls: "sdate,edate,setSearch,saletypeSearch",
        permission: ReportPerm,
        manualid: 22,
      },
      {
        name: "Лиценз тайлан",
        icon: <FileMarkdownOutlined />,
        id: 12,
        component: LicenseList,
        isList: true,
        permission: ReportPerm,
        manualid: 23,
      },
      {
        name: "Дотоодын хэрэгцээний борлуулалтын тайлан",
        icon: <FileMarkdownOutlined />,
        id: 13,
        method: "POST",
        url: "/report/saleownreport",
        model: "setsaleownreport",
        isPagination: true,
        isList: true,
        columns: Columns.LossReportColumns,
        permission: ReportPerm,
        controls: "sdate,edate,catidSearch,saletypeSearch",
        manualid: 24,
      },
      {
        name: "Artlab тайлан",
        icon: <FileMarkdownOutlined />,
        id: 14,
        method: "POST",
        url: "/artlab/gethist",
        model: "artlabreport",
        isPagination: true,
        isList: true,
        columns: Columns.ArtlabReportColumns,
        permission: ReportPerm,
        license: NLicenceType.artlab,
        controls: "sdate,edate",
        manualid: 25,
      },
    ],
  },
  {
    name: "Тохиргоо",
    route: "/management/settings",
    img: "settings",
    title: "Системийн тохиргоонууд",
    type: MainWindow.Tohirgoo,
    menus: [
      {
        name: "Үндсэн тохиргоо",
        isSettings: true,
        icon: <SettingOutlined />,
        id: 1,
        menus: [
          {
            method: "GET",
            url: `/user/getfoodinformation/${props._auth.foodid}`,
            model: "userconfig",
            name: "Хоолны газрын мэдээлэл",
            id: 1,
            isSettings: true,
            forms: Forms.BaseSettings,
            initialValues: props.resuserconfig,
            permission: SettingsPerm,
            manualid: 26,
          },
          {
            method: "GET",
            url: `/system/printer/get/${props._auth.foodid}`,
            model: "printerconfig",
            name: "Кассын тохиргоо",
            id: 2,
            isSettings: true,
            forms: Forms.CashierSettings,
            initialValues: props.resprinterconfig,
            permission: SettingsPerm,
            license: NLicenceType.systemconfig,
            manualid: 27,
          },
          /*  {
            name: "Төхөөрөмжийн тохиргоо",
            id: 3,
            isSettings: true,
            permission: SettingsPerm,
          }, */
          {
            name: "Татварын тохиргоо",
            id: 4,
            isSettings: true,
            forms: Forms.VatSettings(props),
            initialValues: props._auth,
            permission: SettingsPerm,
            license: NLicenceType.systemconfig,
            manualid: 28,
          },
          isMobile() && {
            name: "Bluetooth принтер",
            isList: true,
            component: BTSettings,
            id: 5,
            permission: SettingsPerm,
            license: NLicenceType.systemconfig,
          },
        ],
      },
      {
        name: "Хэрэглэгчийн эрх",
        isSettings: true,
        icon: <UsergroupAddOutlined />,
        id: 2,
        menus: [
          {
            name: "Хэрэглэгчийн бүртгэл",
            id: 1,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            changePassBtn: true,
            columns: Columns.UserPermColumns,
            forms: Forms.UserPermForms,
            method: "POST",
            url: "/user/userlist",
            model: "userlist",
            permission: SettingsPerm,
            manualid: 29,
          },
        ],
      },
      {
        name: "Холболтын тохиргоо",
        isSettings: true,
        icon: <ApartmentOutlined />,
        id: 3,
        menus: [
          {
            name: "Голомт IPPOS",
            id: 1,
            isSettings: true,
            forms: Forms.GolomtIPPOSSettings,
            initialValues: props.resdealerinfoippos,
            permission: GolomtSettlementPerm,
            license: NLicenceType.bankpos,
          },
          {
            name: "Датабанк",
            id: 2,
            isSettings: true,
            forms: Forms.DatabankSettings,
            initialValues: props.resdealerinfodatabank,
            permission: SettingsPerm,
            license: NLicenceType.bankpos,
          },
          /* {
            name: "Minu Pay",
            id: 3,
            isSettings: true,
            forms: Forms.MidSettings,
            initialValues: props.resdealerinfomid,
            permission: SettingsPerm,
            license: LicenseType.Main,
          }, */
          {
            name: "Most Money",
            id: 3,
            isSettings: true,
            forms: Forms.MostSettings,
            initialValues: props.resdealerinfomost,
            permission: SettingsPerm,
            license: NLicenceType.bankpos,
          },
          {
            name: "Social Pay",
            id: 4,
            isSettings: true,
            forms: Forms.SocialPaySettings,
            initialValues: props.resdealersocialpay,
            permission: SettingsPerm,
            license: NLicenceType.bankpos,
          },
          {
            name: "QPay",
            id: 5,
            isSettings: true,
            checkDisable: "acccode",
            forms: Forms.QpaySettings,
            initialValues: props.resdealerqpay,
            url: "/dealer/qpay",
            model: "dealerqpay",
            permission: QpaySettingsPerm,
            license: NLicenceType.bankpos,
          },
          {
            method: "GET",
            url: `/artlab/getconfig`,
            model: "artlab",
            name: "Artlab",
            id: 6,
            isSettings: true,
            forms: Forms.ArtlabSettings,
            initialValues: props.resartlab,
            permission: SettingsPerm,
            license: NLicenceType.artlab,
          },
        ],
      },
      {
        name: "Нэмэлт тохиргоо",
        icon: <AppstoreAddOutlined />,
        id: 4,
        menus: [
          {
            name: "Зардлын төрөл",
            id: 1,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            columns: Columns.CostTypeColumns,
            forms: Forms.CostTypeSettings,
            method: "POST",
            url: "/cost/costtype/list",
            model: "costtypelist",
            permission: SettingsPerm,
            license: NLicenceType.cost,
            manualid: 30,
          },
          {
            name: "Захиалгын төрөл",
            method: "POST",
            url: "/order/ordertype/list",
            model: "ordertypelist",
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            id: 2,
            columns: Columns.OrderTypeSettingsColumns,
            forms: Forms.OrderTypeSettings,
            permission: SettingsPerm,
            manualid: 31,
          },
          {
            component: TableDraw,
            name: "Ширээ бүртгэл",
            isList: true,
            id: 3,
            permission: SettingsPerm,
            license: NLicenceType.dintable,
            manualid: 32,
          },
          {
            name: "Порцын тохиргоо",
            id: 4,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            method: "POST",
            url: "/unitandportion/portionlist",
            model: "portionlist",
            forms: Forms.PortionTypeSettings,
            columns: Columns.PortionList,
            permission: SettingsPerm,
            manualid: 33,
          },
          {
            name: "Хэмжих нэгж тохиргоо",
            id: 5,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            method: "POST",
            url: "/unitandportion/unitlist",
            model: "unitlist",
            forms: Forms.UnitTypeSettings,
            columns: Columns.UnitList,
            permission: SettingsPerm,
            manualid: 34,
          },
          {
            name: "Цагийн урамшуулал",
            id: 6,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            method: "POST",
            url: "/system/get/timelog",
            model: "timelog",
            forms: Forms.TimeEventSettings,
            columns: Columns.TimeEventList,
            permission: SettingsPerm,
            license: NLicenceType.time,
            manualid: 35,
          },
          {
            name: "Зөөгч бүртгэл",
            id: 7,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            method: "GET",
            url: "/user/waiter",
            model: "waiters",
            forms: Forms.WaiterSettings,
            columns: Columns.WaiterList,
            permission: SettingsPerm,
            license: NLicenceType.time,
            manualid: 36,
          },
        ],
      },
      {
        name: "Салбарын тохиргоо",
        isHead: true,
        icon: <ShopOutlined />,
        id: 5,
        menus: [
          {
            name: "Салбарын жагсаалт",
            id: 1,
            isList: true,
            method: "POST",
            url: "/branch/list",
            model: "branchlist",
            // component: MasterRegister,
            columns: Columns.BranchList,
            permission: SettingsPerm,
            license: NLicenceType.branch,
            manualid: 37,
          },
        ],
      },
      {
        name: "Киоск тохиргоо",
        icon: <TabletOutlined />,
        id: 6,
        menus: [
          {
            name: "Киоск баннер",
            id: 1,
            isSettings: true,
            method: "GET",
            url: "/kiosk",
            model: "kioskinfo",
            initialValues: props.reskioskinfo,
            forms: Forms.KioskSettings,
            permission: SettingsPerm,
          },
          {
            name: "Төлбөр төлөлт",
            id: 2,
            isSettings: true,
            method: "GET",
            url: "/kiosk",
            model: "kioskinfo",
            initialValues: props.reskioskinfo,
            forms: Forms.KioskDealerSettings,
            permission: SettingsPerm,
          },
          {
            name: "Төхөөрөмж",
            id: 3,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            method: "GET",
            url: "/kiosk/device",
            model: "kioskdevices",
            columns: Columns.KioskDeviceColumns,
            forms: Forms.KioskDeviceSettings,
            permission: SettingsPerm,
            manualid: 38,
          },
        ],
      },
      {
        name: "QR menu тохиргоо",
        icon: <QrcodeOutlined />,
        id: 7,
        menus: [
          {
            name: "Нүүр хуудас",
            id: 1,
            isSettings: true,
            method: "GET",
            url: "/menu/config",
            model: "menuconfig",
            initialValues: props.resmenuconfig,
            forms: Forms.QRMenuSettings,
            permission: SettingsPerm,
            license: NLicenceType.qrmenu,
          },
          {
            component: TableDrawForQR,
            name: "QR menu",
            isList: true,
            // initialValues: props.resmenuconfig,
            id: 2,
            permission: SettingsPerm,
            license: NLicenceType.qrmenu,
          },
        ],
      },
      {
        name: "Гал тогоо тохиргоо",
        icon: <BellOutlined />,
        id: 8,
        menus: [
          {
            name: "Принтерийн тохиргоо",
            id: 1,
            isList: true,
            isDoubleClick: true,
            isNew: true,
            isEdit: true,
            columns: Columns.PrinterSettingsColumn,
            forms: Forms.KithchenSettings,
            method: "GET",
            url: `/system/printkitchen/list/${props._auth.foodid}`,
            // model: "printerconfig",
            model: "kitchenprintlist",
            permission: SettingsPerm,
          },
          // {
          //   method: "GET",
          //   url: `/system/printer/get/${props._auth.foodid}`,
          //   model: "printerconfig",
          //   name: "Принтерийн тохиргоо",
          //   id: 1,
          //   isSettings: true,
          //   forms: Forms.KithchenSettings,
          //   initialValues: props.resprinterconfig,
          //   permission: SettingsPerm,
          //   license: NLicenceType.kitchen,
          // },
          {
            method: "GET",
            url: `/order/get/orderconfig`,
            model: "orderwindow",
            name: "Заалны дэлгэцийн тохиргоо",
            id: 2,
            isSettings: true,
            forms: Forms.OrderWindowSettings,
            initialValues: props.resorderwindow,
            permission: SettingsPerm,
            license: NLicenceType.kitchen,
          },
        ],
      },
    ],
  },
  {
    name: "Гал тогоо",
    route: "/kitchen",
    img: "kitchen",
    title: "Захиалгууд харах",
    permission: KitchenPerm,
    license: NLicenceType.kitchen,
    type: MainWindow.Zaal,
  },
];

export const PaymentTypes = [
  {
    name: "Бэлнээр",
    type: PaymentType.Бэлнээр,
    isActive: true,
    cname: "Бэлэн",
    isPayed: true,
    icon: Wallet,
  },
  {
    name: "Бэлэн бус",
    type: PaymentType.Картаар,
    isActive: true,
    cname: "Бэлэн бус",
    isPayed: true,
    icon: Visa,
  },
  {
    name: "Голомт",
    type: PaymentType.Голомтоор,
    isActive: true,
    license: NLicenceType.bankpos,
    icon: Golomt,
  },
  {
    name: "QPAY",
    type: PaymentType.Qpay,
    isActive: true,
    license: NLicenceType.merchant,
    hasQR: true,
    icon: Qpay,
  },
  {
    name: "SocialPay",
    type: PaymentType.SocialPay,
    isActive: true,
    license: NLicenceType.merchant,
    hasQR: true,
    icon: SocialPay,
  },
  {
    name: "Хаанбанк",
    type: PaymentType.Хаанбанк,
    isActive: true,
    license: NLicenceType.bankpos,
    icon: Epos,
  },
  {
    name: "Minu Pay",
    type: PaymentType.MinuPay,
    license: NLicenceType.merchant,
    hasQR: true,
  },
  {
    name: "Most Money",
    type: PaymentType.Most,
    isActive: true,
    license: NLicenceType.merchant,
    hasQR: true,
    icon: Mostmoney,
  },
];

export const MockBillData = [
  {
    name: "Бууз",
    count: 78,
    price: 800,
  },
  {
    name: "Цуйван",
    count: 15,
    price: 7500,
  },
  {
    name: "Будаатай хуурга",
    count: 20,
    price: 7500,
  },
  {
    name: "Банштай цай",
    count: 5,
    price: 7500,
  },
  {
    name: "Банштай ногоотой шөл",
    count: 15,
    price: 6500,
  },
  {
    name: "Ногоотой шөл",
    count: 2,
    price: 6500,
  },
  {
    name: "Хавиргатай ногоотой шөл",
    count: 302,
    price: 7500,
  },
  {
    name: "Нарийн мах",
    count: 20,
    price: 7500,
  },
  {
    name: "Тефтель",
    count: 19,
    price: 7000,
  },
  {
    name: "Гуляш",
    count: 15,
    price: 7800,
  },
  {
    name: "Өндөгтэй бифштекс",
    count: 15,
    price: 8000,
  },
  {
    name: "Цай",
    count: 60,
    price: 600,
  },
  {
    name: "MCS Cola",
    count: 35,
    price: 600,
  },
  {
    name: "Үзэмний шүүс",
    count: 26,
    price: 800,
  },
  {
    name: "Нийслэл салад",
    count: 10,
    price: 3500,
  },
  {
    name: "Пирошки",
    count: 68,
    price: 1000,
  },
  {
    name: "Мантуун бууз",
    count: 50,
    price: 800,
  },
  {
    name: "Шарсан ороомог",
    count: 26,
    price: 800,
  },
  {
    name: "Жигнэсэн ороомог",
    count: 35,
    price: 800,
  },
];

export const MockSaleBillData = [
  {
    name: "Бууз",
    count: 1,
    price: 800,
  },
  {
    name: "Цуйван",
    count: 1,
    price: 7500,
  },
  {
    name: "Будаатай хуурга",
    count: 1,
    price: 7500,
  },
  {
    name: "Банштай цай",
    count: 1,
    price: 7500,
    discount: 500,
  },
];

export const BillTypeData = [
  { label: "Хувь хүн", value: 1 },
  { label: "Байгууллага", value: 3 },
];

export const STEPS = [
  {
    selector: '[data-tour="notification-button"]',
    content: "Мэдэгдэл",
  },
  {
    selector: "#settlement-button",
    content: "Хаалт",
  },
];

export const MANUALS = [
  {
    title: "Материалын татан авалт бүртгэх",
    manuals: [1],
    link: "https://www.youtube.com/watch?v=wIcBCSBLEAQ",
  },
  {
    title: "Ширээ бүртгэх",
    manuals: [2],
    link: "https://www.youtube.com/watch?v=AJaLJ9zr9eE",
  },
  {
    title: "Нэмэлт зардал бүртгэх",
    manuals: [3],
    link: "https://www.youtube.com/watch?v=fyiI11m1k18",
  },
  {
    title: "Порц бүртгэх",
    manuals: [4],
    link: "https://www.youtube.com/watch?v=BML9EK5OFSQ",
  },
  {
    title: "Хоолны ангилал бүртгэх",
    manuals: [5],
    link: "https://www.youtube.com/watch?v=frWvjI_QNyM",
  },
  {
    title: "Хоолны технологийн карт бүртгэх",
    manuals: [6],
    link: "https://www.youtube.com/watch?v=tYvIAxBFGzY",
  },
  {
    title: "Хоол бүртгэх",
    manuals: [7],
    link: " ",
  },
  {
    title: "Захиалга үүсгэх",
    manuals: [8],
    link: "https://www.youtube.com/watch?v=RjalBZoIBrQ",
  },
  {
    title: "Захиалгын тооцоо хийх",
    manuals: [9],
    link: "https://www.youtube.com/watch?v=I2AZrz3mMxA",
  },
  {
    title: "Хэрэглэгч нэмэх",
    manuals: [10],
    link: " ",
  },
  {
    title: "Кассын тооцоо хаах",
    manuals: [11],
    link: "https://www.youtube.com/watch?v=Ch8INNWt44Q",
  },
  {
    title: "Ширээнд захиалга бүртгэх",
    manuals: [12],
    link: "https://www.youtube.com/watch?v=zOK0SB8kB6c",
  },
  {
    title: "Салбар нэмэх",
    manuals: [13],
    link: " ",
  },
  {
    title: "Салбарын тохиргоо",
    manuals: [14],
    link: " ",
  },
  {
    title: "Мастер бүртгэл татах",
    manuals: [15],
    link: " ",
  },
  {
    title: "Сэт хоол бүртгэл",
    manuals: [16],
    link: " ",
  },
  {
    title: "Нэмэлт бүртгэх",
    manuals: [17],
    link: " ",
  },
  {
    title: "Гал тогоо",
    manuals: [18],
    link: " ",
  },
  {
    title: "Киоск тохиргоо",
    manuals: [19],
    link: " ",
  },
  {
    title: "Бараа бүртгэх",
    manuals: [20],
    link: " ",
  },
  {
    title: "Үйлчилгээний нэмэгдэл",
    manuals: [21],
    link: " ",
  },
  {
    title: "Цагийн төлбөр тооцох",
    manuals: [22, 23],
    link: " ",
  },
  {
    title: "QR menu ашиглах",
    manuals: [24],
    link: " ",
  },
  {
    title: "Тооцоо хувааж төлөх",
    manuals: [25],
    link: " ",
  },
  {
    title: "Захиалга нэгтгэх",
    manuals: [26],
    link: " ",
  },
  {
    title: "Зөөгч бүртгэх",
    manuals: [27],
    link: " ",
  },
];

export const DEMO_VERSION_ENABLED = [
  {
    title: "Меню бүртгэл",
  },
  {
    title: "Захиалга, борлуулалт хийх",
  },
  {
    title: "Тохиргоо хийх",
  },
  {
    title: "Хэрэглэгч нэмэх",
  },
];

export const DEMO_VERSION_DISABLED = [
  {
    title: "И-баримт хэвлэх",
  },
  {
    title: "Банкны картууд унших",
  },
  {
    title: "Тайлангууд харах",
  },
  {
    title: "Нэмэлт боломжууд",
  },
];
