import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import back from "../../assets/back.png";
import dayjs from "dayjs";
import { owntoast } from "../../utils";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
function LicenseList(props) {
  const navigate = useNavigate();
  const today = dayjs().format("YYYY-MM-DD");
  const { reslicenselist, resvoucherlist } = props;
  const [visible, setVisible] = useState(false);
  const { pathname } = window.location;

  useEffect(() => {
    props
      .request({ url: `/license/foodlist`, model: "licenselist" })
      .then((res) => {
        if (res.success) {
        } else {
          owntoast(res.message, "error");
        }
      });
    props
      .request({ url: `/license/user/voucherlist`, model: "voucherlist" })
      .then((res) => {
        if (res.success) {
        } else {
          owntoast(res.message, "error");
        }
      });
  }, []);

  const handleBack = () => {
    navigate("/license");
  };

  const splitString = (value, char, index) => {
    if (visible) return value.match(/.{1,4}/g).join(char);
    else return "************".match(/.{1,4}/g).join(char);
  };

  return (
    <div className="flex items-center justify-center h-full w-full">
      <div
        className={
          pathname.includes("management")
            ? "h-full w-full"
            : "bg-white p-10 rounded-lg border border-gray-200 overflow-auto overflow-x-auto drop-shadow-xl 2xl:h-4/6 2xl:w-3/5"
        }
      >
        <div className={`${resvoucherlist ? "grid-rows-2" : null}`}>
          <div className="overflow-auto overflow-x-auto 2xl:h-4/6">
            <p className="text-center text-license text-3xl">Төлсөн төлбөр</p>
            <div className="mb-2 items-center justify-between text-md font-semibold border-b grid grid-cols-6 ">
              <div className="flex justify-start">Багц</div>
              <div className="flex justify-center">Тоо ширхэг</div>
              <div className="flex justify-end">Төлсөн дүн</div>
              <div className="flex justify-center">Төлсөн огноо</div>
              <div className="flex justify-center">Эхлэх огноо</div>
              <div className="flex justify-center">Дуусах огноо</div>
            </div>
            {reslicenselist &&
              reslicenselist.map((item) => (
                <div>
                  <div className="mb-2 items-center justify-between border-b grid grid-cols-6">
                    <div className="flex justify-start">
                      <span>{item?.licensename}</span>
                    </div>
                    <div className="flex justify-center">
                      {item.type === 3 || item.type === 4 ? item.qty : "-"}
                    </div>
                    <div className="flex justify-end">
                      {item.useramount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      ₮
                    </div>
                    <div className="flex justify-center">
                      {dayjs(item.insymd).format("YYYY-MM-DD")}
                    </div>
                    <div className="flex justify-center">
                      {item.type === 3 || item.type === 4
                        ? "-"
                        : dayjs(item.sdate).format("YYYY-MM-DD")}
                    </div>
                    <div
                      className={`flex justify-center ${
                        item.edate > today ? "text-green" : "text-red-500"
                      }`}
                    >
                      {item.type === 3 || item.type === 4
                        ? "-"
                        : dayjs(item.edate).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  {item?.type === 3 && item?.users?.length > 0 && (
                    <div className="mb-2 items-center justify-between text-md font-semibold border-b grid grid-cols-6 ml-6">
                      <div className="flex justify-start">Нэр</div>
                      <div className="flex justify-center -ml-9">
                        Нэвтрэх нэр
                      </div>
                      <div className="flex justify-end mr-2">Төлсөн дүн</div>
                      <div />
                      <div className="flex justify-center -ml-3">
                        Эхлэх огноо
                      </div>
                      <div className="flex justify-center -ml-1">
                        Дуусах огноо
                      </div>
                    </div>
                  )}
                  {item?.type === 3 &&
                    item?.users?.map((user, i) => (
                      <div
                        key={i}
                        className="mb-2 items-center justify-between text-md border-b grid grid-cols-6 ml-6"
                      >
                        <div className="flex justify-start">{user?.name}</div>
                        <div className="flex justify-center -ml-9">
                          {user?.uname}
                        </div>
                        <div className="flex justify-end mr-2">
                          {new Intl.NumberFormat().format(user?.amount || 0)}₮
                        </div>
                        <div />
                        <div className="flex justify-center -ml-3">
                          {dayjs(user?.sdate).format("YYYY-MM-DD")}
                        </div>
                        <div
                          className={`flex justify-center -ml-1 ${
                            user?.edate > today ? "text-green" : "text-red-500"
                          }`}
                        >
                          {dayjs(user?.edate).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    ))}
                  {item.type === 4 && item.devices.length > 0 && (
                    <div className="mb-2 items-center justify-between text-md font-semibold border-b grid grid-cols-6 ml-6">
                      <div className="flex justify-start">Төхөөрөмж</div>
                      <div className="flex justify-center -ml-9">Нэр</div>
                      <div className="flex justify-end mr-2">Төлсөн дүн</div>
                      <div />
                      <div className="flex justify-center -ml-3">
                        Эхлэх огноо
                      </div>
                      <div className="flex justify-center -ml-1">
                        Дуусах огноо
                      </div>
                    </div>
                  )}
                  {item.type === 4 &&
                    item.devices.map((kiosk, i) => (
                      <div
                        key={i}
                        className="mb-2 items-center justify-between text-md border-b grid grid-cols-6 ml-6"
                      >
                        <div className="flex justify-start">
                          <span>{splitString(kiosk.dkey, "-", i)}</span>
                          <Button
                            className="-mt-2 -ml-2"
                            type="text"
                            onClick={() => setVisible(!visible)}
                          >
                            {!visible ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </Button>
                        </div>
                        <div className="flex justify-center -ml-9">
                          {kiosk.name}
                        </div>
                        <div className="flex justify-end mr-2">
                          {kiosk.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          ₮
                        </div>
                        <div />
                        <div className="flex justify-center -ml-3">
                          {dayjs(kiosk.sdate).format("YYYY-MM-DD")}
                        </div>
                        <div
                          className={`flex justify-center -ml-1 ${
                            kiosk.edate > today ? "text-green" : "text-red-500"
                          }`}
                        >
                          {dayjs(kiosk.edate).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
          {resvoucherlist && resvoucherlist.length >= 1 ? (
            <div className="overflow-auto overflow-x-auto">
              <p className="text-center text-license text-3xl">Купон</p>
              <div className="mb-2 items-center justify-between text-md font-semibold border-b grid grid-cols-5 ">
                <div className="flex justify-start">Купоны код</div>
                <div className="flex justify-center">Купон төрөл</div>
                <div className="flex justify-center">Купон нэр</div>
                <div className="flex justify-center">Хямдрал</div>
                <div className="flex justify-end">Купон ашигласан огноо</div>
              </div>
              {resvoucherlist &&
                resvoucherlist.map((item, i) => (
                  <div className="mb-2 items-center justify-between border-b grid grid-cols-5 ">
                    <div className="flex justify-start font-semibold text-blue">
                      <span>{item.promocode}</span>
                    </div>
                    <div className="flex justify-center text-gray-400">
                      <span>
                        {item.type === 1
                          ? "Үнийн дүнгээр"
                          : item.type === 2
                          ? "Хувиар"
                          : item.type === 3
                          ? "Хоногоор"
                          : ""}
                      </span>
                    </div>
                    <div className="flex justify-center text-gray-400">
                      <span>{item.name}</span>
                    </div>
                    <div className="flex justify-center text-gray-400">
                      {item.text}
                    </div>
                    <div className="flex justify-center text-gray-400">
                      {dayjs(item.eatdate).format("YYYY-MM-DD")}
                    </div>
                  </div>
                ))}
            </div>
          ) : null}
        </div>
        {!pathname.includes("management") && (
          <div className="flex justify-center mt-4">
            <div className="w-3/5 flex justify-center">
              <Button
                icon={<img className="w-4 h-4 mr-2" src={back} alt="logo" />}
                onClick={handleBack}
              >
                Буцах
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LicenseList;
