import * as Components from "../";
import { Input, Checkbox } from "antd";
import { motion } from "framer-motion";
import {
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useMotionHook } from "../../hooks/motionHook";
import { owntoast } from "../../utils";
import UsefulFunctions from "../../utils/UsefulFunctions";
function ListItem(props) {
  const { item, i, isDivide } = props;
  const animations = useMotionHook();
  const removeProduct = (product) => props.removeSaleList(product);
  let qty = item.qty;
  let divideItem = null;
  if (isDivide) {
    let found = props?.divideList?.find?.((x) => {
      if (item.isset) {
        let isset = UsefulFunctions.checkSetProducts(
          item.setdetails,
          x.setdetails
        );
        if (isset) return x;
      } else if (
        x.productid === item?.productid &&
        x.spiceid === item.spiceid
      ) {
        return x;
      }
    });
    qty = found?.qty || 0;
    if (found) divideItem = found;
  }

  const changeQty = (e) => {
    if (e.target.value) {
      if (!isNaN(+e.target.value)) {
        if (isDivide) {
          if (Number(e.target.value) === 0) {
            props.removeDivideList(item);
          } else if (item?.qty >= Number(e.target.value)) {
            props.addDivideList({
              ...item,
              type: "qty",
              qty: Number(e.target.value),
            });
          } else {
            owntoast("Тоо ширхэг их байна.", "error");
          }
        } else {
          if (e.target.value > 0) {
            if (e.target.value <= 999) {
              props.addSaleList({
                ...item,
                type: "qty",
                qty: Number(e.target.value),
              });
            } else {
              owntoast("999 с дээш оруулж болохгүй.", "error");
            }
          }
        }
      }
    } else {
      if (isDivide) {
        props.removeDivideList(item);
      }
    }
  };

  const isSelectedProduct = (item) => {
    if (props.activeProduct) {
      if (props.activeProduct.isset) {
        return UsefulFunctions.checkSetProducts(
          item.setdetails,
          props.activeProduct.setdetails
        );
      } else {
        if (
          props.activeProduct.productid === item.productid &&
          props.activeProduct.spiceid === item.spiceid &&
          props.activeProduct.istime === item.istime
        )
          return true;
      }
    }
    return false;
  };

  const renderSpices = () => {
    let tmp;
    if (item.isset && item.setdetails) {
      tmp = item.setdetails.map((detail, detailIndex) => {
        return (
          <span key={detailIndex} className=" text-xs text-mainOrange mt-1">{`${
            detail.productname
          } ${
            detail.spiceid && detail.spicename ? `/${detail.spicename}/` : ""
          }  `}</span>
        );
      });
    } else if (item.spiceid && item.spicename) {
      return (
        <span className="text-xs text-mainOrange mt-1">{item.spicename}</span>
      );
    }
    return tmp;
  };

  const onChangeCheck = (e) => {
    if (e.target.checked) {
      props?.addDivideList({ ...item, type: "plus" });
    } else {
      props.removeDivideList(item);
    }
  };

  const getChecked = () => {
    if (isDivide) {
      let found = props?.divideList?.find?.((x) => {
        if (item.isset) {
          let isset = UsefulFunctions.checkSetProducts(
            item.setdetails,
            x.setdetails
          );
          if (isset) return x;
        } else if (
          x.productid === item?.productid &&
          x.spiceid === item.spiceid
        ) {
          return x;
        }
      });
      return found ? true : false;
    }
    return false;
  };

  const getAmount = () => {
    if (isDivide) {
      if (divideItem) {
        return new Intl.NumberFormat().format(divideItem?.amount);
      }
      return new Intl.NumberFormat().format(
        qty === 0 ? 0 : item.price - item.unitdiscount
      );
    } else {
      return new Intl.NumberFormat().format(item.amount);
    }
  };

  return (
    <motion.div
      {...animations}
      onClick={() => {
        if (props.setActiveProduct) {
          props.setActiveProduct({ ...item });
        }
      }}
      className={`relative grid grid-cols-12 grid-rows-2 gap-1 p-2 rounded-md ${
        i % 2 === 0 ? "bg-primaryOpa" : ""
      } ${
        isSelectedProduct(item)
          ? "border-2 border-primary transition-all delay-75"
          : ""
      }`}
    >
      <div className="col-span-6 row-span-2 flex">
        {isDivide ? (
          <Checkbox checked={getChecked()} onChange={onChangeCheck} />
        ) : (
          <Components.Ripple
            className="bg-buttonGrey text-white min-w-[20px] h-[20px] w-[20px] flex items-center justify-center rounded-sm row-span-2 col-span-8 "
            onClick={() => removeProduct(item)}
          >
            <CloseOutlined className="text-xs flex items-center justify-center" />
          </Components.Ripple>
        )}

        <div className="ml-3 flex flex-col w-full">
          <span className="text-black">{item.productname}</span>
          {renderSpices()}
          {/* {item.isset &&
            item.setdetails &&
            item.setdetails.map((detail, detailIndex) => {
              if (detail.spiceid && detail.spicename) {
                return (
                  <span
                    key={detailIndex}
                    className="text-black text-xs text-mainOrange mt-1"
                  >{`${detail.productname} /${detail.spicename}/`}</span>
                );
              }
            })} */}
        </div>
      </div>
      {item.unitdiscount ? (
        <div className="col-span-6 row-span-1 flex justify-end items-center">
          <span className="text-grey text-xs ml-3 w-18 text-right line-through">
            {new Intl.NumberFormat().format(item.price)}
          </span>
          <span className="text-black ml-1 w-18 text-right">
            {new Intl.NumberFormat().format(item.price - item.unitdiscount)}
          </span>
        </div>
      ) : (
        <div className="col-span-6 row-span-1 flex justify-end">
          <span className="text-black ml-3 w-18 text-right">
            {new Intl.NumberFormat().format(item.price)}
          </span>
        </div>
      )}

      <div className="col-span-6 row-span-1 flex justify-end ">
        <Components.Ripple
          className="bg-primary w-6 h-6 flex items-center justify-center rounded-sm"
          onClick={() => {
            if (item?.istime) {
              owntoast("Цагийн төлбөрийг өөрчлөх боломжгүй.", "error");
              return;
            }
            if (isDivide) {
              if (qty > 1) {
                props.addDivideList({ ...item, type: "minus" });
              } else {
                props.removeDivideList(item);
              }
            } else {
              if (item.qty > 1) {
                props.addSaleList({ ...item, type: "minus" });
              }
            }
          }}
        >
          <MinusOutlined className="text-xs flex items-center justify-center text-white" />
        </Components.Ripple>
        <div className="flex flex-col items-center">
          {item?.isedited && item?.editedqty ? (
            <div className="absolute flex items-center top-3 pulse-small">
              <InfoCircleOutlined className="text-mainOrange mr-1" />
              <span className="text-xs">
                <span className="text-sm">{item?.editedqty}</span> нэмэгдсэн
              </span>
            </div>
          ) : null}
          <Input
            className="w-10 mx-2 h-6 text-center p-1"
            onChange={changeQty}
            value={qty}
            disabled={item?.istime}
          />
        </div>

        {/* <div className="w-8 text-center">{item.qty}</div> */}
        <Components.Ripple
          className="bg-primary w-6 h-6 flex items-center justify-center rounded-sm"
          onClick={() => {
            if (item?.istime) {
              owntoast("Цагийн төлбөрийг өөрчлөх боломжгүй.", "error");
              return;
            }
            if (isDivide) {
              if (item?.qty > qty) {
                props?.addDivideList({
                  ...item,
                  qty: qty > 0 ? qty : 1,
                  type: "plus",
                });
              } else {
                owntoast("Тоо ширхэг хүрсэн байна.", "error");
              }
            } else {
              props.addSaleList({ ...item, type: "plus" });
            }
          }}
        >
          <PlusOutlined className="text-xs flex items-center justify-center text-white" />
        </Components.Ripple>
        <span className="text-black ml-3 w-16 text-right font-semibold">
          {getAmount()}
        </span>
      </div>
    </motion.div>
  );
}

export default ListItem;
