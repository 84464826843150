import { useRef, useEffect, useState } from "react";
import { TableCustomizer, FormItems } from "..";
import { Button, Drawer, Form, Input, Spin } from "antd";
import { QRTemplateSettings } from "../../data/Forms";
import { owntoast } from "../../utils";
import QRCode from "react-qr-code";
import * as Components from "..";
import FImage from "../../assets/tables/paving.png";
import CryptoJS from "crypto-js";
import domtoimage from "dom-to-image";
import { API_URL, QRMENU_URL } from "../../utils";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";

const QRPage = ({
  isOpen,
  onClose,
  dataQr,
  tableName,
  encryptedData,
  big,
  small,
}) => {
  const Print = () => {
    const printStyleEl = document.createElement("style");
    printStyleEl.innerHTML = `
      @media print {
        body * {
          visibility: hidden;
        }
        #divToDownload, #divToDownload * {
          visibility: visible;
          color: black !important;
        }
        #divToDownload {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: auto;
        }
        #background1 {
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
          print-color-adjust: exact !important;
          background-size: cover !important;
          background-position: center !important;
          width: 100%; /* Adjust the width to 100% for full width */
          height: auto; /* Let the height adjust automatically */
        }
        #background2 {
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
          print-color-adjust: exact !important;
          background-size: cover !important;
          background-position: center !important;
          width: 30px; /* Adjust the width as needed */
          height: 30px; /* Adjust the height as needed */
        }
      }
    `;
    document.head.appendChild(printStyleEl);

    window.print();

    document.head.removeChild(printStyleEl);
  };

  const Download = async () => {
    const divToDownload = document.getElementById("divToDownload");
    if (divToDownload) {
      try {
        const blob = await domtoimage.toBlob(divToDownload);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `QRmenu ${tableName}-р ширээ.png`;
        // const clonedDiv = divToDownload.cloneNode(true);
        // const background2 = clonedDiv.querySelector("#background2");
        // background2.style.visibility = "visible";
        // link.appendChild(clonedDiv);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error during image generation or download:", error);
      }
    }
  };
  return (
    <Drawer
      title="Ширээний QR код"
      placement="right"
      height={350}
      width={700}
      open={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-row justify-end">
        <Button
          className="w-fit mr-2"
          onClick={Print}
          icon={<PrinterOutlined />}
        >
          Хэвлэх
        </Button>
        <Button
          className="w-fit bg-primary mr-2"
          type="primary"
          onClick={Download}
          icon={<DownloadOutlined />}
        >
          Татах
        </Button>

        {
          // altka egch haa geed ene buttton haasan
          /* <Button
          className="w-fit bg-primary"
          type="primary"
          onClick={() => window.open(encryptedData, "_blank")}
          icon={<DownloadOutlined />}
        >
          Нээх
        </Button> */
        }
      </div>
      <div className="flex justify-center items-center mt-4" id="divToDownload">
        <div
          className="relative"
          id="background1"
          style={{
            width: dataQr?.size === 0 ? "301px" : "600px",
            height: dataQr?.size === 0 ? "301px" : "600px",
            borderWidth: "1px",
            borderColor: "grey",
            // backgroundImage: image,
            backgroundImage: `url("${big}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute top-0 left-0 w-full text-center font-bold mt-4 pl-4 pr-4">
            {dataQr?.header}
          </div>
          {dataQr?.tableid === 1 ? (
            <div
              className="absolute top-0 left-0 w-full text-center font-bold"
              style={{
                marginTop: dataQr?.size === 0 ? "50px" : "90px",
              }}
            >
              Ширээ № : {tableName}
            </div>
          ) : null}

          <QRCode
            value={encryptedData}
            size={dataQr?.size === 0 ? 150 : 300}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <div
            className="absolute"
            id="background2"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "30px",
              backgroundImage: dataQr?.logo === 1 ? `url("${small}")` : "",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            className="absolute bottom-0 left-0 w-full text-center font-bold pl-4 pr-4"
            style={{ marginBottom: dataQr?.size === 0 ? "20px" : "40px" }}
          >
            {dataQr?.footer}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const TableDrawForQR = (props) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState({});
  const [smallimg, setSmallImg] = useState();
  const [openQrPage, setOpenQrPage] = useState(false);
  const [tableImage, setTableImage] = useState(0);
  const [floorImage, setFloorImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [tableName, setTableName] = useState("");
  const [encryptedData, setEncryptedData] = useState("");
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [big, setBig] = useState(null);
  const [small, setSmall] = useState(null);

  const [dataQr, setQrData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [textHeader, setTextHeader] = useState();
  const [textFooter, setTextFooter] = useState();

  const [qrState, setQrState] = useState({
    smallImg: null,
    showTable: false,
    showLogo: false,
    textHeader: "",
    textFooter: "",
    isOpen: false,
  });

  useEffect(() => {
    if (props.resdintablelist && props.resdintablelist.length) {
      const filteredData = props.resdintablelist.filter(
        (item) => item.isenable === 1
      );
      setData(filteredData);
      if (props.resdintablelist[0]) {
        setFloorImage(props.resdintablelist[0]?.floorid);
      }
    }
    reload();
    form?.setFieldsValue({
      header: dataQr?.header,
      footer: dataQr?.footer,
      tableid: dataQr?.tableid === 1 ? true : false,
      size: dataQr.size,
      logo: dataQr.logo === 1 ? true : false,
    });

    //form?.setFieldsValue({ ...props?.resmenuconfig });
  }, [props.resdintablelist]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setTableImage(0);
    }
  }, [isOpen]);

  const reload = () => {
    props
      .request({
        url: `/menu/config`,
        method: "GET",
        // body: { foodid: props._auth.foodid },
      })
      .then((res) => {
        setQrData(res.data);
        setFiles({ prevqrimg: res?.data?.qrimg });
      });
  };

  const imagePathSmallLogo =
    smallimg === undefined || smallimg === null || smallimg === ""
      ? ""
      : smallimg.replace(/\\/g, "/");
  const smalllogo = `${API_URL}${imagePathSmallLogo}`;

  const imagePath = files?.prevqrimg?.replace(/\\/g, "/");
  const backgroundImg = `${API_URL}${imagePath}`;

  const showimage = dataQr?.img?.replace(/\\/g, "/");
  const smallLogoDownload = `${API_URL}${showimage}`;

  function getBase64Image(imgUrl, callback) {
    if (smalllogo !== undefined || showimage !== undefined) {
      if (imgUrl) {
        fetch(imgUrl)
          .then((response) => response.blob())
          .then((blob) => {
            let reader = new FileReader();
            reader.onloadend = function () {
              callback(reader.result);
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) => console.error("Error:", error));
      }
    }
  }

  getBase64Image(`${API_URL}${imagePath}`, function (base64Image) {
    const big = base64Image;
    setBig(big);
  });
  getBase64Image(`${API_URL}${showimage}`, function (base64Image) {
    const small = base64Image;
    setSmall(small);
  });

  const showQrTemplate = () => {
    form?.setFieldsValue({
      header: dataQr?.header,
      footer: dataQr?.footer,
      tableid: dataQr?.tableid === 1 ? true : false,
      size: dataQr?.size,
      logo: dataQr?.logo === 1 ? true : false,
      qrimg: dataQr?.qrimg,
    });
    // setBackgroundFiles(dataQr?.qrimg);
    // setFiles(dataQr?.qrimg);
    setSmallImg(dataQr?.img);
    setShowTable(dataQr?.tableid === 1 ? true : false);
    setShowLogo(dataQr?.logo === 1 ? true : false);
    setTextHeader(dataQr?.header);
    setTextFooter(dataQr?.footer);
    setIsOpen(true);
    // setQrState({
    //   ...qrState,
    //   smallImg: dataQr?.img,
    //   showTable: dataQr?.tableid === 1,
    //   showLogo: dataQr?.logo === 1,
    //   textHeader: dataQr?.header,
    //   textFooter: dataQr?.footer,
    //   isOpen: true,
    // });
  };

  const onFinish = (values) => {
    const params = {
      footer: values.footer,
      header: values.header,
      tableid: values.tableid === true ? 1 : 0,
      size: values.size,
      logo: values.logo === true ? 1 : 0,
      qrimg: files?.qrimg,
    };
    setLoading(true);
    props
      .request({
        url: QRTemplateSettings.url,
        method: QRTemplateSettings.method,
        body: params,
        isfile: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setIsOpen(false);
          reload();
          owntoast(res.message, "success");
        } else {
          owntoast(res.message, "error");
        }
      });
  };

  const onClickTable = (tableItem) => {
    if (
      dataQr?.img !== null &&
      dataQr?.note !== null &&
      dataQr?.img !== undefined &&
      dataQr?.note !== undefined
    ) {
      form?.setFieldsValue({ qrimg: dataQr?.qrimg });
      let found = data.find((x) => x.id === tableItem.id);
      if (found) {
        let tableFound = props.resdintablelist.find((x) => x.id === found.id);
        if (tableFound) {
          setTableImage(tableFound.tableimage);
          form.setFieldsValue({
            name: tableFound.name,
            description: tableFound.description,
            isenable: tableFound.isenable,
            price: tableFound.price,
            id: tableFound.id,
          });
          setOpenQrPage(true);
          setTableName(tableFound.name);
          const jsonObject = {
            name: tableItem.name,
            foodid: tableItem.foodid,
            id: tableItem.id,
          };

          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(jsonObject),
            "onisfood"
          ).toString();
          // QRMENU_URL
          const url = `${QRMENU_URL}?qr=${ciphertext}`;
          // const url = `http://10.160.0.35:8090?qr=${ciphertext}`;
          setEncryptedData(url);
        }
      }
    } else {
      owntoast("Нүүр хуудасны тохиргоог бүрэн бөглөнө үү", "error");
    }
  };

  const onChangeForm = (e) => {
    if (e.target.id === "footer") setTextFooter(e.target.value);
    else if (e.target.id === "logo") setShowLogo(e.target.checked);
    else if (e.target.id === "tableid") setShowTable(e.target.checked);
    else if (e.target.id === "header") setTextHeader(e.target.value);
  };

  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex gap-2 pb-2">
        <Button
          loading={loading}
          onClick={showQrTemplate}
          icon={<img src={FImage} alt="" className="object-contain mr-1 w-4" />}
        >
          QR загвар
        </Button>
      </div>
      <Spin spinning={loading}>
        <div className="h-full w-full" id="testContainer" ref={containerRef}>
          <TableCustomizer
            data={data}
            setData={setData}
            width={1920}
            height={961}
            isEdit={false}
            containerRef={containerRef}
            floorImage={floorImage}
            onClickTable={onClickTable}
            IsDraggable={false}
            //onClickTableX={onClickTableX}
          />
        </div>
      </Spin>

      <QRPage
        isOpen={openQrPage}
        tableName={tableName}
        dataQr={dataQr}
        encryptedData={encryptedData}
        backgroundImg={backgroundImg}
        smallLogoDownload={smallLogoDownload}
        big={big}
        small={small}
        onClose={() => {
          setOpenQrPage(false);
        }}
      />

      <Components.CustomModal
        title="QR загвар тохируулах"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        width={800}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onChange={onChangeForm}
          defaultValue={{
            id: 0,
          }}
          className="p-4"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <div className="flex items-stretch justify-between">
            <div className="w-1/3">
              {QRTemplateSettings?.leftForms?.form?.map((item, i) => (
                <FormItems
                  key={i}
                  item={item}
                  form={form}
                  files={files}
                  setFiles={setFiles}
                  props={{
                    request: props.request,
                  }}
                />
              ))}
            </div>
            <div className="w-1/2 flex justify-center items-center">
              <div
                className="relative"
                style={{
                  width: "350px",
                  borderWidth: "1px",
                  borderColor: "grey",
                  height: "350px",
                  backgroundImage: files?.prevqrimg?.includes("Resource")
                    ? `url("${backgroundImg}")`
                    : `url("${files.prevqrimg}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="absolute top-0 left-0 w-full text-center font-bold mt-4 pl-8 pr-8">
                  {textHeader}
                </div>
                {showTable === true ? (
                  <div className="absolute top-0 left-0 w-full text-center font-bold mt-16">
                    Ширээ № : 1
                  </div>
                ) : null}

                <QRCode
                  value={"YourValueHere"}
                  size={150}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />

                <div
                  className="absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "30px",
                    height: "30px",
                    backgroundImage:
                      showLogo !== true ? "" : `url("${smalllogo}")`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <div className="absolute bottom-0 left-0 w-full text-center font-bold mb-12 pl-8 pr-8">
                  {textFooter}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-2 justify-end">
            <Button loading={loading} onClick={() => setIsOpen(false)}>
              Хаах
            </Button>
            <Button
              loading={loading}
              className="bg-primary"
              type="primary"
              htmlType="submit"
            >
              Хадгалах
            </Button>
          </div>
        </Form>
      </Components.CustomModal>
    </div>
  );
};

export default TableDrawForQR;
