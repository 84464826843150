import React, { createContext, useReducer } from "react";

/* import * as Ant from "antd"; */
import { useNavigate } from "react-router-dom";
import * as Context from "../";
import { fetchRequest } from "../fetch";
import { API_URL, SERVICE_URL, PAYMENT_SERVICE_URL } from "../../utils";

const initialState = {
  islogin: !!JSON.parse(localStorage.getItem("auth")),
  _auth: JSON.parse(localStorage.getItem("auth")),
  products: [],
  notification: false,
  lang: "mn",
  filter: undefined,
  saleList: [],
  divideList: [],
  order: {},
  errorMsg: "",
  isOpenError: false,
  isLicenseModal: false,
  guideContent: [],
};

const models = {};

// API URL
const service = `${API_URL}api`;

// Local deer suusan service url
const service_url = `${SERVICE_URL}api`;

// qr tulburiin turluudin api url
const payment_service_url = `${PAYMENT_SERVICE_URL}api`;
export const GlobalContext = createContext(initialState);

export const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(Context.appReducer, initialState);

  const addmodel = ({ model }) => {
    models[model] = {
      request: `request_${model}`,
      response: `response_${model}`,
      error: `error_${model}`,
    };
  };
  const navigate = useNavigate();
  const request = async ({
    url,
    model,
    body,
    method = "GET",
    isfile,
    isservice,
    ispaymentservice,
    ismanual,
  }) => {
    try {
      if (isfile && body) {
        let formData = new FormData();
        Object.keys(body).map((keyname) => {
          if (Array.isArray(body[keyname]) && !keyname.includes("prev")) {
            body[keyname].map((item, index) => {
              if (Object.keys(item).length > 0) {
                if (keyname?.includes("img")) {
                  formData.append(`${keyname}`, item);
                } else if (keyname?.includes("removed")) {
                  formData.append(keyname, item);
                } else {
                  formData.append(keyname, JSON.stringify(item));
                }
              } else {
                formData.append(keyname, item);
              }
            });
          } else {
            if (!keyname.includes("prev"))
              formData.append(keyname, body[keyname]);
          }
          return null;
        });
        body = formData;
      }

      if (model) {
        addmodel({ model: model });
      }

      const res = await fetchRequest({
        url: `${
          isservice
            ? `${service_url}${url}`
            : ispaymentservice
            ? `${payment_service_url}${url}`
            : ismanual
            ? `https://food.onispos.mn/files/manual.json`
            : `${service}${url}`
        }`,
        method,
        body,
        isservice,
        ismanual,
        ispaymentservice,
        model: model ? models[model] : null,
        dispatchEvent: dispatch,
        isfile: isfile,
      });
      if (res.status === 401) {
        localStorage.removeItem("auth");
        localStorage.removeItem("access_token");
        setlogin(false);
        navigate("/login");
      }
      /* notification: notification, */
      return res;
    } catch (error) {
      console.log("%c 🍬 error: ", error);
    }
  };

  const setlogin = (login) => dispatch({ type: "login", response: login });
  const setProducts = (data) => dispatch({ type: "products", response: data });
  const setFilter = (data) => dispatch({ type: "filter", response: data });
  const addSaleList = (data) =>
    dispatch({ type: "addSaleList", response: data });
  const addDivideList = (data) =>
    dispatch({ type: "divideList", response: data });
  const removeSaleList = (data) =>
    dispatch({ type: "removeSaleList", response: data });
  const removeDivideList = (data) =>
    dispatch({ type: "removeDivideList", response: data });

  const setOrderDetail = (data) =>
    dispatch({ type: "setOrderDetail", response: data });
  const setError = (data) => dispatch({ type: "setError", response: data });
  const setGuide = (data) => dispatch({ type: "setGuide", response: data });
  const setData = ({ data, model }) =>
    dispatch({ type: model, response: data });

  const setNotification = (value) => {
    dispatch({ type: "notification", response: value });
  };

  /*  const notification = ({ type, message, description }) => {
    Ant.notification[type]({
      message: message,
      description: description,
    });
  }; */
  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          ...state,
          request: request,
          setlogin,
          setProducts,
          setFilter,
          addSaleList,
          addDivideList,
          removeSaleList,
          removeDivideList,
          setOrderDetail,
          setNotification,
          setError,
          setGuide,
          setData,
        }}
      >
        {props.children}
      </GlobalContext.Provider>
    </React.Fragment>
  );
};
