import { useEffect } from "react";

import logo from "../../assets/logos/companyLogo.png";

function Contact(props) {
  useEffect(() => {
    document.title = "Холбоо барих";
  }, []);
  return (
    <div className="flex items-center h-full justify-center bg-white">
      <div className="bg-white w-full h-full 2xl:w-3/5 2xl:h-4/6 p-12 rounded-md border border-gray-200 drop-shadow-xl">
        <div className="grid grid-cols-3 items-center ml-4 flex flex-col text-center h-96 my-auto">
          <div className="col-span-1 w-full h-full xl:w-4/5 2xl:w-full">
            <img className="w-48 h-24" src={logo} alt="logo" />
            <p className="font-bold text-left text-md mt-10">Манай хаяг :</p>
            <p className="text-left text-md xl:w-8/12 2xl:w-10/12">
              Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, 8-р хороо, Сан
              Бизнес төв 8 давхарт
            </p>
            <div className="flex display-flex">
              <p className="font-bold text-left text-md">Утас :</p>
              <p className="text-left text-md">+976 7606-0006</p>
            </div>
            <div className="flex display-flex">
              <p className="font-bold text-left text-md">Имэйл :</p>
              <p className="text-left text-md ml-1">info@datacare.mn</p>
            </div>
            <div className="flex display-flex">
              <p className="font-bold text-left text-md">Цахим хуудас :</p>
              <a
                className="text-left text-md ml-1"
                href="https://onispos.mn/"
                target="_blank"
              >
                www.onispos.mn
              </a>
            </div>
            <div className="flex display-flex">
              <p className="font-bold text-left text-md">Сошиал холбоос :</p>
              <a
                href="https://www.facebook.com/OnisSystem"
                rel="noreferrer"
                target="_blank"
                className="text-sky-600 ml-2"
              >
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/onissystems"
                rel="noreferrer"
                target="_blank"
                className="ml-3 text-red-500"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
            </div>
            <div className="flex display-flex">
              <p className="font-bold text-left text-md">what3words хаяг :</p>
              <a
                className="text-left text-md ml-1"
                href="https://what3words.com/%D0%B4%D0%B0%D1%80%D1%86%D0%B0%D0%B3.%D1%86%D0%BE%D0%B3%D0%B8%D1%85.%D0%BE%D1%80%D0%BE%D0%B9%D0%B4%D0%BE%D0%BE?alias=%D0%B4%D0%B0%D1%80%D1%86%D0%B0%D0%B3.%D1%86%D0%BE%D0%B3%D0%B8%D1%85.%D0%BE%D1%80%D0%BE%D0%B9%D0%B4%D0%BE%D0%BE"
                target="_blank"
                translate="no"
              >
                ///дарцаг.цогих.оройдоо
              </a>
            </div>
          </div>
          <div className="lg:w-80 xl:w-72 w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1336.9205802733202!2d106.9209605!3d47.9201108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692412f0e3ef9%3A0x7e657309dcfec844!2sSAN%20Business%20Center!5e0!3m2!1smn!2smn!4v1631063654765!5m2!1smn!2smn"
              width="600"
              height="450"
              frameborder="2"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
