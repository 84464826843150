import React, { useState } from "react";
import * as Components from "../../components";
import MasterRegister from "../MasterRegister";
import { useNavigate } from "react-router-dom";
import success from "../../assets/icons/success.svg";
import { Button } from "antd";

function Success(props) {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("register"));
  const [isBranch] = useState(props.isBranch ? true : false);
  const [isOpen, setIsOpen] = useState(false);
  let tmp = {
    branchid: data.foodid,
    name: data.name,
  };
  const handleDone = () => {
    localStorage.setItem("foodid", data.foodid);
    localStorage.setItem("username", data.phone);
    if (data.type) {
      localStorage.removeItem("register");
      navigate("/login");
    } else {
      navigate("/registermap");
    }
  };

  const handleGoHome = () => {
    setIsOpen(false);
    props.setStep(0);
    props.setProcent(0);
    localStorage.removeItem("register");
    navigate("/");
  };

  return (
    <div className="items-center flex flex-col text-center">
      <p className="font-bold text-lg">
        {isBranch ? "САЛБАР АМЖИЛТТАЙ БҮРТГЭГДЛЭЭ" : "НЭВТРЭХ БҮРТГЭЛ"}
      </p>
      <div className="w-full mt-4 items-center flex flex-col">
        <div className="lg:w-72 w-full items-center flex flex-col">
          <img className="w-16 h-16" src={success} alt="logo" />
          {!isBranch && (
            <p className="text-gray-500 text-md mt-8">
              Та ОНЬС системд нэгдлээ
            </p>
          )}
          {!isBranch && (
            <p className="text-gray-500 text-md">
              Мэйл хаягтаа ирсэн бүртгэлийн кодоороо нэвтрэн орж системээ
              ашиглаарай
            </p>
          )}
          {!isBranch ? (
            <Components.Ripple
              className="bg-primary h-8 w-full flex items-center justify-center text-white rounded-sm lg:mt-16"
              onClick={handleDone}
            >
              НЭВТРЭХ
            </Components.Ripple>
          ) : (
            <div>
              <p className="text-gray-500 text-md mt-4">
                ТА ҮНДСЭН САЛБАРЫНХАА МАСТЕР МЭДЭЭЛЛИЙГ ТАТАЖ ХАДГАЛАХ УУ?
              </p>
              <div className="w-full flex flex-row lg:mt-8 justify-center">
                <Button
                  className="rounded-sm w-16 bg-modalHeader border-modalHeader text-white"
                  onClick={handleGoHome}
                >
                  Үгүй
                </Button>
                <Button
                  className="rounded-sm ml-4 w-16 bg-primary"
                  type="primary"
                  onClick={() => setIsOpen(true)}
                >
                  Тийм
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="hidden">
        <MasterRegister
          isOpen={isOpen}
          data={tmp}
          setIsOpen={handleGoHome}
          request={props.request}
        />
      </div>
    </div>
  );
}

export default Success;
