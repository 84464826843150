import { useEffect, useContext, useState, useRef } from "react";
import { Button, Drawer, DatePicker } from "antd";
import dayjs from "dayjs";
import { GlobalContext } from "../../context";
import { KitchenCard } from "../../components";
import notfSound from "../../assets/sound/notification.mp3";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const FoodCount = ({ productname, qty }) => {
  return (
    <div className="flex flex-col items-center justify-center p-2 border shadow-lg bg-white">
      <span className="w-24 text-center">{productname}</span>
      <div className="mt-2 font-bold text-white flex items-center justify-center rounded-full w-7 h-7 bg-[#E57985]">
        {qty}
      </div>
    </div>
  );
};

const EndOrderDrawer = (props) => {
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    if (props.openDrawer) {
      props.request({
        url: `/sale/kitchen/list/1/${date.format("YYYY-MM-DD")}`,
        model: "endKitchenList",
      });
    }
  }, [props.openDrawer, date]);

  const onChangeDate = (e) => {
    setDate(e);
  };

  return (
    <Drawer
      title="Дууссан захиалгууд "
      placement="bottom"
      height={600}
      onClose={() => props.setOpenDrawer(false)}
      open={props.openDrawer}
    >
      <DatePicker value={date} onChange={onChangeDate} allowClear={false} />
      <div className="h-full relative overflow-auto mt-3">
        <div className="absolute flex flex-nowrap overflow-auto h-full gap-3 pb-3">
          {props.resendKitchenList &&
            props.resendKitchenList.map((item, i) => (
              <KitchenCard
                key={i}
                {...item}
                request={props.request}
                ended={true}
              />
            ))}
        </div>
      </div>
    </Drawer>
  );
};

const Kitchen = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setIsOpen] = useState("h-0");
  const [catId, setCatId] = useState([]);
  const detailsQty = useRef(-1);
  const context = useContext(GlobalContext);

  useEffect(() => {
    const qty = context?.reskitchenList?.reduce(
      (a, v) => (a = a + v.detailqty),
      0
    );
    if (qty > -1 && qty !== detailsQty?.current) {
      var sound;
      sound = new window.Howl({
        src: [notfSound],
      });
      sound.play();
    }
    detailsQty.current = qty === 0 ? -1 : qty;
  }, [context?.reskitchenList]);

  const setData = (data) => {
    props.setData({ data: data, model: "setdata_kitchenList" });
  };

  const setData1 = (data) => {
    props.setData({ data: data, model: "setdata_kitchenfoods" });
  };

  useEffect(() => {
    context.request({
      url: "/sale/kitchen/list/0/2022-01-01",
      model: "kitchenList",
    });
    context.request({ url: "/sale/kitchen/products", model: "kitchenfoods" });

    if (props.wbConnection) {
      props.wbConnection.on("kitchen", setData);
      props.wbConnection.on("kitchenproduct", setData1);
    }

    return function clenup() {
      if (props.wbConnection) {
        props.wbConnection.off("kitchen", setData);
        props.wbConnection.off("kitchenproduct", setData1);
      }
    };
  }, []);

  useEffect(() => {
    let tmp = [];
    props?.rescategorylist?.map((item, i) => {
      item.isChoosed = true;
      tmp = [...tmp, item.id];
    });
    setCatId(tmp);
  }, [props.rescategorylist, props.resendKitchenList]);

  const onChangeHeight = () => {
    if (isOpen === "h-0") setIsOpen("h-40");
    else setIsOpen("h-0");
  };

  const renderCatButton = () => {
    let tmp = props?.rescategorylist?.map((item, i) => {
      if (item.isenable === 1) {
        return (
          <Button
            key={i}
            value={item.id}
            className={`mr-2 ${item.isChoosed ? "border-red-600" : ""}`}
            onClick={() => {
              onChangeCategory(i, item.isChoosed ? false : true);
            }}
          >
            {item.name}
          </Button>
        );
      }
    });
    return tmp;
  };

  const onChangeCategory = (i, isChoosed) => {
    props.rescategorylist[i].isChoosed = isChoosed;
    let find = catId.find((x) => x === props.rescategorylist[i].id);
    if (find) setCatId(catId.filter((x) => x !== props.rescategorylist[i].id));
    else setCatId([...catId, props.rescategorylist[i].id]);
  };

  return (
    <div className="px-10 py-1 flex flex-col justify-between h-full">
      <div className="flex justify-between">
        <Button className="w-fit my-2 flex items-center justify-center">
          Хүлээгдэж байгаа захиалга
          {context.reskitchenList && context.reskitchenList.length > 0 && (
            <div className="flex items-center justify-center ml-2 h-5 w-5 bg-kitchenYellow rounded-full text-white font-bold">
              {context.reskitchenList.length}
            </div>
          )}
        </Button>
        <Button onClick={() => setOpenDrawer(true)}>
          Дууссан захиалгууд харах
        </Button>
      </div>
      <div className="flex h-16 my-4 w-full overflow-x-auto">
        {renderCatButton()}
      </div>
      <div className="h-full relative overflow-auto mb-6 ">
        <div className="absolute flex flex-nowrap overflow-auto h-full gap-3 pb-6">
          {context.reskitchenList &&
            context.reskitchenList.map((item, i) => (
              <div key={i} className="h-full w-64 ">
                <KitchenCard
                  {...item}
                  request={context.request}
                  catId={catId}
                />
              </div>
            ))}
        </div>
      </div>
      <Button
        onClick={onChangeHeight}
        icon={isOpen === "h-0" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        className="w-fit mb-2 flex items-center justify-center"
      >
        Хүлээгдэж байгаа хоолнууд
        {context.reskitchenfoods && context.reskitchenfoods.length > 0 && (
          <div className="flex items-center justify-center ml-2 h-5 w-5 bg-kitchenYellow rounded-full text-white font-bold">
            {context.reskitchenfoods.length}
          </div>
        )}
      </Button>
      <div className={`${isOpen} transition-all`}>
        <div className="flex gap-3 flex-nowrap overflow-auto pb-6">
          {context.reskitchenfoods &&
            context.reskitchenfoods.map((item, i) => (
              <FoodCount {...item} key={i} />
            ))}
        </div>
      </div>
      <EndOrderDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        {...context}
      />
    </div>
  );
};

export default Kitchen;
