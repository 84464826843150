import { useState, useEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import ListItem from "./ListItem";
import { Button, Popconfirm, Tooltip } from "antd";
import * as Components from "../";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { getEnabledOptions, owntoast, postMessageMobile } from "../../utils";
import { DeleteOrderPerm } from "../../utils/enums";
import comment from "../../assets/comment.png";
import { useReactToPrint } from "react-to-print";
import { Input } from "antd";
import { SaleBill } from "../SaleBill";
import { ReceiptType, PaperSize } from "../../utils/enums";
import {
  CheckOutlined,
  CommentOutlined,
  DeleteOutlined,
  PrinterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import iLocation from "../../assets/icons/location.png";
const CustomPop = ({ children, text, onConfirm, loading }) => {
  return (
    <Popconfirm
      title={() => {
        return (
          <div className="flex flex-col">
            <span>{text}</span>
          </div>
        );
      }}
      placement="topLeft"
      onConfirm={(e) => onConfirm && onConfirm(e)}
      okText="Тийм"
      loading={loading}
      cancelText="Үгүй"
    >
      {children}
    </Popconfirm>
  );
};

function OrderTable(props) {
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [isOpenCombine, setIsOpenCombine] = useState(false);
  const [isOpenWaiter, setIsOpenWaiter] = useState(false);
  const { saleList, order, activeTable, setActiveTable, setOrderDetail } =
    props;
  const [waiter, setWaiter] = useState(order?.waiterid || 0);
  useEffect(() => {
    setText(order?.comment);
  }, [order?.comment]);

  useEffect(() => {
    setWaiter(order?.waiterid || 0);
    if (order?.waiterid) {
      localStorage.setItem("waiter", order?.waiterid);
    }
  }, [order]);

  useEffect(() => {
    if (isOpenWaiter) {
      if (!order) setWaiter(0);
    }
  }, [isOpenWaiter]);

  const [text, setText] = useState(order?.comment);
  const [receiptData, setReceiptData] = useState(null);
  const data = getEnabledOptions(props, "dintablelist");

  const orderRef = useRef();
  useEffect(() => {
    if (receiptData) {
      printOrder();
    }
  }, [receiptData]);

  const checkDeleteOrder = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: DeleteOrderPerm,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };

  const recycleTable = (type) => {
    if (type === 1) {
      if (checkDeleteOrder()) {
        if (order) {
          setLoading(true);
          props
            .request({
              url: `/sale/orderrecycle/${order?.id}/${type}`,
              method: "POST",
            })
            .then((res) => {
              setLoading(false);
              if (res.success) {
                if (type) {
                  setOrderDetail &&
                    setOrderDetail({
                      order: {},
                      detail: [],
                    });
                } else {
                  props
                    .request({ url: `/sale/orderdetail/${order?.id}` })
                    .then((detres) => {
                      if (detres.success) props.setOrderDetail(detres?.data);
                    });
                }
                setActiveTable && setActiveTable(null);
                props.request({
                  url: `/category/dintable/list`,
                  method: "POST",
                  model: "dintablelist",
                  body: { foodid: props._auth.foodid },
                });
                props.request({
                  url: "/sale/orderlist/ongoing",
                  model: "ongoingorders",
                });
                owntoast(res.message, "success");
              } else {
                owntoast(res.message, "error");
              }
            });
        }
      }
    } else {
      if (order) {
        setLoading(true);
        props
          .request({
            url: `/sale/orderrecycle/${order?.id}/${type}`,
            method: "POST",
          })
          .then((res) => {
            setLoading(false);
            if (res.success) {
              if (type) {
                setOrderDetail &&
                  setOrderDetail({
                    order: {},
                    detail: [],
                  });
              } else {
                props
                  .request({ url: `/sale/orderdetail/${order?.id}` })
                  .then((detres) => {
                    if (detres.success) props.setOrderDetail(detres?.data);
                  });
              }
              setActiveTable && setActiveTable(null);
              props.request({
                url: `/category/dintable/list`,
                method: "POST",
                model: "dintablelist",
                body: { foodid: props._auth.foodid },
              });
              props.request({
                url: "/sale/orderlist/ongoing",
                model: "ongoingorders",
              });
              owntoast(res.message, "success");
            } else {
              owntoast(res.message, "error");
            }
          });
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    // setText(order?.id ? text : "");
  };

  const Save = () => {
    const params = {
      orderid: order?.id,
      comment: text,
    };
    if (order.id !== undefined) {
      props
        .request({
          url: "/sale/postcomment",
          method: "POST",
          body: params,
        })
        .then((res) => {
          if (res.success) {
            owntoast(res.message, "success");
          } else {
            owntoast(res.message, "error");
          }
        });
      setIsOpen(false);
    } else {
      localStorage.setItem("comment", text);
      setIsOpen(false);
    }
  };

  const handleTextAreaChange = (e) => {
    setText(e.target.value);
  };

  const printOrder = useReactToPrint({
    content: () => orderRef.current,
  });

  const getPrinterSize = () => {
    if (props.resprinterconfig) {
      if (props.resprinterconfig.printertype) {
        return props.resprinterconfig.printertype;
      }
    }
    return PaperSize.m58;
  };

  const print = (data) => {
    if (data) {
      const deviceType = localStorage.getItem("deviceType");
      const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));
      let body = {
        ...data,
        vatname: props._auth.vatname,
        regno: props._auth.regno,
        foodsname: props._auth.foodsname,
        printersize: getPrinterSize(),
      };
      if (props.rescheckService === true) {
        props.request({
          url: `/print/${getPrinterSize()}`,
          body: body,
          method: "POST",
          isservice: true,
        });
      } else if (deviceType === "ios" || deviceType === "android") {
        if (bluetooth && bluetooth.address) {
          postMessageMobile({
            type: "PRINT_BILL",
            data: JSON.stringify({ btaddress: bluetooth.address, ...body }),
          });
        } else {
          owntoast("Bluetooth принтер сануулаагүй байна.", "error");
        }
      } else {
        setReceiptData(body);
      }
    }
  };

  const Check = () => {
    if (
      JSON.stringify(props.prevDetail) !== JSON.stringify(props.saleList) ||
      props.activeTable !== props.prevOrder?.tableid
    ) {
      props.setOpenSave(true);
    } else {
      handleReprint();
    }
  };
  const handleReprint = () => {
    setLoadingPrint(true);
    props.request({ url: `/sale/orderprint/${order?.id}` }).then((res) => {
      setLoadingPrint(false);
      if (res.success) {
        const data = {
          ...res.data,
          isReturn: false,
          isReprint: true,
          type: ReceiptType.Борлуулалт,
        };

        owntoast(res.message, "success");
        print({ ...data, isReturn: false, isReprint: true });
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  const combine = () => {
    setIsOpenCombine(true);
  };

  const getPopConfirmMsg = () => {
    if (order?.mainorder) {
      return `Тухайн захиалгын тооцоог хувааж төлсөн байна. ${order?.salenum} захиалгыг утсгахдаа итгэлтэй байна уу ?`;
    }
    return `${order?.salenum} дугаартай захиалга устах болно. Итгэлтэй байна уу ?`;
  };

  const openLocation = () => {
    props?.setIsOpenLocation(true);
  };

  const handleSaveLocation = () => {};

  const saveWaiter = () => {
    if (order.id !== undefined) {
      const params = {
        orderid: order?.id,
        waiterid: waiter,
      };
      props
        .request({
          url: "/sale/waiter",
          method: "POST",
          body: params,
        })
        .then((res) => {
          if (res.success) {
            props?.getOrderDetail(order?.id);
            owntoast(res.message, "success");
          } else {
            owntoast(res.message, "error");
          }
        });
      setIsOpenWaiter(false);
    } else {
      localStorage.setItem("waiter", waiter);
      setIsOpenWaiter(false);
    }
  };

  const getTableName = () => {
    let table = null;
    if (data && data.length && activeTable) {
      table = data.find((item) => item.id === activeTable);
    }
    return (
      <div className="px-2 flex flex-col gap-1">
        <div className="hidden">
          <SaleBill
            ref={orderRef}
            data={receiptData}
            printqty={props.resprinterconfig && props.resprinterconfig.printqty}
          />
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-5">
            {order?.salenum ? (
              <span className="text-grey">
                Захиалга:
                <span className="font-bold text-black ml-2">
                  № {order?.salenum}
                </span>
              </span>
            ) : null}
            {table ? (
              <span className="text-grey">
                Ширээ:
                <span className="font-bold text-black ml-2">
                  № {table.name}
                </span>
              </span>
            ) : null}
          </div>
          <div>
            {order?.waitername ? (
              <span className="text-grey">
                Зөөгч:
                <span className="font-bold text-black ml-2">
                  {order?.waitername}
                </span>
              </span>
            ) : null}
          </div>
        </div>

        <div className="flex overflow-auto gap-1 justify-end">
          {table && order?.id && (
            <CustomPop
              text={`${table.name} дугаартай ширээг хоослох уу ?`}
              onConfirm={() => recycleTable(0)}
              loading={loading}
            >
              <Button size="small" type="dashed" loading={loading}>
                Ширээ чөлөөлөх
              </Button>
            </CustomPop>
          )}

          {order?.id && (
            <Button
              size="small"
              type="dashed"
              loading={loadingPrint}
              onClick={combine}
            >
              Захиалга нэгтгэх
            </Button>
          )}
          <Tooltip title="Зөөгч оруулах">
            <Button
              size="small"
              type="dashed"
              className="flex items-center justify-center"
              onClick={() => setIsOpenWaiter(true)}
              loading={loading}
              icon={<UserOutlined className="text-primary" />}
            />
          </Tooltip>

          {props?.activeDelivery === 5 && (
            <Tooltip title="Хүргэлтийн хаяг">
              <Button
                size="small"
                type="dashed"
                className="p-0.5"
                icon={<img src={iLocation} alt="iLocation" />}
                onClick={openLocation}
              />
            </Tooltip>
          )}
          {order?.id && (
            <Tooltip title="Захиалга хэвлэх">
              <Button
                size="small"
                type="dashed"
                icon={<PrinterOutlined className="text-primary" />}
                loading={loadingPrint}
                onClick={Check}
              />
            </Tooltip>
          )}
          <Tooltip title="Хэрэглэгчийн хүсэлт оруулах">
            <Button
              size="small"
              type="dashed"
              className="flex items-center justify-center"
              onClick={() => setIsOpen(true)}
              loading={loading}
              icon={<CommentOutlined className="text-primary" />}
            />
          </Tooltip>

          {order?.id && (
            <CustomPop
              text={getPopConfirmMsg()}
              onConfirm={() => recycleTable(1)}
              loading={loading}
            >
              <Tooltip title="Захиалга устгах">
                <Button
                  size="small"
                  type="dashed"
                  icon={<DeleteOutlined className="text-primary" />}
                  loading={loading}
                />
              </Tooltip>
            </CustomPop>
          )}
          <Components.CustomModal
            width={387}
            height={308}
            isOpen={isOpen}
            onClose={closeModal}
            title={"Хэрэглэгчийн хүсэлт оруулах"}
            footer={[
              <Button onClick={closeModal}>Хаах</Button>,
              <Button type="primary" autoFocus onClick={Save}>
                Хадгалах
              </Button>,
            ]}
          >
            <div className="p-4 items-center">
              <div className="flex flex-row">
                <img src={comment} alt="smile" className="w-6 h-6 mr-2" />
                <div>Хэрэглэгчийн хүсэлтийг оруулах</div>
              </div>
              <Input.TextArea
                onChange={handleTextAreaChange}
                className="w-full mt-4"
                rows={5}
                value={text}
                maxLength={100}
              />
            </div>
          </Components.CustomModal>
          <Components.CustomModal
            width={387}
            height={308}
            isOpen={isOpenWaiter}
            onClose={() => setIsOpenWaiter(false)}
            title={"Зөөгч сонгох"}
            footer={[
              <Button onClick={() => setIsOpenWaiter(false)}>Хаах</Button>,
              <Button type="primary" autoFocus onClick={saveWaiter}>
                Хадгалах
              </Button>,
            ]}
          >
            <div className="p-4 grid grid-cols-3 gap-2">
              {props?.reswaiters?.map((item, i) =>
                item.isenable ? (
                  <Button
                    icon={waiter === item?.id ? <CheckOutlined /> : null}
                    type={waiter === item?.id ? "primary" : "default"}
                    key={i}
                    onClick={() => {
                      if (item?.id === waiter) {
                        setWaiter(0);
                      } else setWaiter(item?.id);
                    }}
                  >
                    {item?.name}
                  </Button>
                ) : null
              )}
              {/* <div className="flex flex-row">
                <img src={comment} alt="smile" className="w-6 h-6 mr-2" />
                <div>Зөөгч сонгох</div>
              </div> */}
            </div>
          </Components.CustomModal>

          <Components.CustomModal
            width={387}
            height={308}
            isOpen={props?.isOpenLocation}
            onClose={() => props?.setIsOpenLocation(false)}
            title={"Хүргэлтийн хаяг оруулах"}
            footer={null}
          >
            <Components.DeliveryLocation
              isOpenLocation={props?.isOpenLocation}
              setIsOpenLocation={props?.setIsOpenLocation}
              {...props}
            />
          </Components.CustomModal>
        </div>

        {/* <span className="text-grey">{dayjs().format("YYYY-MM-DD")}</span> */}
        <Components.OrderCombineModal
          isOpen={isOpenCombine}
          setIsOpen={setIsOpenCombine}
          order={order}
          ongoingorders={props?.resongoingorders || []}
          request={props?.request}
          setOrderDetail={setOrderDetail}
          setError={props?.setError}
        />
      </div>
    );
  };

  return (
    <div className="bg-white py-2 px-1 rounded-md mb-3 grow h-full flex flex-col">
      {getTableName()}
      <div className="relative h-full mt-2">
        <div className="px-2 absolute top-0 bottom-0 overflow-y-auto w-full">
          <AnimatePresence>
            {saleList.map((item, i) => (
              <ListItem
                item={item}
                key={i}
                i={i}
                {...props}
                activeProduct={props.activeProduct}
                setActiveProduct={props.setActiveProduct}
              />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default OrderTable;
