import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
function TableExcel({ name }) {
  return (
    <div>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        table="table-to-xls"
        filename={name}
        sheet="Жагсаалт"
        buttonText="Эксел"
      />
    </div>
  );
}

export default TableExcel;
