import React, { useState } from "react";
import { Form, Button } from "antd";
import { StopOutlined, SaveOutlined } from "@ant-design/icons";
import { owntoast } from "../../../utils";
import { AntdTable, CustomModal } from "../../";
import { KioskDeviceModalColumns } from "../../../data/Columns";
import { useEffect } from "react";

function Modals(props) {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {}, [props?.kioskList]);

  const formSubmit = () => {
    if (selectedRows.keys && selectedRows.keys.length > 0) {
      props?.setKioskList(selectedRows);
      setOpen(false);
    } else {
      owntoast("Киоск сонгоно уу", "error");
    }
  };

  useEffect(() => {
    if (open) {
      props.request({ url: `/kiosk/device`, model: "kioskdevices" });
      setSelectedRows(props?.kioskList);
    }
  }, [open]);

  return (
    <div className="mt-2">
      <Button
        onClick={() => setOpen(true)}
        type="dashed text-kitchenYellow border-kitchenYellow"
        className="w-fit"
        disabled={props?.disabled}
      >
        Киоск нэмэх
      </Button>
      <CustomModal
        title="Киоск сонгох"
        isOpen={open}
        onClose={() => setOpen(false)}
        footer={null}
        width={750}
      >
        <Form onFinish={formSubmit} form={form} className="p-4">
          <AntdTable
            columns={KioskDeviceModalColumns}
            dataSource={props?.reskioskdevices}
            isModal
            isSelectRow={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              width: "100%",
              marginRight: 15,
            }}
          >
            <Button
              icon={<StopOutlined />}
              onClick={() => setOpen(false)}
              style={{ backgroundColor: "#595959", color: "white" }}
            >
              Болих
            </Button>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5 }}
            >
              Сонгох
            </Button>
          </div>
        </Form>
      </CustomModal>
    </div>
  );
}
export default Modals;
