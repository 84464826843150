import iClock from "../../../assets/icons/icClock.svg";
import iStar from "../../../assets/icons/star.svg";
import hat from "../../../assets/hat.png";
import snowCap from "../../../assets/snow-cap.png";
import { isNewYear } from "../../../utils";
import { useRef, useEffect } from "react";
const title = {
  1: "хүлээгдэж байгаа",
  2: "бэлтгэгдсэн захиалга",
};

const icon = {
  1: iClock,
  2: iStar,
};

const bgColor = {
  1: "bg-primaryLight",
  2: "bg-mainOrange",
};

const Index = (props) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        let newScrollPosition = scrollRef.current.scrollTop + 2;
        if (
          newScrollPosition >=
          scrollRef.current.scrollHeight - scrollRef.current.clientHeight
        ) {
          newScrollPosition = 0;
        }
        scrollRef.current.scrollTo(0, newScrollPosition, {
          behavior: "smooth",
        });
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="col-span-6 flex flex-col h-full">
      <div className="relative flex items-center justify-center px-6 py-5 rounded-md shadow-md h-32 bg-labelGrey">
        <span className="font-bold text-3xl uppercase">
          {title[props?.type || 1]}
        </span>
        <img
          src={icon[props?.type || 1]}
          alt="iClock"
          className="absolute right-2 top-2 w-6 h-6"
        />
        {isNewYear() ? (
          <img
            src={hat}
            alt="hat"
            className="absolute -top-5 -right-8 rotate-45 w-14 h-14 z-10"
          />
        ) : null}
      </div>
      <div className="relative h-full ">
        <div
          ref={scrollRef}
          className=" overflow-y-auto absolute top-0 bottom-0 w-full py-5"
        >
          <div className="grid grid-cols-6 gap-4">
            {props?.data?.map((item, i) => (
              <div
                key={i}
                className={`col-span-3 ${
                  bgColor[props?.type || 1]
                } rounded-md border border-white flex items-center justify-center h-28 shadow shadow-md relative`}
              >
                {isNewYear() ? (
                  <img
                    src={snowCap}
                    alt="snowCap"
                    className="w-full absolute h-fit z-20 -top-4"
                  />
                ) : null}

                <span className="text-white font-bold text-7xl">
                  {item?.salenum}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
