import { useState, useRef, useEffect } from "react";
import * as Components from "../";
import {
  Input,
  Button,
  Form,
  InputNumber,
  Table,
  Spin,
  Checkbox,
  Radio,
} from "antd";
import {
  LICENSE_MSG,
  PRICE_PARSERS,
  owntoast,
  postMessageMobile,
  useMobileEvent,
} from "../../utils";

import { DesktopOutlined, YoutubeOutlined } from "@ant-design/icons";
import loader from "../../assets/logos/loader.gif";
import {
  DiscountTableColumn,
  PaymentCloseColumn,
  ClosePaymentData,
} from "../../data/Columns";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import {
  DiscountPerm,
  ReceiptType,
  PaperSize,
  NLicenceType,
} from "../../utils/enums";
import { SaleBill } from "../SaleBill";
import Hotkeys from "react-hot-keys";
import Chair from "../../assets/chair2.png";
import UsefulFunctions from "../../utils/UsefulFunctions";
const { Search } = Input;

/* Хоолны зартын жагсаалт болон түүнээс хайх */
function OrderProducts(props) {
  const orderRef = useRef();
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const { filter, resfilteredproducts, isfilteredproducts } = props;
  const [isPaymentClose, setIsPaymentClose] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [receiptData, setReceiptData] = useState(null);
  const [form] = Form.useForm();
  const [ischeck, setischeck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isopenwarning, setisopenwarning] = useState(false);
  const [sms, setsms] = useState("");

  useEffect(() => {
    if (receiptData) {
      printOrder();
    }
  }, [receiptData]);

  useEffect(() => {
    props.request({ url: "/sale/checkset", model: "checkset" }).then((res) => {
      if (!res.success) {
        setsms(res.message);
        setisopenwarning(true);
      }
    });
  }, []);

  const getPrinterSize = () => {
    if (props.resprinterconfig) {
      if (props.resprinterconfig.printertype) {
        return props.resprinterconfig.printertype;
      }
    }
    return PaperSize.m58;
  };

  let newDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const print = (data) => {
    if (data) {
      const deviceType = localStorage.getItem("deviceType");
      const bluetooth = JSON.parse(localStorage.getItem("bluetooth"));
      let body = {
        amount: data.totalamount,
        saleamount: data.saleamount,
        returnamount: data.returnamount,
        products: data.saleDetail,
        setProds: data.salePackSet,
        username: props._auth.username,
        insymd: data?.insymd,
        cash: 0,
        noncash: 0,
        type: ReceiptType.Хаалт,
        totalqty: data.totalqty,
        salePay: data.salePay,
        useramount: data.useramount,
        timeamount: data.timeamount,
        fee: data.fee,
        ischeck: ischeck,
        printersize: getPrinterSize(),
        printBillType: data.printBillType,
      };
      if (body.products && body.products.length) {
        const cats = body?.products?.reduce((a, item) => {
          const obj = a.get(item.catid) || { products: [] };
          obj.catid = item.catid;
          if (!obj.catid) {
            obj.catname = "Бусад";
          } else {
            obj.catname = item.catname;
          }

          obj.products.push({ ...item });
          return a.set(item.catid, obj);
        }, new Map());

        const pays = body?.salePay?.reduce((a, item) => {
          const obj = a.get(item.saledate) || { salePay: [] };
          obj.saledate = item.saledate;
          if (!obj.saledate) {
            obj.saledate = "Бусад";
          } else {
            obj.saledate = item.saledate;
          }

          obj.salePay.push({ ...item });
          return a.set(item.saledate, obj);
        }, new Map());

        body.products = [...cats.values()].sort((a, b) =>
          a?.catid > b?.catid ? -1 : 1
        );
        body.salePay = [...pays.values()].sort((a, b) =>
          a?.saledate > b?.saledate ? -1 : 1
        );
      }
      if (props.rescheckService === true) {
        props.request({
          url: `/print/settlement/${
            props.resprinterconfig && props.resprinterconfig.printertype
              ? props.resprinterconfig.printertype
              : PaperSize.m58
          }`,
          body: body,
          method: "POST",
          isservice: true,
        });
      } else if (deviceType === "ios" || deviceType === "android") {
        if (bluetooth && bluetooth.address) {
          postMessageMobile({
            type: "PRINT_BILL",
            data: JSON.stringify({ btaddress: bluetooth.address, ...body }),
          });
        } else {
          owntoast("Bluetooth принтер сануулаагүй байна.", "error");
        }
      } else {
        setReceiptData(body);
      }
    }
  };

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "RESULT_PRINT_BILL") {
          if (data.success) {
            owntoast(data.message, "success");
          } else {
            owntoast(data.message, "error");
          }
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const printOrder = useReactToPrint({
    content: () => orderRef.current,
  });

  const searchData = () => {
    if (!resfilteredproducts) return [];
    if (text) {
      let newList = resfilteredproducts.filter(
        (a) =>
          (a.name && a.name.toLowerCase().includes(text.toLowerCase())) ||
          (a.barcode && a.barcode.toLowerCase().includes(text.toLowerCase()))
      );
      return newList;
    } else {
      return resfilteredproducts;
    }
  };
  const handleOpen = () => {
    if (check(DiscountPerm)) {
      if (props.activeProduct && Object.keys(props.activeProduct).length > 0) {
        if (props.activeProduct.isset === 1) {
          owntoast("Сэт хоолонд хямдрал оруулах боломжгүй.", "error");
        } else if (props.activeProduct.istime) {
          owntoast("Цагийн төлбөрт хямдрал оруулах боломжгүй.", "error");
        } else {
          setIsOpen(true);
        }
      } else {
        owntoast("Мөр сонгоно уу.", "error");
      }
    }
  };

  const check = (permission, license) => {
    return UsefulFunctions.checkPermission({
      pagePermission: permission,
      permission: props._auth.permission,
      reslicense: props.reslicense,
      isMsg: true,
      setError: props.setError,
      license: license,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      props.setActiveProduct({});
    }
  }, [isOpen]);

  const handleClosePayment = () => {
    if (
      props?.reslicense?.detail?.find(
        (x) => Number(x) === NLicenceType.cashierset
      )
    ) {
      setIsPaymentClose(true);
      setIsOpen(true);
    } else {
      props?.setError({
        isOpen: true,
        message: LICENSE_MSG,
        isLicenseModal: true,
      });
    }
  };

  const handleChangeDiscount = (e) => {
    if (props.activeProduct) {
      form.setFieldsValue({
        unitdiscount: e,
        amount: props.activeProduct.price - e,
        discountPercent: Math.round((e * 100) / props.activeProduct.price, 2),
      });
    }
  };

  const handleDiscount = (values) => {
    let product = { ...props.activeProduct };
    product.unitdiscount = values.discount;
    props.addSaleList({ ...product, type: "discount" });
    form.resetFields();
    setIsOpen(false);
  };

  const onKeyDown = (keyName, e, handle) => {
    if (e.keyCode === 117) {
      handleOpen();
    }
  };

  const closeModal = () => {
    if (isPaymentClose) {
      form.resetFields();
      setIsPaymentClose(false);
      for (var i = 0; i < ClosePaymentData.length; i++) {
        ClosePaymentData[i].qty = 0;
        ClosePaymentData[i].totalamt = 0;
      }
    }
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpenWarning(true);
  };

  const handlePaymentClose = (e) => {
    var total = 0;

    for (var i = 0; i < ClosePaymentData.length; i++) {
      if (ClosePaymentData[i].totalamt) {
        total = total + ClosePaymentData[i].totalamt;
      }
      ClosePaymentData[i].qty = 0;
      ClosePaymentData[i].totalamt = 0;
    }
    setLoading(true);
    props
      .request({ url: `/sale/payset/${total}/${ischeck}`, method: "PUT" })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          owntoast(res.message, "success");
          props.request({
            url: `/category/dintable/list`,
            method: "POST",
            model: "dintablelist",
            body: { foodid: props._auth.foodid },
          });
          setIsOpenWarning(false);
          setIsOpen(false);
          setIsPaymentClose(false);
          form.resetFields();
          print({ ...res.data, printBillType: "settlement" });
          props.setOrderDetail({
            order: {},
            detail: [],
          });
        } else {
          owntoast(res.message, "error");
          setIsOpenWarning(false);
          setIsPaymentClose(false);
          setIsOpen(false);
        }
      });
  };

  const onChange = ({ value, i }) => {
    let totalamount =
      ClosePaymentData[i].amount *
      Number(value.target.value === "" ? 0 : value.target.value);
    ClosePaymentData[i].totalamt = totalamount;
    ClosePaymentData[i].qty = Number(value.target.value);
    form.setFieldsValue({
      [`totalamt${i}`]: totalamount,
    });
    setTotalAmount(totalamount);
  };

  const handleOpenOrder = () => {
    if (check(null, NLicenceType.kitchen)) {
      window.open(
        `${window.location.origin}/orderwindow`,
        "_blank",
        "noreferrer"
      );
    }
  };
  const handleOpenLink = () => {
    window.open("https://www.youtube.com/watch?v=RjalBZoIBrQ&t=3s", "_blank");
  };

  return (
    <Hotkeys keyName="f6" onKeyDown={onKeyDown}>
      <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-8 2xl:col-span-8 bg-white py-2 px-1 rounded-md flex flex-col h-full w-full">
        <div className="flex  justify-between px-2 flex-col xl:flex-row 2xl:flex-row">
          <div className="col-span-1 ">
            <span className="text-grey">Бүгд -</span>
            <span className="text-black font-semibold ml-1">
              {searchData().length}
            </span>
          </div>
          <div className="grid grid-cols-2 xl:grid-cols-5 2xl:grid-cols-5 gap-1 mt-1">
            <div className="justify-end flex">
              <Button onClick={handleOpenLink}>
                <YoutubeOutlined />
              </Button>
            </div>

            <Button
              className="text-center"
              type="dashed"
              icon={<DesktopOutlined />}
              onClick={handleOpenOrder}
            >
              Заалны дэлгэц нээх
            </Button>
            {/* <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2"> */}
            <Button
              type="primary"
              className="text-center bg-primary"
              onClick={handleClosePayment}
            >
              Тооцоо хаах
            </Button>
            <Button onClick={handleOpen}>Хямдрал (F6)</Button>
            <Button
              onClick={props.onOpen}
              icon={
                <img
                  src={Chair}
                  alt=""
                  className="mr-1 w-6 h-6 object-contain"
                />
              }
            >
              Ширээ
            </Button>
          </div>
        </div>
        <div className="w-full px-2 mt-2">
          <Search
            placeholder="хайх"
            className="searchInput w-full"
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <div className="mt-3 relative h-full">
          <div className="overflow-y-auto absolute top-0 bottom-0 w-full">
            <div className="px-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
              {searchData().map((item, i) => (
                <Components.Card key={i} i={i} item={item} {...props} />
              ))}
            </div>
          </div>
          <Spin
            indicator={<img src={loader} alt="" className="w-16 h-10" />}
            spinning={isfilteredproducts ? true : false}
            className="flex items-center justify-center h-full"
          />
        </div>
        {isOpen ? (
          <Components.CustomModal
            width={isPaymentClose ? 500 : 600}
            isOpen={isOpen}
            onClose={closeModal}
            title={isPaymentClose ? "Тооцоо хаах" : "Хямдрал оруулах"}
            footer={null}
          >
            {isPaymentClose ? (
              <div className="p-3">
                <p>{`Касс : ${props._auth.username}`}</p>
                <p>{`Огноо : ${newDate}`}</p>
                <Form layout="inline" onFinish={handleOpenModal} form={form}>
                  <Form.Item
                    name="ischeck"
                    className="border border-primary rounded-md w-full p-3"
                    rules={[{ required: true, message: "Төрөл сонгоно уу." }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(e) => setischeck(e.target.value)}
                    >
                      <Radio value={true}>
                        Тооцоо хийгээгүй захиалгыг устгах /Бүх кассчины/
                      </Radio>
                      <Radio value={false}>
                        Зөвхөн өөрийн захиалгын тооцоог хаах
                      </Radio>
                    </Radio.Group>
                    {/* <Checkbox>Сүүлийн хаалт</Checkbox> */}
                  </Form.Item>
                  <Components.AntdTable
                    columns={PaymentCloseColumn(onChange)}
                    dataSource={ClosePaymentData}
                    pagination={false}
                    clearSymbol={true}
                    isModal={true}
                  />
                  <div className="flex w-full gap-x-2 justify-end mt-2">
                    <Button loading={loading} onClick={closeModal}>
                      Буцах
                    </Button>
                    <Button
                      type="primary"
                      className="bg-primary"
                      htmlType="submit"
                      loading={loading}
                      id="settlement-button"
                    >
                      Хаалт хийх
                    </Button>
                  </div>
                </Form>
              </div>
            ) : (
              <div className="p-3">
                <Form layout="inline" onFinish={handleDiscount} form={form}>
                  <Components.InputNumberForm
                    name="discount"
                    required={true}
                    placeholder="Хямдрал оруулах..."
                    length={7}
                    className="h-full"
                    autoFocus={true}
                    onChange={handleChangeDiscount}
                    max={props.activeProduct && props.activeProduct.price}
                    errMsg="Хямдрал оруулна уу."
                  />
                  <Form.Item name="discountPercent">
                    <InputNumber
                      placeholder="Хямдрал %"
                      className="w-full"
                      disabled
                      {...PRICE_PARSERS}
                    />
                  </Form.Item>
                  <Table
                    className="w-full"
                    columns={DiscountTableColumn}
                    dataSource={[props.activeProduct]}
                    pagination={false}
                  />

                  <div className="flex w-full gap-x-2 justify-end mt-2">
                    <Button onClick={closeModal}>Буцах</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="bg-primary"
                    >
                      Батлах
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Components.CustomModal>
        ) : null}
        <div className="hidden">
          <SaleBill
            ref={orderRef}
            type={ReceiptType.Хаалт}
            data={receiptData}
          />
        </div>
        <Components.CustomModal
          footer={[
            <Button loading={loading} onClick={() => setIsOpenWarning(false)}>
              Үгүй
            </Button>,
            <Button
              loading={loading}
              type="primary"
              className="bg-primary"
              onClick={handlePaymentClose}
              autoFocus
            >
              Тийм
            </Button>,
          ]}
          width={400}
          isOpen={isOpenWarning}
          onClose={() => setIsOpenWarning(false)}
          title="Анхааруулга"
        >
          <div className="py-3 px-10 flex flex-col items-center justify-center">
            <span className="text-center">
              {ischeck
                ? " Хаалт хийхэд тооцоо хийгээгүй захиалгууд устах болохыг анхаарна уу!"
                : "Тооцоо хийгдээгүй захиалгуудыг үлдээж тооцоо хаах болохыг анхаарна уу!"}
            </span>
            <span>Хаалт хийхдээ итгэлтэй байна уу?</span>
          </div>
        </Components.CustomModal>
        <Components.CustomModal
          footer={[
            <Button
              type="primary"
              autoFocus
              onClick={() => {
                handleClosePayment();
                setisopenwarning(false);
              }}
            >
              Хаалт хийх
            </Button>,
          ]}
          width={400}
          isOpen={isopenwarning}
          onClose={() => setisopenwarning(false)}
          title="Анхааруулга"
        >
          <div className="py-3 px-10 flex flex-col items-center justify-center">
            <span className="text-center">{sms}</span>
          </div>
        </Components.CustomModal>
      </div>
    </Hotkeys>
  );
}

export default OrderProducts;
