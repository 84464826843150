import { useState, useRef, useCallback, useEffect } from "react";
import { Modal, Button, Carousel, Input } from "antd";
import * as Components from "../";
import noimage from "../../assets/noimage.png";
import { API_URL, owntoast } from "../../utils";
import {
  LeftOutlined,
  RightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import UsefulFunctions from "../../utils/UsefulFunctions";

const SetFood = ({ foodData, isOpenSet, onCloseModal, addSaleList }) => {
  const [foodStep, setFoodStep] = useState(0);

  const swiperRef = useRef();
  const [, updateState] = useState();

  useEffect(() => {
    if (foodData && foodData.products && foodData.products[foodStep].subs) {
      if (foodData.products[foodStep].subs.length === 1) {
        let subProduct = foodData.products[foodStep];
        if (subProduct) {
          if (!subProduct.spices || subProduct.spices.length === 0) {
            foodData.products[foodStep].subs[0].buyqty =
              foodData.products[foodStep].qty;
            handleNextSet();
          }
        }
      }
    }
  }, [foodStep, foodData]);

  const forceUpdate = useCallback(() => updateState({}), []);
  const handleNextSet = () => {
    if (foodData && foodData.products) {
      const subProducts = foodData.products[foodStep].subs;
      const setProduct = foodData.products[foodStep];
      const sumQty = sumbuyqty(subProducts);
      if (subProducts && subProducts.length) {
        if (sumQty < setProduct.qty) {
          owntoast("Боломжит тоо ширхэг хүрээгүй байна.", "error");
          return;
        }
        if (foodData.products.length - 1 > foodStep) {
          setFoodStep((prev) => prev + 1);
          return;
        }

        let tmp = {
          productid: 0,
          productname: foodData.name,
          barcode: null,
          qty: 1,
          price: foodData.price,
          amount: foodData.price,
          discount: 0,
          unitdiscount: 0,
          totalamount: foodData.price,
          type: "plus",
          isset: 1,
          setid: foodData.id,
        };
        let setDetails = getSaleDetail(foodData.products);

        tmp.setdetails = setDetails ? [...setDetails] : [];
        addSaleList(tmp);
        closeModal();
      }
    }
  };

  const getSaleDetail = (data) => {
    if (data) {
      let tmp = [];
      data.map((product) => {
        if (product.subs && product.subs.length) {
          product.subs.map((sub) => {
            if (sub.buyqty > 0) {
              tmp.push(UsefulFunctions.getSetDetailItem(sub, null));
            } else {
              if (sub.spices && sub.spices) {
                sub.spices.map((spice) => {
                  if (spice.buyqty > 0) {
                    tmp.push(UsefulFunctions.getSetDetailItem(sub, spice));
                  }
                });
              }
            }
          });
        }
      });

      return tmp;
    }
    return [];
  };

  const handlePrev = () => {
    if (checkIsSet()) {
      if (foodStep > 0) {
        setFoodStep((prev) => prev - 1);
      } else closeModal();
    } else {
      closeModal();
    }
  };

  const handleSetCount = (type, item, sumQty, count) => {
    if (item) {
      if (type === "dec") {
        if (!item.buyqty) item.buyqty = 0;
        else item.buyqty -= 1;
      } else {
        if (sumQty >= count) {
          owntoast("Боломжит тоо хэмжээ хүрсэн байна.", "error");
          return;
        } else {
          if (!item.buyqty) item.buyqty = 1;
          else item.buyqty += 1;
        }
      }
      forceUpdate();
    }
  };

  const handleClickCard = ({ spiceid, name }) => {
    if (foodData) {
      foodData.spiceid = spiceid;
      foodData.spicename = name;
      let tmp = {
        productid: foodData.id,
        productname: foodData.name,
        barcode: foodData.barcode,
        qty: 1,
        price: foodData.sprice,
        amount: foodData.sprice,
        discount: 0,
        unitdiscount: 0,
        totalamount: foodData.sprice,
        type: "plus",
        isset: 0,
        spiceid: spiceid,
        spicename: name,
      };
      addSaleList(tmp);
      closeModal();
    }
  };

  const renderCard = ({
    img,
    name,
    qty,
    product,
    buyqty,
    sumQty,
    type,
    spiceid,
    spicename,
  }) => {
    if (type === "spice") {
      return (
        <div className="px-2 py-3  transition-all hover:bg-primaryOpa rounded-md">
          <Components.Ripple
            onClick={() => handleClickCard({ spiceid, name: `/${spicename}/` })}
          >
            <div className="flex flex-col ">
              <img
                src={`${API_URL}${img}`}
                alt="food"
                onError={({ currentTarget }) => {
                  currentTarget.src = noimage;
                }}
                className="object-contain h-[100px]"
              />
              <span className="w-full border rounded-xl mt-2 p-2 hover:bg-primary hover:text-white z-10 transition-all truncate-2-lines flex items-center justify-center">
                {name} <br /> {`/${spicename}/`}
              </span>
            </div>
          </Components.Ripple>
        </div>
      );
    }
    return (
      <div className="px-2 py-3 slide h-full">
        <div className="flex flex-col h-full">
          <img
            src={`${API_URL}${img}`}
            alt="food"
            onError={({ currentTarget }) => {
              currentTarget.src = noimage;
              //currentTarget.className = "object-cover h-[100px]";
            }}
            className="object-contain"
            style={{
              height: "140px",
              width: "200px",
            }}
          />
          <div className="w-full border rounded-xl mt-2 py-2 justify-between flex flex-col h-full">
            <div className="text-center w-full">
              {name} <br />
              {spicename && `/${spicename}/`}
            </div>
            <div className="flex gap-2 items-center justify-center relative">
              <Components.Ripple
                className="bg-primary w-6 h-6 flex items-center justify-center relative rounded-sm "
                onClick={() => handleSetCount("dec", product, sumQty, qty)}
              >
                <MinusOutlined className="text-xs flex items-center justify-center text-white" />
              </Components.Ripple>
              <Input
                className="w-10 h-10 flex items-center justify-center rounded-full font-bold text-center"
                value={buyqty ? buyqty : 0}
                onChange={(e) => {
                  if (product) {
                    if (e.target.value) {
                      if (!isNaN(+e.target.value)) {
                        if (Number(e.target.value) >= 0) {
                          if (Number(e.target.value) > qty) {
                            owntoast(
                              "Боломжит тоо хэмжээ хүрсэн байна.",
                              "error"
                            );
                            return;
                          }
                          if (Number(e.target.value) + sumQty - buyqty > qty) {
                            owntoast(
                              "Боломжит тоо хэмжээ хүрсэн байна.",
                              "error"
                            );
                            return;
                          }
                          if (sumQty > qty) {
                            owntoast(
                              "Боломжит тоо хэмжээ хүрсэн байна.",
                              "error"
                            );
                            return;
                          } else {
                            product.buyqty = Number(e.target.value);
                            forceUpdate();
                          }
                        }
                      }
                    } else {
                      product.buyqty = 0;
                      forceUpdate();
                    }
                  }
                }}
              />
              <Components.Ripple
                className="bg-primary w-6 h-6 flex items-center justify-center rounded-sm"
                onClick={() => handleSetCount("inc", product, sumQty, qty)}
              >
                <PlusOutlined className="text-xs flex items-center justify-center text-white" />
              </Components.Ripple>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSetSlide = () => {
    let tmp = [];
    if (foodData && foodData.products) {
      let cardData = foodData.products[foodStep].subs;
      if (cardData) {
        let sumQty = sumbuyqty(cardData);
        cardData.map((item, i) => {
          if (item.spices && item.spices.length) {
            item.spices.map((spice, j) => {
              tmp.push(
                renderCard({
                  img: item.imgpos,
                  name: `${item.name}`,
                  spicename: spice.name,
                  product: spice,
                  sumQty: sumQty,
                  qty: foodData.products[foodStep].qty,
                  buyqty: spice.buyqty,
                })
              );
            });
          } else {
            tmp.push(
              renderCard({
                img: item.imgpos,
                name: `${item.name}`,
                product: item,
                sumQty: sumQty,
                qty: foodData.products[foodStep].qty,
                buyqty: item.buyqty,
              })
            );
          }
        });
      }
    } else if (foodData.spices && foodData.spices.length) {
      foodData.spices.map((spice, i) => {
        tmp.push(
          renderCard({
            img: foodData.imgpos,
            name: `${foodData.name}`,
            spicename: spice.name,
            spiceid: spice.spicesid,
            product: foodData,
            sumQty: 0,
            qty: 0,
            buyqty: 0,
            type: "spice",
          })
        );
      });
    }
    return tmp;
  };

  const renderEmptyCard = (length) => {
    const diff = 4 - length;
    let tmp = [];
    for (let i = 0; i < diff; i++) {
      tmp.push(<div />);
    }
    return tmp;
  };

  const checkIsSet = () => {
    if (foodData && foodData.products) return true;
    else if (foodData && foodData.spices && foodData.spices.length > 0)
      return false;

    return false;
  };

  const renderSetFoodData = () => {
    if (foodData && foodData.products) {
      let sumQty = sumbuyqty(foodData.products[foodStep].subs);
      let carContent = renderSetSlide();
      return (
        <div className="py-3 px-5">
          <div className="flex">
            <span className="bg-primary text-white rounded-full flex w-6 h-6 justify-center items-center">
              {foodStep + 1}
            </span>
            <div className="flex flex-col">
              <span className="ml-2">
                Сонгох бүтээгдэхүүн :
                <span className="font-bold">
                  {foodData.products[foodStep].name}
                </span>
              </span>
              <span className="ml-2">
                Боломжит тоо :
                <span className="font-bold">
                  {foodData.products[foodStep].qty}
                </span>
              </span>
              <span className="ml-2">
                Сонгосон : <span className="font-bold">{sumQty}</span>
              </span>
            </div>
          </div>
          <div
            className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 overflow-y-auto"
            style={{ height: carContent.length > 4 && 420 }}
          >
            {carContent}
          </div>

          {/*  <div className="mx-5" style={{ width: 800 }}>
            <Carousel
              slidesToShow={4}
              infinite={true}
              ref={swiperRef}
              draggable={true}
              lazyLoad={true}
              arrows={true}
              nextArrow={<RightOutlined />}
              prevArrow={<LeftOutlined />}
              className="set-slider"
              dots={false}
              slidesToScroll={1}
              swipeToSlide={true}
            >
              {carContent}
              {renderEmptyCard(carContent.length)}
            </Carousel>
          </div> */}
        </div>
      );
    } else if (foodData && foodData.spices && foodData.spices.length > 0) {
      let carContent = renderSetSlide();
      return (
        <div className="py-3 px-5 mx-5" style={{ width: 800 }}>
          <Carousel
            slidesToShow={4}
            infinite={true}
            ref={swiperRef}
            lazyLoad={true}
            draggable={true}
            className="set-slider"
            slidesToScroll={1}
            arrows={true}
            nextArrow={<RightOutlined />}
            prevArrow={<LeftOutlined />}
            dots={false}
            swipeToSlide={true}
          >
            {carContent}
            {renderEmptyCard(carContent.length)}
          </Carousel>
        </div>
      );
    }
    return null;
  };

  const sumbuyqty = (data) => {
    if (data) {
      let sum = 0;
      data.map((item) => {
        if (item.spices && item.spices.length) {
          item.spices.map((spice) => {
            sum += spice.buyqty ? spice.buyqty : 0;
          });
        } else {
          sum += item.buyqty ? item.buyqty : 0;
        }
      });
      return sum;
    }
    return 0;
  };

  const closeModal = () => {
    onCloseModal();
    setFoodStep(0);
  };

  return (
    <Components.CustomModal
      width={"auto"}
      footer={[
        <Button onClick={handlePrev}>{checkIsSet() ? "Буцах" : "Хаах"}</Button>,
        checkIsSet() && (
          <Button
            type="primary"
            className="bg-primary"
            onClick={handleNextSet}
            autoFocus
          >
            Үргэлжлүүлэх
          </Button>
        ),
      ]}
      isOpen={isOpenSet}
      onClose={closeModal}
      title={foodData && foodData.name}
    >
      {renderSetFoodData()}
    </Components.CustomModal>
  );
};

export default SetFood;
