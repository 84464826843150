import React, { useEffect, useContext, useState } from "react";
import toast, { useToasterStore } from "react-hot-toast";
import { isNewYear, owntoast, SOCKET_URL } from "../../utils";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import * as Components from "../../components";
import { GlobalContext } from "../../context";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import * as Containers from "../";
import { Layout, Button, Spin } from "antd";
import loader from "../../assets/logos/loader.gif";
import io from "socket.io-client";
import packageJson from "../../../package.json";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import Snowfall from "react-snowfall";
import useMinuteListener from "../../hooks/useMinuteListener";
import { NLicenceType } from "../../utils/enums";

function PrivateRoute({ Page, ...props }) {
  if (props.islicense === false) {
    if (!props.islogin && !props._auth) {
      return <Navigate to={"/login"} />;
    }
    return <Page {...props} />;
  }
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Spin
        spinning={true}
        indicator={<img src={loader} alt="" className="w-16 h-10" />}
      />
    </div>
  );
}

function App() {
  const [wbConnection, setWbConnection] = useState(null);
  const { toasts } = useToasterStore();
  const navigate = useNavigate();
  const context = useContext(GlobalContext);
  const isOnline = useOnlineStatus();
  const { pathname } = window.location;
  const minute = useMinuteListener();

  useEffect(() => {
    function caching() {
      let version = localStorage.getItem("version");
      if (version != packageJson.version) {
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload(true);
        }
        localStorage.setItem("version", packageJson.version);
      }
    }
    caching();
  }, []);

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 8)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  window.onbeforeunload = () => {
    Object.keys(localStorage).map((key) => {
      if (key.includes("pagination") || key.includes("search")) {
        localStorage.removeItem(key);
      }
    });
    localStorage.setItem("editRows", JSON.stringify({}));
  };

  /* Интернэт холболт салах эсвэл холбогдох үед мсж харуулна */
  useEffect(() => {
    if (isOnline) owntoast("Интернэт холболт орлоо.", "success");
    else owntoast("Интернэт холболт саллаа.", "error");
  }, [isOnline]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      context.request({ url: `/user/touchaccess`, model: "touchaccess" });
    }

    // const fetchVersion = async () => {
    //   const data = await fetch(`${window?.location?.origin}/meta.json`);
    //   if (data?.status === 200) {
    //     const json = await data.json();
    //     if (json?.version !== packageJson?.version) {
    //       toast(
    //         (t) => (
    //           <span>
    //             Шинэчлэлт гарсан байна. Шинэчлэх үү ?
    //             <Button
    //               type="primary"
    //               className="bg-primary"
    //               onClick={() => {
    //                 toast.dismiss(t.id);
    //                 if ("caches" in window) {
    //                   caches.keys().then((names) => {
    //                     names.forEach((name) => {
    //                       caches.delete(name);
    //                     });
    //                   });
    //                 }
    //                 window?.location?.reload(true);
    //               }}
    //             >
    //               Шинэчлэх
    //             </Button>
    //           </span>
    //         ),
    //         {
    //           position: "bottom-right",
    //         }
    //       );
    //     }
    //   }
    // };

    // fetchVersion();
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      context?.reslicense?.detail?.find(
        (x) => Number(x) === NLicenceType.artlab
      )
    ) {
      if (context?.resartlab?.artdaytime === dayjs().format("HH:mm")) {
        context.setError({
          isOpen: true,
          message: "Таны артлаб-д гүйлгээ илгээх цаг болсон байна илгээх үү?",
          isArtlabModal: true,
        });
      }
    }
  }, [minute]);
  const sendArtlab = () => {
    context.request({ url: `/artlab/send`, method: "POST" }).then((res) => {
      if (res.success) {
        owntoast(res.message, "success");
        context.setArtlab({ isOpen: false });
      } else {
        owntoast(res.message, "error");
      }
    });
  };

  useEffect(() => {
    /* context.setProducts(FOODS); */
    if (context._auth && context.islogin) {
      const connection = io(SOCKET_URL);

      connection.on("connect", () => {
        connection.emit(
          "join",
          { name: context._auth.userid, room: context._auth.foodid },
          (error) => {
            console.log(error);
          }
        );
      });

      connection.on("disconnect", () => {
        console.log(connection.id); // undefined
      });

      setWbConnection(connection);
      context.request({ url: `/license/checklicense`, model: "license" });
      context.request({
        url: `/order/get/orderconfig`,
        model: "orderwindow",
      });
      if (!pathname.includes("orderwindow")) {
        context.request({ url: `/license/info`, model: "licenseinfo" });
        context.request({
          url: `/system/printkitchen/list/${context._auth.foodid}`,
          model: "kitchenprintlist",
        });

        context.request({ url: `/license/foodlist`, model: "licenselist" });
        context.request({
          url: `/license/user/voucherlist`,
          model: "voucherlist",
        });
        context.request({ url: `/system/usernotf`, model: "notifications" });
        context.request({
          url: `/user/getfoodinformation/${context._auth.foodid}`,
          model: "userconfig",
        });
        context.request({
          url: `/system/get/timelog`,
          model: "timelog",
          method: "POST",
          body: {
            name: "",
          },
        });
        context.request({
          url: `/user/waiter`,
          model: "waiters",
          method: "GET",
        });

        context.request({
          url: `/category/dintable/list`,
          model: "searchstables",
          method: "POST",
          body: { foodid: context._auth.foodid },
        });

        context.request({ url: `/system/classcodes`, model: "classcodes" });
        context.request({
          url: `/system/location/province`,
          model: "provinces",
        });
        context.request({
          url: `/main/check`,
          model: "checkService",
          isservice: true,
        });
        context.request({ url: ``, model: "manuallist", ismanual: true });
      }
    } else {
      if (wbConnection) {
        setWbConnection(null);
      }
    }

    return function cleanup() {
      if (wbConnection) {
        setWbConnection(null);
      }
    };
  }, [context._auth]);

  useEffect(() => {
    if (
      context.reslicense &&
      Object.keys(context.reslicense).length > 0 &&
      !pathname?.includes("orderwindow")
    ) {
      context.request({
        url: `/system/printer/get/${context._auth.foodid}`,
        model: "printerconfig",
      });

      context.request({
        url: `/system/dealerinfo`,
        method: "POST",
        body: { type: 1 },
        model: "dealerinfoippos",
      });
      context.request({
        url: `/system/dealerinfo`,
        method: "POST",
        body: { type: 3 },
        model: "dealerinfodatabank",
      });
      context.request({ url: `/dealer/mid`, model: "dealerinfomid" });
      context.request({ url: `/dealer/most`, model: "dealerinfomost" });
      context.request({ url: `/dealer/socialpay`, model: "dealersocialpay" });
      context.request({ url: `/dealer/qpay`, model: "dealerqpay" });
      context.request({ url: `/system/cashierinfo`, model: "cashierinfo" });
      context.request({ url: `/kiosk`, model: "kioskinfo" });
      context.request({ url: "/menu/config", model: "menuconfig" });
      context.request({ url: `/artlab/getconfig`, model: "artlab" });

      context.request({
        url: `/set/list/dropdown`,
        model: "setsearch",
        method: "POST",
      });
      context.request({
        url: `/unitandportion/unitlist`,
        method: "POST",
        model: "unitlist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/unitandportion/portionlist`,
        method: "POST",
        model: "portionlist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/material/list`,
        method: "POST",
        model: "materiallist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/product/unitlist`,
        method: "POST",
        model: "unitproductlist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/category/dintable/list`,
        method: "POST",
        model: "dintablelist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/category/list`,
        method: "POST",
        model: "categorylist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/material/foodcardlist`,
        method: "POST",
        model: "foodcardlist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/order/ordertype/list`,
        method: "POST",
        model: "ordertypelist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/spices/list`,
        method: "POST",
        model: "spiceslist",
        body: { foodid: context._auth.foodid },
      });
      context.request({
        url: `/category/materiallist`,
        method: "POST",
        model: "catmateriallist",
        body: { foodid: context._auth.foodid },
      });
    }
  }, [context.reslicense]);

  const isShow = () =>
    pathname.includes("login") || pathname.includes("register");

  const isOrderWindow = () => pathname.includes("orderwindow");
  return (
    <div>
      <Layout className="h-full justify-center items-center fixed w-full">
        {!isShow() && !isOrderWindow() ? (
          <Components.Header {...context} />
        ) : (
          <div className="bg-primary h-1 w-full fixed top-0" />
        )}
        <Layout.Content className="w-full" /* style={{ marginTop: 64 }} */>
          {isNewYear() ? <Snowfall /> : null}

          <Routes>
            <Route
              path="/"
              element={<PrivateRoute {...context} Page={Containers.Home} />}
            />
            <Route
              path="/sale"
              element={
                <PrivateRoute
                  {...context}
                  Page={Containers.Sale}
                  wbConnection={wbConnection}
                />
              }
            />
            <Route
              path="/orderwindow"
              element={
                <PrivateRoute
                  {...context}
                  Page={Containers.OrderWindow}
                  wbConnection={wbConnection}
                />
              }
            />
            <Route
              path="/management/:id/*"
              element={
                <PrivateRoute {...context} Page={Containers.Management} />
              }
            />
            <Route path="/login" element={<Containers.Login {...context} />} />
            <Route
              path="/register/*"
              element={<Containers.Register {...context} />}
            />
            <Route
              path="/registerMap"
              element={<Containers.RegisterMap {...context} />}
            />
            <Route
              path="/test"
              element={<Containers.TestPage {...context} />}
            />
            <Route
              path="/notification"
              element={
                <PrivateRoute {...context} Page={Containers.Notification} />
              }
            />
            <Route
              path="/contact"
              element={<PrivateRoute {...context} Page={Containers.Contact} />}
            />
            <Route
              path="/help"
              element={<PrivateRoute {...context} Page={Containers.Help} />}
            />
            <Route
              path="/license"
              element={<PrivateRoute {...context} Page={Containers.License} />}
            />
            <Route
              path="/licensepayment"
              element={
                <PrivateRoute {...context} Page={Containers.LicensePayment} />
              }
            />
            <Route
              path="/licensedemo"
              element={
                <PrivateRoute {...context} Page={Containers.LicenseDemo} />
              }
            />
            <Route
              path="/licenselist"
              element={
                <PrivateRoute {...context} Page={Containers.LicenseList} />
              }
            />
            <Route
              path="/kitchen"
              element={
                <PrivateRoute
                  {...context}
                  Page={Containers.Kitchen}
                  wbConnection={wbConnection}
                />
              }
            />
            <Route
              path="/addBranch/*"
              element={
                <PrivateRoute {...context} Page={Containers.AddBranch} />
              }
            />
          </Routes>
        </Layout.Content>
        {isShow() && !isOrderWindow() ? (
          <Components.Footer {...context} />
        ) : (
          <div
            className="bg-primary h-1 w-full bottom-0"
            style={{ zIndex: 9999 }}
          />
        )}
      </Layout>
      <Components.NotificationSlider {...context} />

      <Components.CustomModal
        footer={[
          /*    <Button onClick={() => context.setError({ isOpen: false })}>Үгүй</Button>, */
          <Button
            type="primary"
            className="bg-primary"
            autoFocus
            onClick={() => {
              if (context.isLicenseModal) {
                navigate("/license");
              } else if (context.isArtlabModal) {
                sendArtlab();
              }
              context.setError({ isOpen: false });
            }}
          >
            {context.isLicenseModal ? "Худалдаж авах" : "OK"}
          </Button>,
        ]}
        width={400}
        isOpen={context.isOpenError}
        onClose={() => context.setError({ isOpen: false })}
        title="Анхааруулга"
      >
        <div className="py-3 px-10 flex flex-col items-center justify-center">
          <span className="text-center">{context.errorMsg}</span>
        </div>
      </Components.CustomModal>
    </div>
  );
}

export default App;
