import { useEffect, useState } from "react";
import { Button } from "antd";
import ProductItem from "./ProductItem";
import CashierItem from "./CashierItem";
import BarChart from "./BarChart";
import UsefulFunctions from "../../utils/UsefulFunctions";
import { DashPerm, LicenseType } from "../../utils/enums";
import PieChart from "./PieChart";
import dayjs from "dayjs";
import pp from "../../assets/icons/PP.svg";

const TopCards = [
  {
    label: "Хамгийн өндөр борлуулалттай хоол",
    propvalue: "highAmount",
  },
  {
    label: "Хамгийн олон зарагдсан",
    propvalue: "highQty",
    suffix: "ш",
  },
  {
    label: "Хамгийн их хорогдолтой материал",
    propvalue: "highDep",
  },
  {
    label: "Хамгийн бага хорогдолтой материал",
    propvalue: "lowDep",
  },
];

const Tabs = [
  {
    value: 1,
    label: "Өнөөдөр",
  },
  {
    value: 2,
    label: "Сар",
  },
  {
    value: 3,
    label: "Жил",
  },
];

function SaleDashboard(props) {
  const [type, setType] = useState(1);
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (check()) {
      props.request({
        url: `/report/dashboard/fourfood/${type}`,
        model: "dashboardbest",
      });
      props.request({
        url: `/report/dashboard/cashier/${type}`,
        model: "dashboardcashier",
      });
      props.request({
        url: `/report/dashboard/waiter/${type}`,
        model: "dashboardwaiter",
      });
      props.request({
        url: `/report/dashboard/pie/${type}`,
        model: "dashboardpie",
      });
      props.request({
        url: `/report/dashboard/devicepie/${type}`,
        model: "dashboarddevicepie",
      });
      props.request({
        url: `/report/dashboard/food/${type}`,
        model: "dashobardfood",
      });
      props.request({
        url: `/report/dashboard/category/${type}`,
        model: "dashobardcategory",
      });
    }
    if (type === 1) {
      setDate(dayjs().format("YYYY-MM-DD"));
    } else if (type === 2) {
      setDate(dayjs().format("YYYY-MM"));
    } else if (type === 3) {
      setDate(dayjs().format("YYYY"));
    }
  }, [type]);

  const check = () => {
    return UsefulFunctions.checkPermission({
      pagePermission: DashPerm,
      reslicense: props.reslicense,
      permission: props._auth.permission,
      isMsg: true,
      setError: props.setError,
    });
  };
  return (
    <div className="grid grid-cols-4 gap-4 w-full h-full relative">
      {/* <button onClick={() => setOpen(true)}>fsafa</button> */}
      <div className="col-span-4 gap-2 flex">
        {Tabs.map((item, i) => (
          <Button
            className="w-20 flex items-center justify-center bg-primary"
            onClick={() => setType(item.value)}
            type={item.value === type ? "primary" : ""}
          >
            {item.label}
          </Button>
        ))}
      </div>
      {TopCards.map((item, i) => (
        <ProductItem
          loading={props.isdashboardbest}
          label={item.label}
          suffix={item.suffix}
          data={
            props.resdashboardbest ? props.resdashboardbest[item.propvalue] : {}
          }
        />
      ))}
      <div className="grid col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-4 xl:col-span-2 2xl:col-span-2 gap-5">
        <div className="bg-white rounded-lg p-5">
          <span className="font-bold text-lg">Борлуулалт /хоолоор/ {date}</span>
          <div className="mt-4 w-[99%]">
            <BarChart
              color="#3DD598"
              data={props.resdashobardfood ? props.resdashobardfood : []}
              x="productname"
              y="amount"
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-5">
          <span className="font-bold text-lg">
            Борлуулалт /ангиллаар/ {date}
          </span>
          <div className="mt-4 w-[99%]">
            <BarChart
              color="#0062FF"
              data={
                props.resdashobardcategory ? props.resdashobardcategory : []
              }
              x="name"
              y="amount"
            />
          </div>
        </div>
      </div>
      <div
        className={`col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-1 2xl:col-span-1 p-5 bg-white rounded-lg transition-all  flex flex-col`}
      >
        <div className=" h-1/2">
          <div className="flex justify-center">
            <span className="text-lg font-bold">{date}</span>
          </div>
          <div className="flex flex-col mt-2 justify-center">
            <span className="font-bold text-lg">Кассчинаар</span>
            <span className="text-grey">/хамгийн өндөр борлуулалттай/</span>
            {props.resdashboardcashier &&
              props.resdashboardcashier.map((item, i) => (
                <CashierItem key={i} data={item} />
              ))}
          </div>
          {/* <div className="text-blue text-center uppercase mt-5 cursor-pointer">Бүх хэрэглэгч</div> */}
        </div>
        <div className="h-1/2">
          <div className="flex justify-center">
            <span className="text-lg font-bold">{date}</span>
          </div>
          <div className="flex flex-col mt-2 justify-center">
            <span className="font-bold text-lg">Зөөгчөөр</span>
            <span className="text-grey">/хамгийн өндөр борлуулалттай/</span>
            {props.resdashboardwaiter &&
              props.resdashboardwaiter.map((item, i) => (
                <div key={i} className="mt-3 flex items-center justify-between">
                  <div className="flex">
                    <img src={pp} alt="" className="w-8" />
                    <div className="flex flex-col ml-2">
                      <span className="font-bold">{item && item.name}</span>
                      <span className="text-grey">
                        Тоо ширхэг: {item && item.count}
                      </span>
                    </div>
                  </div>
                  <span className="font-bold text-lg">
                    {Intl.NumberFormat().format(item.amount ? item.amount : 0)}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className={`col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-1 2xl:col-span-1 bg-white rounded-lg p-5 `}
      >
        <div className="flex justify-center">
          <span className="text-lg font-bold">{date}</span>
        </div>
        <div className="flex flex-col">
          <div>
            <PieChart
              data={props.resdashboardpie ? props.resdashboardpie : []}
            />
          </div>
          <div>
            <PieChart
              data={
                props.resdashboarddevicepie ? props.resdashboarddevicepie : []
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaleDashboard;
