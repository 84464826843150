import { useState, useCallback, useRef, useEffect } from "react";
import { Button, Form, Input } from "antd";
import QRCode from "react-qr-code";
import * as Components from "../../components";
import { PaymentTypes } from "../../data";
import Hotkeys from "react-hot-keys";
import { owntoast, LICENSE_MSG } from "../../utils";
import { LicenseType, PaymentType, IsEnable } from "../../utils/enums";
import UsefulFunctions from "../../utils/UsefulFunctions";
/* Захиалгийн нийт дүн болон төрлүүд */
function OrderPayment(props) {
  const [loading, setLoading] = useState(false);
  const [disabled, setdDisabled] = useState(false);
  const [payments, setPayments] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [billType, setBillType] = useState(1);
  const [paymentInv, setPaymentInv] = useState(null);
  const [qrText, setQr] = useState("");
  const [activePaymentType, setPaymentType] = useState(1);
  const [timer, setTimer] = useState("00:00");
  const Ref = useRef(null);

  useEffect(() => {
    if (timer === "00:01") {
      if (activePaymentType === PaymentType?.Qpay) {
        if (paymentInv) cancelQpay(paymentInv?.id);
      }
      setPaymentInv(null);
      setdDisabled(false);
      // setIsOpen(false);
    }
  }, [timer]);

  useEffect(() => {
    if (paymentInv) {
      const intervalId = setInterval(() => {
        if (activePaymentType === PaymentType.MinuPay) {
          let body = {
            invoiceNumber: paymentInv.entity,
          };
          props
            .request({
              url: `/mid/checkinvoice`,
              method: "POST",
              body: body,
              ispaymentservice: true,
            })
            .then((res) => {
              if (res.success) {
                owntoast(res.message, "success");
                addPaymentList(props?.modalForm?.getFieldValue("amount"));
                setPaymentInv(null);
                setdDisabled(false);
              }
            });
        } else if (activePaymentType === PaymentType.Most) {
          let body = {
            payeeCode: props.resdealerinfomost.payeecode,
            posNo: props.resdealerinfomost.posno,
            qrCode: [paymentInv.qrCode],
          };
          props
            .request({
              url: `/most/check`,
              method: "POST",
              body: body,
              ispaymentservice: true,
            })
            .then((res) => {
              if (res.success) {
                if (res.data) {
                  if (res.data.responseCode === 0) {
                    if (
                      res.data.qrList !== null &&
                      res.data.qrList.length > 0 &&
                      res.data.qrList[0] != null
                    ) {
                      if (res.data.qrList[0].responseCode === 0) {
                        owntoast(res.message, "success");
                        addPaymentList(
                          props?.modalForm?.getFieldValue("amount")
                        );
                        setPaymentInv(null);
                        setdDisabled(false);
                      }
                    }
                  }
                }
              }
            });
        } else if (activePaymentType === PaymentType.SocialPay) {
          props
            .request({
              url: `/socialpay/check`,
              method: "POST",
              body: paymentInv,
              ispaymentservice: true,
            })
            .then((res) => {
              if (res.success) {
                owntoast(res.message, "success");
                addPaymentList(props?.modalForm?.getFieldValue("amount"));
                setPaymentInv(null);
                setdDisabled(false);
              }
            });
        } else if (activePaymentType === PaymentType.Qpay) {
          props
            .request({
              url: `/qpay/check`,
              method: "POST",
              body: {
                check: {
                  invoice_id: paymentInv.id,
                  terminal_id: props.resdealerqpay.userterminal,
                },
              },
              ispaymentservice: true,
            })
            .then((res) => {
              if (res.success) {
                if (res.data && res.data.invoice_status === "PAID") {
                  owntoast(res.message, "success");
                  addPaymentList(props?.modalForm?.getFieldValue("amount"));
                  setPaymentInv(null);
                  setdDisabled(false);
                }
              }
            });
        }
      }, 5000);

      if (!disabled) clearInterval(intervalId);

      return () => clearInterval(intervalId);
    }
  }, [paymentInv, disabled]);

  useEffect(() => {
    let payedSum = getPayedSum();
    let value = sum - payedSum;
    props?.modalForm?.setFieldsValue({
      amount: new Intl.NumberFormat().format(value),
      wamount: new Intl.NumberFormat().format(value),
    });
  }, [payments]);

  useEffect(() => {
    if (props?.isOpen) {
      setPayments([]);
      setPaymentType(1);
      props?.modalForm?.setFieldsValue({
        amount: new Intl.NumberFormat().format(sum),
        wamount: new Intl.NumberFormat().format(sum),
      });
    }
  }, [props?.isOpen]);

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 480);
    return deadline;
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const getPaymentTypeName = () => {
    let res = Object.keys(PaymentType).find(
      (a) => PaymentType[a] === activePaymentType
    );
    return res;
  };

  const startTimer = useCallback((e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  }, []);

  const cancelQpay = (id) => {
    setLoading(true);
    props
      ?.request({
        url: `/qpay/cancel`,
        method: "POST",
        body: {
          invoiceid: id,
        },
        ispaymentservice: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.success) setdDisabled(false);
        else owntoast(res.message, "error");
      });
  };

  const getAmount = (type) => {
    let value = payments.reduce((a, v) => {
      if (type === PaymentType.Бэлнээр && v.type === type) {
        return (a = a + v.amount);
      } else if (
        type === PaymentType.Картаар &&
        v.type !== PaymentType.Бэлнээр
      ) {
        return (a = a + v.amount);
      }
      return (a = a + 0);
    }, 0);
    if (value) return value;
    return 0;
  };

  const sum = UsefulFunctions.getSum({
    saleList: props?.data,
    fee: props?.resprinterconfig?.fee,
  });

  const getPayedSum = () => payments.reduce((a, v) => (a = a + v.amount), 0);

  const diffPrice = (isSale) => {
    let diff = getPayedSum() - sum;
    return isSale ? diff : diff < 0 ? 0 : diff;
  };

  const onKeyDown = (key) => {
    if (key === "f8") {
      setIsOwner(!isOwner);
      if (!isOwner) {
        props?.modalForm?.setFieldsValue({ billType: 1 });
        setBillType(1);
      }
    }
  };

  const checkPaymentType = () => {
    if (activePaymentType) {
      const res = PaymentTypes.find((x) => x.type === activePaymentType);
      if (res.hasQR) return true;
    }
    return false;
  };

  const addPaymentList = (amount) => {
    setPayments((prev) => {
      let prevPayment = prev.find((a) => a.type === activePaymentType);

      if (prevPayment) {
        prevPayment.amount += Number(amount);
        return [...prev];
      }
      return [...prev, { type: activePaymentType, amount: Number(amount) }];
    });
  };

  const addPayment = (values) => {
    if (diffPrice(true) >= 0) {
      if (props.order && props.order.id) {
        props?.sendOrder({
          values: {
            ...props?.modalForm?.getFieldsValue(),
            ...props?.form?.getFieldsValue?.(),
            changeprice: diffPrice(),
            userAmount: getPayedSum(),
            payments: payments,
            isOwner: isOwner,
            details: props?.data,
          },
          order: props?.order,
          isSale: true,
          isOrder: true,
        });
      } else {
        props?.sendOrder({
          values: {
            ...props?.modalForm?.getFieldsValue(),
            ...props?.form?.getFieldsValue?.(),
            changeprice: diffPrice(),
            userAmount: getPayedSum(),
            details: props?.data,
            payments: payments,
            isOwner: isOwner,
          },
          isSale: true,
          isOrder: true,
        });
      }
    } else {
      if (activePaymentType === PaymentType.Хаанбанк) {
        if (
          props.resdealerinfodatabank &&
          props.resdealerinfodatabank.isenable === IsEnable.Идэвхтэй
        ) {
          setLoading(true);
          props
            .request({
              url: `/databank/sale`,
              body: { amount: values.amount },
              method: "POST",
              isservice: true,
            })
            .then((res) => {
              setLoading(false);
              if (res.success) {
                owntoast(res.message, "success");
                addPaymentList(values.amount);
              } else props.setError({ isOpen: true, message: res.message });
            });
        } else
          props.setError({
            isOpen: true,
            message: "Уучлаарай IPPOS холбох тохиргоо хийгээгүй байна.",
          });
      } else if (activePaymentType === PaymentType.Голомтоор) {
        if (
          props.resdealerinfoippos &&
          props.resdealerinfoippos.isenable === IsEnable.Идэвхтэй
        ) {
          if (props.resdealerinfoippos.terminalid) {
            setLoading(true);
            props
              .request({
                url: `/golomt/sale`,
                body: {
                  amount: values.amount,
                  terminalid: props.resdealerinfoippos.terminalid,
                  entryMode: 0,
                },
                method: "POST",
                isservice: true,
              })
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  owntoast(res.message, "success");
                  addPaymentList(values.amount);
                } else props.setError({ isOpen: true, message: res.message });
              });
          } else
            props.setError({
              isOpen: true,
              message: "Уучлаарай TERMINAL ID олдсонгүй.",
            });
        } else
          props.setError({
            isOpen: true,
            message: "Уучлаарай IPPOS холбох тохиргоо хийгээгүй байна.",
          });
      } else if (activePaymentType === PaymentType.MinuPay) {
        if (
          props.resdealerinfomid &&
          props.resdealerinfomid.isenable === IsEnable.Идэвхтэй
        ) {
          if (props.resdealerinfomid.merchantid) {
            if (props.resdealerinfomid.terminalid) {
              if (disabled) {
                setdDisabled(false);
              } else {
                setLoading(true);
                let mdl = {
                  txnDesc: "Оньс фүүд худалдан авалт",
                  amount: Number(values.amount),
                  merchantNo: props.resdealerinfomid.merchantid,
                  terminalNo: props.resdealerinfomid.terminalid,
                };
                props
                  .request({
                    url: `/mid/invoice`,
                    method: "POST",
                    body: mdl,
                    ispaymentservice: true,
                  })
                  .then((res) => {
                    if (res.success) {
                      setPaymentInv(res.data);
                      setQr(res.data.entity);
                      setLoading(false);
                      setdDisabled(true);
                      clearTimer(getDeadTime());
                    } else {
                      owntoast(res.message, "error");
                    }
                  });
              }
            } else
              props.setError({
                isOpen: true,
                message: "Уучлаарай TERMINAL ID олдсонгүй.",
              });
          } else
            props.setError({
              isOpen: true,
              message: "Уучлаарай MERCHANT ID олдсонгүй.",
            });
        } else
          props.setError({
            isOpen: true,
            message: "Уучлаарай Minu Pos холбох тохиргоо хийгээгүй байна.",
          });
      } else if (activePaymentType === PaymentType.Most) {
        if (
          props.resdealerinfomost &&
          props.resdealerinfomost.isenable === IsEnable.Идэвхтэй
        ) {
          if (props.resdealerinfomost.payeecode) {
            if (props.resdealerinfomost.posno) {
              if (disabled) {
                setdDisabled(false);
              } else {
                setLoading(true);
                let mdl = {
                  payeeCode: props.resdealerinfomost.payeecode,
                  posNo: props.resdealerinfomost.posno,
                  tranAmnt: Number(values.amount),
                  tranDesc: "Оньс фүүд худалдан авалт",
                  invoiceId: props._auth.regno,
                };
                props
                  .request({
                    url: `/most/invoice`,
                    method: "POST",
                    body: mdl,
                    ispaymentservice: true,
                  })
                  .then((res) => {
                    setLoading(false);
                    if (res.success) {
                      if (res.data) {
                        if (res.data.responseCode === 0) {
                          setQr(res.data.qrCode);
                          setPaymentInv(res.data);
                          setdDisabled(true);
                          clearTimer(getDeadTime());
                        } else {
                          owntoast(res.data.responseDesc, "error");
                        }
                      }
                    } else {
                      owntoast(res.message, "error");
                    }
                  });
              }
            } else
              props.setError({
                isOpen: true,
                message: "Уучлаарай POS ID олдсонгүй.",
              });
          } else
            props.setError({
              isOpen: true,
              message: "Уучлаарай MERCHANT ID олдсонгүй.",
            });
        } else
          props.setError({
            isOpen: true,
            message: "Уучлаарай Most Money холбох тохиргоо хийгээгүй байна.",
          });
      } else if (activePaymentType === PaymentType.SocialPay) {
        if (props.resdealersocialpay) {
          if (
            props.resdealersocialpay.key &&
            props.resdealersocialpay.terminalid
          ) {
            let mdl = {
              amount: values.amount.toString(),
              terminal: props.resdealersocialpay.terminalid,
              key: props.resdealersocialpay.key,
              invoice: props._auth.regno,
            };
            if (disabled) {
              setdDisabled(false);
            } else {
              setLoading(true);
              props
                .request({
                  url: `/socialpay/createqr`,
                  method: "POST",
                  body: mdl,
                  ispaymentservice: true,
                })
                .then((res) => {
                  setLoading(false);
                  if (res.success) {
                    if (res.data) {
                      setQr(res.data.qr);
                      setPaymentInv(res.data);
                      setdDisabled(true);
                      clearTimer(getDeadTime());
                    }
                  } else {
                    owntoast(res.message, "error");
                  }
                });
            }
          } else {
            props.setError({
              isOpen: true,
              message: "Уучлаарай SocialPay холбох тохиргоо хийгээгүй байна.",
            });
          }
        } else {
          props.setError({
            isOpen: true,
            message: "Уучлаарай SocialPay холбох тохиргоо хийгээгүй байна.",
          });
        }
      } else if (activePaymentType === PaymentType.Qpay) {
        if (props.resdealerqpay) {
          if (
            props.resdealerqpay.terminalid &&
            props.resdealerqpay.bankcode &&
            props.resdealerqpay.acccode
          ) {
            let body = {
              invoice: {
                amount: Number(values.amount),
                merchant_id: props.resdealerqpay.terminalid,
                customer_name: "",
                description: `${props._auth.foodsname} худалдан авалт`,
                mcc_code: "5812",
                terminal_id: props.resdealerqpay.userterminal,
                bank_accounts: [
                  {
                    is_default: true,
                    account_bank_code: props.resdealerqpay.bankcode,
                    account_number: props.resdealerqpay.acccode,
                    account_name: props.resdealerqpay.accname,
                  },
                ],
              },
            };
            if (disabled) {
              if (paymentInv) {
                cancelQpay(paymentInv?.id);
              } else setdDisabled(false);
            } else {
              setLoading(true);
              props
                .request({
                  url: `/qpay/create`,
                  method: "POST",
                  body: body,
                  ispaymentservice: true,
                })
                .then((res) => {
                  setLoading(false);
                  if (res.success) {
                    if (res.data) {
                      setQr(res.data.qr_code);
                      setPaymentInv(res.data);
                      setdDisabled(true);
                      clearTimer(getDeadTime());
                    }
                  } else {
                    owntoast(res.message, "error");
                  }
                });
            }
          } else {
            props.setError({
              isOpen: true,
              message: "Уучлаарай Qpay холбох тохиргоо хийгээгүй байна.",
            });
          }
        } else {
          props.setError({
            isOpen: true,
            message: "Уучлаарай Qpay холбох тохиргоо хийгээгүй байна.",
          });
        }
      } else addPaymentList(values.amount);
    }
  };

  const clearTimer = useCallback(
    (e) => {
      setTimer("00:00");
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1);
      Ref.current = id;
    },
    [startTimer]
  );

  return (
    <Components.CustomModal
      footer={null}
      isOpen={props?.isOpen}
      onClose={() => !disabled && props?.setIsOpen(false)}
      width={600}
      closable={!disabled}
      maskClosable={!disabled}
      title="Захиалгын тооцоо хийх"
    >
      <Hotkeys keyName="f8" onKeyDown={onKeyDown}>
        <div className="p-3 bg-fullBack">
          <div className="grid grid-cols-6 gap-3">
            <div className="col-span-2 bg-white rounded-md px-3 py-2">
              <span className="text-grey">Төлбөрийн төрлүүд</span>
              <div className="grid grid-rows-6 gap-2 mt-2">
                {PaymentTypes.map((item, i) =>
                  item.isActive ? (
                    <Components.Ripple
                      key={i}
                      className={` font-semibold ${
                        activePaymentType === item.type
                          ? "active-border"
                          : "border"
                      } rounded-xl px-3 py-3 flex items-center`}
                      disabled={disabled}
                      onClick={() => {
                        setPaymentType((prev) => {
                          if (item.license === undefined) {
                            return item.type;
                          } else if (!props.reslicense) {
                            owntoast(LICENSE_MSG, "error");
                            return prev;
                          } else if (
                            props.reslicense.type !== LicenseType.Main
                          ) {
                            owntoast(LICENSE_MSG, "error");
                            return prev;
                          }
                          return item.type;
                        });
                      }}
                    >
                      {item.icon ? (
                        <img
                          className="w-8 object-contain h-8 rounded-md"
                          src={item.icon}
                          alt={item.name}
                        />
                      ) : null}
                      <span className="ml-2">{item.name}</span>
                    </Components.Ripple>
                  ) : null
                )}
              </div>
            </div>
            <div className="col-span-4 bg-white rounded-md px-3 py-2 ">
              <span className="text-grey">Төлбөр төлөлт</span>
              {checkPaymentType() ? (
                <div className="mt-4 flex justify-center w-full text-center">
                  {disabled && paymentInv && qrText ? (
                    <div>
                      <QRCode value={qrText} size={180} />
                      <span className="text-xs text-grey">{timer}</span>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="bg-labelGrey grid grid-cols-2 gap-2 mt-2 p-2 uppercase">
                  <div className="py-2">Нийт төлөх дүн</div>
                  <div className="bg-white p-2 text-right font-bold">
                    {new Intl.NumberFormat().format(sum)}
                  </div>
                  {PaymentTypes.map((item, i) =>
                    item.isActive && item.isPayed ? (
                      <>
                        <div className="py-2">
                          {item.cname ? item.cname : item.name}
                        </div>
                        <div className="bg-white p-2 text-right">
                          {new Intl.NumberFormat().format(getAmount(item.type))}
                        </div>
                      </>
                    ) : null
                  )}
                  <div className="py-2">Нийт төлсөн</div>
                  <div className="bg-white p-2 text-right font-bold">
                    {new Intl.NumberFormat().format(getPayedSum())}
                  </div>
                  <div className="py-2">Хариулт</div>
                  <div className="bg-white p-2 text-right">
                    {new Intl.NumberFormat().format(diffPrice())}
                  </div>
                </div>
              )}
              <Form
                name="modalForm?"
                form={props?.modalForm}
                onFinish={addPayment}
              >
                {diffPrice(true) >= 0 ? (
                  props.reslicense &&
                  props.reslicense.type === LicenseType.Main ? (
                    <Components.ChooseBilltype
                      {...props}
                      form={props?.modalForm}
                      billType={billType}
                      setBillType={setBillType}
                      isOwner={isOwner}
                    />
                  ) : null
                ) : (
                  <div className="bg-labelGrey mt-10 flex justify-between rounded active-border">
                    <span className="p-2">{getPaymentTypeName()}:</span>
                    <Components.InputNumberForm
                      name="amount"
                      noStyleq
                      required={true}
                      placeholder="Төлөх дүн"
                      max={
                        activePaymentType !== 1
                          ? sum - getPayedSum()
                          : 999999999
                      }
                      className="h-full"
                      autoFocus={true}
                      errMsg="Дүн оруулна уу."
                      disabled={disabled}
                    />
                  </div>
                )}
                <Form.Item hidden name="ongoingtime">
                  <Components.InputNumberForm />
                </Form.Item>
                <Form.Item hidden name="timelogid">
                  <Components.InputNumberForm />
                </Form.Item>
                <Form.Item hidden name="timesdate">
                  <Input />
                </Form.Item>
                <Form.Item hidden name="timeedate">
                  <Input />
                </Form.Item>
                <Form.Item hidden name="hour">
                  <Input />
                </Form.Item>
                <div className="w-full mt-5">
                  <Button
                    type=""
                    htmlType="submit"
                    className={`w-42 float-right flex justify-center text-white ${
                      diffPrice(true) >= 0 ? "bg-primary" : "bg-modalHeader"
                    }`}
                    loading={loading || props?.loading}
                  >
                    {diffPrice(true) >= 0
                      ? `Төлбөр дуусгах ${
                          isOwner ? " /Дотоодын хэрэгцээнд/" : ""
                        }`
                      : disabled
                      ? "Дүн засах"
                      : checkPaymentType()
                      ? "QR гаргах"
                      : "Төлбөр оруулах"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Hotkeys>
    </Components.CustomModal>
  );
}

export default OrderPayment;
